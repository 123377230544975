import { Language } from 'Sombra/api';

import translations from './translations';

export const AvailableLanguagesLabel: Record<Language, string> = {
  en: 'English',
  fr: 'Français',
};

export type StringKey = keyof typeof translations;
const defaultLanguage = Language.En;

const currentLang: { lang: Language } = {
  lang: defaultLanguage,
};

export const AppLanguage = {
  set: (lang: Language): void => {
    currentLang.lang = lang;
  },
  get: (): Language => currentLang.lang,
};

Object.freeze(AppLanguage);

const w = (key: StringKey): string => {
  const strings = translations[key];
  if (!strings) return key;
  const language = AppLanguage.get();
  return strings[language as keyof typeof strings] || strings[defaultLanguage];
};

export const DayOfWeekMap = {
  sunday: 0,
  monday: 1,
  tuesday: 2,
  wednesday: 3,
  thursday: 4,
  friday: 5,
  saturday: 6,
};

const NumberInputRegex = /\d*(\.\d*)?$/;

export const IsValidNumberInput = (input: string): boolean =>
  !isNaN(Number(input)) && NumberInputRegex.test(input);

export default w;
