import { Spin } from 'antd';
import React, { useEffect, useState } from 'react';

import { detectLanguage, LL, setLanguage } from '../../../i18n';

export const LanguageProvider: React.FC<React.PropsWithChildren> = (props) => {
  const [languageLoaded, setLanguageLoaded] = useState(false);

  useEffect(() => {
    async function initLanguage() {
      if (!LL) {
        const detectedLanguage = detectLanguage();
        await setLanguage(detectedLanguage);
      }

      setLanguageLoaded(true);
    }

    initLanguage();
  }, []);

  if (!languageLoaded || !LL) {
    return (
      <div
        className={'flex h-full w-full flex-col items-center justify-center'}
      >
        <Spin size={'large'} />
      </div>
    );
  }

  return <>{props.children}</>;
};
