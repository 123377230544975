/* eslint-disable */
export default {
  "dash_validate_tab": {
    "en": "Construction logs to validate",
    "fr": "Rapports de chantier à valider"
  },
  "status_action_0": {
    "en": "To Schedule",
    "fr": "À céduler"
  },
  "status_action_1": {
    "en": "To Fill",
    "fr": "À remplir"
  },
  "status_action_2": {
    "en": "To Review",
    "fr": "À réviser"
  },
  "status_action_3": {
    "en": "To Validate",
    "fr": "À valider"
  },
  "status_action_default": {
    "en": "Validated",
    "fr": "Validé"
  },
  "resources_organizations_tab": {
    "en": "Organizations",
    "fr": "Organisations"
  },
  "resources_employees_tab": {
    "en": "Employees",
    "fr": "Employés"
  },
  "resources_equipments_tab": {
    "en": "Equipments",
    "fr": "Équipements"
  },
  "resources_materials_tab": {
    "en": "Materials",
    "fr": "Matériaux"
  },
  "timeline_title": {
    "en": "Timeline",
    "fr": "Calendrier"
  },
  "timeline_dispatch_tab": {
    "en": "Dispatch",
    "fr": "Déploiement"
  },
  "timeline_team_tab": {
    "en": "Teams",
    "fr": "Équipes"
  },
  "timeline_teamLeader_override": {
    "en": "The team leader for that day has been changed to:",
    "fr": "Le chef d'équipe pour cette journée a été changé pour:"
  },
  "search_label": {
    "en": "Search",
    "fr": "Rechercher"
  },
  "timeline_searchbar_placeholder": {
    "en": "Search any team, project, department or employee",
    "fr": "Rechercher une équipe, un projet ou un employé"
  },
  "timeline_activities": {
    "en": "Scheduled Activities",
    "fr": "Activités cédulées"
  },
  "timeline_add_activities_tooltip_part_1_plurial": {
    "en": "activities have been assigned to a team out of the",
    "fr": "activités ont été affectées à une équipe sur les"
  },
  "timeline_add_activities_tooltip_part_2_plurial": {
    "en": "that was scheduled for this day",
    "fr": "qui étaient cédulées pour cette journée"
  },
  "timeline_add_activities_tooltip_part_1_singular": {
    "en": "activity has been assigned to a team out of the",
    "fr": "activité a été affectée à une équipe sur les"
  },
  "timeline_add_activities_tooltip_part_2_singular": {
    "en": "that was scheduled for this day",
    "fr": "qui était cédulée pour cette journée"
  },
  "timeline_status_filter_label": {
    "en": "Status Filter",
    "fr": "Filtrer l'état"
  },
  "date_button_back": {
    "en": "Back",
    "fr": "Précédent"
  },
  "date_button_today": {
    "en": "Today",
    "fr": "Aujourd'hui"
  },
  "date_button_next": {
    "en": "Next",
    "fr": "Suivant"
  },
  "timeline_update_dailylog_title_desc_title": {
    "en": "Next",
    "fr": "Modifier le titre et la description du rapport journalier"
  },
  "timeline_add_dailylog_button": {
    "en": "Add daily log",
    "fr": "Ajouter un rapport"
  },
  "timeline_move_dailylog_title": {
    "en": "Move Daily Log",
    "fr": "Déplacer le rapport journalier"
  },
  "timeline_copy_dailylog_title": {
    "en": "Copy Daily Log",
    "fr": "Copier le rapport journalier"
  },
  "timeline_copy_dailylog_include_title": {
    "en": "Include Title",
    "fr": "Inclure le titre"
  },
  "timeline_copy_dailylog_include_description": {
    "en": "Include Description",
    "fr": "Inclure la description"
  },
  "timeline_add_dailylog_title": {
    "en": "Add a Daily Log",
    "fr": "Rapport Journalier"
  },
  "timeline_delete_dailylog_title": {
    "en": "Delete the Daily Log",
    "fr": "Supprimer le rapport journalier"
  },
  "timeline_delete_dailylog_text": {
    "en": "You are about to delete this daily log. Are you sure you want to continue?",
    "fr": "Vous êtes sur le point de supprimer le rapport journalier. Êtes-vous sûr de vouloir continuer?"
  },
  "button_save": {
    "en": "Save",
    "fr": "Sauvegarder"
  },
  "button_add": {
    "en": "Add",
    "fr": "Ajouter"
  },
  "button_delete": {
    "en": "Delete",
    "fr": "Supprimer"
  },
  "dailylog_button_show_checkbox": {
    "en": "Pensions",
    "fr": "Primes"
  },
  "dailylog_button_show_schedule_blocks": {
    "en": "Schedule",
    "fr": "Cédule"
  },
  "dailylog_label_project": {
    "en": "Project",
    "fr": "Projet"
  },
  "dailylog_label_activity": {
    "en": "Activity",
    "fr": "Activité"
  },
  "dailylog_button_add_role": {
    "en": "Add Employee Placeholders",
    "fr": "Ajouter Employé provisoire"
  },
  "dailylog_label_role": {
    "en": "Employee placeholder",
    "fr": "Employé provisoire"
  },
  "dailylog_button_add_type": {
    "en": "Add Equipment Placeholders",
    "fr": "Ajouter Équipement provisoire"
  },
  "dailylog_label_type": {
    "en": "Equipment placeholder",
    "fr": "Équipement provisoire"
  },
  "dailylog_button_add_equipment": {
    "en": "Add Equipment",
    "fr": "Ajouter Équipement"
  },
  "dailylog_label_equipment": {
    "en": "Equipment",
    "fr": "Équipement"
  },
  "dailylog_button_add_employee": {
    "en": "Add Employee",
    "fr": "Ajouter Employé"
  },
  "dailylog_dialog_project_remove_message": {
    "en": "will be deleted when this project is removed",
    "fr": "seront supprimés lorsque ce projet est enlevé."
  },
  "dailylog_dialog_activity_remove_message": {
    "en": "will be deleted when this activity is removed",
    "fr": "seront supprimés lorsque cette activité est enlevé."
  },
  "dailylog_label_employee": {
    "en": "Employee",
    "fr": "Employé"
  },
  "dailylog_button_add_material": {
    "en": "Add Material",
    "fr": "Ajouter matériel"
  },
  "dailylog_label_material": {
    "en": "Material",
    "fr": "Matériau"
  },
  "dailylog_resultat_employee": {
    "en": "Employees",
    "fr": "Employés"
  },
  "dailylog_resultat_rental": {
    "en": "Employees and Equipment",
    "fr": "Employés et Équipements"
  },
  "dailylog_button_add_activity": {
    "en": "Add Activities",
    "fr": "Ajouter Activité"
  },
  "dailylog_resultat_activity": {
    "en": "Activities",
    "fr": "Activités"
  },
  "dailylog_search_employee": {
    "en": "Find Employee",
    "fr": "Rechercher Employé"
  },
  "dailylog_search_activity": {
    "en": "Find Activity",
    "fr": "Rechercher Activité"
  },
  "dailylog_search_rental": {
    "en": "Find Employee or Equipment",
    "fr": "Rechercher Employé ou Équipement"
  },
  "dailylog_addpopup_activity_select": {
    "en": "Choose Project",
    "fr": "Choisir Projet"
  },
  "dailylog_addpopup_activity_select_placeholder": {
    "en": "Write project name or code",
    "fr": "Écrire le nom ou le code d'un projet"
  },
  "dailylog_addpopup_activity_confirm_button": {
    "en": "ADD ACTIVITIES",
    "fr": "AJOUTER ACTIVITÉS"
  },
  "dailylog_addpopup_organization_role_select": {
    "en": "Choose Organization",
    "fr": "Choisir Organisation"
  },
  "dailylog_addpopup_organization_role_select_placeholder": {
    "en": "Write organization name",
    "fr": "Écrire le nom de l'organisation"
  },
  "dailylog_addpopup_role_confirm_button": {
    "en": "ADD ROLES",
    "fr": "AJOUTER RÔLES"
  },
  "dailylog_addpopup_role_search": {
    "en": "Find role",
    "fr": "Rechercher rôle"
  },
  "dailylog_addpopup_role_select_placeholder": {
    "en": "Write role name",
    "fr": "Écrire le nom du rôle"
  },
  "dailylog_addpopup_type_select_placeholder": {
    "en": "Write type name",
    "fr": "Écrire le nom du type"
  },
  "dailylog_addpopup_employee_select": {
    "en": "Choose Employee",
    "fr": "Choisir Employé"
  },
  "dailylog_addpopup_employee_select_placeholder": {
    "en": "Write employee name",
    "fr": "Écrire le nom de l'employé"
  },
  "dailylog_addpopup_employee_title": {
    "en": "Add Employee",
    "fr": "Ajouter Employé"
  },
  "dailylog_addpopup_activity_title": {
    "en": "Add Activity",
    "fr": "Ajouter Activité"
  },
  "dailylog_addpopup_team_title": {
    "en": "Add Team",
    "fr": "Ajouter Équipe"
  },
  "dailylog_addpopup_role_title": {
    "en": "Add Role",
    "fr": "Ajouter Role"
  },
  "dailylog_addpopup_type_title": {
    "en": "Add Type",
    "fr": "Ajouter Type"
  },
  "dailylog_addpopup_organization_select": {
    "en": "Choose Organization",
    "fr": "Choisir Organisation"
  },
  "dailylog_addpopup_organization_select_placeholder": {
    "en": "Write organization name",
    "fr": "Écrire le nom de l'organisation"
  },
  "dailylog_addpopup_activity_search_placeholder": {
    "en": "Write activity name",
    "fr": "Écrire le nom de l'activité"
  },
  "resource_description_employee_title": {
    "en": "Employees",
    "fr": "Employés"
  },
  "resource_description_expander": {
    "en": "Expanded",
    "fr": "Étendu"
  },
  "dailylog_rentals_resource_title": {
    "en": "Rentals & Subcontractor",
    "fr": "Location et sous-traitant"
  },
  "dailylog_equipments_resource_title": {
    "en": "Equipments",
    "fr": "Équipements"
  },
  "dailylog_activities_resource_title": {
    "en": "Activities",
    "fr": "Activités"
  },
  "dailylog_employees_resource_title": {
    "en": "Employees",
    "fr": "Employés"
  },
  "action_remove_item": {
    "en": "Delete",
    "fr": "Supprimer"
  },
  "action_open_item": {
    "en": "Open",
    "fr": "Ouvrir"
  },
  "action_edit_item": {
    "en": "Edit",
    "fr": "Modifier"
  },
  "action_restore_item": {
    "en": "Restore",
    "fr": "Restaurer"
  },
  "dailylog_search_equipments": {
    "en": "Find Heavy Equipment",
    "fr": "Rechercher Équipements lourds"
  },
  "dailylog_resultat_equipments": {
    "en": "Heavy Equipment",
    "fr": "Équipements lourds"
  },
  "dailylog_addpopup_equipments_select": {
    "en": "Choose Heavy Equipment",
    "fr": "Choisir équipements lourds"
  },
  "dailylog_addpopup_equipments_select_placeholder": {
    "en": "Write equipment name",
    "fr": "Écrire le nom de l'équipement"
  },
  "dailylog_button_add_equipments": {
    "en": "Add Equipment",
    "fr": "Ajouter Équipement"
  },
  "dailylog_addpopup_equipments_title": {
    "en": "Add Equipment",
    "fr": "Ajouter Équipement"
  },
  "dailylog_header_category_title_activities": {
    "en": "Activities",
    "fr": "Activités"
  },
  "dailylog_header_category_title_employees": {
    "en": "Employees",
    "fr": "Employées"
  },
  "dailylog_header_category_title_inventory": {
    "en": "Small Tools & Supplies",
    "fr": "Petits Outils & Fournitures"
  },
  "dailylog_header_category_title_equipments": {
    "en": "Heavy Equipment",
    "fr": "Équipements Lourds"
  },
  "dailylog_header_category_title_materials": {
    "en": "Materials",
    "fr": "Matériaux"
  },
  "sidebar_schedule": {
    "en": "Schedule",
    "fr": "Planification"
  },
  "sidebar_schedule_v1": {
    "en": "Schedule",
    "fr": "Planification"
  },
  "sidebar_timeline": {
    "en": "Timeline",
    "fr": "Calendrier"
  },
  "sidebar_dashboard": {
    "en": "Dashboard",
    "fr": "Tableau de bord"
  },
  "sidebar_map": {
    "en": "Map",
    "fr": "Carte"
  },
  "sidebar_documents": {
    "en": "Documents",
    "fr": "Documents"
  },
  "sidebar_files": {
    "en": "Documents",
    "fr": "Documents"
  },
  "sidebar_forms": {
    "en": "Forms",
    "fr": "Formulaires"
  },
  "sidebar_form_list": {
    "en": "Form list",
    "fr": "Liste des formulaires"
  },
  "sidebar_form_instances": {
    "en": "Form Instances",
    "fr": "Instances de formulaire"
  },
  "sidebar_form": {
    "en": "Form",
    "fr": "Formulaire"
  },
  "sidebar_projects": {
    "en": "Project List",
    "fr": "Liste des projets"
  },
  "sidebar_projectGroupV2": {
    "en": "ProjectsV2",
    "fr": "ProjetsV2"
  },
  "sidebar_projectGroup": {
    "en": "Projects",
    "fr": "Projets"
  },
  "sidebar_report": {
    "en": "Analytics",
    "fr": "Analyse des données"
  },
  "sidebar_resources": {
    "en": "Resources",
    "fr": "Ressources"
  },
  "sidebar_units": {
    "en": "Units",
    "fr": "Unités"
  },
  "sidebar_teams": {
    "en": "Teams",
    "fr": "Équipes"
  },
  "sidebar_activities": {
    "en": "Activities",
    "fr": "Activités"
  },
  "sidebar_cbs": {
    "en": "Cost Structure",
    "fr": "Structure des coûts"
  },
  "sidebar_products": {
    "en": "Products",
    "fr": "Produits"
  },
  "sidebar_administration": {
    "en": "Administration",
    "fr": "Administration"
  },
  "sidebar_admin": {
    "en": "Admin",
    "fr": "Admin"
  },
  "sidebar_users": {
    "en": "Users",
    "fr": "Utilisateurs"
  },
  "sidebar_preferences": {
    "en": "Company settings",
    "fr": "Paramètre de compagnie"
  },
  "sidebar_settings": {
    "en": "Settings",
    "fr": "Paramètres"
  },
  "sidebar_holidays": {
    "en": "Holidays",
    "fr": "Jours fériés"
  },
  "sidebar_applications": {
    "en": "Installed applications",
    "fr": "Applications installées"
  },
  "sidebar_short_term": {
    "en": "Daily logs",
    "fr": "Rapports journaliers"
  },
  "sidebar_employee_logs": {
    "en": "Employee Logs",
    "fr": "Registres des employés"
  },
  "sidebar_calendar": {
    "en": "Calendar",
    "fr": "Calendrier"
  },
  "sidebar_daily_log_list": {
    "en": "List",
    "fr": "Liste"
  },
  "sidebar_project_schedule": {
    "en": "Resource Planner",
    "fr": "Planificateur de ressources"
  },
  "sidebar_long_term": {
    "en": "Long Term",
    "fr": "Long terme"
  },
  "sidebar_inventory": {
    "en": "Small Tools & Supplies",
    "fr": "Petits Outils & Fournitures"
  },
  "sidebar_timecard_summary": {
    "en": "Worked hours",
    "fr": "Heures travaillées"
  },
  "sidebar_employee_dashboard": {
    "en": "Timecard",
    "fr": "Carte de temps"
  },
  "sidebar_occupations": {
    "en": "Occupation list",
    "fr": "Liste des métiers"
  },
  "sidebar_employees_list": {
    "en": "Employee list",
    "fr": "Liste des employés"
  },
  "sidebar_form_builder": {
    "en": "Form Builder",
    "fr": "Créer un formulaire"
  },
  "sidebar_manage_holidays": {
    "en": "Manage Holidays",
    "fr": "Gérer les jours fériés"
  },
  "sidebar_logbook_integration": {
    "en": "Logbook",
    "fr": "Logbook"
  },
  "sidebar_resources_rates": {
    "en": "Rates",
    "fr": "Taux"
  },
  "sidebar_task_structure":{
    "en": "Task structure",
    "fr": "Structure de tâches",
  },
  "sidebar_tasks":{
    "en": "Tasks",
    "fr": "Tâches",
  },
  "sidebar_subtasks": {
    "en": "Subtasks",
    "fr": "Sous-tâches",
  },
  "header_timeline_dispatch_tab": {
    "en": "Dispatch",
    "fr": "Déploiement"
  },
  "header_timeline_teams_tab": {
    "en": "Teams",
    "fr": "Équipes"
  },
  "header_dashboard_validate_tab": {
    "en": "Daily Logs to validate",
    "fr": "Rapports journalier à valider"
  },
  "header_resource_organizations_tab": {
    "en": "Organizations",
    "fr": "Organisations"
  },
  "header_resource_employees_tab": {
    "en": "Employees",
    "fr": "Employés"
  },
  "header_resource_equipments_tab": {
    "en": "Equipments",
    "fr": "Équipments"
  },
  "header_resource_materials_tab": {
    "en": "Materials",
    "fr": "Matériaux"
  },
  "header_administration_user_tab": {
    "en": "User & Permissions",
    "fr": "Utilisateurs & Permissions"
  },
  "header_administration_billing_tab": {
    "en": "Billing Information",
    "fr": "Information de facturation"
  },
  "header_dailylog_title": {
    "en": "Daily Log",
    "fr": "Rapport journalier"
  },
  "header_dailylog_priceanalysis_title": {
    "en": "Daily Log Price Analysis",
    "fr": "Validation des prix"
  },
  "header_documents_title": {
    "en": "Documents",
    "fr": "Documents"
  },
  "header_projects_title": {
    "en": "Projects",
    "fr": "Projets"
  },
  "header_project_description_title": {
    "en": "Project Description",
    "fr": "Description du projet"
  },
  "header_wbs": {
    "en": "Work Breakdown Structure",
    "fr": "Structure de répartition du travail"
  },
  "header_project_documents_title": {
    "en": "Project Documents",
    "fr": "Documents du projet"
  },
  "header_project_analysis_title": {
    "en": "Project Analysis",
    "fr": "Analyse de projet"
  },
  "header_report_title": {
    "en": "Report",
    "fr": "Rapport"
  },
  "header_organization_details_title": {
    "en": "Organization Details",
    "fr": "Détails de l'organisation"
  },
  "header_teams_title": {
    "en": "Teams",
    "fr": "Équipes"
  },
  "header_activities_title": {
    "en": "Activities",
    "fr": "Activités"
  },
  "header_map_title": {
    "en": "Map",
    "fr": "Carte"
  },
  "resources_organization_priceType_pension_button": {
    "en": "Pension Types",
    "fr": "Primes"
  },
  "resources_organization_add_priceType_button": {
    "en": "Add Price",
    "fr": "Ajouter Prix"
  },
  "resources_organization_name_column": {
    "en": "Name",
    "fr": "Nom"
  },
  "resources_organization_team_row": {
    "en": "Team",
    "fr": "Equipe"
  },
  "resources_organization_search_employees": {
    "en": "Search for an employee",
    "fr": "Rechercher un employé"
  },
  "resources_organization_search_equipments": {
    "en": "Search for an equipment",
    "fr": "Rechercher un équipment"
  },
  "resources_organization_search_materials": {
    "en": "Search for a material",
    "fr": "Rechercher un matériau"
  },
  "weather_tooltip": {
    "en": "Show the weather",
    "fr": "Météo"
  },
  "weather_placeholder": {
    "en": "Current Location",
    "fr": "Localisation courante"
  },
  "weather_title": {
    "en": "Weather",
    "fr": "Météo"
  },
  "organization_pricetype_label": {
    "en": "Price Name",
    "fr": "Nom du prix"
  },
  "currently_active_in": {
    "en": "is currently active in",
    "fr": "est actuellement actif dans"
  },
  "weather_probability": {
    "en": "Probability of precipitation",
    "fr": "Probabilité de précipitations"
  },
  "Administration_table_organization_header_title": {
    "en": "Organization",
    "fr": "Organisation"
  },
  "Administration_table_State_header_title": {
    "en": "State",
    "fr": "État"
  },
  "Administration_table_Permission_header_title": {
    "en": "Permission",
    "fr": "Permission"
  },
  "Administration_table_Actions_header_title": {
    "en": "Actions",
    "fr": "Actions"
  },
  "Administration_table_User_header_title": {
    "en": "User",
    "fr": "Utilisateur"
  },
  "Administration_table_Invite_User": {
    "en": "Invite User",
    "fr": "Inviter Utilisateur"
  },
  "Administration_table_Search_placeholder": {
    "en": "Search Employees",
    "fr": "Rechercher Employées"
  },
  "Administration_table_status_header_title": {
    "en": "Status",
    "fr": "Statut"
  },
  "Administration_table_Send_Invite": {
    "en": "Resend invite",
    "fr": "Réenvoyer invitation"
  },
  "Administration_table_last_login": {
    "en": "Last login",
    "fr": "Dernière connexion"
  },
  "Administration_table_expired": {
    "en": "Expired",
    "fr": "Expiré"
  },
  "Administration_table_expired_on": {
    "en": "Expired on",
    "fr": "A expiré le"
  },
  "Administration_table_expires_on": {
    "en": "Expires on",
    "fr": "Expire le"
  },
  "Administration_table_pending": {
    "en": "Pending",
    "fr": "En attente"
  },
  "Administration_table_last_login_never": {
    "en": "Never",
    "fr": "Jamais"
  },
  "Administration_table_Remove_User": {
    "en": "Remove",
    "fr": "Supprimer"
  },
  "Remove_User_Confirmation_Text": {
    "en": "You're about to delete this user. Are you sure you want to continue?",
    "fr": "Vous êtes sur le point de supprimer cet utilisateur. Êtes-vous sûr de vouloir continuer?"
  },
  "Remove_User_Confirmation_Title": {
    "en": "Delete the user",
    "fr": "Supprimer l'utilisateur"
  },
  "Administration_table_results": {
    "en": "Users",
    "fr": "Utilisateurs"
  },
  "formdialog_create_user": {
    "en": "Create new user",
    "fr": "Créer un nouveau utilisateur"
  },
  "formdialog_edit_user": {
    "en": "Edit user",
    "fr": "Modifier l'utilisateur"
  },
  "formdialog_create": {
    "en": "Create",
    "fr": "Créer"
  },
  "formdialog_edit": {
    "en": "Edit",
    "fr": "Modifier"
  },
  "delete_dialog_title": {
    "en": "Delete confirmation",
    "fr": "Confirmation de suppression"
  },
  "delete_dialog_text": {
    "en": "You are about to delete this element. Are you sure you want to continue?",
    "fr": "Vous êtes sur le point de supprimer cet élément. Êtes-vous sûr de vouloir continuer?"
  },
  "no_unit": {
    "en": "No unit",
    "fr": "Pas d'unité"
  },
  "no_category": {
    "en": "No category",
    "fr": "Pas de catégorie"
  },
  "no_type": {
    "en": "No type",
    "fr": "Pas de type"
  },
  "no_team": {
    "en": "Not assigned to a team",
    "fr": "Pas assigné à une équipe"
  },
  "Schedule_no_team": {
    "en": "is not assigned to a team.",
    "fr": "n'est pas assigné à une équipe."
  },
  "Schedule_unsual_team": {
    "en": "is not in his usual team.",
    "fr": "n'est pas dans son équipe habituelle."
  },
  "action_remove_item_caps": {
    "en": "DELETE",
    "fr": "SUPPRIMER"
  },
  "scheduleblocklist_expander": {
    "en": "Expand",
    "fr": "Étendre"
  },
  "bulk_delete_resources": {
    "en": "Remove all resources",
    "fr": "Supprimer toutes resources"
  },
  "bulk_delete_resources_message": {
    "en": "Are you sure you want to remove all resources of this organization?",
    "fr": "Êtes-vous sûre de supprimer toutes resources de cette organisation"
  },
  "scheduleblocklist_select_activities_project_group_label": {
    "en": "1. Project's Activities",
    "fr": "1. Activités du projet"
  },
  "scheduleblocklist_select_activities_general_group_label": {
    "en": "2. General Activities",
    "fr": "2. Activités générales"
  },
  "schedule_popup_add_resources_equipment_link_message": {
    "en": "Equipment Page",
    "fr": "Page d'Équipement"
  },
  "schedule_popup_add_resources_equipment_message": {
    "en": "There are no more equipments available. Add more at the equipments page",
    "fr": "Il n'y a plus d'équipements disponibles. Veuillez en rajouter dans page d'équipements"
  },
  "schedule_popup_add_resources_equipment_type_message": {
    "en": "There are no type available. Add more at the equipments page",
    "fr": "Il y a pas de type disponible. Veuillez en rajouter dans la page d'équipements"
  },
  "schedule_popup_add_resources_employee_link_message": {
    "en": "Employee Page",
    "fr": "Page employés"
  },
  "schedule_popup_add_resources_employee_message": {
    "en": "There are no more employee available. Add more at the employee page",
    "fr": "Il n'y a plus d'employés disponible. Veuillez en rajouter dans page employés"
  },
  "schedule_popup_add_resources_employee_role_message": {
    "en": "There are no more roles available. Add more at the roles page",
    "fr": "Il n'y a pas de rôle disponible. Veuillez en rajouter dans la page employés"
  },
  "cancel_button_label": {
    "en": "Cancel",
    "fr": "Annuler"
  },
  "ok_button_label": {
    "en": "Ok",
    "fr": "Ok"
  },
  "confirm_button_label": {
    "en": "Confirm",
    "fr": "Confirmer"
  },
  "update_button_label": {
    "en": "Update",
    "fr": "Modifier"
  },
  "Name": {
    "en": "Name",
    "fr": "Nom"
  },
  "Unit": {
    "en": "Unit",
    "fr": "Unité"
  },
  "Role": {
    "en": "Role",
    "fr": "Rôle"
  },
  "Model": {
    "en": "Model",
    "fr": "Modèle"
  },
  "Brand": {
    "en": "Brand",
    "fr": "Marque"
  },
  "orgainzation_edit_price": {
    "en": "Edit price",
    "fr": "Modifier prix"
  },
  "mobile_login_title": {
    "en": "Welcome to Civalgo",
    "fr": "Bienvenue sur Civalgo"
  },
  "login_error": {
    "en": "Sorry, we had an error with your login",
    "fr": "Désolé, nous avons eu une erreur avec votre connexion"
  },
  "add_placeholder_price": {
    "en": "Write price name",
    "fr": "Écrire le nom du prix"
  },
  "select_date": {
    "en": "Select date",
    "fr": "Sélectionner la date"
  },
  "apply": {
    "en": "Apply",
    "fr": "Appliquer"
  },
  "dailylog_create_success": {
    "en": "Daily log created successfully",
    "fr": "Rapport journalier créé avec succès"
  },
  "dailylog_create_error": {
    "en": "Sorry, we had an error with the daily log creation",
    "fr": "Désolé, une erreur est survenue lors de la création du rapport journalier"
  },
  "dailylog_update_success": {
    "en": "Daily log updated successfully",
    "fr": "Rapport journalier mis à jour avec succès"
  },
  "dailylog_update_error": {
    "en": "Sorry, we had an error with the daily log update",
    "fr": "Désolé, une erreur est survenue lors de la mise à jour du rapport journalier"
  },
  "dailylog_move_success": {
    "en": "Daily log moved successfully",
    "fr": "Rapport journalier déplacé avec succès"
  },
  "dailylog_move_error": {
    "en": "Sorry, we had an error with the daily log move",
    "fr": "Désolé, une erreur est survenue lors du déplacement du rapport journalier"
  },
  "dailylog_delete_success": {
    "en": "Daily log deleted successfully",
    "fr": "Rapport journalier supprimé avec succès"
  },
  "dailylog_delete_error": {
    "en": "Sorry, we had an error with the construction log deletion",
    "fr": "Désolé, une erreur est survenue lors de la suppression du rapport journalier"
  },
  "dailylog_copy_success": {
    "en": "Daily log copied successfully",
    "fr": "Rapport journalier copié avec succès"
  },
  "dailylog_copy_error": {
    "en": "Sorry, we had an error with the dailyLog copy",
    "fr": "Désolé, une erreur est survenue lors de la copie du rapport journalier"
  },
  "dailylog_add_resource_success": {
    "en": "Resource created successfully",
    "fr": "Ressource créée avec succès"
  },
  "dailylog_add_resource_error": {
    "en": "Sorry, we had an error with the resource creation",
    "fr": "Désolé, une erreur est survenue lors de la création de la ressource"
  },
  "dailylog_remove_resource_success": {
    "en": "Resource removed successfully",
    "fr": "Ressource supprimée avec succès"
  },
  "dailylog_remove_resource_error": {
    "en": "Sorry, we had an error with the resource removal",
    "fr": "Désolé, une erreur est survenue lors de la suppression de la ressource"
  },
  "dailylog_update_resource_success": {
    "en": "Resource updated successfully",
    "fr": "Ressource mise à jour avec succès"
  },
  "dailylog_update_resource_error": {
    "en": "Sorry, we had an error with the resource update",
    "fr": "Désolé, une erreur est survenue lors de la mise à jour de la ressource"
  },
  "dailylog_add_placeholder_success": {
    "en": "Placeholder created successfully",
    "fr": "Ressource créée avec succès"
  },
  "dailylog_add_placeholder_error": {
    "en": "Sorry, we had an error with the placeholder creation",
    "fr": "Désolé, une erreur est survenue lors de la création de la ressource"
  },
  "dailylog_remove_placeholder_success": {
    "en": "Placeholder removed successfully",
    "fr": "L'ensemble d'espace réservé a été retiré avec succès"
  },
  "dailylog_remove_placeholder_error": {
    "en": "Sorry, we had an error with the placeholder removal",
    "fr": "Désolé, nous avons eu une erreur avec la suppression de l'espace réservé"
  },
  "dailylog_placeholder_to_resource_success": {
    "en": "Resource converted successfully",
    "fr": "Ressource converti avec succès"
  },
  "dailylog_placeholder_to_resource_error": {
    "en": "Sorry, we had an error with the resource conversion",
    "fr": "Désolé, nous avons eu une erreur avec la conversion des ressources"
  },
  "dailylog_add_project_success": {
    "en": "Project added successfully",
    "fr": "Projet ajouté avec succès"
  },
  "dailylog_add_project_error": {
    "en": "Sorry, we had an error with the project creation",
    "fr": "Désolé, nous avons eu une erreur avec la création du projet"
  },
  "dailylog_remove_project_success": {
    "en": "Project removed successfully",
    "fr": "Projet supprimé avec succès"
  },
  "dailylog_remove_project_error": {
    "en": "Sorry, we had an error with the project removal",
    "fr": "Désolé, nous avons eu une erreur avec la suppression du projet"
  },
  "dailylog_add_activity_success": {
    "en": "Daily log activity created successfully",
    "fr": "Activité du journal quotidien créé avec succès"
  },
  "dailylog_add_activity_error": {
    "en": "Sorry, we had an error with the daily log activity creation",
    "fr": "Désolé, nous avons eu une erreur avec la création quotidienne d'activité logarithmique"
  },
  "dailylog_update_activity_success": {
    "en": "Daily log activity updated successfully",
    "fr": "Activité du journal quotidien mise à jour avec succès"
  },
  "dailylog_update_activity_error": {
    "en": "Sorry, we had an error with the construction log activity update",
    "fr": "Désolé, nous avons eu une erreur avec la mise à jour de l'activité du journal de construction"
  },
  "dailylog_remove_activity_success": {
    "en": "Daily log activity deleted successfully",
    "fr": "Activité du journal quotidien supprimé avec succès"
  },
  "dailylog_remove_activity_error": {
    "en": "Sorry, we had an error with the construction log activity deletion",
    "fr": "Désolé, nous avons eu une erreur avec la suppression de l'activité du journal de construction"
  },
  "dailylog_bulk_add_activity_success": {
    "en": "Daily log activities created successfully",
    "fr": "Les activités de journal quotidien ont été créées avec succès"
  },
  "dailylog_bulk_add_activity_error": {
    "en": "Sorry, we had an error with the construction log activities creation",
    "fr": "Désolé, nous avons eu une erreur avec la création d'activités de journal de construction"
  },
  "comment_query_error": {
    "en": "Sorry we had an error while fetching your comments",
    "fr": "Désolé, nous avons eu une erreur lors de la récupération de vos commentaires"
  },
  "dailylog_document_add_success": {
    "en": "Document created successfully",
    "fr": "Document créé avec succès"
  },
  "dailylog_document_update_success": {
    "en": "Document updated successfully",
    "fr": "Documer mis à jour avec succès"
  },
  "dailylog_document_upsert_error": {
    "en": "Sorry, we had an error with the document",
    "fr": "Désolé, nous avons eu une erreur avec le document"
  },
  "dailylog_documents_add_success": {
    "en": "Documents added successfully",
    "fr": "Documents ajoutés avec succès"
  },
  "dailylog_documents_add_error": {
    "en": "Sorry, we had an error with the documents add",
    "fr": "Désolé, nous avons eu une erreur avec les documents ajouter"
  },
  "dailylog_document_delete_success": {
    "en": "Document deleted successfully",
    "fr": "Document supprimé avec succès"
  },
  "dailylog_document_delete_error": {
    "en": "Sorry, we had an error with the document delete",
    "fr": "Désolé, nous avons eu une erreur avec la suppression du document"
  },
  "dailylog_add_material_success": {
    "en": "Material added successfully",
    "fr": "Matériau ajouté avec succès"
  },
  "dailylog_add_material_error": {
    "en": "Sorry, we had an error with the material add",
    "fr": "Désolé, nous avons eu une erreur avec le matériau ajouter"
  },
  "dailylog_update_material_success": {
    "en": "Material removed successfully",
    "fr": "Matériau retiré avec succès"
  },
  "dailylog_update_material_error": {
    "en": "Sorry, we had an error with the material edit",
    "fr": "Désolé, nous avons eu une erreur avec le matériau modifier"
  },
  "dailylog_remove_material_success": {
    "en": "Successfully edit your material",
    "fr": "Modifiez avec succès votre matériau"
  },
  "dailylog_remove_material_error": {
    "en": "Sorry, we had an error with the material removal",
    "fr": "Désolé, nous avons eu une erreur avec la suppression des matériaux"
  },
  "dailylog_add_block_success": {
    "en": "Activity block created successfully",
    "fr": "Bloc d'activité créé avec succès"
  },
  "dailylog_add_block_error": {
    "en": "Sorry, we had an error with the activity block creation",
    "fr": "Désolé, nous avons eu une erreur avec la création de blocs d'activité"
  },
  "dailylog_update_block_success": {
    "en": "Activity block updated successfully",
    "fr": "Bloc d'activité mis à jour avec succès"
  },
  "dailylog_update_block_error": {
    "en": "Sorry, we had an error with the activity block update",
    "fr": "Désolé, nous avons eu une erreur avec la mise à jour du bloc d'activité"
  },
  "dailylog_remove_block_success": {
    "en": "Activity block deleted successfully",
    "fr": "Bloc d'activité supprimé avec succès"
  },
  "dailylog_remove_block_error": {
    "en": "Sorry, we had an error with the activity block deletion",
    "fr": "Désolé, nous avons eu une erreur avec la suppression du bloc d'activité"
  },
  "characters": {
    "en": "Characters",
    "fr": "Caractères"
  },
  "dailylog_show_description": {
    "en": "Show description",
    "fr": "Montrer la description"
  },
  "dailylog_hide_description": {
    "en": "Hide description",
    "fr": "Description de la peau"
  },
  "dailylog_add_title": {
    "en": "Add a title",
    "fr": "Ajouter un titre"
  },
  "dailylog_add_description": {
    "en": "Add a description",
    "fr": "ajouter une description"
  },
  "dailylog_comments": {
    "en": "Comments",
    "fr": "Commentaires"
  },
  "dailylog_status_0": {
    "en": "Draft",
    "fr": "Ébauche"
  },
  "dailylog_status_1": {
    "en": "Schedule done",
    "fr": "Planification completé"
  },
  "dailylog_status_2": {
    "en": "Daily log filled",
    "fr": "Rapport journalier rempli"
  },
  "dailylog_status_3": {
    "en": "Daily log reviewed",
    "fr": "Rapport journalier révisé"
  },
  "dailylog_status_4": {
    "en": "Daily log validated",
    "fr": "Rapport journalier validé"
  },
  "dailylog_status_short_0": {
    "en": "Draft",
    "fr": "Ébauche"
  },
  "dailylog_status_short_1": {
    "en": "Done",
    "fr": "Completé"
  },
  "dailylog_status_short_2": {
    "en": "Filled",
    "fr": "Rempli"
  },
  "dailylog_status_short_3": {
    "en": "Reviewed",
    "fr": "Révisé"
  },
  "dailylog_status_short_4": {
    "en": "Validated",
    "fr": "Validé"
  },
  "comments_edited": {
    "en": "Edited",
    "fr": "Édité"
  },
  "comments_hide": {
    "en": "Hide comments",
    "fr": "Cacher des commentaires"
  },
  "comments_show": {
    "en": "Show comments",
    "fr": "Montrer des commentaires"
  },
  "comments_reply": {
    "en": "Reply",
    "fr": "Réponse"
  },
  "comments_edit": {
    "en": "Edit comment",
    "fr": "Modifier le commentaire"
  },
  "comments_delete": {
    "en": "Delete comment",
    "fr": "Supprimer le commentaire"
  },
  "comments_confirm_add": {
    "en": "Send comment",
    "fr": "Envoyer un commentaire\n"
  },
  "comments_confirm_edit": {
    "en": "Apply changes",
    "fr": "Appliquer les modifications"
  },
  "comments_confirm_reply": {
    "en": "Reply",
    "fr": "Réponse"
  },
  "comments_placeholder_add": {
    "en": "Write your comment",
    "fr": "Écrivez votre commentaire"
  },
  "comments_placeholder_edit": {
    "en": "Edit your comment",
    "fr": "Modifiez votre commentaire"
  },
  "comments_placeholder_reply": {
    "en": "Write your response to the comment",
    "fr": "Écrivez votre réponse au commentaire"
  },
  "listtemplate_endlist_message": {
    "en": "There is no data left",
    "fr": "Il n'y a plus de données restantes"
  },
  "dailylogemployees_title": {
    "en": "Scheduled Employees",
    "fr": "Employés cédulés"
  },
  "close_dialog_button": {
    "en": "Close",
    "fr": "Fermer"
  },
  "dailylog_resource_edit_description_button_label": {
    "en": "Edit note",
    "fr": "Modifier note"
  },
  "dailylog_replace_resource": {
    "en": "Add resource",
    "fr": "Ajouter une ressource"
  },
  "form_error_of_characters_0": {
    "en": "This value must be under",
    "fr": "Cette valeur doit avoir moins que"
  },
  "form_error_of_characters_1": {
    "en": "characters",
    "fr": "caractères"
  },
  "form_error_required": {
    "en": "is required",
    "fr": "est obligatoire"
  },
  "form_error_invalid_email": {
    "en": "This is not a valid email address",
    "fr": "Cette adresse courriel n'est pas valide"
  },
  "form_error_invalid_phone_number": {
    "en": "This is not a valid phone number",
    "fr": "Ce n'est pas un numéro de téléphone valide"
  },
  "form_change": {
    "en": "Change",
    "fr": "Changer"
  },
  "form_picture": {
    "en": "Picture",
    "fr": "Photo"
  },
  "form_optional": {
    "en": "Optional",
    "fr": "Optionnel"
  },
  "form_edit_note_resource": {
    "en": "Modify the note of the resource",
    "fr": "Modifier la note de la ressource"
  },
  "form_product": {
    "en": "Product",
    "fr": "Produit"
  },
  "form_activity": {
    "en": "Activity",
    "fr": "Activité"
  },
  "form_project": {
    "en": "Project",
    "fr": "Projet"
  },
  "form_start": {
    "en": "Start",
    "fr": "Débute"
  },
  "form_end": {
    "en": "End",
    "fr": "Termine"
  },
  "form_note": {
    "en": "Note",
    "fr": "Note"
  },
  "form_placeholder_note": {
    "en": "Write the note of this resource",
    "fr": "Écrivez la note de cette ressource"
  },
  "card_declined": {
    "en": "The card has been declined",
    "fr": "La carte a été refusée"
  },
  "incorrect_cvc": {
    "en": "The card’s security code is invalid. Check the card’s security code or use a different card",
    "fr": "Le code de sécurité de la carte n'est pas valide. Vérifiez le code de sécurité de la carte ou utilisez une carte différente"
  },
  "incorrect_number": {
    "en": "The card number is invalid. Check the card details or use a different card",
    "fr": "Le numéro de carte n'est pas valide. Vérifiez les détails de la carte ou utilisez une autre carte"
  },
  "incorrect_zip": {
    "en": "The zip code you supplied failed validation.",
    "fr": "Le code postal que vous avez fourni a échoué la validation."
  },
  "incorrect_address": {
    "en": "The card’s address is incorrect. Check the card’s address or use a different card.",
    "fr": "L'adresse de la carte est incorrecte. Vérifiez l'adresse de la carte ou utilisez une autre carte."
  },
  "expired_card": {
    "en": "The card has expired. Check the expiration date or use a different card.",
    "fr": "La carte a expiré. Vérifiez la date d'expiration ou utilisez une carte différente."
  },
  "invalid_format": {
    "en": "Invalid format",
    "fr": "Format invalide"
  },
  "processing_error": {
    "en": "An error occurred while processing your card. Try again in a little bit.",
    "fr": "Une erreur s'est produite lors du traitement de votre carte. Ressayez un peu."
  },
  "signup_title": {
    "en": "Your Fully-Featured Operation Solution",
    "fr": "Votre solution d'opération entièrement tracée"
  },
  "signup_description": {
    "en": "Performant and reliable operation management software for professionals who'd rather spend their time making business decision than doing double manipulation.",
    "fr": "Logiciel de gestion des opérations performant et fiable pour des professionnels qui préfèrent passer leur temps à prendre une décision commerciale plutôt que de faire une double manipulation."
  },
  "signup_check1": {
    "en": "A single app to track and manage all your teams, employees and equipments",
    "fr": "Une seule application pour suivre et gérer toutes vos équipes, employés et équipements"
  },
  "signup_check2": {
    "en": "Built with security and ease of use in mind",
    "fr": "Construit avec la sécurité et la facilité d'utilisation à l'esprit"
  },
  "signup_check3": {
    "en": "Work from everywhere on your Desktop, Tablet and Phone",
    "fr": "Travaillez de partout sur votre bureau, tablette et téléphone"
  },
  "signup_check4": {
    "en": "100% free for 30 days with support included",
    "fr": "100% gratuit pendant 30 jours avec le soutien inclus"
  },
  "start_trial": {
    "en": "Start a 100% free 30 days trial",
    "fr": "Démarrer un essai 100% gratuit de 30 jours"
  },
  "user_name": {
    "en": "Your name",
    "fr": "Votre nom"
  },
  "company_name": {
    "en": "Company name",
    "fr": "Nom de l'entreprise"
  },
  "phone": {
    "en": "Phone",
    "fr": "Téléphone"
  },
  "email_address": {
    "en": "Email address",
    "fr": "Adresse e-mail"
  },
  "password": {
    "en": "Your password",
    "fr": "Votre mot de passe"
  },
  "password_creation": {
    "en": "Password (9 characters min)",
    "fr": "Mot de passe (9 caractères min)"
  },
  "business_account": {
    "en": "This is a business account",
    "fr": "Ceci est un compte commercial"
  },
  "agree_terms_policy": {
    "en": "I agree to the Terms of Service & Privacy Policy",
    "fr": "J'accepte les conditions d'utilisation et la politique de confidentialité"
  },
  "next_add_address": {
    "en": "Next: Add Address",
    "fr": "Suivant: Ajouter une adresse"
  },
  "terms_of_service": {
    "en": "Terms of Service",
    "fr": "Conditions d'utilisation"
  },
  "privacy_policy": {
    "en": "Privacy Policy",
    "fr": "Politique de confidentialité"
  },
  "company_name_2_characters": {
    "en": "Company name must be at least 2 characters",
    "fr": "Le nom de l'entreprise doit comporter au moins 2 caractères"
  },
  "email_taken": {
    "en": "This email address is already used",
    "fr": "Cette adresse e-mail est déjà utilisée"
  },
  "company_name_used": {
    "en": "Company name already used",
    "fr": "Nom de l'entreprise déjà utilisé"
  },
  "add_billing_address": {
    "en": "Add a billing address",
    "fr": "Ajouter une adresse de facturation"
  },
  "address": {
    "en": "Address",
    "fr": "Adresse"
  },
  "unit_suite": {
    "en": "Unit or suite",
    "fr": "Unité ou suite"
  },
  "city": {
    "en": "City",
    "fr": "Ville"
  },
  "state": {
    "en": "State",
    "fr": "Province"
  },
  "zip_code": {
    "en": "Zip code",
    "fr": "Code postal"
  },
  "country": {
    "en": "Select a country",
    "fr": "Choisir un pays"
  },
  "next_add_billing": {
    "en": "Next: Add Payment method",
    "fr": "Suivant: Ajouter une méthode de paiement"
  },
  "why_credit_card": {
    "en": "Why do I need to provide a credit card?",
    "fr": "Pourquoi dois-je fournir une carte de crédit?"
  },
  "why_credit_card_tip": {
    "en": "We require a credit card to ensure there is no disruption of your service after your trial period is over. We won't bill you during your free trial. We will notify you well in advance of your trial end date so you don't have any unexpected surprises. This is part of our commitment to being as transparent as possible.",
    "fr": "Nous avons besoin d'une carte de crédit pour nous assurer qu'il n'y a aucune perturbation de votre service après la fin de votre période d'essai. Nous ne vous facturerons pas pendant votre essai gratuit. Nous vous informerons bien avant la date de fin de votre essai afin que vous n'ayez pas de surprises inattendues. Cela fait partie de notre engagement à être aussi transparent que possible."
  },
  "add_payment": {
    "en": "Add a payment method",
    "fr": "Ajouter un moyen de paiement"
  },
  "card_number": {
    "en": "Card Number",
    "fr": "Numéro de carte"
  },
  "cardholder": {
    "en": "Cardholder ",
    "fr": "Titulaire de la carte"
  },
  "expiry_date": {
    "en": "Expires",
    "fr": "Expire"
  },
  "cvc": {
    "en": "CVC",
    "fr": "CVC"
  },
  "total": {
    "en": "Total",
    "fr": "Total"
  },
  "required_field": {
    "en": "This field is required",
    "fr": "Ce champ est requis"
  },
  "valid_email": {
    "en": "Please enter a valid email address",
    "fr": "S'il vous plaît, mettez une adresse email valide"
  },
  "valid_password": {
    "en": "The password must be more than 9 characters.",
    "fr": "Le mot de passe doit comporter plus de 9 caractères."
  },
  "check_this_box": {
    "en": "Please check this box if you want to proceed.",
    "fr": "Veuillez cocher cette case si vous souhaitez continuer."
  },
  "create_account": {
    "en": "Create Account",
    "fr": "Créer un compte"
  },
  "billing_address_update_success": {
    "en": "Billing address updated with success",
    "fr": "Adresse de facturation mise à jour avec succès"
  },
  "billing_address_update_error": {
    "en": "Sorry, we had an error with your update",
    "fr": "Désolé, nous avons eu une erreur avec votre mise à jour"
  },
  "billing_address": {
    "en": "Billing Address",
    "fr": "Adresse de facturation"
  },
  "billing_email_update_success": {
    "en": "Email updated with success",
    "fr": "E-mail mis à jour avec succès"
  },
  "billing_email_update_error": {
    "en": "Sorry, we had an error with your update",
    "fr": "Désolé, nous avons eu une erreur avec votre mise à jour"
  },
  "invoice_notifications_title": {
    "en": "Invoice notifications",
    "fr": "Notifications de facture"
  },
  "invoice_notifications_description": {
    "en": "All invoices notifications will be send to the following email:",
    "fr": "Toutes les notifications de factures seront envoyées à l'e-mail suivant:"
  },
  "credit_card_update_success": {
    "en": "Card information updated with success",
    "fr": "Informations sur la carte mise à jour avec succès"
  },
  "credit_card_update_error": {
    "en": "Sorry, we had an error with your update",
    "fr": "Désolé, nous avons eu une erreur avec votre mise à jour"
  },
  "payment_method_title": {
    "en": "Payment Method",
    "fr": "Mode de paiement"
  },
  "payment_method_description": {
    "en": "Plan payments will be charged to the following card",
    "fr": "Les paiements du plan seront facturés à la carte suivante"
  },
  "payment": {
    "en": "Payments",
    "fr": "Paiements"
  },
  "start_billing_period": {
    "en": "Start of billing period",
    "fr": "Début de la période de facturation"
  },
  "end_billing_period": {
    "en": "End of billing period",
    "fr": "Période de fin de facturation"
  },
  "usage": {
    "en": "Usage",
    "fr": "Usage"
  },
  "questions": {
    "en": "If you have questions or requests about your subscription please contact us by phone at",
    "fr": "Si vous avez des questions ou des demandes sur votre abonnement, veuillez nous contacter par téléphone à"
  },
  "or_email": {
    "en": "or by email at",
    "fr": "ou par e-mail à"
  },
  "verify_email_error": {
    "en": "An unexpected error occured when verifying your email. Try again later or contact support for more information",
    "fr": "Une erreur inattendue s'est produite lors de la vérification de votre e-mail. Réessayer plus tard ou contacter le support pour plus d'informations"
  },
  "unhandled_checkout_error": {
    "en": "An unexpected error occured while creating your company. Contact support if you receive any message regarding an incoming invoice",
    "fr": "Une erreur inattendue s'est produite lors de la création de votre entreprise. Contactez le support si vous recevez un message concernant une facture entrante"
  },
  "company_creation_failed": {
    "en": "An unexpected error occured while creating your company. Contact support if you receive any message regarding an incoming invoice",
    "fr": "Une erreur inattendue s'est produite lors de la création de votre entreprise. Contactez le support si vous recevez un message concernant une facture entrante"
  },
  "subscription_creation_failed": {
    "en": "An unexpected error occured when trying to create your account. Try again later or contact support for more information",
    "fr": "Une erreur inattendue s'est produite lorsque vous essayez de créer votre compte. Réessayer plus tard ou contacter le support pour plus d'informations"
  },
  "customer_creation_failed": {
    "en": "An unexpected error occured when trying to create your account. Try again later or contact support for more information",
    "fr": "Une erreur inattendue s'est produite lorsque vous essayez de créer votre compte. Réessayer plus tard ou contacter le support pour plus d'informations"
  },
  "not_subscribed": {
    "en": "No subscription to the billing service",
    "fr": "Aucun abonnement au service de facturation"
  },
  "unsubscribed": {
    "en": "You have been unsubscribed from the billing service",
    "fr": "Vous n'avez pas été inscrit au service de facturation"
  },
  "remove_employee_icon": {
    "en": "Remove avatar image",
    "fr": "Supprimer l'image d'avatar"
  },
  "remove_employee": {
    "en": "Remove employee",
    "fr": "Retirer employé\r\n"
  },
  "delete_form_text": {
    "en": "Are you sure you want to remove this employee?",
    "fr": "Êtes-vous sûr de vouloir supprimer cet employé?"
  },
  "form_add_employee": {
    "en": "Add new employee",
    "fr": "Ajouter un nouvel employé"
  },
  "form_replace_button": {
    "en": "Replace",
    "fr": "Remplacer"
  },
  "no_dailylog_available": {
    "en": "No daily log available",
    "fr": "Aucun rapport journalier disponible"
  },
  "swipe_left_right": {
    "en": "Swipe left or right to change the day.",
    "fr": "Faites glisser à gauche ou à droite pour changer la journée."
  },
  "permission_administrator": {
    "en": "Administrator",
    "fr": "Administrateur"
  },
  "permission_master": {
    "en": "Master",
    "fr": "Maître"
  },
  "permission_resourcesManager": {
    "en": "Resources Manager",
    "fr": "Gestionnaire de ressources"
  },
  "permission_scheduler": {
    "en": "Scheduler",
    "fr": "Planificateur"
  },
  "permission_employee": {
    "en": "Employee",
    "fr": "Employé"
  },
  "permission_fieldWorker": {
    "en": "Field worker",
    "fr": "Travailleur de chantier"
  },
  "permission_teamLeader": {
    "en": "Team Leader",
    "fr": "Chef d'équipe"
  },
  "permission_editor": {
    "en": "Editor",
    "fr": "Éditeur"
  },
  "permission_editor_description": {
    "en": "Can view, create, edit",
    "fr": "Peut lire, créer, modifier"
  },
  "permission_viewer": {
    "en": "Viewer",
    "fr": "Lecteur"
  },
  "permission_viewer_description": {
    "en": "Can view",
    "fr": "Peut lire"
  },
  "permission_all": {
    "en": "All Members",
    "fr": "Tous les membres"
  },
  "permission_title": {
    "en": "Permissions",
    "fr": "Autorisations"
  },
  "permissions_share_with": {
    "en": "Share with",
    "fr": "Partager avec"
  },
  "permission_full": {
    "en": "Full access",
    "fr": "Accès total"
  },
  "permission_full_description": {
    "en": "Can view, create, edit, delete",
    "fr": "Peut lire, créer, modifier, supprimer"
  },
  "permission_no_access": {
    "en": "No access",
    "fr": "Pas d'accès"
  },
  "permission_selected_select": {
    "en": "Select",
    "fr": "Sélectionner"
  },
  "permission_group_members_full": {
    "en": "members have",
    "fr": "membres  ont l'"
  },
  "permission_group_members_editor": {
    "en": "members are",
    "fr": "membres sont"
  },
  "permission_group_members_viewer": {
    "en": "members are",
    "fr": "membres sont"
  },
  "permission_group_members_select": {
    "en": "members are",
    "fr": "membres sont"
  },
  "permission_group_members_no_access": {
    "en": "members have",
    "fr": "membres ont"
  },
  "permission_selected_full": {
    "en": "Full access",
    "fr": "Accès total"
  },
  "permission_selected_editor": {
    "en": "Editors",
    "fr": "Éditeurs"
  },
  "permission_selected_viewer": {
    "en": "Viewer",
    "fr": "Lecteurs"
  },
  "permission_selected_no_access": {
    "en": "No access",
    "fr": "Pas d'accès"
  },
  "permission_organizer": {
    "en": "Organizer",
    "fr": "Organisateur"
  },
  "permission_administrator_description": {
    "en": "Full access. Can make corrections in the past and permanent delete.",
    "fr": "Accès complet. Peut faire des corrections dans le passé et supprimer définitivement."
  },
  "permission_master_description": {
    "en": "Full access excluding administration page. Can make corrections in the past and permanent delete.",
    "fr": "Accès complet excepté la page administration. Peut faire des corrections dans le passé et supprimer définitivement."
  },
  "permission_resourcesManager_description": {
    "en": "Manages resources without interacting with planning.",
    "fr": "Gère les ressources sans interagir à la planification."
  },
  "permission_scheduler_description": {
    "en": "Manage team planning without access to pricing pages.",
    "fr": "Gère la planification des équipes sans avoir accès aux pages de prix."
  },
  "permission_employee_description": {
    "en": "He only sees the information in relation to him and can fill it himself his part in the daily report without seeing the information of the other employees.\r\n",
    "fr": "Il voit seulement les informations par rapport à lui et peut remplir lui-même ça partit dans le rapport journalier sans voir l'information des autres employés."
  },
  "permission_fieldWorker_description": {
    "en": "Track only the information in relation to him. Do not see the planning.",
    "fr": "Voit seulement les informations par rapport à lui. Ne voit pas la planification."
  },
  "permission_teamLeader_description": {
    "en": "Can only edit the daily log when the scheduler gives it access. Track only the information in relation to him.",
    "fr": "Peut seulement modifier le rapport journalier lorsque le planificateur lui donne accès. Voit seulement les informations par rapport à lui."
  },
  "permission_organizer_description": {
    "en": "Access to price pages as well as planning and resources.",
    "fr": "Accès aux pages de prix de même que la planification et les ressources."
  },
  "change_company": {
    "en": "Change company",
    "fr": "Changer la compagnie"
  },
  "choose_company": {
    "en": "Choose company",
    "fr": "Choisir la compagnie"
  },
  "update_configuration_success": {
    "en": "Configuration update",
    "fr": "Mise à jour de la configuration"
  },
  "update_configuration_error": {
    "en": "Sorry, we had an error with the user configuration update",
    "fr": "Désolé, nous avons eu une erreur avec la mise à jour de la configuration de l'utilisateur"
  },
  "logout_request_success": {
    "en": "Your logout request was successful",
    "fr": "Votre demande de déconnexion a été acceptée"
  },
  "logout_request_error": {
    "en": "Sorry, we had an error with the logout",
    "fr": "Désolé, nous avons eu une erreur avec la déconnexion"
  },
  "logout_text": {
    "en": "Log out",
    "fr": "Se déconnecter"
  },
  "feature_coming_soon": {
    "en": "This feature is coming soon.",
    "fr": "Cette fonctionnalité sera bientôt disponible."
  },
  "no_project": {
    "en": "No project",
    "fr": "Aucun project"
  },
  "no_title": {
    "en": "No title",
    "fr": "Pas de titre"
  },
  "no_description": {
    "en": "No description",
    "fr": "Pas de description"
  },
  "dailylogequipments_title": {
    "en": "Scheduled Equipments",
    "fr": "Équipements planifiés"
  },
  "remove_equipment": {
    "en": "Remove equipment",
    "fr": "Supprimer l'équipement"
  },
  "remove_equipment_icon": {
    "en": "Remove avatar image",
    "fr": "Supprimer l'image de l'avatar"
  },
  "form_add_equipment": {
    "en": "Add new equipment",
    "fr": "Ajouter un nouvel équipement"
  },
  "expected": {
    "en": "Expected",
    "fr": "Prévue"
  },
  "executed": {
    "en": "Executed",
    "fr": "Exécutée"
  },
  "delete_form_text_items": {
    "en": "You're about to delete this item. Are you sure you want to continue?",
    "fr": "Vous êtes sur le point de supprimer cet élément. Êtes-vous sûr de vouloir continuer?"
  },
  "delete_form_title_project_activities": {
    "en": "Remove Project and Activities",
    "fr": "Supprimer le projet et les activités"
  },
  "edit_form_activities_title": {
    "en": "Edit quantity of the activity",
    "fr": "Modifier la quantité de l'activité"
  },
  "cost_expected": {
    "en": "Budgeted Cost",
    "fr": "Coût Budgété"
  },
  "cost_current": {
    "en": "Actual Cost",
    "fr": "Coût Actuel"
  },
  "cost_rolled_up_activity_warning_message": {
    "en": "Value can't be edited because the budgeted cost was calculated using activity cost details below",
    "fr": "Le coût budgété a été calculé à l'aide des détails de coûts d'activité ci-dessous"
  },
  "cost_rolled_up_activity_resources_quantity_warning_message": {
    "en": "Can't edit budgeted quantity because there is budgeted resources below",
    "fr": "La valeur n'est pas modifiable, car il y a des ressources budgétées ci-dessous"
  },
  "cost_rolled_up_activity_resources_cost_warning_message": {
    "en": "Can't edit budgeted cost because there is budgeted resources below",
    "fr": "La valeur n'est pas modifiable, car il y a des ressources budgétées ci-dessous"
  },
  "cost_rolled_up_activity_resources_warning_message": {
    "en": "Can't edit budgeted cost because there is budgeted resources below",
    "fr": "La valeur n'est pas modifiable, car il y a des ressources budgétées ci-dessous"
  },
  "cost_rolled_up_warning_message": {
    "en": "Budgeted cost was calculated using activity cost details",
    "fr": "Le coût budgété a été calculé à l'aide des détails de coûts d'activité"
  },
  "cost_current_on_expected": {
    "en": "Actual/Budgeted Cost",
    "fr": "Coût Actuel/Budgété"
  },
  "quantity_expected": {
    "en": "Budgeted Quantity",
    "fr": "Quantité Budgétée"
  },
  "quantity_goal": {
    "en": "Quantity goal",
    "fr": "Objectif de quantité"
  },
  "quantity_executed": {
    "en": "Actual Quantity",
    "fr": "Quantité Actuelle"
  },
  "quantity_used": {
    "en": "Quantity used",
    "fr": "Quantité utilisée"
  },
  "rename_document_form_title": {
    "en": "Rename Document",
    "fr": "Renommer le document"
  },
  "rename_document_form_button": {
    "en": "Rename",
    "fr": "Renommer"
  },
  "rename_document_form_name_field": {
    "en": "Document Name",
    "fr": "Nom du document"
  },
  "delete_document_form_title": {
    "en": "Delete Document",
    "fr": "Supprimer le document"
  },
  "delete_document_form_confirmation_text": {
    "en": "Are you sure you want to remove this document?",
    "fr": "Êtes-vous sûr de vouloir supprimer ce document?"
  },
  "document_separation_row_project_prefix": {
    "en": "Project",
    "fr": "Projet"
  },
  "document_header_count_subtitle_plural": {
    "en": "files",
    "fr": "fichiers"
  },
  "document_header_count_subtitle_singular": {
    "en": "file",
    "fr": "dossier"
  },
  "document_header_title": {
    "en": "Documents",
    "fr": "Documents"
  },
  "document_filter_archived": {
    "en": "Archived",
    "fr": "Archivé"
  },
  "document_filter_template": {
    "en": "Templates",
    "fr": "Gabarits"
  },
  "preview_document_dialog_title": {
    "en": "Preview",
    "fr": "Visualisation de"
  },
  "preview_document_dialog_title_compare_versions": {
    "en": "Comparing versions of",
    "fr": "Comparaison des versions de"
  },
  "preview_document_dialog_title_compare": {
    "en": "Comparing",
    "fr": "Comparaison entre"
  },
  "preview_document_dialog_title_compare_to": {
    "en": "to",
    "fr": "et"
  },
  "add_document_dialog_title": {
    "en": "Add Documents",
    "fr": "Ajouter des documents"
  },
  "uppy_form_error_query": {
    "en": "There is an error with the information request",
    "fr": "Il y a une erreur avec la demande d'informations"
  },
  "uppy_form_daily_log_not_exist": {
    "en": "This daily Log doesn't not exist",
    "fr": "Ce journal quotidien n'existe pas"
  },
  "no_sector": {
    "en": "No sector",
    "fr": "Pas de secteur"
  },
  "edit_form_daily_log": {
    "en": "Edit the daily log of the",
    "fr": "Modifier le rapport journalier du"
  },
  "user_status_inactive": {
    "en": "Inactive",
    "fr": "Inactif"
  },
  "user_status_pending": {
    "en": "Pending",
    "fr": "En attente"
  },
  "user_status_active": {
    "en": "Active",
    "fr": "Actif"
  },
  "switch_company_page_tile": {
    "en": "Choose a company",
    "fr": "Choisissez une compagnie"
  },
  "service_get_company_list_error": {
    "en": "Sorry, we had an error while fetching your company list",
    "fr": "Désolé, nous avons eu une erreur lors de l'extraction de votre liste de compagnies"
  },
  "performance_report": {
    "en": "Performance Report",
    "fr": "Rapport de performance"
  },
  "action_go_back": {
    "en": "Go back",
    "fr": "Retourner"
  },
  "update_comment_success": {
    "en": "Comment updated successfully",
    "fr": "Commentaire mis à jour avec succès"
  },
  "update_comment_error": {
    "en": "Sorry, we had an error with the comment",
    "fr": "Désolé, nous avons eu une erreur avec le commentaire"
  },
  "daily_add_note": {
    "en": "Add note",
    "fr": "Ajouter une note"
  },
  "team_details_employee_title": {
    "en": "Members",
    "fr": "Membres"
  },
  "team_details_equipment_title": {
    "en": "Heavy Equipment",
    "fr": "Équipements lourds"
  },
  "role_archived": {
    "en": "The role you attempted to create already exist but is archived. Choose another name or restore the role",
    "fr": "Le rôle que vous avez tenté de créer existe déjà mais est archivé. Choisissez un autre nom ou restaurez le rôle"
  },
  "role_invalid": {
    "en": "This is not a valid role name",
    "fr": "Ce n'est pas un nom de rôle valide"
  },
  "name_unique": {
    "en": "This name is already used",
    "fr": "Ce nom est déjà utilisé"
  },
  "type_archived": {
    "en": "The type you attempted to create already exist but is archived. Choose another name or restore the type",
    "fr": "Le type que vous avez tenté de créer existe déjà mais est archivé. Choisissez un autre nom ou restaurez le type"
  },
  "type_invalid": {
    "en": "This is not a valid type name",
    "fr": "Ce n'est pas un nom de type valide"
  },
  "unit_archived": {
    "en": "The unit you attempted to create already exist but is archived. Choose another name or restore the unit",
    "fr": "L'unité que vous avez tenté de créer existe déjà mais est archivée. Choisissez un autre nom ou restaurez l'unité"
  },
  "unit_invalid": {
    "en": "This is not a valid unit name",
    "fr": "Ce n'est pas un nom d'unité valide"
  },
  "organization_page_employee_tab": {
    "en": "Employee",
    "fr": "Employé"
  },
  "organization_page_equipment_tab": {
    "en": "Equipment",
    "fr": "Équipement"
  },
  "organization_page_material_tab": {
    "en": "Materials",
    "fr": "Matériaux"
  },
  "organization_page_item_tab": {
    "en": "Items",
    "fr": "Acticles"
  },
  "organization_page_event_tab": {
    "en": "Event Logs",
    "fr": "Événements"
  },
  "send_sms_disabled_tooltip": {
    "en": "Unable to send an SMS for a daily log in the past",
    "fr": "Impossible d'envoyer un SMS pour un journal quotidien dans le passé"
  },
  "header_team_name": {
    "en": "Team name",
    "fr": "Nom de l'équipe"
  },
  "button_previous": {
    "en": "Previous",
    "fr": "Précédent"
  },
  "move_daily_log_success": {
    "en": "Daily log moved successfully",
    "fr": "Le journal quotidien s'est déplacé avec succès"
  },
  "move_daily_log_error": {
    "en": "Sorry, we had an error with the daily log's move",
    "fr": "Désolé, nous avons eu une erreur avec le mouvement du journal quotidien"
  },
  "material_page_supplier": {
    "en": "Supplier",
    "fr": "Fournisseur"
  },
  "material_page_organization": {
    "en": "Organization",
    "fr": "Organisme"
  },
  "material_page_material": {
    "en": "Material",
    "fr": "Matériau"
  },
  "material_page_activity": {
    "en": "Activity",
    "fr": "Activité"
  },
  "material_page_project": {
    "en": "Project",
    "fr": "Projet"
  },
  "material_page_quantity_expected": {
    "en": "Qty expected",
    "fr": "Qté attendu"
  },
  "material_page_quantity_executed": {
    "en": "Qty executed",
    "fr": "Qté exécuté"
  },
  "material_page_options": {
    "en": "Options",
    "fr": "Options"
  },
  "material_page_material_description_success": {
    "en": "Daily log material note updated successfully",
    "fr": "Remarque du matériau de journal quotidien mis à jour avec succès"
  },
  "material_page_material_description_error": {
    "en": "Sorry, we had an error with the daily log material note update",
    "fr": "Désolé, nous avons eu une erreur avec la mise à jour quotidienne du matériau de journal"
  },
  "material_page_note": {
    "en": "Note",
    "fr": "Note"
  },
  "disconnected_title": {
    "en": "You were disconnected from your organization",
    "fr": "Vous étiez déconnecté de votre organisation"
  },
  "disconnected_message": {
    "en": "Try to contact your administrator if you think this is a mistake",
    "fr": "Essayez de contacter votre administrateur si vous pensez que c'est une erreur"
  },
  "action_write_description_placeholder": {
    "en": "Write the note",
    "fr": "Écrivez la note"
  },
  "material_page_subtitle": {
    "en": "Material Info",
    "fr": "Informations matérielles"
  },
  "material_page_material_name": {
    "en": "Material name",
    "fr": "Nom de matériau"
  },
  "material_page_material_name_placeholder": {
    "en": "Write the material name",
    "fr": "Écrivez le nom du matériau"
  },
  "activity_price_type_header": {
    "en": "Set activity price presets",
    "fr": "Définir les préréglages des prix de l'activité"
  },
  "activity_price_none_menu_item": {
    "en": "None",
    "fr": "Aucun"
  },
  "activity_price_no_price_types": {
    "en": "No price types",
    "fr": "Aucun type de prix"
  },
  "activity_edit": {
    "en": "Edit Activity",
    "fr": "Modifier l'activité "
  },
  "activity_darwer_warning": {
    "en": "If you leave the activity, you'll lose your changes",
    "fr": "Si vous quittez l'activité, vous perdrez vos modifications"
  },
  "activity_is_union_work": {
    "en": "Union work",
    "fr": "Travail syndiqué"
  },
  "unexpected_error": {
    "en": "An unexpected error occured",
    "fr": "Une erreur inattendue s'est produite"
  },
  "header_no_team_leader": {
    "en": "No name",
    "fr": "Sans nom"
  },
  "confirmation_comment_text": {
    "en": "You have forget to add the comment.",
    "fr": "Vous avez oublié d'ajouter le commentaire."
  },
  "confirmation_comment_title": {
    "en": "Forget to add the comment",
    "fr": "Oubliez d'ajouter le commentaire"
  },
  "language": {
    "en": "Language",
    "fr": "Langage"
  },
  "update_company_subscription_failed": {
    "en": "TO DELETE",
    "fr": "TO DELETE"
  },
  "maintenance_title": {
    "en": "We are in the update process",
    "fr": "Nous sommes dans le processus de mise à jour"
  },
  "maintenance_message": {
    "en": "Our engineers are working as fast as they can to bring you the latest update.",
    "fr": "Nos ingénieurs travaillent aussi vite que possible pour vous apporter la dernière mise à jour."
  },
  "maintenance_subtitle": {
    "en": "Thank you for your patience.",
    "fr": "Merci pour votre patience."
  },
  "comment_edited_on": {
    "en": "Edited on",
    "fr": "Édité sur"
  },
  "create_password_placeholder": {
    "en": "Write your password",
    "fr": "Écrivez votre mot de passe"
  },
  "create_password_placeholder_confirm": {
    "en": "Write again your password",
    "fr": "Écrivez à nouveau votre mot de passe"
  },
  "header_looker_dashboard_title": {
    "en": "Dashboard",
    "fr": "Tableau de bord"
  },
  "header_looker_explore_title": {
    "en": "Report",
    "fr": "Rapport"
  },
  "choose_company_no_location": {
    "en": "No location",
    "fr": "Aucun emplacement"
  },
  "choose_company_switch_action": {
    "en": "Choose",
    "fr": "Choisir"
  },
  "choose_company_active": {
    "en": "Active",
    "fr": "Actif"
  },
  "choose_company_title": {
    "en": "Choose company workspace",
    "fr": "Choisissez l'espace de travail de l'entreprise"
  },
  "choose_company_current_text": {
    "en": "Currently in",
    "fr": "Actuellement en"
  },
  "choose_company_availables_companies": {
    "en": "Availables companies",
    "fr": "Sociétés disponibles"
  },
  "sidebar_title_planning": {
    "en": "Planning",
    "fr": "Planification"
  },
  "sidebar_title_operations": {
    "en": "Operations",
    "fr": "Opérations"
  },
  "sidebar_title_insight": {
    "en": "Insight",
    "fr": "Observations"
  },
  "sidebar_title_management": {
    "en": "Management",
    "fr": "Gestion"
  },
  "sidebar_title_configuration": {
    "en": "Configuration",
    "fr": "Configuration"
  },
  "sidebar_title_administration": {
    "en": "Administration",
    "fr": "Administration"
  },
  "sidebar_title_development": {
    "en": "Development",
    "fr": "Développement"
  },
  "sidebar_title_plugins": {
    "en": "Plugins",
    "fr": "Plug-ins"
  },
  "sidebar_title_forms": {
    "en": "Forms",
    "fr": "Formulaires"
  },
  "sidebar_navigation": {
    "en": "Navigation",
    "fr": "Navigation"
  },
  "sidebar_weekly_view": {
    "en": "Weekly View",
    "fr": "Vue hebdomadaire"
  },
  "sidebar_teams_view": {
    "en": "Team View",
    "fr": "Vue par équipe"
  },
  "sidebar_projects_view": {
    "en": "Project View",
    "fr": "Vue par projet"
  },
  "sidebar_timesheet": {
    "en": "Payroll",
    "fr": "Paie"
  },
  "sidebar_timesheet_help": {
    "en": "Help",
    "fr": "Aide"
  },
  "sidebar_timesheet_rules": {
    "en": "Pay Rules",
    "fr": "Règles de paie"
  },
  "timesheet_rules_description": {
    "en": "Set pay rules to apply to all work shifts",
    "fr": "Définir les règles de paie à appliquer à tous les quarts de travail"
  },
  "sidebar_employees_worked_hours": {
    "en": "Worked Hours",
    "fr": "Heures travaillées"
  },
  "timesheet_employees_worked_hours_description": {
    "en": "Show total worked hours by employees",
    "fr": "Montrer les heures travaillées des employés par pèriode"
  },
  "timesheet_export_description": {
    "en": "Select options to export data",
    "fr": "Sélectionnez les options pour exporter les données"
  },
  "sidebar_project_controls": {
    "en": "Project Control",
    "fr": "Contrôle du projet"
  },
  "sidebar_project_kanban": {
    "en": "List / Board",
    "fr": "Liste / Tableau"
  },
  "sidebar_project_files": {
    "en": "Project Documents",
    "fr": "Documents du projet"
  },
  "sidebar_project_gantt": {
    "en": "Project Gantt",
    "fr": "Gantt du projet"
  },
  "sidebar_overview_inventory": {
    "en": "Project Overview"
  },
  "sidebar_project_inventory": {
    "en": "Project Small Tools & Supplies",
    "fr": "Petits outils & fournitures du projet"
  },
  "sidebar_information": {
    "en": "Information",
    "fr": "Information"
  },
  "sidebar_management": {
    "en": "Management",
    "fr": "Gestion"
  },
  "sidebar_plugins": {
    "en": "Plugins",
    "fr": "Plugins"
  },
  "sidebar_webhooks": {
    "en": "Webhooks",
    "fr": "Webhooks"
  },
  "sidebar_api_credentials": {
    "en": "API Credentials",
    "fr": "Identifiants d'API"
  },
  "sidebar_auditlog": {
    "en": "Audit Log",
    "fr": "Journal d'audit"
  },
  "sidebar_audit_log": {
    "en": "Audit Log",
    "fr": "Journal d'audit"
  },
  "sidebar_app_store": {
    "en": "App Store",
    "fr": "Magasin d'applications"
  },
  "forget_password_page_information_part_1": {
    "en": "Don't worry. We'll help you.",
    "fr": "Ne vous inquiétez pas. Nous vous aiderons."
  },
  "forget_password_page_information_part_2": {
    "en": "Enter your email address below, and we will send you a reset link for your password.",
    "fr": "Entrez votre adresse email ci-dessous et nous vous enverrons un lien pour réinitialiser votre mot de passe."
  },
  "forget_password_page_title": {
    "en": "Password reset",
    "fr": "Réinitialisation du mot de passe"
  },
  "sidebar_dispatch": {
    "en": "Dispatch",
    "fr": "Dispatch"
  },
  "sidebar_team": {
    "en": "Team",
    "fr": "Équipe"
  },
  "email_sent_page_message": {
    "en": "We just sent you an email with a password reset link.",
    "fr": "Nous venons de vous envoyer un e-mail avec un lien de réinitialisation de mot de passe."
  },
  "email_sent_page_dont_received_message": {
    "en": "I didn't get anything.",
    "fr": "Je n'ai pas reçu d'email."
  },
  "email_sent_page_resend_message": {
    "en": "Send it one more time",
    "fr": "Envoyez-le une fois de plus"
  },
  "creation_password_page_error_required": {
    "en": "This field is required",
    "fr": "Ce champ est requis"
  },
  "creation_password_page_error_min_length": {
    "en": "You need to have at least 9 characters",
    "fr": "Vous devez avoir au moins 9 caractères"
  },
  "creation_password_page_error_confirm_password_same": {
    "en": "Does not match with the password",
    "fr": "Ne correspond pas au mot de passe"
  },
  "creation_password_page_error_confirm_password_required": {
    "en": "You need to confirm your password",
    "fr": "Vous devez confirmer votre mot de passe"
  },
  "creation_password_page_title": {
    "en": "Create your new password",
    "fr": "Créez votre nouveau mot de passe"
  },
  "creation_password_page_welcome_message_part_1": {
    "en": "Welcome",
    "fr": "Bienvenue"
  },
  "creation_password_page_welcome_message_part_2": {
    "en": "It's good to see you",
    "fr": "à Civalgo"
  },
  "creation_password_page_welcome_message_part_3": {
    "en": "Here you can create your Civalgo account password",
    "fr": "Créez votre mot de passe de votre compte Civalgo"
  },
  "creation_password_page_confirm_password": {
    "en": "Confirm your password",
    "fr": "Confirmer votre mot de passe"
  },
  "creation_password_page_requirement": {
    "en": "The password must be at least 9 characters",
    "fr": "Le mot de passe doit comporter au moins 9 caractères"
  },
  "no_sidebar_account": {
    "en": "Do you have an account?",
    "fr": "Avez-vous un compte?"
  },
  "no_sidebar_login": {
    "en": "Login",
    "fr": "Connexion"
  },
  "forget_password_action_reset": {
    "en": "Reset",
    "fr": "Réinitialiser"
  },
  "sidebar_collapse_sidebar": {
    "en": "Collapse sidebar",
    "fr": "Réduire le menu"
  },
  "organization_page_tab": {
    "en": "Organization",
    "fr": "Organisation"
  },
  "sidebar_organizations": {
    "en": "Organizations",
    "fr": "Organisations"
  },
  "sidebar_employees": {
    "en": "Employees",
    "fr": "Employés"
  },
  "sidebar_equipments": {
    "en": "Equipments",
    "fr": "Équipements"
  },
  "sidebar_materials": {
    "en": "Materials",
    "fr": "Matériaux"
  },
  "project_header_project_title": {
    "en": "Project",
    "fr": "Projet"
  },
  "project_header_project_leader": {
    "en": "Project Leader",
    "fr": "Chef de projet"
  },
  "project_header_project_address": {
    "en": "Address",
    "fr": "Adresse"
  },
  "sidebar_close_sidebar": {
    "en": "Close sidebar",
    "fr": "Fermer"
  },
  "timeline_dispatch_results": {
    "en": "totals results",
    "fr": "totaux résultats"
  },
  "report_iframe_error": {
    "en": "Sorry, there was a problem with the loading of the report",
    "fr": "Désolé, il y a eu un problème avec le chargement du rapport"
  },
  "usermenu_switchorg": {
    "en": "Switch Organization",
    "fr": "Changer de compagnie"
  },
  "usermenu_signout": {
    "en": "Sign Out",
    "fr": "Déconnexion"
  },
  "languagepicker_changelanguage": {
    "en": "Change Language",
    "fr": "Changer de language"
  },
  "languagepicker_changelanguageto": {
    "en": "Change language to:",
    "fr": "Changer le language à:"
  },
  "search_employee": {
    "en": "Search for an employee",
    "fr": "Rechercher un employé"
  },
  "search_equipment": {
    "en": "Search for an equipment",
    "fr": "Rechercher un équipment"
  },
  "search_material": {
    "en": "Search for a material",
    "fr": "Rechercher un matériau"
  },
  "search_organization": {
    "en": "Search for an organization",
    "fr": "Rechercher une organisation"
  },
  "search_project": {
    "en": "Search for a project",
    "fr": "Rechercher un projet"
  },
  "search_address": {
    "en": "Search an address",
    "fr": "Rechercher une adresse"
  },
  "sms_send_title": {
    "en": "Send SMS",
    "fr": "Envoyez SMS"
  },
  "sms_send_button_text": {
    "en": "Send the message",
    "fr": "Envoyez le message"
  },
  "sms_template_employee_name": {
    "en": "Employee name",
    "fr": "Nom de l'employé"
  },
  "sms_template_date": {
    "en": "Date",
    "fr": "Date"
  },
  "sms_template_team": {
    "en": "Team",
    "fr": "Équipe"
  },
  "sms_template_team_name": {
    "en": "Team name",
    "fr": "Nom de l'équipe"
  },
  "sms_template_project_code": {
    "en": "Project code",
    "fr": "Code du projet"
  },
  "sms_template_project_name": {
    "en": "Project name",
    "fr": "Nom du projet"
  },
  "sms_template_project_address": {
    "en": "Project address",
    "fr": "Adresse du projet"
  },
  "sms_template_additional_infos": {
    "en": "Additional informations",
    "fr": "Informations supplémentaires"
  },
  "sms_template_hello": {
    "en": "Hello",
    "fr": "Bonjour"
  },
  "sms_template_scheduled": {
    "en": "Your work schedule for",
    "fr": "Votre cédule de travail pour"
  },
  "sms_template_projects": {
    "en": "Projects",
    "fr": "Projets"
  },
  "sms_template_message_sent": {
    "en": "Sent by",
    "fr": "Envoyé par"
  },
  "sms_additional_infos_placeholder": {
    "en": "Add additional information [activities, schedule, etc] (optional)",
    "fr": "Ajouter des informations supplémentaires [activités, calendrier, etc.] (facultatif)"
  },
  "sms_employees": {
    "en": "Employees",
    "fr": "Employés"
  },
  "sms_message": {
    "en": "Message",
    "fr": "Message"
  },
  "no_data": {
    "en": "There is no data to display",
    "fr": "Il n'y a pas de données à afficher"
  },
  "no_phone": {
    "en": "This employee does not have a phone number",
    "fr": "Cet employé n'as pas de numéro de téléphone"
  },
  "organizations_search_placeholder": {
    "en": "Search in organizations",
    "fr": "Rechercher dans les organisations"
  },
  "filter_files": {
    "en": "Filter Files",
    "fr": "Filtrer les fichiers"
  },
  "filter_Label_name": {
    "en": "Show files for:",
    "fr": "Afficher les fichiers pour:"
  },
  "filter_allFiles": {
    "en": "All files",
    "fr": "Tous les fichiers"
  },
  "filter_dailyLog_files": {
    "en": "This daily log",
    "fr": "Ce rapport journalier"
  },
  "filter_dailyLog_project_files": {
    "en": "Daily log projects",
    "fr": "Projets de ce rapport journalier"
  },
  "filter_project_files": {
    "en": "From this Project",
    "fr": "À partir de ce projet"
  },
  "form_label_organization_name": {
    "en": "Organization name",
    "fr": "Nom de l'organisation"
  },
  "form_label_location": {
    "en": "Location",
    "fr": "Emplacement"
  },
  "form_label_note": {
    "en": "Note",
    "fr": "Note"
  },
  "form_label_action": {
    "en": "Actions",
    "fr": "Actions"
  },
  "organizations_action_bar_button_text": {
    "en": "Add Organization",
    "fr": "Ajouter une organisation"
  },
  "action_bar_total_result_label": {
    "en": "result",
    "fr": "résultat"
  },
  "action_bar_total_result_label_plural": {
    "en": "results",
    "fr": "résultats"
  },
  "help_center_label": {
    "en": "Help center",
    "fr": "Centre d'aide"
  },
  "help_label": {
    "en": "Help center",
    "fr": "Centre d'aide"
  },
  "date": {
    "en": "Date",
    "fr": "Date"
  },
  "team": {
    "en": "Team",
    "fr": "Team"
  },
  "teams": {
    "en": "Teams",
    "fr": "Équipes"
  },
  "table_archived_indicator": {
    "en": "Archived",
    "fr": "Archivé"
  },
  "comment_send_button_text": {
    "en": "Send",
    "fr": "Envoyer"
  },
  "comment_input_placeholder": {
    "en": "Add a comment...",
    "fr": "Ajouter un commentaire..."
  },
  "open_daily_log_page": {
    "en": "Open Fullscreen",
    "fr": "Ouvrir plein écran"
  },
  "timeline_add_daily_log_button_text": {
    "en": "Add daily log",
    "fr": "Ajouter"
  },
  "documents_page_empty_folder": {
    "en": "This folder is empty",
    "fr": "Ce dossier est vide"
  },
  "documents_page_drag_here": {
    "en": "Click on this button to add some files",
    "fr": "Cliquez sur ce bouton pour ajouter des fichiers"
  },
  "documents_page_add_files": {
    "en": "Add files",
    "fr": "Ajouter des documents"
  },
  "documents_page_add_files_template": {
    "en": "Add from template",
    "fr": "Ajouter depuis gabarit"
  },
  "documents_page_add_folder": {
    "en": "Add folder",
    "fr": "Ajouter dossier"
  },
  "documents_page_group_by_projects": {
    "en": "Group by projects",
    "fr": "Grouper par projets"
  },
  "documents_header_file_name": {
    "en": "Name",
    "fr": "Nom du fichier"
  },
  "documents_header_file_created": {
    "en": "Created On",
    "fr": "Date de création"
  },
  "documents_header_file_modified": {
    "en": "Last Modified",
    "fr": "Dernière modification"
  },
  "documents_header_file_size": {
    "en": "Size",
    "fr": "Taille"
  },
  "documents_title": {
    "en": "Documents",
    "fr": "Documents"
  },
  "documents_drawer_download_button_label": {
    "en": "Download",
    "fr": "Télécharger"
  },
  "documents_drawer_preview_button_label": {
    "en": "Preview",
    "fr": "Aperçu"
  },
  "documents_context_download_annotated": {
    "en": "Download with annotations",
    "fr": "Télécharger avec annotations"
  },
  "documents_context_open_preview": {
    "en": "Open Preview",
    "fr": "Ouvrir l'aperçu"
  },
  "documents_context_open_folder": {
    "en": "Open Folder",
    "fr": "Ouvrir le dossier"
  },
  "documents_context_download": {
    "en": "Download original",
    "fr": "Télécharger la version originale"
  },
  "documents_context_share": {
    "en": "Share",
    "fr": "Partager"
  },
  "documents_context_move": {
    "en": "Move",
    "fr": "Déplacer"
  },
  "documents_context_copy": {
    "en": "Copy",
    "fr": "Copier"
  },
  "documents_context_rename": {
    "en": "Rename",
    "fr": "Renommer"
  },
  "documents_context_delete": {
    "en": "Delete",
    "fr": "Supprimer"
  },
  "documents_context_show_info": {
    "en": "Show Info",
    "fr": "Plus de détails"
  },
  "documents_context_show_in_daily_logs": {
    "en": "Show in dailylogs",
    "fr": "Afficher dans le rapport journalier"
  },
  "documents_context_hide_in_daily_logs": {
    "en": "Hide in dailylogs",
    "fr": "Cacher du rapport journalier"
  },
  "documents_new_folder_placeholder": {
    "en": "New folder",
    "fr": "Nouveau fichier"
  },
  "documents_input_folder_placeholder": {
    "en": "Folder name",
    "fr": "Nom du dossier"
  },
  "documents_dialog_add_folder_title": {
    "en": "Add a new Folder",
    "fr": "Ajouter un nouveau dossier"
  },
  "documents_input_file_placeholder": {
    "en": "File name",
    "fr": "Nom du fichier"
  },
  "documents_input_default_name_file": {
    "en": "New file",
    "fr": "Nouveau fichier"
  },
  "documents_input_default_name_folder": {
    "en": "New folder",
    "fr": "Nouveau dossier"
  },
  "documents_cancel_search": {
    "en": "Cancel",
    "fr": "Annuler"
  },
  "documents_search_button_tooltip": {
    "en": "Search files",
    "fr": "Rechercher des fichiers"
  },
  "documents_search_input_placeholder": {
    "en": "Search files",
    "fr": "Recherche de fichiers"
  },
  "documents_reset_search": {
    "en": "Reset search",
    "fr": "Annuler la recherche"
  },
  "documents_tooltip_download": {
    "en": "Download the file",
    "fr": "Télécharger le fichier"
  },
  "documents_tooltip_file_info": {
    "en": "File information",
    "fr": "Informations sur le fichier"
  },
  "documents_tooltip_rename": {
    "en": "Rename file",
    "fr": "Renommer le fichier"
  },
  "documents_tooltip_folder_rename": {
    "en": "Rename folder",
    "fr": "Renommer le dossier"
  },
  "documents_tooltip_bin": {
    "en": "Delete files",
    "fr": "Supprimer les fichiers"
  },
  "documents_tooltip_add_folder": {
    "en": "Add a folder",
    "fr": "Ajouter un dossier"
  },
  "documents_tooltip_add_template": {
    "en": "Add a file from a template",
    "fr": "Ajouter un fichier à partir d'un gabarit"
  },
  "documents_tooltip_list_view": {
    "en": "List View",
    "fr": "Vue en liste"
  },
  "documents_tooltip_gallery_view": {
    "en": "Gallery View",
    "fr": "Vue en galerie"
  },
  "documents_tooltip_file_inside_daily_logs": {
    "en": "This file is visible inside the dailylogs.",
    "fr": "Ce fichier est visible dans les rapports journaliers."
  },
  "documents_tooltip_folder_inside_daily_logs": {
    "en": "This folder is visible inside the dailylogs.",
    "fr": "Ce dossier est visible dans les rapports journaliers."
  },
  "dialog_delete_confirm_message": {
    "en": "Please type \"delete\" to confirm.",
    "fr": "Veuillez taper \"delete\" pour confirmer."
  },
  "dialog_delete_confirm_placeholder": {
    "en": "delete",
    "fr": "delete"
  },
  "documents_dialog_delete_title": {
    "en": "Delete files",
    "fr": "Supprimer les fichiers"
  },
  "documents_dialog_delete_text": {
    "en": "Are you sure you want to delete the selected files?",
    "fr": "Êtes-vous sûr que vous voulez supprimer les fichiers sélectionnés?"
  },
  "documents_dialog_download_title": {
    "en": "Download file",
    "fr": "Télécharger le fichier"
  },
  "documents_dialog_download_text": {
    "en": "The file you want to preview is not supported by the file viewer. Would you like to download the file instead?",
    "fr": "Le fichier que vous voulez afficher n'est pas supporté pas le lecteur de fichiers. Voulez-vous le télécharger?"
  },
  "documents_dialog_download_yes": {
    "en": "Download",
    "fr": "Télécharger"
  },
  "documents_dialog_download_no": {
    "en": "Cancel",
    "fr": "Annuler"
  },
  "documents_dialog_add_file_template_title": {
    "en": "Add a file from a template",
    "fr": "Ajouter un fichier à partir d'un gabarit"
  },
  "documents_dialog_add_file_template_name": {
    "en": "File name",
    "fr": "Nom du fichier"
  },
  "documents_dialog_add_file_template_name_placeholder": {
    "en": "filename",
    "fr": "nomdufichier"
  },
  "documents_dialog_add_file_template_template": {
    "en": "Template",
    "fr": "Gabarit"
  },
  "documents_project_filter_label": {
    "en": "Filter projects",
    "fr": "Filtrer les projets"
  },
  "documents_project_filter_placeholder": {
    "en": "All projects",
    "fr": "Tous les projets"
  },
  "documents_file_info_details": {
    "en": "Details",
    "fr": "Détails"
  },
  "documents_file_info_folder_name": {
    "en": "Folder name",
    "fr": "Nom du dossier"
  },
  "documents_file_info_file_name": {
    "en": "File name",
    "fr": "Nom du fichier"
  },
  "documents_file_info_version": {
    "en": "Version",
    "fr": "Version"
  },
  "documents_file_info_type": {
    "en": "Type",
    "fr": "Type"
  },
  "documents_file_info_modify_date": {
    "en": "Modified original",
    "fr": "Modifié originalement le"
  },
  "documents_file_info_dimensions": {
    "en": "Dimensions",
    "fr": "Dimensions"
  },
  "documents_file_info_image_orientation": {
    "en": "Orientation",
    "fr": "Orientation"
  },
  "documents_file_info_gps_coordinates": {
    "en": "GPS coordinates",
    "fr": "Coordonnées GPS"
  },
  "documents_file_info_model": {
    "en": "Model",
    "fr": "modèle"
  },
  "documents_file_info_device_description": {
    "en": "Device information",
    "fr": "Informations sur l'appareil"
  },
  "documents_file_info_image_description": {
    "en": "Image information",
    "fr": "Informations sur l'image"
  },
  "documents_file_info_date_time_original": {
    "en": "Created original",
    "fr": "Créé originalement le"
  },
  "documents_file_info_size": {
    "en": "Size",
    "fr": "Taille"
  },
  "documents_file_info_tags": {
    "en": "Tags",
    "fr": "Tags"
  },
  "documents_file_info_no_tags": {
    "en": "none",
    "fr": "aucun"
  },
  "documents_file_info_creation": {
    "en": "Created",
    "fr": "Créé le"
  },
  "documents_file_info_modification": {
    "en": "Modified",
    "fr": "Modifié le"
  },
  "documents_file_info_toggle_show_in_daily_logs": {
    "en": "Show in Daily Logs",
    "fr": "Afficher dans les rapports journaliers"
  },
  "documents_file_info_toggle_show_in_templates": {
    "en": "Show in template list",
    "fr": "Afficher dans la liste des gabarits"
  },
  "documents_file_info_toggle_read_only": {
    "en": "Read only",
    "fr": "Lecture seule"
  },
  "file_annotations_add_error": {
    "en": "Sorry, we had an error with the annotation addition",
    "fr": "Désolé, nous avons eu une erreur avec l'ajout de l'annotation"
  },
  "file_annotations_set_rotation_error": {
    "en": "Sorry, we had an error with the file rotation update",
    "fr": "Désolé, nous avons eu une erreur avec la mise à jour de la rotation du fichier"
  },
  "employee_list_csv_import_error": {
    "en": "Sorry, we had an error with the csv import",
    "fr": "Désolé, nous avons eu une erreur avec l'importation CSV"
  },
  "file_annotations_update_error": {
    "en": "Sorry, we had an error with the annotation update",
    "fr": "Désolé, nous avons eu une erreur avec la mise à jour des annotations"
  },
  "documents_annotation_file_dialog_title": {
    "en": "Select a file from documents or add new file",
    "fr": "Sélectionnez un fichier de mes documents ou ajouter un nouveau  "
  },
  "documents_add_file__annotation_pdftron": {
    "en": "Add File",
    "fr": "Ajouter un fichier"
  },
  "documents_link_annotation_file_pdftron": {
    "en": "Documents",
    "fr": "Documents"
  },
  "file_annotations_delete_error": {
    "en": "Sorry, we had an error with the annotation deletion",
    "fr": "Désolé, nous avons eu une erreur avec la suppression des annotations"
  },
  "file_form_value_add_error": {
    "en": "Sorry, we had an error with the form value addition",
    "fr": "Désolé, nous avons eu une erreur avec l'ajout de la valeur de formulaire"
  },
  "file_form_value_update_error": {
    "en": "Sorry, we had an error with the form value update",
    "fr": "Désolé, nous avons eu une erreur avec la mise à jour de la valeur de formulaire"
  },
  "file_move_to_error": {
    "en": "Sorry, we had an error with the file move operation",
    "fr": "Désolé, nous avons eu une erreur avec l'opération de déplacement du fichier"
  },
  "print_pdf_combined": {
    "en": "Combined project report",
    "fr": "Rapport avec projets combinés"
  },
  "print_pdf_separated": {
    "en": "Distinct project report",
    "fr": "Rapport avec projets distincts"
  },
  "timeline_block_team_leader_label": {
    "en": "Team Leader",
    "fr": "Chef d'équipe"
  },
  "deleted_comment": {
    "en": "This message has been deleted",
    "fr": "Ce message a été supprimé"
  },
  "deleted_comment_file": {
    "en": "This file has been deleted",
    "fr": "Ce fichier a été supprimé"
  },
  "uploaderdialog_done": {
    "en": "Done",
    "fr": "Terminé"
  },
  "uploaderdialog_title": {
    "en": "Upload Files",
    "fr": "Télécharger des fichiers"
  },
  "uploader_dialog_description_start": {
    "en": "You can only upload",
    "fr": "Vous pouvez télécharger seulement"
  },
  "uploader_dialog_description_end": {
    "en": "file(s) at a time.",
    "fr": "fichier(s) à la fois."
  },
  "uploader_select_project": {
    "en": "Associate a project with the file",
    "fr": "Associer un projet au fichier"
  },
  "timeline_date": {
    "en": "Date",
    "fr": "Date"
  },
  "suggestion_button": {
    "en": "Make a suggestion",
    "fr": "Faire une suggestion"
  },
  "status_button": {
    "en": "Status page",
    "fr": "Page de status"
  },
  "chat_button": {
    "en": "Chat with us",
    "fr": "Discutez avec nous"
  },
  "contact_button": {
    "en": "Contact us",
    "fr": "Contactez-nous"
  },
  "comment_discard_changes_title": {
    "en": "Discard changes",
    "fr": "Annuler les modifications"
  },
  "comment_discard_changes_text": {
    "en": "Do you want to discard your changes ?",
    "fr": "Voulez-vous annuler les modifications ?"
  },
  "comment_discard_changes_yes": {
    "en": "Yes",
    "fr": "Oui"
  },
  "comment_discard_changes_no": {
    "en": "No",
    "fr": "Non"
  },
  "dailylog_section_error": {
    "en": "There was an error with this section. Try reloading the page or contacting support",
    "fr": "Il y eu une erreur avec cette section. Essayez de recharger la page ou de contacter le support"
  },
  "dailylog_section_forbidden": {
    "en": "You are forbidden from seeing this section",
    "fr": "Vous êtes interdit de voir cette section"
  },
  "unread_comment_divider_text": {
    "en": "New Messages",
    "fr": "Nouveaux Messages"
  },
  "yes": {
    "en": "Yes",
    "fr": "Oui"
  },
  "no": {
    "en": "No",
    "fr": "Non"
  },
  "list_edit_type": {
    "en": "Edit types",
    "fr": "Modifier les types"
  },
  "list_edit_unit": {
    "en": "Edit units",
    "fr": "Modifier les unités"
  },
  "bold": {
    "en": "Bold",
    "fr": "Gras"
  },
  "italic": {
    "en": "Italic",
    "fr": "Italique"
  },
  "underline": {
    "en": "Underline",
    "fr": "Souligner"
  },
  "list_bullets": {
    "en": "Bulleted list",
    "fr": "Liste à point"
  },
  "list_numbers": {
    "en": "Ordered list",
    "fr": "Liste ordonnée"
  },
  "edit_role": {
    "en": "Edit roles",
    "fr": "Modifier les rôles"
  },
  "map_section_projects_title": {
    "en": "Projects",
    "fr": "Projets"
  },
  "map_section_organizations_title": {
    "en": "Organizations",
    "fr": "Organisations"
  },
  "projects_no_activity_selected": {
    "en": "No activity selected",
    "fr": "Aucune activité sélectionnée"
  },
  "timeline_block_menu_move": {
    "en": "Move",
    "fr": "Déplacer"
  },
  "timeline_block_menu_copy": {
    "en": "Copy",
    "fr": "Copier"
  },
  "timeline_block_menu_move_error": {
    "en": "Can only move a daily log when state is \"draft\"",
    "fr": "Ne peut déplacer un journal quotidien que l'État est \"brouillon\""
  },
  "timeline_block_menu_delete": {
    "en": "Delete",
    "fr": "Supprimer"
  },
  "timeline_block_menu_add_copy_next_day": {
    "en": "Add copy next day",
    "fr": "Ajouter une copie au prochain jour"
  },
  "timeline_block_menu_error_permission": {
    "en": "You don't have the permission to perform this action",
    "fr": "Vous n'avez pas la permission d'effectuer cette action"
  },
  "timeline_block_menu_edit": {
    "en": "Edit",
    "fr": "Modifier"
  },
  "dailylog_activities_delete_project_title": {
    "en": "Remove project",
    "fr": "Retirer le projet "
  },
  "dailylog_activities_delete_project_text": {
    "en": "Are you sure you want to remove this project?",
    "fr": "Êtes-vous certain de vouloir supprimer ce projet?"
  },
  "dailylog_activities_delete_activity_title": {
    "en": "Remove activity",
    "fr": "Retirer l'activité"
  },
  "dailylog_activities_delete_activity_text": {
    "en": "Are you sure you want to remove this activity?",
    "fr": "Êtes-vous certain de vouloir supprimer cette activité?"
  },
  "create_item_title": {
    "en": "Create new item",
    "fr": "Créer un nouvel élément"
  },
  "edit_item_title": {
    "en": "Edit the item",
    "fr": "Modifier l'article"
  },
  "delete_item_title": {
    "en": "Delete the item",
    "fr": "Supprimer l'élément"
  },
  "delete_item_text": {
    "en": "You're about to delete this item. Are you sure you want to continue?",
    "fr": "Vous êtes sur le point de supprimer cet élément. Êtes-vous sûr de vouloir continuer?"
  },
  "restore_item_title": {
    "en": "Restore the item",
    "fr": "Restaurer l'élément"
  },
  "restore_item_text": {
    "en": "You're about to restore this item. Are you sure you want to continue?",
    "fr": "Vous êtes sur le point de restaurer cet élément. Êtes-vous sûr de vouloir continuer?"
  },
  "search_items": {
    "en": "Search items",
    "fr": "Rechercher des éléments"
  },
  "item_name": {
    "en": "Name",
    "fr": "Nom"
  },
  "item_name_placeholder": {
    "en": "Write the name",
    "fr": "Écris le nom"
  },
  "table_actions": {
    "en": "Actions",
    "fr": "Actions"
  },
  "dailylog_title": {
    "en": "Title",
    "fr": "Titre"
  },
  "dailylog_title_placeholder": {
    "en": "Daily log title",
    "fr": "Titre du rapport journalier"
  },
  "dailylog_description": {
    "en": "Description",
    "fr": "Description"
  },
  "dailylog_description_placeholder": {
    "en": "Daily log description",
    "fr": "Description du rapport journalier"
  },
  "dailylog_replace_employee_placeholder_employee": {
    "en": "Employee",
    "fr": "Employé"
  },
  "dailylog_replace_equipment_placeholder_equipment": {
    "en": "Equipment",
    "fr": "Équipement"
  },
  "dailylog_replace_equipment_placeholder_title": {
    "en": "Replace Equipment Placeholder",
    "fr": "Remplacer l'équipement provisoire"
  },
  "dailylog_replace_employee_placeholder_title": {
    "en": "Replace Employee Placeholder",
    "fr": "Remplacer l'employé provisoire"
  },
  "dailylog_materials_activity": {
    "en": "Activity",
    "fr": "Activité"
  },
  "dailylog_materials_project": {
    "en": "Project",
    "fr": "Projet"
  },
  "dailylog_materials_material_type": {
    "en": "Material type",
    "fr": "Type de matériau"
  },
  "dailylog_materials_material": {
    "en": "Material",
    "fr": "Matériau"
  },
  "dailylog_materials_supplier": {
    "en": "Supplier",
    "fr": "Fournisseur"
  },
  "dailylog_materials_add_dialog_title": {
    "en": "Add material",
    "fr": "Ajouter un matériau"
  },
  "dailylog_materials_edit_dialog_title": {
    "en": "Edit material",
    "fr": "Modifier un matériau"
  },
  "dailylog_materials_delete_dialog_title": {
    "en": "Remove material",
    "fr": "Retirez le matériau"
  },
  "dailylog_materials_delete_dialog_text": {
    "en": "Are you sure you want to remove this material?",
    "fr": "Êtes-vous certain de vouloir supprimer ce matériau?"
  },
  "dailylog_employees_delete_dialog_title": {
    "en": "Remove employee",
    "fr": "Retirez l'employé"
  },
  "dailylog_employees_delete_dialog_text": {
    "en": "Are you sure you want to remove this employee?",
    "fr": "Êtes-vous certain de vouloir supprimer cet employé?"
  },
  "dailylog_equipments_delete_dialog_title": {
    "en": "Remove equipment",
    "fr": "Retirez l'équipement"
  },
  "dailylog_equipments_delete_dialog_text": {
    "en": "Are you sure you want to remove this equipment?",
    "fr": "Êtes-vous certain de vouloir supprimer cet équipement?"
  },
  "dailylog_resources_note_dialog_note": {
    "en": "Note",
    "fr": "Note"
  },
  "dailylog_resources_note_dialog_note_placeholder": {
    "en": "Write the note of this resource",
    "fr": "Écrivez la note de cette ressource"
  },
  "dailylog_edit_block_dialog_title": {
    "en": "Edit a block",
    "fr": "Modifier un bloc"
  },
  "dailylog_add_block_dialog_title": {
    "en": "Add a block",
    "fr": "Ajouter un bloc"
  },
  "dailylog_edit_associated_blocks_dialog_title": {
    "en": "Edit associated blocks",
    "fr": "Modifier les blocs associés"
  },
  "dailylog_edit_associated_blocks_dialog": {
    "en": "Edit associated blocks",
    "fr": "Modifier les blocs associés"
  },
  "dailylog_delete_block_dialog_title": {
    "en": "Remove a block",
    "fr": "Supprimer un bloc"
  },
  "dailylog_delete_block_dialog_text": {
    "en": "Are you sure you want to remove this block ?",
    "fr": "Êtes-vous sûr de vouloir supprimer ce bloc?"
  },
  "dailylog_block_dialog_start": {
    "en": "Start",
    "fr": "De"
  },
  "dailylog_block_dialog_end": {
    "en": "End",
    "fr": "À"
  },
  "dailylog_block_dialog_schedule": {
    "en": "Schedule",
    "fr": "Horaire"
  },
  "dailylog_block_dialog_resource": {
    "en": "Resources",
    "fr": "Ressources"
  },
  "dailylog_block_dialog_resource_add_more": {
    "en": "Add another resource...",
    "fr": "Ajouter une ressource..."
  },
  "dailylog_block_dialog_resource_add": {
    "en": "Add",
    "fr": "Ajouter"
  },
  "dailylog_block_dialog_activity": {
    "en": "Activity",
    "fr": "Activité"
  },
  "dailylog_files_project": {
    "en": "Project",
    "fr": "Projet"
  },
  "dailylog_shared_folder_label": {
    "en": "Shared folder",
    "fr": "Dossier partagé"
  },
  "dailylog_files_select_dailylog_label": {
    "en": "Daily log",
    "fr": "Rapport journalier"
  },
  "files_selected": {
    "en": "Files Selected",
    "fr": "Fichiers sélectionnés"
  },
  "dailylog_back_previous_status": {
    "en": "Back to previous state",
    "fr": "Retour à l'état précédent"
  },
  "dailylog_go_to_price_analysis": {
    "en": "Price validation",
    "fr": "Validation des prix"
  },
  "dailylog_shift": {
    "en": "Shift",
    "fr": "Quart"
  },
  "dailylog_shift_any": {
    "en": "No preference",
    "fr": "Aucune préférence"
  },
  "dailylog_shift_any_description": {
    "en": "No view selected by default when creating a daily log",
    "fr": "Aucune vue de sélectionné par défaut lors de la création d'un rapport journalier"
  },
  "dailylog_shift_day": {
    "en": "Day shift",
    "fr": "Quart de jour"
  },
  "dailylog_shift_day_description": {
    "en": "12 AM - 12 AM",
    "fr": "12 AM - 12 AM"
  },
  "dailylog_shift_night": {
    "en": "Night shift",
    "fr": "Quart de nuit"
  },
  "dailylog_shift_night_description": {
    "en": "12 PM - 12 PM",
    "fr": "12 PM - 12 PM"
  },
  "dailylog_set_shift_success": {
    "en": "Shift updated successfully",
    "fr": "Quart de travail mis à jour avec succès"
  },
  "dailylog_set_shift_error": {
    "en": "Sorry we had an error with the shift update",
    "fr": "Désolé, nous avons eu une erreur avec la mise à jour du quart de travail"
  },
  "tooltip_add": {
    "en": "Add",
    "fr": "Ajouter"
  },
  "tooltip_edit": {
    "en": "Edit",
    "fr": "Modifier"
  },
  "tooltip_restore": {
    "en": "Restore",
    "fr": "Restaurer"
  },
  "tooltip_delete": {
    "en": "Delete",
    "fr": "Effacer"
  },
  "tooltip_sort": {
    "en": "Sort",
    "fr": "Trier"
  },
  "tooltip_close": {
    "en": "Close",
    "fr": "proche"
  },
  "tooltip_tag": {
    "en": "Tag",
    "fr": "Étiquette"
  },
  "tooltip_print": {
    "en": "Print",
    "fr": "Imprimer"
  },
  "tooltip_export_excel": {
    "en": "Export Excel file",
    "fr": "Exporter le fichier Excel"
  },
  "tooltip_send_invite": {
    "en": "Send Invite",
    "fr": "Envoyer l'invitation"
  },
  "tooltip_new_metric_method": {
    "en": "This is a non-standard unit.",
    "fr": "Cette unité est non-standard."
  },
  "tooltip_click_to_update": {
    "en": "Click here to upgrade",
    "fr": "Cliquez ici pour la mettre à jour"
  },
  "employee_types": {
    "en": "Types",
    "fr": "Types"
  },
  "in_my_organization": {
    "en": "In my organization",
    "fr": "Dans mon organization"
  },
  "show_map": {
    "en": "Show on the map",
    "fr": "Afficher sur la carte"
  },
  "color_invalid": {
    "en": "Invalid color",
    "fr": "Couleur invalide"
  },
  "color_dialog_title": {
    "en": "Choose color",
    "fr": "Choisir la couleur"
  },
  "color_dialog_examples": {
    "en": "Color examples",
    "fr": "Exemples de couleur"
  },
  "color_dialog_hexadecimal": {
    "en": "Hex value",
    "fr": "Valeur hexadécimale"
  },
  "location_dialog_title": {
    "en": "Select location",
    "fr": "Sélectionnez l'emplacement"
  },
  "errorpage_404_title": {
    "en": "Uh Oh! Where's the page?",
    "fr": "Euh oh! Où est la page?"
  },
  "errorpage_404_text": {
    "en": "The page you are looking for doesn't seem to exist...",
    "fr": "La page que vous recherchez ne semble pas exister ..."
  },
  "errorpage_404_button_text": {
    "en": "Back to home page",
    "fr": "Retour à la page d'accueil"
  },
  "timeline_upsert_dailylog_team_placeholder": {
    "en": "Choose the team",
    "fr": "Choisissez l'équipe"
  },
  "timeline_upsert_dailylog_project_placeholder": {
    "en": "Choose the projects",
    "fr": "Choisissez les projets"
  },
  "timeline_add_dailylog_dialog_title": {
    "en": "Add a daily log",
    "fr": "Ajouter un rapport journalier"
  },
  "timeline_delete_dailylog_dialog_title": {
    "en": "Delete the daily log",
    "fr": "Supprimer le rapport journalier"
  },
  "timeline_delete_dailylog_dialog_text": {
    "en": "You're about to delete this daily log. Are you sure you want to continue?",
    "fr": "Vous êtes sur le point de supprimer ce rapport journalier. Êtes-vous certain de vouloir continuer?"
  },
  "teams_create_team_button_text": {
    "en": "New team",
    "fr": "Nouvelle équipe"
  },
  "teams_edit_team_button_text": {
    "en": "Edit team",
    "fr": "Modifier l'équipe"
  },
  "teams_delete_team_button_text": {
    "en": "Delete team",
    "fr": "Supprimer l'équipe"
  },
  "teams_search_team_placeholder": {
    "en": "Search a team",
    "fr": "Rechercher une équipe"
  },
  "teams_not_assigned": {
    "en": "Not assigned",
    "fr": "Non affecté"
  },
  "teams_delete_employee_dialog_title": {
    "en": "Remove employee",
    "fr": "Retirer l'employé"
  },
  "teams_delete_employee_dialog_text": {
    "en": "Are you sure you want to remove this employee from the team?",
    "fr": "Êtes-vous certain de vouloir supprimer cet employé de l'équipe?"
  },
  "teams_delete_team_dialog_title": {
    "en": "Delete the team",
    "fr": "Supprimer l'équipe"
  },
  "teams_delete_team_dialog_text": {
    "en": "Are you sure you want to delete this team?",
    "fr": "Êtes-vous certain de vouloir supprimer cette équipe?"
  },
  "teams_restore_team_dialog_title": {
    "en": "Restore the team",
    "fr": "Restaurer l'équipe"
  },
  "teams_restore_team_dialog_text": {
    "en": "Are you sure you want to restore this team?",
    "fr": "Êtes-vous certain de vouloir restaurer cette équipe?"
  },
  "teams_restore_team_dialog_yes": {
    "en": "Yes",
    "fr": "Oui"
  },
  "teams_restore_team_dialog_no": {
    "en": "No",
    "fr": "Non"
  },
  "teams_create_team_dialog_title": {
    "en": "Create a new team",
    "fr": "Créer une nouvelle équipe"
  },
  "teams_edit_team_dialog_title": {
    "en": "Edit the team",
    "fr": "Modifier l'équipe"
  },
  "teams_team_dialog_color": {
    "en": "Color",
    "fr": "Couleur"
  },
  "teams_team_dialog_team_name": {
    "en": "Team name",
    "fr": "Nom de l'équipe"
  },
  "team_name_exist": {
    "en": "Team name already exist",
    "fr": "Le nom de l'équipe existe déjà"
  },
  "teams_team_dialog_team_name_placeholder": {
    "en": "Write the team name",
    "fr": "Écrivez le nom de l'équipe"
  },
  "teams_team_dialog_team_leader": {
    "en": "Team leader",
    "fr": "Chef d'équipe"
  },
  "teams_team_dialog_team_leader_placeholder": {
    "en": "Choose the team leader",
    "fr": "Choisissez le chef d'équipe"
  },
  "teams_team_dialog_scheduler": {
    "en": "Scheduler",
    "fr": "Planificateur"
  },
  "teams_team_dialog_scheduler_placeholder": {
    "en": "Choose the scheduler",
    "fr": "Choisissez le planificateur"
  },
  "teams_team_dialog_employees_members": {
    "en": "Team members",
    "fr": "Membres de l'équipe"
  },
  "teams_team_dialog_employees_list": {
    "en": "List of employees",
    "fr": "Liste des employés"
  },
  "teams_team_dialog_employees_search": {
    "en": "Search employees",
    "fr": "Rechercher des employés"
  },
  "teams_team_dialog_equipments_members": {
    "en": "Team heavy equipment",
    "fr": "Équipements lourds de l'équipe"
  },
  "teams_team_dialog_equipments_list": {
    "en": "List of heavy equipment",
    "fr": "Liste des équipements lourds"
  },
  "teams_team_dialog_equipments_search": {
    "en": "Search equipments",
    "fr": "Rechercher des équipement lourds"
  },
  "teams_team_dialog_resource_already_assigned": {
    "en": "is already assigned to a team",
    "fr": "est déjà dans une équipe"
  },
  "team_dialog_empty_team_employees": {
    "en": "No employees in the team",
    "fr": "Aucun employé dans l'équipe"
  },
  "team_dialog_empty_team_equipments": {
    "en": "No heavy equipment in the team ",
    "fr": "Aucun équipement lourds dans l'équipe"
  },
  "team_dialog_empty_available_employees": {
    "en": "No employees available",
    "fr": "Aucun employé disponible"
  },
  "team_dialog_empty_available_equipments": {
    "en": "No heavy equipment available",
    "fr": "Aucun équipement lourds disponible"
  },
  "activities_search_all": {
    "en": "Search sectors, products or activities",
    "fr": "Rechercher des secteurs, produits ou activités"
  },
  "activities_new_activity": {
    "en": "New activity",
    "fr": "Nouvelle activité"
  },
  "activities_add_activity": {
    "en": "Add activity",
    "fr": "Ajouter une activité"
  },
  "activities_add_activity_to_product": {
    "en": "Add activity to product",
    "fr": "Ajouter une activité au produit"
  },
  "activities_edit_activity": {
    "en": "Edit activity",
    "fr": "Modifier l'activité"
  },
  "activities_delete_activity": {
    "en": "Delete activity",
    "fr": "Supprimer l'activité"
  },
  "activities_restore_activity_title": {
    "en": "Restore activity",
    "fr": "Restaurer l'activité"
  },
  "activities_restore_activity_text": {
    "en": "You're about to restore this activity. Are you sure you want to continue?",
    "fr": "Vous êtes sur le point de restaurer cette activité. Êtes-vous certain de vouloir continuer?"
  },
  "activities_remove_activity_product_title": {
    "en": "Remove activity from product",
    "fr": "Supprimer l'activité du produit"
  },
  "activities_remove_activity_product_text": {
    "en": "You're about to remove this activity from this product. Are you sure you want to continue?",
    "fr": "Vous êtes sur le point de supprimer cette activité de ce produit. Êtes-vous certain de vouloir continuer?"
  },
  "activities_search_activities": {
    "en": "Search activities",
    "fr": "Rechercher des activités"
  },
  "activities_search_products": {
    "en": "Search products, activities or attributes",
    "fr": "Rechercher des produits, activités ou attributs"
  },
  "activities_product_activities": {
    "en": "Product activities",
    "fr": "Activités de produit"
  },
  "activities_add_product_activity": {
    "en": "Add a product activity",
    "fr": "Ajouter une activité de produit"
  },
  "activities_edit_product_activity": {
    "en": "Edit a product activity",
    "fr": "Modifier l'activité de produit"
  },
  "activities_delete_product_activity_title": {
    "en": "Delete product activity",
    "fr": "Supprimer l'activité de produit"
  },
  "activities_delete_product_activity_text": {
    "en": "You're about to delete this product activity. Are you sure you want to continue?",
    "fr": "Vous êtes sur le point de supprimer cette activité de produit. Êtes-vous sûr de vouloir continuer?"
  },
  "activities_general_activities": {
    "en": "General activities",
    "fr": "Activités générales"
  },
  "activities_add_general_activity": {
    "en": "Add a general activity",
    "fr": "Ajouter une activité générale"
  },
  "activities_edit_general_activity": {
    "en": "Edit a general activity",
    "fr": "Modifier l'activité générale"
  },
  "activities_delete_general_activity_title": {
    "en": "Delete a general activity",
    "fr": "Supprimer l'activité générale"
  },
  "activities_delete_general_activity_text": {
    "en": "You're about to delete this general activity. Are you sure you want to continue?",
    "fr": "Vous êtes sur le point de supprimer cette activité générale. Êtes-vous certain de vouloir continuer?"
  },
  "activities_add_sector": {
    "en": "Add sector",
    "fr": "Ajouter un secteur"
  },
  "activities_edit_sector": {
    "en": "Edit sector",
    "fr": "Modifier le secteur"
  },
  "activities_delete_sector_title": {
    "en": "Delete sector",
    "fr": "Supprimer le secteur"
  },
  "activities_delete_sector_text": {
    "en": "You're about to delete this sector. Are you sure you want to continue?",
    "fr": "Vous êtes sur le point de supprimer ce secteur. Êtes-vous certain de vouloir continuer?"
  },
  "activities_restore_sector_title": {
    "en": "Restore sector",
    "fr": "Restaurer le secteur"
  },
  "activities_restore_sector_text": {
    "en": "You're about to restore this sector. Are you sure you want to continue?",
    "fr": "Vous êtes sur le point de restaurer ce secteur. Êtes-vous certain de vouloir continuer?"
  },
  "activities_new_product": {
    "en": "New product",
    "fr": "Nouveau produit"
  },
  "activities_add_product": {
    "en": "Add product",
    "fr": "Ajouter le produit"
  },
  "activities_add_product_to_sector": {
    "en": "Add product to sector",
    "fr": "Ajouter le produit au secteur"
  },
  "activities_edit_product": {
    "en": "Edit product",
    "fr": "Modifier le produit"
  },
  "activities_delete_product_title": {
    "en": "Delete product",
    "fr": "Supprimer le produit"
  },
  "activities_delete_product_text": {
    "en": "You're about to delete this product. Are you sure you want to continue?",
    "fr": "Vous êtes sur le point de supprimer ce produit. Êtes-vous certain de vouloir continuer?"
  },
  "activities_restore_product_title": {
    "en": "Restore product",
    "fr": "Restaurer le produit"
  },
  "activities_restore_product_text": {
    "en": "You're about to restore this product. Are you sure you want to continue?",
    "fr": "Vous êtes sur le point de restaurer ce produit. Êtes-vous certain de vouloir continuer?"
  },
  "activities_upsert_dialog_name_placeholder": {
    "en": "Write the activity name here",
    "fr": "Écrivez le nom d'activité ici"
  },
  "activities_upsert_dialog_product_placeholder": {
    "en": "Write the product name here",
    "fr": "Écrivez le nom du produit ici"
  },
  "activities_upsert_dialog_sector_placeholder": {
    "en": "Write the sector name here",
    "fr": "Écrivez le nom du secteur ici"
  },
  "employees_create_employee_dialog_title": {
    "en": "Create new employee",
    "fr": "Créer un nouvel employé"
  },
  "employees_edit_employee_dialog_title": {
    "en": "Edit the employee",
    "fr": "Modifier l'employé"
  },
  "employees_upsert_dialog_name_placeholder": {
    "en": "Write the employee's full name here",
    "fr": "Écrivez le nom complet de l'employé ici"
  },
  "employees_upsert_dialog_role_placeholder": {
    "en": "Choose the role",
    "fr": "Choisissez le rôle"
  },
  "employees_upsert_dialog_email_placeholder": {
    "en": "Write the employee's email address here",
    "fr": "Écrivez l'adresse e-mail de l'employé ici"
  },
  "employees_upsert_dialog_phone_placeholder": {
    "en": "Write the employee's phone number here",
    "fr": "Écrivez le numéro de téléphone de l'employé ici"
  },
  "employees_upsert_dialog_organization_placeholder": {
    "en": "Choose the organization",
    "fr": "Choisissez l'organisation"
  },
  "employees_delete_employee_dialog_title": {
    "en": "Delete the employee",
    "fr": "Supprimer l'employé"
  },
  "employees_delete_employee_dialog_text": {
    "en": "You're about to delete this employee. Are you sure you want to continue?",
    "fr": "Vous êtes sur le point de supprimer cet employé. Êtes-vous certain de vouloir continuer?"
  },
  "employees_search_employee": {
    "en": "Search an employee",
    "fr": "Rechercher un employé"
  },
  "employees_new_employee": {
    "en": "New employee",
    "fr": "Nouvel employé"
  },
  "equipments_upsert_dialog_name_placeholder": {
    "en": "Write the equipment's name here",
    "fr": "Écrivez le nom de l'équipement ici"
  },
  "equipments_upsert_dialog_type_placeholder": {
    "en": "Choose the type",
    "fr": "Choisissez le type"
  },
  "equipments_upsert_dialog_model_placeholder": {
    "en": "Write the equipment's model here",
    "fr": "Écrivez ici le modèle de l'équipement"
  },
  "equipments_upsert_dialog_brand_placeholder": {
    "en": "Write the equipment's brand here",
    "fr": "Écrivez ici la marque de l'équipement"
  },
  "equipments_upsert_dialog_organization_placeholder": {
    "en": "Choose the organization",
    "fr": "Choisissez l'organisation"
  },
  "equipments_upsert_dialog_team_placeholder": {
    "en": "Choose the team",
    "fr": "Choisissez l'équipe"
  },
  "equipments_search_equipment": {
    "en": "Search an equipment",
    "fr": "Rechercher un équipement"
  },
  "equipments_new_equipment": {
    "en": "New equipment",
    "fr": "Nouvel équipement"
  },
  "equipments_add_equipment_dialog_title": {
    "en": "Create a new equipment",
    "fr": "Créer un nouvel équipement"
  },
  "equipments_edit_equipment_dialog_title": {
    "en": "Edit the equipment",
    "fr": "Modifier l'équipement"
  },
  "equipments_delete_equipment_dialog_title": {
    "en": "Delete the equipment",
    "fr": "Supprimer l'équipement"
  },
  "equipments_delete_equipment_dialog_text": {
    "en": "You're about to delete this equipment. Are you sure you want to continue?",
    "fr": "Vous êtes sur le point de supprimer cet équipement. Êtes-vous certain de vouloir continuer?"
  },
  "materials_upsert_dialog_name_placeholder": {
    "en": "Write the material's name here",
    "fr": "Écrivez le nom du matériau ici"
  },
  "materials_upsert_dialog_type_placeholder": {
    "en": "Choose the type",
    "fr": "Choisissez le type"
  },
  "materials_upsert_dialog_unit_placeholder": {
    "en": "Choose the unit",
    "fr": "Choisissez l'unité"
  },
  "materials_upsert_dialog_organizations_placeholder": {
    "en": "Choose the organizations",
    "fr": "Choisissez les organisations"
  },
  "materials_search_material": {
    "en": "Search a material",
    "fr": "Rechercher un matériau"
  },
  "materials_new_material": {
    "en": "New material",
    "fr": "Nouveau matériau"
  },
  "materials_delete_material_dialog_title": {
    "en": "Delete the material",
    "fr": "Supprimer le matériau"
  },
  "materials_delete_material_dialog_text": {
    "en": "You're about to delete this material. Are you sure you want to continue?",
    "fr": "Vous êtes sur le point de supprimer ce matériau. Êtes-vous certain de vouloir continuer?"
  },
  "projects_upsert_dialog_code_placeholder": {
    "en": "Write the project's code here",
    "fr": "Écrivez le code du projet ici"
  },
  "projects_upsert_dialog_name_placeholder": {
    "en": "Write the project's name here",
    "fr": "Écrivez le nom du projet ici"
  },
  "projects_upsert_dialog_manager_placeholder": {
    "en": "Choose the project manager",
    "fr": "Choisissez le gestionnaire de projet"
  },
  "projects_upsert_dialog_location_placeholder": {
    "en": "Write the project's address here",
    "fr": "Écrivez l'adresse du projet ici"
  },
  "projects_upsert_dialog_client_placeholder": {
    "en": "Choose the client organization",
    "fr": "Choisissez l'organisation client"
  },
  "projects_search_project": {
    "en": "Search a project",
    "fr": "Rechercher un projet"
  },
  "projects_new_project": {
    "en": "New project",
    "fr": "Nouveau projet"
  },
  "projects_add_project_dialog_title": {
    "en": "Create a new project",
    "fr": "Créer un nouveau projet"
  },
  "projects_edit_project_dialog_title": {
    "en": "Edit the project",
    "fr": "Modifier le projet"
  },
  "projects_delete_project_dialog_title": {
    "en": "Delete the project",
    "fr": "Supprimer le projet"
  },
  "projects_delete_project_dialog_text": {
    "en": "You're about to delete this project. Are you sure you want to continue?",
    "fr": "Vous êtes sur le point de supprimer ce projet. Êtes-vous certain de vouloir continuer?"
  },
  "project_add_activity_dialog_title": {
    "en": "Add activity",
    "fr": "Ajouter une activité"
  },
  "project_add_activity_to_product_dialog_title": {
    "en": "Add activity to product",
    "fr": "Ajouter une activité au produit"
  },
  "organizations_name_placeholder": {
    "en": "Write the organization's name here",
    "fr": "Écrivez le nom de l'organisation ici"
  },
  "organizations_location_placeholder": {
    "en": "Write the organization's location here",
    "fr": "Écrivez l'emplacement de l'organisation ici"
  },
  "organizations_note_placeholder": {
    "en": "Write the note about the organization here",
    "fr": "Écrivez la note sur l'organisation ici"
  },
  "organizations_add_dialog_title": {
    "en": "Create a new organization",
    "fr": "Créer une nouvelle organisation"
  },
  "organizations_edit_dialog_title": {
    "en": "Edit the organization",
    "fr": "Modifier l'organisation"
  },
  "organizations_new_organization": {
    "en": "New organization",
    "fr": "Nouvelle organisation"
  },
  "organizations_delete_dialog_title": {
    "en": "Delete the organization",
    "fr": "Supprimer l'organisation"
  },
  "organizations_delete_dialog_text": {
    "en": "You're about to delete this organization. Are you sure you want to continue?",
    "fr": "Vous êtes sur le point de supprimer cette organisation. Êtes-vous certain de vouloir continuer?"
  },
  "organization_note_placeholder": {
    "en": "Write the note",
    "fr": "Écrire la note"
  },
  "organization_error_not_exist": {
    "en": "Organization does not exist",
    "fr": "L'organisation n'existe pas"
  },
  "organization_add_price_dialog_title": {
    "en": "Add price",
    "fr": "Ajouter des prix"
  },
  "organization_delete_price_dialog_title": {
    "en": "Delete price",
    "fr": "Supprimer le prix"
  },
  "organization_delete_price_dialog_text": {
    "en": "You're about to delete this price. Are you sure you want to continue?",
    "fr": "Vous êtes sur le point de supprimer ce prix. Êtes-vous certain de vouloir continuer?"
  },
  "organization_set_price": {
    "en": "Set price",
    "fr": "Fixer le prix"
  },
  "organization_remove_price": {
    "en": "Remove price",
    "fr": "Supprimer le prix"
  },
  "priceanalysis_header_date": {
    "en": "Date",
    "fr": "Date"
  },
  "priceanalysis_employees_cost": {
    "en": "Employees cost",
    "fr": "Coût des employés"
  },
  "priceanalysis_placeholders_cost": {
    "en": "Placeholders cost",
    "fr": "Coût des ressources provisoires"
  },
  "priceanalysis_equipments_cost": {
    "en": "Equipment cost",
    "fr": "Coût des équipements"
  },
  "priceanalysis_materials_cost": {
    "en": "Material cost",
    "fr": "Coût des matériaux"
  },
  "priceanalysis_total_cost": {
    "en": "Total cost",
    "fr": "Coût total"
  },
  "priceanalysis_total_hours": {
    "en": "Total hours",
    "fr": "Heures totales"
  },
  "priceanalysis_quantity_used": {
    "en": "Quantity used",
    "fr": "Quantité utilisée"
  },
  "priceanalysis_costs_details": {
    "en": "Costs details",
    "fr": "Détails des coûts"
  },
  "priceanalysis_costs_label": {
    "en": "Costs",
    "fr": "Coûts"
  },
  "priceanalysis_validate_costs_button_text": {
    "en": "Validate costs",
    "fr": "Valider les coûts"
  },
  "priceanalysis_apply_preset_button_text": {
    "en": "Apply price presets",
    "fr": "Appliquer des préréglages de prix"
  },
  "priceanalysis_apply_preset_dialog_title": {
    "en": "Apply price presets",
    "fr": "Appliquer des préréglages de prix"
  },
  "priceanalysis_apply_preset_dialog_text": {
    "en": "Applying price presets will erase any existing price on the current daily log. Are you sure you want to continue?",
    "fr": "L'application des préréglages de prix effacera tout prix existant sur le journal quotidien actuel. Es-tu sur de vouloir continuer?"
  },
  "priceanalysis_no_preset": {
    "en": "No preset",
    "fr": "Pas de présélection"
  },
  "priceanalysis_no_cost": {
    "en": "No price",
    "fr": "Pas de prix"
  },
  "priceanalysis_not_corresponding": {
    "en": "Price doesn't correspond to preset",
    "fr": "Le prix ne correspond pas à la présélection"
  },
  "priceanalysis_table_header_cost": {
    "en": "Costs",
    "fr": "Coût"
  },
  "priceanalysis_table_header_unit_price": {
    "en": "Unit Price",
    "fr": "Prix unitaire"
  },
  "common_updated_at": {
    "en": "Updated",
    "fr": "Modifié"
  },
  "common_unit": {
    "en": "Unit",
    "fr": "Unité"
  },
  "common_dates": {
    "en": "Dates",
    "fr": "Dates"
  },
  "common_activity": {
    "en": "Activity",
    "fr": "Activité"
  },
  "common_activity_name": {
    "en": "Activity name",
    "fr": "Nom de l'activité"
  },
  "common_activity_unit": {
    "en": "Unit",
    "fr": "Unité"
  },
  "common_activity_code": {
    "en": "Code",
    "fr": "Code"
  },
  "common_activities": {
    "en": "Activities",
    "fr": "Activités"
  },
  "common_code": {
    "en": "Code",
    "fr": "Code"
  },
  "common_comment": {
    "en": "Comment",
    "fr": "commentaire"
  },
  "common_product": {
    "en": "Product",
    "fr": "Produit"
  },
  "common_product_name": {
    "en": "Product name",
    "fr": "Nom du produit"
  },
  "common_product_code": {
    "en": "Product code",
    "fr": "Code du produit"
  },
  "common_placeholders": {
    "en": "Placeholders",
    "fr": "Ressources provisoires"
  },
  "common_products": {
    "en": "Products",
    "fr": "Produits"
  },
  "common_sector": {
    "en": "Sector",
    "fr": "Secteur"
  },
  "common_sector_name": {
    "en": "Sector name",
    "fr": "Nom du secteur"
  },
  "common_sectors": {
    "en": "Sectors",
    "fr": "Secteurs"
  },
  "common_product_unit": {
    "en": "Unit",
    "fr": "Unité"
  },
  "common_product_units": {
    "en": "Units",
    "fr": "Unités"
  },
  "common_equipment": {
    "en": "Equipment",
    "fr": "Équipement"
  },
  "common_equipment_icon": {
    "en": "Avatar",
    "fr": "Avatar"
  },
  "common_equipment_name": {
    "en": "Name",
    "fr": "Nom"
  },
  "common_equipment_model": {
    "en": "Model",
    "fr": "Modèle"
  },
  "common_equipment_brand": {
    "en": "Brand",
    "fr": "Marque"
  },
  "common_equipment_plate_number": {
    "en": "Plate Number",
    "fr": "Plaque d'immatriculation"
  },
  "common_equipment_vehicle_identification_number": {
    "en": "Vehicle Identification Number",
    "fr": "Numéro d’identification du véhicule"
  },
  "common_equipment_type": {
    "en": "Type",
    "fr": "Type"
  },
  "common_equipment_types": {
    "en": "Types",
    "fr": "Types"
  },
  "common_equipments": {
    "en": "Equipments",
    "fr": "Équipements"
  },
  "common_employee": {
    "en": "Employee",
    "fr": "Employé"
  },
  "common_employee_icon": {
    "en": "Avatar",
    "fr": "Avatar"
  },
  "common_employee_operates": {
    "en": "Operates",
    "fr": "Opère"
  },
  "common_employee_code": {
    "en": "Code",
    "fr": "Code"
  },
  "common_employee_name": {
    "en": "Name",
    "fr": "Nom"
  },
  "common_employee_email": {
    "en": "Email address",
    "fr": "Adresse courriel"
  },
  "common_employee_phone": {
    "en": "Phone number",
    "fr": "Numéro de téléphone"
  },
  "common_employee_role_code": {
    "en": "Role code",
    "fr": "Code du rôle"
  },
  "common_employee_role": {
    "en": "Role",
    "fr": "Rôle"
  },
  "common_employee_roles": {
    "en": "Roles",
    "fr": "Rôles"
  },
  "common_employees": {
    "en": "Employees",
    "fr": "Employés"
  },
  "common_export_button": {
    "en": "Export",
    "fr": "Exporter"
  },
  "common_pension": {
    "en": "Pension",
    "fr": "Prime"
  },
  "common_pension_name": {
    "en": "Name",
    "fr": "Nom"
  },
  "common_pensions": {
    "en": "Pensions",
    "fr": "Primes"
  },
  "common_material": {
    "en": "Material",
    "fr": "Matériau"
  },
  "common_materials": {
    "en": "Materials",
    "fr": "Matériaux"
  },
  "common_job_block": {
    "en": "Job block",
    "fr": "Bloc de tâches"
  },
  "common_job_blocks": {
    "en": "Job blocks",
    "fr": "Blocs de tâches"
  },
  "common_material_name": {
    "en": "Name",
    "fr": "Nom"
  },
  "common_material_type": {
    "en": "Type",
    "fr": "Type"
  },
  "common_material_types": {
    "en": "Types",
    "fr": "Types"
  },
  "common_material_unit": {
    "en": "Unit",
    "fr": "Unité"
  },
  "common_material_units": {
    "en": "Units",
    "fr": "Unités"
  },
  "common_material_operated_by": {
    "en": "Operated by",
    "fr": "Opéré par"
  },
  "common_material_supplier": {
    "en": "Supplier",
    "fr": "Fournisseur"
  },
  "common_team": {
    "en": "Team",
    "fr": "Équipe"
  },
  "common_team_name": {
    "en": "Team name",
    "fr": "Nom de l'équipe"
  },
  "common_team_color": {
    "en": "Color",
    "fr": "Couleur"
  },
  "common_teams": {
    "en": "Teams",
    "fr": "Équipes"
  },
  "common_team_leader": {
    "en": "Team leader",
    "fr": "Chef d'équipe"
  },
  "common_multiple_holidays": {
    "en": "Multiple Holidays",
    "fr": "Plusieurs jours fériés"
  },
  "teams_dialog_department": {
    "en": "Department",
    "fr": "Département"
  },
  "common_from": {
    "en": "From",
    "fr": "De"
  },
  "common_to": {
    "en": "To",
    "fr": "À"
  },
  "common_template": {
    "en": "Template",
    "fr": "Gabarit"
  },
  "common_scheduler": {
    "en": "Scheduler",
    "fr": "Planificateur"
  },
  "common_organization": {
    "en": "Organization",
    "fr": "Organisation"
  },
  "common_organization_name": {
    "en": "Name",
    "fr": "Nom"
  },
  "common_organization_location": {
    "en": "Location",
    "fr": "Emplacement"
  },
  "common_organization_note": {
    "en": "Note",
    "fr": "Note"
  },
  "common_organizations": {
    "en": "Organizations",
    "fr": "Organisations"
  },
  "common_project": {
    "en": "Project",
    "fr": "Projet"
  },
  "common_project_contract_number": {
    "en": "Contract Number",
    "fr": "Numéro du contrat"
  },
  "common_project_contract_name": {
    "en": "Contract Name",
    "fr": "Nom du contrat"
  },
  "common_project_color": {
    "en": "Color",
    "fr": "Couleur"
  },
  "common_project_code": {
    "en": "Code",
    "fr": "Code"
  },
  "common_project_name": {
    "en": "Name",
    "fr": "Nom"
  },
  "common_project_manager": {
    "en": "Project Manager",
    "fr": "Gestionnaire de projet"
  },
  "common_project_location": {
    "en": "Address",
    "fr": "Adresse"
  },
  "common_project_client": {
    "en": "Client",
    "fr": "Client"
  },
  "common_project_product_alias": {
    "en": "Alias",
    "fr": "Alias"
  },
  "common_projects": {
    "en": "Projects",
    "fr": "Projets"
  },
  "common_timesheet_rule_name": {
    "en": "Name",
    "fr": "Nom"
  },
  "common_timesheet_rule_output_name": {
    "en": "Name",
    "fr": "Nom"
  },
  "common_timesheet_rule_output_description": {
    "en": "Description",
    "fr": "Description"
  },
  "common_timesheet_rule_output_variable_name": {
    "en": "Variable name",
    "fr": "Nom de la variable"
  },
  "common_permission": {
    "en": "Permission",
    "fr": "Permission"
  },
  "common_dailylog": {
    "en": "Daily Log",
    "fr": "Rapport journalier"
  },
  "common_dailylog_date": {
    "en": "Date",
    "fr": "Date"
  },
  "common_dailylog_status": {
    "en": "Status",
    "fr": "Statut"
  },
  "common_dailylog_block_start": {
    "en": "Start",
    "fr": "Début"
  },
  "common_dailylog_block_end": {
    "en": "End",
    "fr": "Fin"
  },
  "common_property_name": {
    "en": "Name",
    "fr": "Nom"
  },
  "common_attribute_value": {
    "en": "Value",
    "fr": "Valeur"
  },
  "common_weekday_sunday": {
    "en": "Sunday",
    "fr": "Dimanche"
  },
  "common_weekday_monday": {
    "en": "Monday",
    "fr": "Lundi"
  },
  "common_weekday_tuesday": {
    "en": "Tuesday",
    "fr": "Mardi"
  },
  "common_weekday_wednesday": {
    "en": "Wednesday",
    "fr": "Mercredi"
  },
  "common_weekday_thursday": {
    "en": "Thursday",
    "fr": "Jeudi"
  },
  "common_weekday_friday": {
    "en": "Friday",
    "fr": "Vendredi"
  },
  "common_weekday_saturday": {
    "en": "Saturday",
    "fr": "Samedi"
  },
  "common_year": {
    "en": "Year",
    "fr": "Année"
  },
  "common_month": {
    "en": "Month",
    "fr": "Mois"
  },
  "common_week": {
    "en": "Week",
    "fr": "Semaine"
  },
  "common_quarter": {
    "en": "Quarter",
    "fr": "Trimestre"
  },
  "common_category": {
    "en": "Category",
    "fr": "Catégorie"
  },
  "common_operator": {
    "en": "Operator",
    "fr": "Opérateur"
  },
  "common_custom": {
    "en": "Custom",
    "fr": "Personnalisé"
  },
  "common_system": {
    "en": "System",
    "fr": "Système"
  },
  "auditlog_author": {
    "en": "Author",
    "fr": "Auteur"
  },
  "auditlog_action": {
    "en": "Action",
    "fr": "Action"
  },
  "auditlog_description": {
    "en": "Description",
    "fr": "Description"
  },
  "auditlog_entityName": {
    "en": "Entity Name",
    "fr": "Nom de l'entité"
  },
  "auditlog_timestamp": {
    "en": "Timestamp",
    "fr": "Date"
  },
  "auditlog_file_name": {
    "en": "auditlog",
    "fr": "audit"
  },
  "auditlog_type_userEvent": {
    "en": "User",
    "fr": "Utilisateur"
  },
  "auditlog_type_dailyLogEvent": {
    "en": "Daily Log",
    "fr": "Rapport journalier"
  },
  "auditlog_type_zipBuildEvent": {
    "en": "Folder download",
    "fr": "Dossier téléchargé"
  },
  "auditlog_action_create": {
    "en": "created",
    "fr": "créé"
  },
  "auditlog_action_update": {
    "en": "updated",
    "fr": "modifié"
  },
  "auditlog_action_delete": {
    "en": "deleted",
    "fr": "supprimé"
  },
  "auditlog_action_permissionChanged": {
    "en": "Permissions set to",
    "fr": "Permissions définies à"
  },
  "common_auditlog_error": {
    "en": "Sorry, we had an error while loading audit log",
    "fr": "Désolé, nous avons eu une erreur lors du chargement du journal d'audit"
  },
  "common_auditlog_placeholder_filter": {
    "en": "Select a user",
    "fr": "Sélectionner un utilisateur"
  },
  "common_webhook_name": {
    "en": "Name",
    "fr": "Nom"
  },
  "common_webhook_url": {
    "en": "Url",
    "fr": "Url"
  },
  "common_webhook_event_list": {
    "en": "Events",
    "fr": "Évènements"
  },
  "common_webhook_security_header_value": {
    "en": "Security Header",
    "fr": "Entête de sécurité"
  },
  "common_api_credential_name": {
    "en": "Name",
    "fr": "Nom"
  },
  "common_api_credential_token": {
    "en": "Client ID",
    "fr": "Identifiant du client"
  },
  "common_api_credential_permissions": {
    "en": "Permissions",
    "fr": "Permissions"
  },
  "common_api_credential_owner": {
    "en": "Owner",
    "fr": "Propriétaire"
  },
  "common_api_credential_expired_at": {
    "en": "Expires on",
    "fr": "Expire le"
  },
  "common_application_name": {
    "en": "Name",
    "fr": "Nom"
  },
  "common_plugin_name": {
    "en": "Name",
    "fr": "Nom"
  },
  "common_plugin_url": {
    "en": "Url",
    "fr": "Url"
  },
  "common_plugin_navigation_section": {
    "en": "Section",
    "fr": "Section"
  },
  "common_file_version": {
    "en": "Version",
    "fr": "Version"
  },
  "common_file_version_number": {
    "en": "Version Number",
    "fr": "Numéro de version"
  },
  "common_file_created_by": {
    "en": "Created by",
    "fr": "Créer par"
  },
  "common_action_complete": {
    "en": "Complete",
    "fr": "Compléter"
  },
  "common_action_back": {
    "en": "Back",
    "fr": "Retour"
  },
  "common_action_add": {
    "en": "Add",
    "fr": "Ajouter"
  },
  "services_item_inventory_remove_success": {
    "en": "Item(s) removed successfully",
    "fr": "Article(s) retiré(s) avec succès"
  },
  "services_item_inventory_remove_error": {
    "en": "Sorry, we had an error with the removal of the item",
    "fr": "Désolé, nous avons eu une erreur avec le retrait d'article(s)"
  },
  "services_item_inventory_consolidates_success": {
    "en": "Item consolidated successfully",
    "fr": "L'article a été consolidé avec succès"
  },
  "services_item_inventory_consolidate_error": {
    "en": "Sorry, we had an error with the consolidation of the item",
    "fr": "Désolé, nous avons eu une erreur avec la consolidation de l'article"
  },
  "services_item_inventory_consume_success": {
    "en": "Item consumed successfully",
    "fr": "L'article a été consommé avec succès"
  },
  "services_item_inventory_consume_error": {
    "en": "Sorry, we had an error with the consumption of the item",
    "fr": "Désolé, nous avons eu une erreur avec la consommation de l'article"
  },
  "services_item_inventory_receive_success": {
    "en": "Transfer received successfully",
    "fr": "Le transfert a été reçu avec succès"
  },
  "services_item_inventory_receive_error": {
    "en": "Sorry, we had an error with the reception of the transfer",
    "fr": "Désolé, nous avons eu une erreur avec la réception du transfert"
  },
  "services_item_type_insert_success": {
    "en": "Item created successfully",
    "fr": "L'article a été créée avec succès"
  },
  "services_item_type_insert_error": {
    "en": "Sorry, we had an error with the item creation",
    "fr": "Désolé, nous avons eu une erreur avec la création de l'article "
  },
  "services_item_type_update_success": {
    "en": "Item updated successfully",
    "fr": "L'article a été mis à jour avec succès"
  },
  "services_item_type_update_error": {
    "en": "Sorry, we had an error with the item update",
    "fr": "Désolé, nous avons eu une erreur avec la mise à jour de l'article "
  },
  "services_item_type_delete_success": {
    "en": "Item deleted successfully",
    "fr": "L'article a été supprimée avec succès"
  },
  "services_item_type_delete_error": {
    "en": "Sorry, we had an error with the item deletion",
    "fr": "Désolé, nous avons eu une erreur avec la suppression de l'article "
  },
  "services_item_type_restore_success": {
    "en": "Item restored successfully",
    "fr": "L'article a été restauré avec succès"
  },
  "services_item_type_restore_error": {
    "en": "Sorry, we had an error with the item restoration",
    "fr": "Désolé, nous avons eu une erreur avec la restauration de l'article"
  },
  "services_configuration_update_error": {
    "en": "Sorry, we had an error with the user configuration update",
    "fr": "Désolé, nous avons eu une erreur avec la mise à jour de votre configuration"
  },
  "services_permission_update_success": {
    "en": "Permission updated successfully",
    "fr": "Permission mise à jour avec succès"
  },
  "services_permission_update_error": {
    "en": "Sorry we had an error with the permission update",
    "fr": "Désolé, nous avons eu une erreur avec la mise à jour de la permission"
  },
  "services_logout_success": {
    "en": "Logged out successfully",
    "fr": "Déconnecté avec succès"
  },
  "services_logout_error": {
    "en": "Sorry, we had an error with the logout",
    "fr": "Désolé, nous avons eu une erreur avec la déconnexion"
  },
  "services_invite_user_success": {
    "en": "Your user invite was successfully sent",
    "fr": "Votre invitation utilisateur a été envoyée avec succès"
  },
  "services_invite_user_error": {
    "en": "Sorry, we had an error with the invite request",
    "fr": "Désolé, nous avons eu une erreur avec votre demande d'invitation"
  },
  "services_confirm_signup_error": {
    "en": "Sorry, we had an error with the sign up confirmation",
    "fr": "Désolé, nous avons eu une erreur avec la confirmation de l'inscription"
  },
  "services_user_delete_success": {
    "en": "User deleted successfully",
    "fr": "L'utilisateur a été supprimé avec succès"
  },
  "services_user_delete_error": {
    "en": "Sorry, we had an error with the user deletion",
    "fr": "Désolé, nous avons eu une erreur avec la suppression de l'utilisateur"
  },
  "services_send_reset_password_success": {
    "en": "An email has been sent with a reset link",
    "fr": "Un courriel a été envoyé avec un lien de réinitialisation"
  },
  "services_send_reset_password_error": {
    "en": "Sorry, we are not able to send a email",
    "fr": "Désolé, nous ne sommes pas en mesure d'envoyer le courriel"
  },
  "services_send_reset_password_limit_exceeded": {
    "en": "Number of reset password requests exceed limit",
    "fr": "Nombre de demandes de réinitialisation de mot de passe a dépasser la limite"
  },
  "services_reset_password_success": {
    "en": "Your password had been successfully updated",
    "fr": "Votre mot de passe a été mis à jour avec succès"
  },
  "services_reset_password_error": {
    "en": "Error occurred during confirmation. The code might have expired.",
    "fr": "Une erreur est survenue lors de la confirmation. Le code pourrait être expiré."
  },
  "services_reset_password_invalid_link": {
    "en": "Email link is invalid",
    "fr": "Lien du courriel est invalide"
  },
  "services_reset_password_expired_code": {
    "en": "Invalid or expired reset link. Please try to reset the password again.",
    "fr": "Lien de réinitialisation invalide ou expiré. S'il vous plaît essayer de réinitialiser le mot de passe."
  },
  "services_team_insert_success": {
    "en": "Team created successfully",
    "fr": "L'équipe a été créée avec succès"
  },
  "services_team_insert_error": {
    "en": "Sorry, we had an error with the team creation",
    "fr": "Désolé, nous avons eu une erreur avec la création de l'équipe"
  },
  "services_team_update_success": {
    "en": "Team updated successfully",
    "fr": "L'équipe a été mise à jour avec succès"
  },
  "services_team_update_error": {
    "en": "Sorry, we had an error with the team update",
    "fr": "Désolé, nous avons eu une erreur avec la mise à jour de l'équipe"
  },
  "services_team_delete_success": {
    "en": "Team deleted successfully",
    "fr": "L'équipe a été supprimée avec succès"
  },
  "services_team_delete_error": {
    "en": "Sorry, we had an error with the team deletion",
    "fr": "Désolé, nous avons eu une erreur avec la suppression de l'équipe"
  },
  "services_team_restore_success": {
    "en": "Team restored successfully",
    "fr": "L'équipe restaurée avec succès"
  },
  "services_team_restore_error": {
    "en": "Sorry, we had an error with the team restoration",
    "fr": "Désolé, nous avons eu une erreur avec la restauration de l'équipe"
  },
  "services_sector_insert_success": {
    "en": "Sector created successfully",
    "fr": "Le secteur a créé avec succès"
  },
  "services_sector_insert_error": {
    "en": "Sorry, we had an error with the sector creation",
    "fr": "Désolé, nous avons eu une erreur avec la création du secteur"
  },
  "services_sector_update_success": {
    "en": "Sector updated successfully",
    "fr": "Le secteur a été mis à jour avec succès"
  },
  "services_sector_update_error": {
    "en": "Sorry, we had an error with the sector update",
    "fr": "Désolé, nous avons eu une erreur avec la mise à jour du secteur"
  },
  "services_sector_delete_success": {
    "en": "Sector deleted successfully",
    "fr": "Le secteur a été supprimé avec succès"
  },
  "services_sector_delete_error": {
    "en": "Sorry, we had an error with the sector deletion",
    "fr": "Désolé, nous avons eu une erreur avec la suppression du secteur"
  },
  "services_sector_restore_success": {
    "en": "Sector restored successfully",
    "fr": "Le secteur a été restauré avec succès"
  },
  "services_sector_restore_error": {
    "en": "Sorry, we had an error with the sector restoration",
    "fr": "Désolé, nous avons eu une erreur avec la restauration du secteur"
  },
  "services_employee_role_insert_success": {
    "en": "Role created successfully",
    "fr": "Le rôle a été créé avec succès"
  },
  "services_employee_role_insert_error": {
    "en": "Sorry, we had an error with the role creation",
    "fr": "Désolé, nous avons eu une erreur avec la création du rôle"
  },
  "services_employee_role_update_success": {
    "en": "Role updated successfully",
    "fr": "Le rôle a été mis à jour avec succès"
  },
  "services_employee_role_update_error": {
    "en": "Sorry, we had an error with the role update",
    "fr": "Désolé, nous avons eu une erreur avec la mise à jour du rôle"
  },
  "services_employee_role_delete_success": {
    "en": "Role deleted successfully",
    "fr": "Le rôle a été supprimé avec succès"
  },
  "services_employee_role_delete_error": {
    "en": "Sorry, we had an error with the role deletion",
    "fr": "Désolé, nous avons eu une erreur avec la suppression du rôle"
  },
  "services_employee_role_restore_success": {
    "en": "Role restored successfully",
    "fr": "Le rôle a été restauré avec succès"
  },
  "services_employee_role_restore_error": {
    "en": "Sorry, we had an error with the role restoration",
    "fr": "Désolé, nous avons eu une erreur avec la restauration du rôle"
  },
  "services_employee_role_code_update_error": {
    "en": "Sorry, employee role code already exist",
    "fr": "Désolé, le code du rôle de l'employé existe déjà"
  },
  "services_project_insert_success": {
    "en": "Project created successfully",
    "fr": "Le projet a été créé avec succès"
  },
  "services_project_insert_error": {
    "en": "Sorry, we had an error with the project creation",
    "fr": "Désolé, nous avons eu une erreur avec la création du projet"
  },
  "services_project_update_success": {
    "en": "Project updated successfully",
    "fr": "Le projet a été mis à jour avec succès"
  },
  "services_project_update_error": {
    "en": "Sorry, we had an error with the project update",
    "fr": "Désolé, nous avons eu une erreur avec la mise à jour du projet"
  },
  "services_project_delete_success": {
    "en": "Project deleted successfully",
    "fr": "Le projet a été supprimé avec succès"
  },
  "services_project_delete_error": {
    "en": "Sorry, we had an error with the project deletion",
    "fr": "Désolé, nous avons eu une erreur avec la suppression du projet"
  },
  "services_project_restore_success": {
    "en": "Project restored successfully",
    "fr": "Le projet a été restauré avec succès"
  },
  "services_project_restore_error": {
    "en": "Sorry, we had an error with the project restoration",
    "fr": "Désolé, nous avons eu une erreur avec la restauration du projet"
  },
  "services_project_activity_add_success": {
    "en": "Activity added to project successfully",
    "fr": "L'activité a été ajoutée au projet avec succès"
  },
  "services_project_activity_add_error": {
    "en": "Sorry, we had an error with the activity addition",
    "fr": "Désolé, nous avons eu une erreur avec l'ajout de l'activité"
  },
  "services_project_activity_remove_success": {
    "en": "Activity removed successfully from project ",
    "fr": "L'activité a été supprimé du projet avec succès"
  },
  "services_project_activity_remove_error": {
    "en": "Sorry, we had an error with the activity removal",
    "fr": "Désolé, nous avons eu une erreur avec la suppression de l'activité"
  },
  "services_product_insert_success": {
    "en": "Product created successfully",
    "fr": "Le produit a été créé avec succès"
  },
  "services_product_insert_error": {
    "en": "Sorry, we had an error with the product creation",
    "fr": "Désolé, nous avons eu une erreur avec la création du produit"
  },
  "services_product_update_success": {
    "en": "Product updated successfully",
    "fr": "Le produit a été mis à jour avec succès"
  },
  "services_product_update_error": {
    "en": "Sorry, we had an error with the product update",
    "fr": "Désolé, nous avons eu une erreur avec la mise à jour du produit"
  },
  "services_product_delete_success": {
    "en": "Product deleted successfully",
    "fr": "Le produit a été supprimé avec succès"
  },
  "services_product_delete_error": {
    "en": "Sorry, we had an error with the product deletion",
    "fr": "Désolé, nous avons eu une erreur avec la suppression du produit"
  },
  "services_product_restore_success": {
    "en": "Product restored successfully",
    "fr": "Le produit a été restauré avec succès"
  },
  "services_product_restore_error": {
    "en": "Sorry, we had an error with the product restoration",
    "fr": "Désolé, nous avons eu une erreur avec la restauration du produit"
  },
  "services_product_activity_add_success": {
    "en": "Activity added to product successfully",
    "fr": "L'activité a été ajoutée au produit avec succès"
  },
  "services_product_activity_add_error": {
    "en": "Sorry, we had an error with the activity addition",
    "fr": "Désolé, nous avons eu une erreur avec l'ajout de l'activité"
  },
  "services_product_activity_remove_success": {
    "en": "Activity removed from product successfully",
    "fr": "L'activité a été supprimée de produit avec succès"
  },
  "services_product_activity_remove_error": {
    "en": "Sorry, we had an error with the activity removal",
    "fr": "Désolé, nous avons eu une erreur avec la suppression de l'activité"
  },
  "services_organization_insert_success": {
    "en": "Organization created successfully",
    "fr": "L'organisation a été créée avec succès"
  },
  "services_organization_insert_error": {
    "en": "Sorry, we had an error with the organization creation",
    "fr": "Désolé, nous avons eu une erreur avec la création de l'organisation"
  },
  "services_organization_update_success": {
    "en": "Organization updated successfully",
    "fr": "L'organisation a été mise à jour avec succès"
  },
  "services_organization_update_error": {
    "en": "Sorry, we had an error with the organization update",
    "fr": "Désolé, nous avons eu une erreur avec la mise à jour de l'organisation"
  },
  "services_organization_delete_success": {
    "en": "Organization deleted successfully",
    "fr": "L'organisation a été supprimée avec succès"
  },
  "services_organization_delete_error": {
    "en": "Sorry, we had an error with the organization deletion",
    "fr": "Désolé, nous avons eu une erreur avec la suppression de l'organisation"
  },
  "services_organization_restore_success": {
    "en": "Organization restored successfully",
    "fr": "L'organisation a été restaurée avec succès"
  },
  "services_organization_restore_error": {
    "en": "Sorry, we had an error with the organization restoration",
    "fr": "Désolé, nous avons eu une erreur avec la restauration de l'organisation"
  },
  "services_unit_insert_success": {
    "en": "Unit created successfully",
    "fr": "L'unité a été créée avec succès"
  },
  "services_unit_insert_error": {
    "en": "Sorry, we had an error with the unit creation",
    "fr": "Désolé, nous avons eu une erreur avec la création de l'unité"
  },
  "services_unit_update_success": {
    "en": "Unit updated successfully",
    "fr": "L'unité a été mise à jour avec succès"
  },
  "services_unit_update_error": {
    "en": "Sorry, we had an error with the unit update",
    "fr": "Désolé, nous avons eu une erreur avec la mise à jour de l'unité"
  },
  "services_unit_delete_success": {
    "en": "Unit deleted successfully",
    "fr": "L'unité a été supprimée avec succès"
  },
  "services_unit_delete_error": {
    "en": "Sorry, we had an error with the unit deletion",
    "fr": "Désolé, nous avons eu une erreur avec la suppression de l'unité"
  },
  "services_unit_restore_success": {
    "en": "Unit restored successfully",
    "fr": "L'unité a été restaurée avec succès"
  },
  "services_unit_restore_error": {
    "en": "Sorry, we had an error with the unit restoration",
    "fr": "Désolé, nous avons eu une erreur avec la restauration de l'unité"
  },
  "services_material_unit_insert_success": {
    "en": "Material unit created successfully",
    "fr": "L'unité de matériau a été créée avec succès"
  },
  "services_material_unit_insert_error": {
    "en": "Sorry, we had an error with the material unit creation",
    "fr": "Désolé, nous avons eu une erreur avec la création de l'unité de matériau"
  },
  "services_material_unit_update_success": {
    "en": "Material unit updated successfully",
    "fr": "L'unité de matériau a été mise à jour avec succès"
  },
  "services_material_unit_update_error": {
    "en": "Sorry, we had an error with the material unit update",
    "fr": "Désolé, nous avons eu une erreur avec la mise à jour de l'unité de matériau"
  },
  "services_material_unit_delete_success": {
    "en": "Material unit deleted successfully",
    "fr": "L'unité de matériau a été supprimée avec succès"
  },
  "services_material_unit_delete_error": {
    "en": "Sorry, we had an error with the material unit deletion",
    "fr": "Désolé, nous avons eu une erreur avec la suppression de l'unité de matériau"
  },
  "services_material_unit_restore_success": {
    "en": "Material unit restored successfully",
    "fr": "L'unité de matériau a été restaurée avec succès"
  },
  "services_material_unit_restore_error": {
    "en": "Sorry, we had an error with the material unit restoration",
    "fr": "Désolé, nous avons eu une erreur avec la restauration de l'unité de matériau"
  },
  "services_material_type_update_success": {
    "en": "Material type updated successfully",
    "fr": "Le type de matériau a été mis à jour avec succès"
  },
  "services_material_type_update_error": {
    "en": "Sorry, we had an error with the material type update",
    "fr": "Désolé, nous avons eu une erreur avec la mise à jour du type de matériau"
  },
  "services_material_type_delete_success": {
    "en": "Material type deleted successfully",
    "fr": "Le type de matériau a été supprimé avec succès"
  },
  "services_material_type_delete_error": {
    "en": "Sorry, we had an error with the material type deletion",
    "fr": "Désolé, nous avons eu une erreur avec la suppression du type de matériau"
  },
  "services_material_type_restore_success": {
    "en": "Material type restored successfully",
    "fr": "Le type de matériau a été restauré avec succès"
  },
  "services_material_type_restore_error": {
    "en": "Sorry, we had an error with the material type restoration",
    "fr": "Désolé, nous avons eu une erreur avec la restauration du type de matériau"
  },
  "services_material_delete_success": {
    "en": "Material deleted successfully",
    "fr": "Le matériau a été supprimé avec succès"
  },
  "services_material_delete_error": {
    "en": "Sorry, we had an error with the material deletion",
    "fr": "Désolé, nous avons eu une erreur avec la suppression du matériau"
  },
  "services_material_restore_success": {
    "en": "Material restored successfully",
    "fr": "Le matériau a été restauré avec succès"
  },
  "services_material_restore_error": {
    "en": "Sorry, we had an error with the material restoration",
    "fr": "Désolé, nous avons eu une erreur avec la restauration du matériau"
  },
  "services_pension_type_insert_success": {
    "en": "Pension type created successfully",
    "fr": "Le type de pension a été créé avec succès"
  },
  "services_pension_type_insert_error": {
    "en": "Sorry, we had an error with the pension type creation",
    "fr": "Désolé, nous avons eu une erreur avec la création du type de pension"
  },
  "services_pension_type_update_success": {
    "en": "Pension type updated successfully",
    "fr": "Le type de pension a été mis à jour avec succès"
  },
  "services_pension_type_update_error": {
    "en": "Sorry, we had an error with the pension type update",
    "fr": "Désolé, nous avons eu une erreur avec la mise à jour du type de pension"
  },
  "services_pension_type_delete_success": {
    "en": "Pension type deleted successfully",
    "fr": "Le type de pension a été supprimé avec succès"
  },
  "services_pension_type_delete_error": {
    "en": "Sorry, we had an error with the pension type deletion",
    "fr": "Désolé, nous avons eu une erreur avec la suppression du type de pension"
  },
  "services_pension_type_restore_success": {
    "en": "Pension type restored successfully",
    "fr": "Le type de pension a été restauré avec succès"
  },
  "services_pension_type_restore_error": {
    "en": "Sorry, we had an error with the pension type restoration",
    "fr": "Désolé, nous avons eu une erreur avec la restauration du type de pension"
  },
  "services_equipment_type_insert_success": {
    "en": "Equipment type created successfully",
    "fr": "Le type d'équipement a été créé avec succès"
  },
  "services_equipment_type_insert_error": {
    "en": "Sorry, we had an error with the equipment type creation",
    "fr": "Désolé, nous avons eu une erreur avec la création du type d'équipement"
  },
  "services_equipment_type_update_success": {
    "en": "Equipment type updated successfully",
    "fr": "Le type d'équipement a été mis à jour avec succès"
  },
  "services_equipment_type_update_error": {
    "en": "Sorry, we had an error with the equipment type update",
    "fr": "Désolé, nous avons eu une erreur avec la mise à jour du type d'équipement"
  },
  "services_equipment_type_delete_success": {
    "en": "Equipment type deleted successfully",
    "fr": "Le type d'équipement a été supprimé avec succès"
  },
  "services_equipment_type_delete_error": {
    "en": "Sorry, we had an error with the equipment type deletion",
    "fr": "Désolé, nous avons eu une erreur avec la suppression du type d'équipement"
  },
  "services_equipment_type_restore_success": {
    "en": "Equipment type restored successfully",
    "fr": "Le type d'équipement a été restauré avec succès"
  },
  "services_equipment_type_restore_error": {
    "en": "Sorry, we had an error with the equipment type restoration",
    "fr": "Désolé, nous avons eu une erreur avec la restauration du type d'équipement"
  },
  "services_equipment_insert_success": {
    "en": "Equipment created successfully",
    "fr": "L'équipement a été créé avec succès"
  },
  "services_equipment_insert_error": {
    "en": "Sorry, we had an error with the equipment creation",
    "fr": "Désolé, nous avons eu une erreur avec la création de l'équipement"
  },
  "services_equipment_update_success": {
    "en": "Equipment updated successfully",
    "fr": "L'équipement a été mis à jour avec succès"
  },
  "services_equipment_update_error": {
    "en": "Sorry, we had an error with the equipment update",
    "fr": "Désolé, nous avons eu une erreur avec la mise à jour de l'équipement"
  },
  "services_equipment_delete_success": {
    "en": "Equipment deleted successfully",
    "fr": "L'équipement a été supprimé avec succès"
  },
  "services_equipment_delete_error": {
    "en": "Sorry, we had an error with the equipment deletion",
    "fr": "Désolé, nous avons eu une erreur avec la suppression de l'équipement"
  },
  "services_equipment_restore_success": {
    "en": "Equipment restored successfully",
    "fr": "L'équipement restauré avec succès"
  },
  "services_equipment_restore_error": {
    "en": "Sorry, we had an error with the equipment restoration",
    "fr": "Désolé, nous avons eu une erreur avec la restauration de l'équipement"
  },
  "services_employee_insert_success": {
    "en": "Employee created successfully",
    "fr": "L'employé a été créé avec succès"
  },
  "services_employee_insert_error": {
    "en": "Sorry, we had an error with the employee creation",
    "fr": "Désolé, nous avons eu une erreur avec la création de l'employé"
  },
  "services_employee_update_success": {
    "en": "Employee updated successfully",
    "fr": "L'employé a été mis à jour avec succès"
  },
  "services_employee_update_error": {
    "en": "Sorry, we had an error with the employee update",
    "fr": "Désolé, nous avons eu une erreur avec la mise à jour de l'employé"
  },
  "services_employee_delete_success": {
    "en": "Employee deleted successfully",
    "fr": "L'employé a été supprimé avec succès"
  },
  "services_employee_delete_error": {
    "en": "Sorry, we had an error with the employee deletion",
    "fr": "Désolé, nous avons eu une erreur avec la suppression de l'employé"
  },
  "services_employee_restore_success": {
    "en": "Employee restored successfully",
    "fr": "L'employé a été restauré avec succès"
  },
  "services_employee_restore_error": {
    "en": "Sorry, we had an error with the employee restoration",
    "fr": "Désolé, nous avons eu une erreur avec la restauration de l'employé"
  },
  "services_activity_insert_success": {
    "en": "Activity created successfully",
    "fr": "L'activité a été créée avec succès"
  },
  "services_activity_insert_error": {
    "en": "Sorry, we had an error with the activity creation",
    "fr": "Désolé, nous avons eu une erreur avec la création de l'activité"
  },
  "services_activity_update_success": {
    "en": "Activity updated successfully",
    "fr": "L'activité a été mise à jour avec succès"
  },
  "services_activity_update_error": {
    "en": "Sorry, we had an error with the activity update",
    "fr": "Désolé, nous avons eu une erreur avec la mise à jour de l'activité"
  },
  "services_activity_delete_success": {
    "en": "Activity deleted successfully",
    "fr": "L'activité a été supprimée avec succès"
  },
  "services_activity_delete_error": {
    "en": "Sorry, we had an error with the activity deletion",
    "fr": "Désolé, nous avons eu une erreur avec la suppression de l'activité"
  },
  "services_activity_restore_success": {
    "en": "Activity restored successfully",
    "fr": "L'activité a été restaurée avec succès"
  },
  "services_activity_restore_error": {
    "en": "Sorry, we had an error with the activity restoration",
    "fr": "Désolé, nous avons eu une erreur avec la restauration de l'activité"
  },
  "services_price_type_insert_success": {
    "en": "Price type created successfully",
    "fr": "Le type de prix a été créé avec succès"
  },
  "services_price_type_insert_error": {
    "en": "Sorry, we had an error with the price type creation",
    "fr": "Désolé, nous avons eu une erreur avec la création du type de prix"
  },
  "services_price_type_update_success": {
    "en": "Price type updated successfully",
    "fr": "Le type de prix a été mis à jour avec succès"
  },
  "services_price_type_update_error": {
    "en": "Sorry, we had an error with the price type update",
    "fr": "Désolé, nous avons eu une erreur avec la mise à jour du type de prix"
  },
  "services_price_type_delete_success": {
    "en": "Price type deleted successfully",
    "fr": "Le type de prix a été supprimé avec succès"
  },
  "services_price_type_delete_error": {
    "en": "Sorry, we had an error with the price type deletion",
    "fr": "Désolé, nous avons eu une erreur avec la suppression du type de prix"
  },
  "services_price_type_restore_success": {
    "en": "Price type restored successfully",
    "fr": "Le type de prix a été restauré avec succès"
  },
  "services_price_type_restore_error": {
    "en": "Sorry, we had an error with the price type restoration",
    "fr": "Désolé, nous avons eu une erreur avec la restauration du type de prix"
  },
  "services_price_preset_insert_success": {
    "en": "Price preset created successfully",
    "fr": "Le préréglage de prix a été créé avec succès"
  },
  "services_price_preset_insert_error": {
    "en": "Sorry, we had an error with the price preset creation",
    "fr": "Désolé, nous avons eu une erreur avec la création du préréglage de prix"
  },
  "services_price_preset_update_success": {
    "en": "Price preset updated successfully",
    "fr": "Le préréglage de prix a été mis à jour avec succès"
  },
  "services_price_preset_update_error": {
    "en": "Sorry, we had an error with the price preset update",
    "fr": "Désolé, nous avons eu une erreur avec la mise à jour du préréglage prix"
  },
  "services_price_preset_delete_success": {
    "en": "Price preset deleted successfully",
    "fr": "Le préréglage de prix a été supprimé avec succès"
  },
  "services_price_preset_delete_error": {
    "en": "Sorry, we had an error with the price preset deletion",
    "fr": "Désolé, nous avons eu une erreur avec la suppression du préréglage de prix"
  },
  "services_price_value_insert_success": {
    "en": "Price value created successfully",
    "fr": "Valeur de prix a été créé avec succès"
  },
  "services_price_value_insert_error": {
    "en": "Sorry, we had an error with the price value creation",
    "fr": "Désolé, nous avons eu une erreur avec la création de la valeur de prix"
  },
  "services_price_value_update_success": {
    "en": "Price value updated successfully",
    "fr": "Valeur de prix a été mis à jour avec succès"
  },
  "services_price_value_update_error": {
    "en": "Sorry, we had an error with the price value update",
    "fr": "Désolé, nous avons eu une erreur avec la mise à jour de la valeur de prix"
  },
  "services_price_apply_presets_success": {
    "en": "Price presets applied successfully",
    "fr": "Préréglages de prix appliqués avec succès"
  },
  "services_price_apply_presets_error": {
    "en": "Sorry we had an error with applying price presets",
    "fr": "Désolé, nous avons eu une erreur avec l'application des préréglages de prix"
  },
  "services_dailylog_pension_add_success": {
    "en": "Pension added successfully",
    "fr": "La pension a été ajouté avec succès"
  },
  "services_dailylog_pension_add_error": {
    "en": "Sorry, we had an error with your pension addition",
    "fr": "Désolé, nous avons eu une erreur avec votre ajout de pension"
  },
  "services_dailylog_pension_remove_success": {
    "en": "Pension removed successfully",
    "fr": "La pension a été supprimé avec succès"
  },
  "services_dailylog_pension_remove_error": {
    "en": "Sorry, we had an error with your pension removal",
    "fr": "Désolé, nous avons eu une erreur avec votre suppression de pension"
  },
  "services_dailylog_insert_success": {
    "en": "Daily log created successfully",
    "fr": "Le rapport journalier a été créé avec succès"
  },
  "services_dailylog_insert_error": {
    "en": "Sorry, we had an error with the daily log creation",
    "fr": "Désolé, nous avons eu une erreur avec la création du rapport journalier"
  },
  "services_dailylog_update_success": {
    "en": "Daily log updated successfully",
    "fr": "Le rapport journalier a été mis à jour avec succès"
  },
  "services_dailylog_update_error": {
    "en": "Sorry, we had an error with the daily log  update",
    "fr": "Désolé, nous avons eu une erreur avec la mise à jour du rapport journalier"
  },
  "services_dailylog_delete_success": {
    "en": "Daily log deleted successfully",
    "fr": "Le rapport journalier a été supprimé avec succès"
  },
  "services_dailylog_delete_error": {
    "en": "Sorry, we had an error with the daily log deletion",
    "fr": "Désolé, nous avons eu une erreur avec la suppression du rapport journalier"
  },
  "services_dailylog_copy_success": {
    "en": "Daily log copied successfully",
    "fr": "Le rapport journalier a été copié avec succès"
  },
  "services_dailylog_copy_error": {
    "en": "Sorry, we had an error with the daily log copy",
    "fr": "Désolé, nous avons eu une erreur avec la copie du rapport journalier"
  },
  "services_dailylog_update_status_success": {
    "en": "Daily log status updated successfully",
    "fr": "L'état du rapport journalier a été mis à jour avec succès"
  },
  "services_dailylog_update_status_error": {
    "en": "Sorry, we had an error with the daily log status update",
    "fr": "Désolé, nous avons eu une erreur avec la mise à jour de l'état du rapport journalier"
  },
  "services_dailylog_add_project_success": {
    "en": "Project added successfully to daily log",
    "fr": "Le projet a été ajouté avec succès dans le rapport journalier"
  },
  "services_dailylog_add_project_error": {
    "en": "Sorry, we had an error with the project addition",
    "fr": "Désolé, nous avons eu une erreur avec l'ajout du projet"
  },
  "services_dailylog_remove_project_success": {
    "en": "Project removed successfully from daily log",
    "fr": "Le projet a été supprimé du rapport journalier"
  },
  "services_dailylog_remove_project_error": {
    "en": "Sorry, we had an error with the project removal",
    "fr": "Désolé, nous avons eu une erreur avec la suppression du projet"
  },
  "services_dailylog_add_activity_success": {
    "en": "Activity added successfully to daily log",
    "fr": "L'activité a été ajoutée dans le rapport journalier avec succès "
  },
  "services_dailylog_add_activity_error": {
    "en": "Sorry, we had an error with the activity addition",
    "fr": "Désolé, nous avons eu une erreur avec l'ajout de l'activité"
  },
  "services_dailylog_update_activity_success": {
    "en": "Activity updated successfully",
    "fr": "L'activité a été mise à jour avec succès"
  },
  "services_dailylog_update_activity_error": {
    "en": "Sorry, we had an error with the activity update",
    "fr": "Désolé, nous avons eu une erreur avec la mise à jour d'activité"
  },
  "services_dailylog_remove_activity_success": {
    "en": "Activity removed successfully from daily log",
    "fr": "L'activité a été supprimée du rapport journalier avec succès"
  },
  "services_dailylog_remove_activity_error": {
    "en": "Sorry, we had an error with the activity removal",
    "fr": "Désolé, nous avons eu une erreur avec la suppression de l'activité"
  },
  "services_dailylog_add_resource_success": {
    "en": "Resource added successfully to daily log",
    "fr": "La ressource a été ajoutée dans le rapport journalier avec succès"
  },
  "services_dailylog_add_resource_error": {
    "en": "Sorry, we had an error with the resource addition",
    "fr": "Désolé, nous avons eu une erreur avec l'ajout de ressources"
  },
  "services_dailylog_remove_resource_success": {
    "en": "Resource removed successfully from daily log",
    "fr": "La ressource a été supprimée du rapport journalier"
  },
  "services_dailylog_remove_resource_error": {
    "en": "Sorry, we had an error with the resource removal",
    "fr": "Désolé, nous avons eu une erreur avec la suppression de la ressource"
  },
  "services_dailylog_add_placeholder_success": {
    "en": "Placeholder added successfully to daily log",
    "fr": "La ressource provisoire a été ajoutée dans le rapport journalier avec succès "
  },
  "services_dailylog_add_placeholder_error": {
    "en": "Sorry, we had an error with the placeholder addition",
    "fr": "Désolé, nous avons eu une erreur avec l'ajout de la ressource provisoire"
  },
  "services_dailylog_remove_placeholder_success": {
    "en": "Placeholder removed successfully from daily log",
    "fr": "La ressource provisoire a été supprimée du rapport journalier"
  },
  "services_dailylog_remove_placeholder_error": {
    "en": "Sorry, we had an error with the placeholder removal",
    "fr": "Désolé, nous avons eu une erreur avec la suppression de la ressource provisoire"
  },
  "services_dailylog_insert_block_success": {
    "en": "Activity block created successfully",
    "fr": "Le bloc d'activité a été créé avec succès"
  },
  "services_dailylog_insert_block_error": {
    "en": "Sorry, we had an error with the activity block creation",
    "fr": "Désolé, nous avons eu une erreur avec la création du bloc d'activité"
  },
  "services_dailylog_update_block_success": {
    "en": "Activity block updated successfully",
    "fr": "Le bloc d'activité a été mis à jour avec succès"
  },
  "services_dailylog_update_block_error": {
    "en": "Sorry, we had an error with the activity block update",
    "fr": "Désolé, nous avons eu une erreur avec la mise à jour du bloc d'activité"
  },
  "services_dailylog_delete_block_success": {
    "en": "Activity block deleted successfully",
    "fr": "Le bloc d'activité a été supprimé avec succès"
  },
  "services_dailylog_delete_block_error": {
    "en": "Sorry, we had an error with the activity block deletion",
    "fr": "Désolé, nous avons eu une erreur avec la suppression de bloc d'activité"
  },
  "services_dailylog_add_material_success": {
    "en": "Material added successfully to daily log",
    "fr": "Le matériau a été ajouté dans le rapport journalier avec succès"
  },
  "services_dailylog_add_material_error": {
    "en": "Sorry, we had an error with the material addition",
    "fr": "Désolé, nous avons eu une erreur avec l'ajout du matériau"
  },
  "services_dailylog_update_material_success": {
    "en": "Material updated successfully",
    "fr": "Le matériau a été mis à jour avec succès"
  },
  "services_dailylog_update_material_error": {
    "en": "Sorry, we had an error with the material update",
    "fr": "Désolé, nous avons eu une erreur avec la mise à jour du matériau"
  },
  "services_dailylog_remove_material_success": {
    "en": "Material removed successfully from daily log",
    "fr": "Le matériau a été supprimé de rapport journalier"
  },
  "services_dailylog_remove_material_error": {
    "en": "Sorry, we had an error with the material removal",
    "fr": "Désolé, nous avons eu une erreur avec la suppression du matériau"
  },
  "services_dailylog_add_material_description_success": {
    "en": "Description added successfully to material",
    "fr": "La description du matériau a été ajoutée avec succès"
  },
  "services_dailylog_add_material_description_error": {
    "en": "Sorry, we had an error with the material description addition",
    "fr": "Désolé, nous avons eu une erreur avec l'ajout de la description du matériau"
  },
  "services_dailylog_update_material_description_success": {
    "en": "Material description updated successfully",
    "fr": "La description du matériau a été mise à jour avec succès"
  },
  "services_dailylog_update_material_description_error": {
    "en": "Sorry, we had an error with the material description update",
    "fr": "Désolé, nous avons eu une erreur avec la mise à jour de la description du matériau"
  },
  "services_dailylog_add_employee_description_success": {
    "en": "Description added successfully to employee",
    "fr": "La description de l'employé a été ajoutée avec succès"
  },
  "services_dailylog_add_employee_description_error": {
    "en": "Sorry, we had an error with the employee description addition",
    "fr": "Désolé, nous avons eu une erreur avec l'ajout de la description de l'employé "
  },
  "services_dailylog_update_employee_description_success": {
    "en": "Employee description updated successfully",
    "fr": "La description de l'employé a été mise à jour avec succès"
  },
  "services_dailylog_update_employee_description_error": {
    "en": "Sorry, we had an error with the employee description update",
    "fr": "Désolé, nous avons eu une erreur avec la mise à jour de la description de l'employé"
  },
  "services_dailylog_add_equipment_description_success": {
    "en": "Description added successfully to equipment ",
    "fr": "La description de l'équipement a été ajoutée avec succès"
  },
  "services_dailylog_add_equipment_description_error": {
    "en": "Sorry, we had an error with the equipment description addition",
    "fr": "Désolé, nous avons eu une erreur avec l'ajout de la description de l'équipement"
  },
  "services_dailylog_update_equipment_description_success": {
    "en": "Equipment description updated successfully",
    "fr": "La description de l'équipement a été mise à jour avec succès"
  },
  "services_dailylog_update_equipment_description_error": {
    "en": "Sorry, we had an error with the equipment description update",
    "fr": "Désolé, nous avons eu une erreur avec la mise à jour de la description de l'équipement"
  },
  "services_dailylog_add_placeholder_description_success": {
    "en": "Description added successfully to placeholder",
    "fr": "La description de la ressource provisoire a été ajoutée avec succès"
  },
  "services_dailylog_add_placeholder_description_error": {
    "en": "Sorry, we had an error with the placeholder description addition",
    "fr": "Désolé, nous avons eu une erreur avec l'ajout de la descriptionde la ressource provisoire"
  },
  "services_dailylog_update_placeholder_description_success": {
    "en": "Placeholder description updated successfully",
    "fr": "La description de la ressource provisoire a été mise à jour avec succès"
  },
  "services_dailylog_update_placeholder_description_error": {
    "en": "Sorry, we had an error with the placeholder description update",
    "fr": "Désolé, nous avons eu une erreur avec la mise à jour de la description de la ressource provisoire"
  },
  "services_send_sms_success": {
    "en": "SMS sent successfully to",
    "fr": "Le SMS a été envoyé avec succès"
  },
  "services_send_sms_error": {
    "en": "Sorry we had an error with sending the SMS",
    "fr": "Désolé, nous avons eu une erreur avec l'envoi du SMS"
  },
  "services_folder_create_success": {
    "en": "Folder created successfully",
    "fr": "Dossier créer avec succès"
  },
  "services_folder_create_error": {
    "en": "Sorry, we had an error with the folder creation",
    "fr": "Désolé, nous avons eu une erreur avec la création du dossier"
  },
  "navigate_project": {
    "en": "Go to project page",
    "fr": "Aller à la page projet"
  },
  "navigate_project_schedule": {
    "en": "Go to project schedule",
    "fr": "Aller à la planification du projet"
  },
  "empty_project": {
    "en": "This project don't have assigned activity",
    "fr": "Ce projet n'a pas d'activité assigné"
  },
  "notification_center_title": {
    "en": "Notifications",
    "fr": "Notifications"
  },
  "notification_center_tab_team": {
    "en": "Your teams",
    "fr": "Vos équipes"
  },
  "notification_center_tab_mentions": {
    "en": "Mentions",
    "fr": "Mentions"
  },
  "notification_center_tab_all": {
    "en": "All",
    "fr": "Tous"
  },
  "notification_center_empty_list_message": {
    "en": "You're up to date.",
    "fr": "Vous êtes à jour."
  },
  "taskblock_dialog_mark_complete": {
    "en": "Mark complete",
    "fr": "Marquer terminé"
  },
  "taskblock_dialog_assignees": {
    "en": "Assignees",
    "fr": "Assignés"
  },
  "taskblock_dialog_due_date": {
    "en": "Due date",
    "fr": "Date de livraison"
  },
  "taskblock_dialog_project": {
    "en": "Project",
    "fr": "Projet"
  },
  "taskblock_dialog_status": {
    "en": "Status",
    "fr": "État"
  },
  "taskblock_dialog_description": {
    "en": "Description",
    "fr": "Description"
  },
  "taskblock_dialog_material": {
    "en": "Material",
    "fr": "Matériaux"
  },
  "taskblock_dialog_activities": {
    "en": "Activities",
    "fr": "Activités"
  },
  "taskblock_dialog_status_in_progress": {
    "en": "In Progress",
    "fr": "En court"
  },
  "taskblock_dialog_status_done": {
    "en": "Done",
    "fr": "Terminé"
  },
  "taskblock_dialog_description_placeholder": {
    "en": "Add more details to this task...",
    "fr": "Ajouter une description à cette tâche..."
  },
  "taskblock_dialog_materials_placeholder": {
    "en": "Add material",
    "fr": "Ajouter matériau"
  },
  "taskblock_dialog_activities_placeholder": {
    "en": "Add activity",
    "fr": "Ajouter activité"
  },
  "taskblock_dialog_associated_daily_logs": {
    "en": "Associated daily logs list",
    "fr": "Liste des rapports journaliers associés"
  },
  "taskblock_dialog_tooltip_assign_team_title": {
    "en": "Assign team to task",
    "fr": "Assigner une équipe à la tâche"
  },
  "taskblock_dialog_tooltip_assign_team_body": {
    "en": "Add existing team or create a new one.",
    "fr": "Ajouter une équipe existante ou créer une nouvelle équipe."
  },
  "taskblock_dialog_tooltip_assign_activity_title": {
    "en": "Select activity",
    "fr": "Choisir une activité"
  },
  "taskblock_dialog_tooltip_assign_activity_body": {
    "en": "Select an existing activity or create a new one",
    "fr": "Choisir une activité existante ou créer une nouvelle"
  },
  "taskblock_dialog_tooltip_set_date_title": {
    "en": "Choose date",
    "fr": "Choisir la date"
  },
  "taskblock_dialog_tooltip_set_date_body": {
    "en": "Click here to select a start date and an end date for your task",
    "fr": "Cliquez ici pour choisir une date de début et de fin pour votre tâche"
  },
  "taskblock_dialog_tooltip_assign_material_title": {
    "en": "Select material",
    "fr": "Choisir un matériau"
  },
  "taskblock_dialog_tooltip_assign_material_body": {
    "en": "Select an existing material or create a new one",
    "fr": "Choisir un matériau existant ou créer une nouveau"
  },
  "taskblock_dialog_tooltip_edit_task_name_title": {
    "en": "Add task name",
    "fr": "Ajouter un nom de tâche."
  },
  "taskblock_dialog_tooltip_edit_task_name_body": {
    "en": "Click here to add a task name. Task name will be visible inside the project section.",
    "fr": "Cliquez ici pour ajouter un nom de tâche. Le de la tâche sera visible dans la section du projet."
  },
  "taskblock_dialog_task_name_input_placeholder": {
    "en": "Write a Task name",
    "fr": "Saisissez le nom de la tâche"
  },
  "gantt_info_header_project_task": {
    "en": "Project / Task",
    "fr": "Projet / Tâche"
  },
  "gantt_info_header_start_date": {
    "en": "Start date",
    "fr": "Début"
  },
  "gantt_info_header_end_date": {
    "en": "End date",
    "fr": "Fin"
  },
  "gantt_info_header_duration": {
    "en": "Duration",
    "fr": "Durée"
  },
  "schedule_page_header_add_project": {
    "en": "Add project",
    "fr": "Ajouter un projet"
  },
  "schedule_page_add_project_dialog_title": {
    "en": "Add project",
    "fr": "Ajouter un projet"
  },
  "schedule_page_add_project_dialog_start": {
    "en": "Start date",
    "fr": "Date de début"
  },
  "schedule_page_add_project_dialog_end": {
    "en": "End date",
    "fr": "Date de fin"
  },
  "schedule_page_header_yearly_view": {
    "en": "Yearly view",
    "fr": "Vue annuelle"
  },
  "schedule_page_header_three_months_view": {
    "en": "Quarter view",
    "fr": "Vue trimestrielle"
  },
  "schedule_page_header_month_view": {
    "en": "Month view",
    "fr": "Vue mensuelle"
  },
  "day": {
    "en": "day",
    "fr": "jour"
  },
  "days": {
    "en": "days",
    "fr": "jours"
  },
  "Workload": {
    "en": "Workload",
    "fr": "Charge de travail"
  },
  "workload_filter_title": {
    "en": "Show workload of",
    "fr": "Afficher la charge de travail des"
  },
  "workload_show_activities": {
    "en": "activities",
    "fr": "activités"
  },
  "workload_show_roles": {
    "en": "employees roles",
    "fr": "rôles des employés"
  },
  "workload_show_types": {
    "en": "heavy equipment types",
    "fr": "types d'équipements lourds"
  },
  "workload_show_teams": {
    "en": "teams",
    "fr": "équipes"
  },
  "workload_show_employees": {
    "en": "employees",
    "fr": "employés"
  },
  "workload_show_equipments": {
    "en": "heavy equipment",
    "fr": "équipements lourds"
  },
  "workload_show_equipments_placeholder": {
    "en": "heavy equipment placeholders",
    "fr": "équipements lourds provisoires"
  },
  "workload_show_employees_placeholder": {
    "en": "employees placeholders",
    "fr": "employés provisoires"
  },
  "workload_employee_placeholder_column_title": {
    "en": "Employee placeholder role",
    "fr": "Role d'employé provisoire"
  },
  "workload_equipment_placeholder_column_title": {
    "en": "Equipment placeholder type",
    "fr": "Type d'équipement provisoire"
  },
  "workload_team_column_title": {
    "en": "Teams",
    "fr": "Équipes"
  },
  "workload_team_leader_column_title": {
    "en": "Team Leader",
    "fr": "Chef d'équipe"
  },
  "workload_preview_shift_type": {
    "en": "Shift type",
    "fr": "Quart de travail"
  },
  "workload_preview_resources": {
    "en": "Resources",
    "fr": "Ressources"
  },
  "workload_preview_total_hours": {
    "en": "Total hours",
    "fr": "Heures totales"
  },
  "preferences_shift_type_placeholder": {
    "en": "Choose a preferred shift",
    "fr": "Choisissez un quart préféré"
  },
  "preferences_shift_type": {
    "en": "Preferred shift",
    "fr": "Quart préféré"
  },
  "workpicker_days": {
    "en": "days",
    "fr": "jours"
  },
  "workpicker_confirm": {
    "en": "Confirm",
    "fr": "Confirmer"
  },
  "workpicker_cancel": {
    "en": "Cancel",
    "fr": "Annuler"
  },
  "workpicker_schedulefor": {
    "en": "Schedule for",
    "fr": "Horaire pour"
  },
  "workpicker_to": {
    "en": "To",
    "fr": "À"
  },
  "workpicker_unknown": {
    "en": "(Unknown)",
    "fr": "(Inconnue)"
  },
  "taskblockedit_name_label": {
    "en": "Task",
    "fr": "Tâche"
  },
  "taskblockedit_name_placeholder": {
    "en": "Unnamed task",
    "fr": "Tâche sans nom"
  },
  "taskblockedit_description_label": {
    "en": "Description",
    "fr": "Description"
  },
  "taskblockedit_description_placeholder": {
    "en": "Task Description",
    "fr": "Description de la tâche"
  },
  "taskblockedit_date_label": {
    "en": "Start/End dates",
    "fr": "Dates de début/fin"
  },
  "taskblockedit_activities_label": {
    "en": "Activities",
    "fr": "Activités"
  },
  "taskblockedit_activities_add": {
    "en": "Add activities",
    "fr": "Ajouter activités"
  },
  "taskblockedit_activities_no_data": {
    "en": "No activities selected",
    "fr": "Aucune activité sélectionnée"
  },
  "taskblockedit_teams_label": {
    "en": "Teams",
    "fr": "Équipes"
  },
  "taskblockedit_teams_add": {
    "en": "Add team",
    "fr": "Ajouter équipe"
  },
  "taskblockedit_teams_no_data": {
    "en": "No teams selected",
    "fr": "Aucune équipe sélectionnée"
  },
  "taskblockedit_employees_no_data": {
    "en": "No employees selected",
    "fr": "Aucun employé sélectionné"
  },
  "taskblockedit_equipments_no_data": {
    "en": "No equipments selected",
    "fr": "Aucun équipement sélectionné"
  },
  "taskblockedit_createdailylogs": {
    "en": "Create Daily Logs",
    "fr": "Créer Rapports Journalier"
  },
  "taskblockedit_confirm": {
    "en": "Confirm",
    "fr": "Confirmer"
  },
  "taskblockedit_cancel": {
    "en": "Cancel",
    "fr": "Annuler"
  },
  "taskblockedit_delete": {
    "en": "Delete",
    "fr": "Supprimer"
  },
  "schedule_page_search_placeholder": {
    "en": "Type to search...",
    "fr": "Tapez pour rechercher..."
  },
  "dropdown_default_placeholder": {
    "en": "Select",
    "fr": "Sélectionner"
  },
  "gantt_table_focus_tooltip": {
    "en": "Scroll to element",
    "fr": "Défiler jusqu'à l'élément"
  },
  "gantt_table_project_block_collapse_disabled_tooltip": {
    "en": "This project doesn't have any task associated",
    "fr": "Ce projet n'a aucune tâche associée"
  },
  "gantt_table_task_block_collapse_disabled_tooltip": {
    "en": "This task doesn't have any team or activities associated",
    "fr": "Cette tâche n'a pas d'équipe ou d'activités associées"
  },
  "schedule_page_project_filter_label": {
    "en": "Select a project",
    "fr": "Sélectionnez un projet"
  },
  "schedule_page_project_filter_placeholder": {
    "en": "All Projects",
    "fr": "Tous les projets"
  },
  "schedule_v2_page_project_filter_placeholder": {
    "en": "No project selected",
    "fr": "Aucun project sélectionné"
  },
  "schedule_no_project_message_bold": {
    "en": "No project selected",
    "fr": "Aucun project sélectionné"
  },
  "schedule_no_project_message": {
    "en": "Select a project in the menu located on the header of this page",
    "fr": "Sélectionnez un projet dans le menu situé sur l'en-tête de cette page"
  },
  "activity_insert_success": {
    "en": "Activity created successfully",
    "fr": "L'activité a été créée avec succès"
  },
  "activity_insert_error": {
    "en": "Sorry, we had an error with the activity creation",
    "fr": "Désolé, nous avons eu une erreur avec la création de l'activité"
  },
  "activity_update_success": {
    "en": "Activity updated successfully",
    "fr": "L'activité a été mise à jour avec succès"
  },
  "activity_update_error": {
    "en": "Sorry, we had an error with the activity update",
    "fr": "Désolé, nous avons eu une erreur avec la mise à jour de l'activité"
  },
  "product_insert_success": {
    "en": "Product created successfully",
    "fr": "Le produit a été créé avec succès"
  },
  "product_insert_error": {
    "en": "Sorry, we had an error with the product creation",
    "fr": "Désolé, nous avons eu une erreur avec la création du produit"
  },
  "product_update_success": {
    "en": "Product updated successfully",
    "fr": "Le produit a été mis à jour avec succès"
  },
  "product_update_error": {
    "en": "Sorry, we had an error with the product update",
    "fr": "Désolé, nous avons eu une erreur avec la mise à jour du produit"
  },
  "project_product_add_success": {
    "en": "Product added successfully",
    "fr": "Le produit a été ajouté avec succès"
  },
  "project_product_add_error": {
    "en": "Sorry, we had an error with the product addition",
    "fr": "Désolé, nous avons eu une erreur avec l'ajout du produit"
  },
  "project_product_update_success": {
    "en": "Product updated successfully",
    "fr": "Le produit a été mis à jour avec succès"
  },
  "project_product_update_error": {
    "en": "Sorry, we had an error with the product update",
    "fr": "Désolé, nous avons eu une erreur avec la mise à jour du produit"
  },
  "project_product_remove_success": {
    "en": "Product removed successfully",
    "fr": "Produit supprimé avec succès"
  },
  "project_product_remove_error": {
    "en": "Sorry, we had an error with the product removal",
    "fr": "Désolé, une erreur est survenue lors de la suppression du produit"
  },
  "project_activity_remove_success": {
    "en": "Activity removed successfully",
    "fr": "Activité supprimée avec succès"
  },
  "project_activity_remove_error": {
    "en": "Sorry, we had an error with the activity removal",
    "fr": "Désolé, une erreur est survenue lors de la suppression de l'activité"
  },
  "project_scheduled_date_edit": {
    "en": "Edit Project Scheduled Date",
    "fr": "Modifier La Date Cédulé Du Projet"
  },
  "project_scheduled_date_warning_gantt": {
    "en": "Project scheduled date needs to be valid to be able to use the Gantt, please add it with the button above.",
    "fr": "La date cédulé du projet doit être valide pour pouvoir utiliser le Gantt, veuillez l'ajouter avec le boutton ci-dessus."
  },
  "project_scheduled_date_warning": {
    "en": "Project scheduled date needs to be valid to be able to use the Gantt, please add it.",
    "fr": "La date cédulé du projet doit être valide pour pouvoir utiliser le Gantt, veuillez l'ajouter."
  },
  "group_unspecified": {
    "en": "Unspecified",
    "fr": "Non spécifié"
  },
  "group_specified": {
    "en": "Specified",
    "fr": "Spécifié"
  },
  "filter_button_title": {
    "en": "Filter",
    "fr": "Filtrer"
  },
  "filter_button_help_label": {
    "en": "Filter",
    "fr": "le filtrage"
  },
  "filter_button_help_title": {
    "en": "Filter Helper",
    "fr": "Aide Filtrage"
  },
  "filter_button_help_description": {
    "en": "You can filter element via the values of their properties. Click on a properties and select the value you want to filter on.",
    "fr": "Vous pouvez filtrer l'élément via les valeurs de leurs propriétés. Cliquez sur une propriété et sélectionnez la valeur sur laquelle vous souhaitez filtrer."
  },
  "group_by_menu_title": {
    "en": "Group By",
    "fr": "Grouper"
  },
  "group_by_menu_help_intro": {
    "en": "Learn more about",
    "fr": "En apprendre plus sur"
  },
  "group_by_menu_help": {
    "en": "Group By",
    "fr": "le groupement"
  },
  "group_by_menu_help_title": {
    "en": "Group by Help\n",
    "fr": "Aide Groupement"
  },
  "group_by_menu_help_description": {
    "en": "You can group products together via the values of their properties. Click \"Add next group by\" Then choose a property to group them based on the values of this property. If multiple values are present in a property, the product will be displayed in each group. Groups will be constructed from top to bottom",
    "fr": "Vous pouvez grouper des produits ensemble via les valeurs de leurs propriétés. Cliquez \"Ajouter le prochain groupement\" et choisissez une propriété pour grouper les produits selon les valeurs de cette propriété. Si plusieurs valeurs sont présente dans une propriété, le produit va être affiché dans chacun des groupes. Les groupes sont construire du haut vers le bas"
  },
  "group_by_menu_add": {
    "en": "Add next group by",
    "fr": "Ajouter le prochain groupement"
  },
  "property_delete_dialog_title": {
    "en": "Delete property",
    "fr": "Supprimer la propriété"
  },
  "property_delete_dialog_text": {
    "en": "You are about to delete this property. Are you sure you want to continue?",
    "fr": "Vous êtes sur le point de supprimer la propriété. Êtes-vous sûr de vouloir continuer?"
  },
  "property_add_button_text": {
    "en": "Add property",
    "fr": "Ajouter une propriété"
  },
  "property_add_attribute_placeholder": {
    "en": "Click here to add attributes",
    "fr": "Cliquez ici pour ajouter des attributs"
  },
  "property_insert_success": {
    "en": "Property created successfully",
    "fr": "La propriété a été créée avec succès"
  },
  "property_insert_error": {
    "en": "Sorry, we had an error with the property creation",
    "fr": "Désolé, nous avons eu une erreur avec la création de la propriété"
  },
  "property_delete_success": {
    "en": "Property deleted successfully",
    "fr": "La propriété a été supprimée avec succès"
  },
  "property_delete_error": {
    "en": "Sorry, we had an error with the property deletion",
    "fr": "Désolé, nous avons eu une erreur avec la suppression de la propriété"
  },
  "property_update_success": {
    "en": "Property updated successfully",
    "fr": "La propriété a été mise à jour avec succès"
  },
  "property_update_error": {
    "en": "Sorry, we had an error with the property update",
    "fr": "Désolé, nous avons eu une erreur avec la mise à jour de la propriété"
  },
  "dropdown_create_label": {
    "en": "Create",
    "fr": "Créer"
  },
  "attribute_delete_dialog_title": {
    "en": "Delete attribute",
    "fr": "Supprimer l'attribut"
  },
  "attribute_delete_dialog_text": {
    "en": "You are about to delete this attribute. Are you sure you want to continue?",
    "fr": "Vous êtes sur le point de supprimer l'attribut. Êtes-vous sûr de vouloir continuer?"
  },
  "employee_dialog_phone_format_error": {
    "en": "The phone format must be (000) - 000 - 0000",
    "fr": "Le format du téléphone doit être (000) - 000 - 0000"
  },
  "project_product_add_product_button_text": {
    "en": "Add product",
    "fr": "Ajouter un produit"
  },
  "project_product_edit_dialog_title": {
    "en": "Edit the product",
    "fr": "Modifier le produit"
  },
  "project_product_add_dialog_title": {
    "en": "Add a product to the project",
    "fr": "Ajouter un produit au projet"
  },
  "project_product_add_dialog_search_activities": {
    "en": "Search activities",
    "fr": "Rechercher des activités"
  },
  "project_product_add_dialog_activities_empty": {
    "en": "No activities in the product",
    "fr": "Aucune activité dans le produit"
  },
  "project_product_add_dialog_activities_title": {
    "en": "Activities",
    "fr": "Activités"
  },
  "project_product_add_dialog_activitiy_options_empty": {
    "en": "No activities available",
    "fr": "Aucune activité disponible"
  },
  "project_product_add_dialog_activitiy_options_title": {
    "en": "Available activities",
    "fr": "Activités disponible"
  },
  "property_toggle_menu_title": {
    "en": "Properties",
    "fr": "Propriétés"
  },
  "property_toggle_menu_help_intro": {
    "en": "Learn more about",
    "fr": "En apprendre plus sur"
  },
  "property_toggle_menu_help": {
    "en": "properties",
    "fr": "les propriétés"
  },
  "property_toggle_menu_help_title": {
    "en": "Properties Help",
    "fr": "Aide Propriétés"
  },
  "property_toggle_menu_help_description": {
    "en": "Properties are the values that can be added to the items to give them all kinds of context. When added, these properties can be used to filter, group, sort and search data. ",
    "fr": "Les propriétés sont les valeurs qui peuvent être ajoutées aux éléments pour leur donner toutes sortes de contexte. Une fois ajoutées, ces propriétés peuvent être utilisées pour filtrer, grouper, trier et rechercher des données."
  },
  "file_created_on": {
    "en": "Created On",
    "fr": "Date de création"
  },
  "file_type": {
    "en": "File Extension",
    "fr": "Extension de fichier"
  },
  "file_name": {
    "en": "File name",
    "fr": "Nom du fichier"
  },
  "file_attributes": {
    "en": "Attributes",
    "fr": "Attributs"
  },
  "common_folder": {
    "en": "Folder",
    "fr": "Fichier"
  },
  "attribute_dailyLog.date": {
    "en": "Daily Log - Date",
    "fr": "Rapport Journalier - Date"
  },
  "attribute_dailyLog.status": {
    "en": "Daily Log - Status",
    "fr": "Rapport Journalier - Statut"
  },
  "attribute_dailyLog.teamName": {
    "en": "Daily Log - Team Name",
    "fr": "Rapport Journalier - Nom d'équipe"
  },
  "attribute_dailyLog.shiftType": {
    "en": "Daily Log - Shift Type",
    "fr": "Rapport Journalier - Quart de travail"
  },
  "attribute_dailyLog.teamColor": {
    "en": "Daily Log - Team Color",
    "fr": "Rapport Journalier - Couleur d'équipe"
  },
  "attribute_project.code": {
    "en": "Project - Code",
    "fr": "Projet - Code"
  },
  "attribute_project.name": {
    "en": "Project - Name",
    "fr": "Projet - Nom"
  },
  "attribute_project.color": {
    "en": "Project - Color",
    "fr": "Projet - Couleur"
  },
  "company_preferences_language": {
    "en": "Default Users Language",
    "fr": "Langue des utilisateurs par défaut"
  },
  "company_preferences_language_description": {
    "en": "Civalgo will be in this language for new users that never changed their language",
    "fr": "Civalgo va être dans cette langue pour les nouveaux utilisateurs qui n'ont jamais changée leur langue"
  },
  "company_preferences_day": {
    "en": "First day of the week",
    "fr": "Premier jour de la semaine"
  },
  "company_preferences_day_description": {
    "en": "The timelines will display this day as the first day of the week",
    "fr": "Les calendriers vont afficher cette journée comme le premier jour de la semaine"
  },
  "company_set_preferences_success": {
    "en": "Company settings updated successfully",
    "fr": "Paramètres de la compagnie mis à jour avec succès"
  },
  "company_set_preferences_error_start_pay_period_date": {
    "en": "The date of the start of pay period is invalid",
    "fr": "La date de début de la période de paie n'est pas valide"
  },
  "company_set_preferences_error": {
    "en": "Sorry, we had an error with the company settings update",
    "fr": "Désolé, une erreur est survenue lors de la mise à jour des paramètres de la compagnie"
  },
  "company_set_preferences_pay_period_weekly": {
    "en": "Weekly",
    "fr": "Hebdomadaire"
  },
  "company_set_preferences_pay_period_bi_weekly": {
    "en": "Every two weeks",
    "fr": "Chaque deux semaines"
  },
  "company_set_preferences_pay_period_semi_monthly": {
    "en": "Twice a month",
    "fr": "Deux fois par mois"
  },
  "company_set_preferences_pay_period_monthly": {
    "en": "Monthly",
    "fr": "Mensuelle"
  },
  "company_preferences_pay_period": {
    "en": "Pay period",
    "fr": "Période de paie"
  },
  "company_preferences_pay_period_description": {
    "en": "The interval of pay for the company or a recurring time schedule that determines how often your employees will be paid",
    "fr": "L'intervalle de rémunération de l'entreprise ou un calendrier récurrent qui détermine la fréquence à laquelle vos employés seront payés"
  },
  "company_preferences_start_of_pay_period": {
    "en": "Start of pay period",
    "fr": "Début de la période de paie"
  },
  "company_preferences_start_of_pay_period_description": {
    "en": "The start  of the interval of pay for the company",
    "fr": "Le début de l'intervalle de rémunération pour l'entreprise"
  },
  "company_preferences_temperature": {
    "en": "Temperature unit",
    "fr": "Unité  de température"
  },
  "company_preferences_temperature_description": {
    "en": "The unit of temperature used through the system. Most common scales are Celsius and Fahrenheit. ",
    "fr": "Unité de température utilisée dans le système. Les échelles les plus courantes sont Celsius et Fahrenheit."
  },
  "compare_to_button_label": {
    "en": "Compare to",
    "fr": "Comparer"
  },
  "compare_to_recent_button_label": {
    "en": "Compare to newest version",
    "fr": "Comparer à la version la plus récente"
  },
  "move_to_button_label": {
    "en": "Move to",
    "fr": "Déplacer"
  },
  "add_new_version_button_label": {
    "en": "Add new version",
    "fr": "Ajouter une version"
  },
  "edit_version_button_label": {
    "en": "Edit file version",
    "fr": "Modifier la version du fichier"
  },
  "edit_version_button_tooltip": {
    "en": "Edit version name that is displayed on the file",
    "fr": "Modifier le nom de la version affiché du fichier"
  },
  "previous_versions_button_label": {
    "en": "Version history",
    "fr": "Historique des versions"
  },
  "previous_versions_dialog_title": {
    "en": "Version history",
    "fr": "Historique des versions"
  },
  "restore_version_button_label": {
    "en": "Restore version",
    "fr": "Restaurer la version"
  },
  "newest_version_label": {
    "en": "Newest version",
    "fr": "Version la plus récente"
  },
  "go_to_project_page_text": {
    "en": "Project page",
    "fr": "Page projet"
  },
  "go_to_project_page_description": {
    "en": "There isn't any activities available for this project. You can add activities to the project by going to the project page",
    "fr": "Il n'y a aucune activité disponibles pour ce projet. Vous pouvez ajouter des activités au projet en allant sur la page projet"
  },
  "move_to_dialog_move_file_button": {
    "en": "Move File",
    "fr": "Déplacer le fichier"
  },
  "compare_to_dialog_compare_files_button": {
    "en": "Compare Files",
    "fr": "Comparer les fichiers"
  },
  "move_to_search_placeholder": {
    "en": "Search a file or folder",
    "fr": "Rechercher un fichier ou un dossier"
  },
  "move_to_dialog_title": {
    "en": "Choose a folder to move your file",
    "fr": "Choisir un dossier pour déplacer le fichier"
  },
  "select_file_search_placeholder": {
    "en": "Search for a file",
    "fr": "Rechercher un fichier"
  },
  "compare_to_search_placeholder": {
    "en": "Search a .pdf .docx .xlsx .pptx .png .jpg file",
    "fr": "Rechercher un fichier .pdf .docx .xlsx .pptx .png .jpg"
  },
  "compare_to_dialog_description": {
    "en": "Select a file that you want to compare with ",
    "fr": "Sélectionner un fichier que vous voulez compare avec "
  },
  "compare_to_dialog_extension_warning": {
    "en": "You can only compare .pdf, .docx, .xlsx, .pptx, .png, and .jpg extension files.",
    "fr": "Seulement les fichiers de type .pdf, .docx, .xlsx, .pptx, .png, and .jpg peuvent être comparés."
  },
  "select_file_dialog_button": {
    "en": "Select",
    "fr": "Choisir"
  },
  "select_file_dialog_title": {
    "en": "Select file",
    "fr": "Choisir un fichier"
  },
  "compare_to_dialog_title": {
    "en": "Choose a file to compare",
    "fr": "Choisir un fichier pour comparer"
  },
  "files_search_placeholder": {
    "en": "Search",
    "fr": "Rechercher"
  },
  "license_agreement_terms_of_service": {
    "en": "Terms of Service",
    "fr": "Conditions de service"
  },
  "license_agreement_terms_of_service_url": {
    "en": "https://www.civalgo.com/terms/terms-of-service",
    "fr": "https://fr.civalgo.com/terms/terms-of-service"
  },
  "license_agreement_privacy_policy": {
    "en": "Privacy Policy",
    "fr": "Politique de confidentialité"
  },
  "license_agreement_privacy_policy_url": {
    "en": "https://www.civalgo.com/privacy/privacy-policy",
    "fr": "https://fr.civalgo.com/privacy/privacy-policy"
  },
  "license_agreement_acceptable_use_policy": {
    "en": "Acceptable Use Policy",
    "fr": "Politique d'utilisation acceptable"
  },
  "license_agreement_acceptable_use_policy_url": {
    "en": "https://www.civalgo.com/acceptable-use/acceptable-use-policy",
    "fr": "https://fr.civalgo.com/acceptable-use/acceptable-use-policy"
  },
  "license_agreement_acceptance": {
    "en": "By using this software, you are indicating that you have read and agree to the",
    "fr": "En utilisant ce logiciel, vous indiquez que vous avez lu et accepté les"
  },
  "license_agreement_and": {
    "en": "and",
    "fr": "et"
  },
  "location_error": {
    "en": "\nThere was a problem and your address is invalid. Please enter a new address",
    "fr": "Il y a eu un problème et votre adresse est invalide. Veuillez entrer une nouvelle adresse"
  },
  "team_organization": {
    "en": "Organization",
    "fr": "Organisation"
  },
  "group_by_cost_total_label": {
    "en": "Group cost summary",
    "fr": "Coût total du groupe"
  },
  "no_result_title": {
    "en": "No results",
    "fr": "Aucun résultat"
  },
  "no_result_message": {
    "en": "Try different search terms or filters",
    "fr": "Essayez différents termes de recherche ou filtres"
  },
  "filter_list_add_more_label": {
    "en": "Add more",
    "fr": "Ajouter plus"
  },
  "filter_list_title": {
    "en": "More filters",
    "fr": "Filtres supplémentaires"
  },
  "quick_filter_list_title": {
    "en": "Quick filters",
    "fr": "Filtres rapides"
  },
  "filter_option_search_sentence_start": {
    "en": "Search for",
    "fr": "Rechercher"
  },
  "filter_project_label": {
    "en": "Daily Log Project",
    "fr": "Projet du rapport journalier"
  },
  "filter_product_label": {
    "en": "Product Name",
    "fr": "Nom de Produit"
  },
  "filter_product_code": {
    "en": "Product Code",
    "fr": "Code de Produit"
  },
  "filter_alias_label": {
    "en": "Alias",
    "fr": "Alias"
  },
  "quick_filter_daily_log_projects": {
    "en": "Current daily log projects",
    "fr": "Projets du rapport journalier"
  },
  "daily_log_add_project_button_label": {
    "en": "Add project",
    "fr": "Ajouter projet"
  },
  "filter_list_result_counter_label": {
    "en": "results",
    "fr": "résultats"
  },
  "filter_list_selected_counter_label": {
    "en": "selected",
    "fr": "selectionnés"
  },
  "add_activity_search_placeholder": {
    "en": "Search activities...",
    "fr": "Rechercher des activités..."
  },
  "error_move_job_block_overlap": {
    "en": "Unable to move blocks because of a resource time conflict",
    "fr": "Impossible de déplacer le bloc  en raison d'un conflit horaire de ressources"
  },
  "error_add_job_block_overlap": {
    "en": "Unable to create blocks because of a resource time conflict",
    "fr": "Impossible de créer des blocs en raison d'un conflit horaire de ressources"
  },
  "error_update_job_block_overlap": {
    "en": "Unable to update blocks because of a resource time conflict",
    "fr": "Impossible de mettre à jour les blocs en raison d'un conflit horaire de ressources"
  },
  "error_move_associated_job_block_overlap": {
    "en": "Unable to move block because an associated ressource has a time conflict",
    "fr": "Impossible de déplacer le bloc car une ressource associée a un conflit de temps"
  },
  "error_add_associated_job_block_overlap": {
    "en": "Unable to create block because an associated ressource has a time conflict",
    "fr": "Impossible de créer des blocs  car une ressource associée a un conflit de temps"
  },
  "error_update_associated_job_block_overlap": {
    "en": "Unable to update block because an associated ressource has a time conflict",
    "fr": "Impossible de mettre à jour les blocs  car une ressource associée a un conflit de temps"
  },
  "add_projects_dialog_label": {
    "en": "Projects",
    "fr": "Projets"
  },
  "add_projects_dialog_title": {
    "en": "Add projects",
    "fr": "Ajouter des projets"
  },
  "quick_filter_prefix_in": {
    "en": "In",
    "fr": "Dans"
  },
  "filter_activity_label": {
    "en": "Activity",
    "fr": "Activité"
  },
  "add_activity_blocks_dialog_title": {
    "en": "Add activity",
    "fr": "Ajouter une activité"
  },
  "add_activity_blocks_dialog_search_placeholder": {
    "en": "Search activity",
    "fr": "Rechercher une activité"
  },
  "add_activity_blocks_button_label": {
    "en": "Select activity...",
    "fr": "Sélectionnez l'activité ..."
  },
  "select_product_button_label": {
    "en": "Select product...",
    "fr": "Sélectionner un produit..."
  },
  "select_product_dialog_title": {
    "en": "Select product",
    "fr": "Sélectionner un produit"
  },
  "select_product_dialog_search_placeholder": {
    "en": "Search product",
    "fr": "Rechercher le produit"
  },
  "filter_dialog_search_placeholder": {
    "en": "Search employee...",
    "fr": "Rechercher un employé ..."
  },
  "filter_dialog_organization": {
    "en": "Organization",
    "fr": "Organisme"
  },
  "filter_dialog_employee_role": {
    "en": "Employee role",
    "fr": "Rôle des employés"
  },
  "file_copy_error": {
    "en": "Sorry, we had an error with the file copy",
    "fr": "Désolé, nous avons eu une erreur avec la copie du fichier"
  },
  "file_copy_success": {
    "en": "File copied successfully",
    "fr": "Fichier copié avec succès"
  },
  "file_rename_error": {
    "en": "Sorry, we had an error with the file rename",
    "fr": "Désolé, nous avons eu une erreur avec le renommage du fichier"
  },
  "file_upload_success": {
    "en": "Files uploaded successfully",
    "fr": "Fichiers téléchargés avec succès"
  },
  "file_upload_error": {
    "en": "Sorry, we had an error with the file upload",
    "fr": "Désolé, nous avons eu une erreur avec le téléchargement du fichier"
  },
  "file_restore_error": {
    "en": "Sorry, we had an error with the file restoration",
    "fr": "Désolé, nous avons eu une erreur avec la restauration du fichier"
  },
  "file_name_not_unique_error": {
    "en": "A file with this name already exist in this directory",
    "fr": "Un fichier avec ce nom existe déjà dans ce dossier"
  },
  "file_version_restore_success": {
    "en": "Version restored successfully",
    "fr": "Version restaurée avec succès"
  },
  "file_version_restore_error": {
    "en": "Sorry, we had an error with the version restoration",
    "fr": "Désolé, nous avons eu une erreur avec la restauration de la version"
  },
  "filter_dialog_team": {
    "en": "Team",
    "fr": "Équipe"
  },
  "filter_dialog_team_leader": {
    "en": "Team Leader",
    "fr": "Chef d'équipe"
  },
  "quick_filter_daily_log_team": {
    "en": "Daily log team",
    "fr": "Équipe du rapport journalier"
  },
  "filename_conflicts_resolver_dialog_title": {
    "en": "Resolve conflicts between files",
    "fr": "Résoudre les conflits entre les fichiers"
  },
  "filename_conflicts_resolver_dialog_option": {
    "en": "Option",
    "fr": "Option"
  },
  "filename_conflicts_resolver_dialog_filename": {
    "en": "File name",
    "fr": "Nom du fichier"
  },
  "filename_conflicts_resolver_dialog_version": {
    "en": "Version",
    "fr": "Version"
  },
  "filename_conflicts_resolver_dialog_description": {
    "en": "This folder has files with the same names",
    "fr": "Ce dossier contient des fichiers avec des noms similaire"
  },
  "filename_conflicts_resolver_dialog_increment_version": {
    "en": "Add a new version of the file",
    "fr": "Ajouter une nouvelle version du fichier"
  },
  "filename_conflicts_resolver_dialog_increment_version_description": {
    "en": "A new file will be uploaded with the version you choosed and the older version of the file will be archived",
    "fr": "Un nouveau fichier sera téléchargé avec la version que vous avez choisi et l'ancienne version du fichier sera archivée"
  },
  "filename_conflicts_resolver_dialog_keep_both": {
    "en": "Keep both files",
    "fr": "Garder les deux fichiers"
  },
  "filename_conflicts_resolver_dialog_keep_both_description": {
    "en": "A new file will be uploaded with the name you choosed and the other file will be unchanged",
    "fr": "Un nouveau fichier sera téléchargé avec le nom que vous avez choisi et l'autre fichier sera inchangé"
  },
  "filename_conflicts_resolver_dialog_no_conflict": {
    "en": "No file name conflict found",
    "fr": "Aucun conflit de nom de fichier trouvé"
  },
  "filter_dialog_search_placeholder_equipment": {
    "en": "Search equipment...",
    "fr": "Équipement de recherche ..."
  },
  "filter_dialog_equipment_type": {
    "en": "Type",
    "fr": "Type"
  },
  "sms_dialog_employee_header": {
    "en": "Employees",
    "fr": "Des employés"
  },
  "sms_dialog_phone_header": {
    "en": "Phone number",
    "fr": "Numéro de téléphone"
  },
  "sms_dialog_organization_header": {
    "en": "Organization",
    "fr": "Organisme"
  },
  "sms_dialog_action_header": {
    "en": "Action",
    "fr": "Action"
  },
  "price_preset_dialog_organization_header": {
    "en": "Organization",
    "fr": "Organisme"
  },
  "price_preset_dialog_employee_header": {
    "en": "Employee",
    "fr": "Employé"
  },
  "price_preset_dialog_equipment_header": {
    "en": "Equipment",
    "fr": "Équipement"
  },
  "price_preset_dialog_material_header": {
    "en": "Material",
    "fr": "Matériau"
  },
  "changetenantmodal_title": {
    "en": "Change company",
    "fr": "Changer d'entreprise"
  },
  "changetenantmodal_search": {
    "en": "Search...",
    "fr": "Rechercher..."
  },
  "changetenantmodal_availabletenants": {
    "en": "Available Companies",
    "fr": "Entreprises disponibles"
  },
  "changetenantmodal_cannotload": {
    "en": "Unable to load available companies",
    "fr": "Impossible de charger les entreprises disponibles"
  },
  "changetenantmodal_loading": {
    "en": "Loading...",
    "fr": "Chargement..."
  },
  "changetenantmodal_currently": {
    "en": "Currently",
    "fr": "Actuellement"
  },
  "appframe_changetenant": {
    "en": "Change Company",
    "fr": "Changer d'entreprise"
  },
  "file_download_action": {
    "en": "Download",
    "fr": "Télécharger"
  },
  "file_preview_action": {
    "en": "Preview",
    "fr": "Aperçu"
  },
  "users_invite_success": {
    "en": "User invited successfully",
    "fr": "Utilisateur invité avec succès"
  },
  "users_invite_error": {
    "en": "An error happened while inviting the user",
    "fr": "Une erreur est survenue en invitant l'utilisateur"
  },
  "users_resend_email_success": {
    "en": "User re-invited successfully",
    "fr": "Utilisateur réinvité avec succès"
  },
  "users_resend_email_error": {
    "en": "An error happened while re-inviting the user",
    "fr": "Une erreur est survenue en réinvitant l'utilisateur"
  },
  "users_delete_success": {
    "en": "User deleted successfully",
    "fr": "Utilisateur supprimé avec succès"
  },
  "users_delete_error": {
    "en": "An error happened while deleting the user",
    "fr": "Une erreur est survenue en supprimant l'utilisateur"
  },
  "loading": {
    "en": "Loading...",
    "fr": "Chargement..."
  },
  "detailed_timesheet_time_type": {
    "en": "Wage Rate Code",
    "fr": "Code du taux"
  },
  "detailed_timesheet_employee_name": {
    "en": "Employee Name",
    "fr": "Nom de l'employé"
  },
  "detailed_timesheet_equipment_code": {
    "en": "Equipment Code",
    "fr": "Code de l'équipement"
  },
  "detailed_timesheet_equipments": {
    "en": "Equipment Name",
    "fr": "Nom de l'équipement"
  },
  "detailed_timesheet_equipment_note": {
    "en": "Equipment Note",
    "fr": "Note de l'équipement"
  },
  "detailed_timesheet_file_name": {
    "en": "Detailed-payroll",
    "fr": "Feuille-de-temps-détaillé"
  },
  "detailed_timesheet_project_code": {
    "en": "Project Code",
    "fr": "Code du projet"
  },
  "detailed_timesheet_filters": {
    "en": "Filters",
    "fr": "Filtres"
  },
  "detailed_timesheet_date": {
    "en": "Date",
    "fr": "Date"
  },
  "detailed_timesheet_dailyLog": {
    "en": "Daily Log",
    "fr": "Rapport journalier"
  },
  "detailed_timesheet_activity": {
    "en": "Activity Name",
    "fr": "Nom de l'activité"
  },
  "detailed_timesheet_activity_code": {
    "en": "Activity Code",
    "fr": "Code de l'activité"
  },
  "detailed_timesheet_project_name": {
    "en": "Project Name",
    "fr": "Nom du projet"
  },
  "detailed_timesheet_project_type": {
    "en": "Project Type",
    "fr": "Type de projet"
  },
  "detailed_timesheet_project": {
    "en": "Project",
    "fr": "Projet"
  },
  "detailed_timesheet_product": {
    "en": "Product Name",
    "fr": "Nom du produit"
  },
  "detailed_timesheet_product_alias": {
    "en": "Product Alias",
    "fr": "Alias du produit"
  },
  "detailed_timesheet_product_code": {
    "en": "Product Code",
    "fr": "Code du produit"
  },
  "detailed_timesheet_task": {
    "en": "Task Name",
    "fr": "Nom de la tâche"
  },
  "detailed_timesheet_task_code" : {
    "en": "Task Code",
    "fr": "Code de la tâche"
  },
  "detailed_timesheet_task_alias" : {
    "en": "Task Alias",
    "fr": "Alias de la tâche",
  },
  "detailed_timesheet_subtask": {
    "en": "Subtask",
    "fr": "Nom de la sous-tâche"
  },
  "detailed_timesheet_subtask_code": {
    "en": "Subtask Code",
    "fr": "Code de la sous-tâche"
  },
  "detailed_timesheet_shift_name": {
    "en": "Shift Name",
    "fr": "Nom du quart de travail"
  },
  "detailed_timesheet_shift_description": {
    "en": "Shift Description",
    "fr": "Description du quart de travail"
  },
  "detailed_timesheet_start": {
    "en": "Start Time",
    "fr": "Heure de début"
  },
  "detailed_timesheet_end": {
    "en": "End Time",
    "fr": "Heure de fin"
  },
  "detailed_timesheet_search_placeholder": {
    "en": "Search",
    "fr": "Rechercher"
  },
  "detailed_timesheet_project_contract_number": {
    "en": "Contract Number",
    "fr": "Numéro de contrat"
  },
  "detailed_timesheet_project_contract_name": {
    "en": "Contract Name",
    "fr": "Nom de contrat"
  },
  "detailed_timesheet_equipment_types": {
    "en": "Equipment Type",
    "fr": "Type d'équipement"
  },
  "detailed_timesheet_regular": {
    "en": "Regular Hours",
    "fr": "Heures réguliers"
  },
  "detailed_timesheet_without_pension": {
    "en": "Worked hours without pension",
    "fr": "Heures travaillées sans prime"
  },
  "detailed_timesheet_pension_time": {
    "en": "Pension hours",
    "fr": "Heures de prime"
  },
  "detailed_timesheet_pension_money": {
    "en": "Pension money",
    "fr": "Argent de prime"
  },
  "detailed_timesheet_overtime": {
    "en": "Overtime",
    "fr": "Temps et demi"
  },
  "detailed_timesheet_double_overtime": {
    "en": "Double Overtime",
    "fr": "Temps double"
  },
  "detailed_timesheet_triple_overtime": {
    "en": "Triple Overtime",
    "fr": "Temps triple"
  },
  "detailed_timesheet_regular_day": {
    "en": "Day - Regular Hours",
    "fr": "Heures de jour réguliers"
  },
  "detailed_timesheet_regular_night": {
    "en": "Night - Regular Hours",
    "fr": "Heures de nuit réguliers"
  },
  "detailed_timesheet_overtime_night": {
    "en": "Night - Overtime",
    "fr": "Heures supplémentaires de nuit"
  },
  "detailed_timesheet_overtime_day": {
    "en": "Day - Overtime",
    "fr": "Heures supplémentaires de jour"
  },
  "detailed_timesheet_equipment_hours": {
    "en": "Equipment Hours",
    "fr": "Heures équipement"
  },
  "detailed_timesheet_employee_code": {
    "en": "Employee Code",
    "fr": "Code de l'employé"
  },
  "detailed_timesheet_employee_role_code": {
    "en": "Employee Occupation Code",
    "fr": "Code d'occupation"
  },
  "detailed_timesheet_employee_role_name": {
    "en": "Employee Occupation Name",
    "fr": "Nom de l'occupation"
  },
  "detailed_timesheet_employee_type": {
    "en": "Employee Type",
    "fr": "Type de l'employé"
  },
  "detailed_timesheet_employee_org_name": {
    "en": "Employee Organization",
    "fr": "Organisation de l'employé"
  },
  "detailed_timesheet_employee_note": {
    "en": "Employee Note",
    "fr": "Note de l'employé"
  },
  "detailed_timesheet_duration": {
    "en": "Worked Hours",
    "fr": "Heures travaillées"
  },
  "detailed_timesheet_pension_name": {
    "en": "Pension Name",
    "fr": "Nom de la prime"
  },
  "detailed_timesheet_pension_code": {
    "en": "Pension Code",
    "fr": "Code de la prime"
  },
  "detailed_timesheet_pension_type": {
    "en": "Pension Type",
    "fr": "Type de la prime"
  },
  "detailed_timesheet_pension_value": {
    "en": "Pension Value",
    "fr": "Valeur de la prime"
  },
  "detailed_timesheet_pensions": {
    "en": "Pensions",
    "fr": "Primes"
  },
  "detailed_timesheet_team_name": {
    "en": "Team Name",
    "fr": "Nom d'équipe"
  },
  "detailed_timesheet_team_code": {
    "en": "Team Code",
    "fr": "Code d'équipe"
  },
  "detailed_timesheet_rate_start": {
    "en": "Wage Rate Start",
    "fr": "Début du taux"
  },
  "detailed_timesheet_rate_end": {
    "en": "Wage Rate End",
    "fr": "Fin du taux"
  },
  "detailed_timesheet_rate_effective_date": {
    "en": "Wage Rate Effective Date",
    "fr": "Date effective du taux"
  },
  "detailed_timesheet_rate_value": {
    "en": "Wage Rate value",
    "fr": "Valeur du taux"
  },
  "detailed_timesheet_applied_rule": {
    "en": "Applied rule",
    "fr": "Règle appliquée"
  },
  "detailed_timesheet_block_start": {
    "en": "Block Start",
    "fr": "Début du bloc"
  },
  "detailed_timesheet_block_end": {
    "en": "Block End",
    "fr": "Fin du bloc"
  },
  "detailed_timesheet_hour_type_name": {
    "en": "Work Type Name",
    "fr": "Nom du type de travail"
  },
  "detailed_timesheet_hour_type_code": {
    "en": "Work Type Code",
    "fr": "Code du type de travail"
  },
  "detailed_timesheet_hour_meter_start": {
    "en": "Hour Meter Start",
    "fr": "Début de l'odomètre"
  },
  "detailed_timesheet_hour_meter_end": {
    "en": "Hour Meter End",
    "fr": "Fin de l'odomètre"
  },
  "detailed_timesheet_export_title": {
    "en": "Export",
    "fr": "Exporter"
  },
  "detailed_timesheet_export_type": {
    "en": "Type",
    "fr": "Type"
  },
  "detailed_timesheet_export_csv_format": {
    "en": "CSV",
    "fr": "CSV"
  },
  "detailed_timesheet_export_excel_format": {
    "en": "Excel",
    "fr": "Excel"
  },
  "detailed_timesheet_export_file_format": {
    "en": "File format",
    "fr": "Format de fichier"
  },
  "detailed_timesheet_export_detailed": {
    "en": "Detailed",
    "fr": "Détaillé"
  },
  "detailed_timesheet_export_summary": {
    "en": "Summary",
    "fr": "Sommaire"
  },
  "detailed_timesheet_export_columns_header": {
    "en": "Columns",
    "fr": "Colonnes"
  },
  "detailed_timesheet_export_all_columns": {
    "en": "All Columns",
    "fr": "Toutes les colonnes"
  },
  "detailed_timesheet_export_groupby": {
    "en": "Group by",
    "fr": "Groupé par"
  },
  "detailed_timesheet_export_groupby_project": {
    "en": "Project",
    "fr": "Projet"
  },
  "detailed_timesheet_export_groupby_product": {
    "en": "Product",
    "fr": "Produit"
  },
  "detailed_timesheet_export_groupby_activity": {
    "en": "Activity",
    "fr": "Activité"
  },
  "detailed_timesheet_export_groupby_block": {
    "en": "Block",
    "fr": "Bloc"
  },
  "detailed_timesheet_no_column_ordering": {
    "en": "Column selection and ordering is not currently available for this type of export",
    "fr": "La sélection et l'ordonnacement des colonnes n'est pas présentement disponible pour ce type d'export"
  },
  "detailed_timesheet_export_column_tooltip_title": {
    "en": "Column selection and ordering",
    "fr": "Selection de colonnes et ordre"
  },
  "detailed_timesheet_export_column_tooltip": {
    "en": "You can pick and order the columns you wish to see in your exported CSV. Columns can be re-arranged by drag and drop. Only checked columns will be exported.",
    "fr": "Vous pouvez choisir et ordonner les colonnes tel que vous désirer les voirs dans votre fichier CSV exporté. Les colonnes peuvent être réordonnées en les glissant avec la souris. Seulement les colonnes cochées sont exportées."
  },
  "detailed_timesheet_export_equipments_display": {
    "en": "Equipments display",
    "fr": "Affichage des equipements"
  },
  "detailed_timesheet_export_equipments_on_rows": {
    "en": "On rows",
    "fr": "Par lignes"
  },
  "detailed_timesheet_export_equipments_on_cols": {
    "en": "On columns",
    "fr": "Par colonnes"
  },
  "detailed_timesheet_export_equipments_tooltip_title": {
    "en": "Equipments display",
    "fr": "Affichage des equipements"
  },
  "detailed_timesheet_export_equipments_tooltip": {
    "en": "When multiple equipments are assigned to the same employee exported data can have multiple equipments per row.\nThis option gives you the control on how you want equipments to be displayed in the exported file.\nThe following 2 options are available:\n",
    "fr": "Quand plusieurs equipements sont assignés au même employé, les lignes du fichier exporté peuvent avoir plus d'un équipement par ligne.\nCette option vous permet de controller comment les equipements sont affichés dans le fichier.\nLes deux options suivantes sont disponibles:\n"
  },
  "detailed_timesheet_export_equipments_tooltip_onrow": {
    "en": "- On rows : Will display 1 equipment per row, adds additional rows if necessary",
    "fr": "- Par lignes: 1 equipement par ligne, des lignes additionnelles sont ajoutées au besoin"
  },
  "detailed_timesheet_export_equipments_tooltip_oncol": {
    "en": "- On columns: Will display all equipments on 1 row, adds additional columns if necessary",
    "fr": "- Par colonnes: Tout les equipements sur 1 ligne, des colonnes sont ajoutées au besoin"
  },
  "detailed_timesheet_export_excel_sheet_name": {
    "en": "Sheet1",
    "fr": "Feuil1"
  },
  "detailed_timesheet_export_no_rate_code": {
    "en": "No Rate",
    "fr": "Sans Taux"
  },
  "timesheet_totals_title": {
    "en": "Total",
    "fr": "Total"
  },
  "timesheet_1_equipments": {
    "en": "equipment",
    "fr": "équipment"
  },
  "timesheet_x_equipments": {
    "en": "equipments",
    "fr": "équipments"
  },
  "timesheet_0_equipments": {
    "en": "No equipments",
    "fr": "Aucun équipment"
  },
  "timesheet_columns_button": {
    "en": "Columns",
    "fr": "Colonnes"
  },
  "timesheet_all_validated": {
    "en": "All daily logs validated",
    "fr": "Rapports journalier tous validés"
  },
  "timesheet_x_not_validated": {
    "en": "daily logs not validated",
    "fr": "rapports journalier non validés"
  },
  "timesheet_1_not_validated": {
    "en": "daily log not validated",
    "fr": "rapport journalier non validé"
  },
  "timesheet_no_dailylogs": {
    "en": "No daily logs",
    "fr": "Aucun rapport journalier"
  },
  "timesheet_x_pensions": {
    "en": "pensions",
    "fr": "primes"
  },
  "timesheet_1_pensions": {
    "en": "pension",
    "fr": "prime"
  },
  "timesheet_0_pensions": {
    "en": "No pensions",
    "fr": "Aucune prime"
  },
  "timesheet_pension_count": {
    "en": "Number of pensions",
    "fr": "Nombre de primes"
  },
  "timesheet_regular_title": {
    "en": "Regular",
    "fr": "Régulier"
  },
  "timesheet_total_title": {
    "en": "Total",
    "fr": "Total"
  },
  "timesheet_file_name": {
    "en": "Payroll",
    "fr": "Paie"
  },
  "timesheet_overtime_title": {
    "en": "Overtime",
    "fr": "Heures supplémentaires"
  },
  "timesheet_double_overtime_label": {
    "en": "Double Overtime",
    "fr": "Heures doubles"
  },
  "timesheet_timeoff_label": {
    "en": "Time Off",
    "fr": "Temps libre"
  },
  "timesheet_export_buttton": {
    "en": "Export",
    "fr": "Exporter"
  },
  "timesheet_period_label": {
    "en": "Choose a period",
    "fr": "Choisissez une période"
  },
  "timesheet_download_label_pdf": {
    "en": "Print",
    "fr": "Imprimer"
  },
  "timesheet_download_label_csv": {
    "en": "CSV export",
    "fr": "Exportation de CSV"
  },
  "timesheet_search_placeholder": {
    "en": "Find employee",
    "fr": "Trouver un employé"
  },
  "timesheet_rule_daily_rules": {
    "en": "Daily rules",
    "fr": "Règles quotidiennes"
  },
  "timesheet_rule_pay_period_rules": {
    "en": "Pay period rules",
    "fr": "Règles de période de paie"
  },
  "timesheet_rules_example": {
    "en": "Example",
    "fr": "Exemple"
  },
  "timesheet_rules_rule": {
    "en": "Rule",
    "fr": "Règle"
  },
  "timesheet_rules_syntax": {
    "en": "Syntax",
    "fr": "Syntaxe"
  },
  "timesheet_rules_variables": {
    "en": "Variables",
    "fr": "Variables"
  },
  "timesheet_rules_inputs": {
    "en": "Inputs",
    "fr": "Entrées"
  },
  "timesheet_rules_outputs": {
    "en": "Outputs",
    "fr": "Sorties"
  },
  "timesheet_rules_custom_outputs": {
    "en": "Custom Outputs",
    "fr": "Sorties personnalisés"
  },
  "timesheet_rules_functions": {
    "en": "Functions",
    "fr": "Fonctions"
  },
  "timesheet_rules_operator": {
    "en": "Operators",
    "fr": "Opérateurs"
  },
  "timesheet_rules_math_functions": {
    "en": "Mathematical functions",
    "fr": "Fonctions mathématiques"
  },
  "timesheet_rules_preview": {
    "en": "Preview",
    "fr": "Aperçu"
  },
  "timesheet_rules_reset": {
    "en": "Reset",
    "fr": "Réinitialiser"
  },
  "timesheet_rules_error": {
    "en": "Error",
    "fr": "Erreur"
  },
  "timesheet_rules_success": {
    "en": "Success",
    "fr": "Succès"
  },
  "timesheet_rule_create_success": {
    "en": "Pay rule created successfully",
    "fr": "La règle de paie a été créée avec succès"
  },
  "timesheet_rule_create_error": {
    "en": "Sorry, we had an error with the pay rule creation",
    "fr": "Désolé, nous avons eu une erreur avec la création de la règle de paie"
  },
  "timesheet_rule_update_success": {
    "en": "Pay rule updated successfully",
    "fr": "La règle de paie a été mise à jour avec succès"
  },
  "timesheet_rule_update_error": {
    "en": "Sorry, we had an error with the pay rule update",
    "fr": "Désolé, nous avons eu une erreur avec la mise à jour de la règle de paie "
  },
  "timesheet_rule_delete_success": {
    "en": "Pay rule deleted successfully",
    "fr": "La règle de paie a été supprimée avec succès"
  },
  "timesheet_rule_delete_error": {
    "en": "Sorry, we had an error with the pay rule deletion",
    "fr": "Désolé, nous avons eu une erreur avec la suppression de la règle de paie"
  },
  "timesheet_rule_list_search_placeholder": {
    "en": "Search a rule",
    "fr": "Rechercher une règle"
  },
  "timesheet_rule_name_placeholder": {
    "en": "Name of the rule",
    "fr": "Nom de la règle"
  },
  "timesheet_rule_list_add_rule": {
    "en": "Add rule",
    "fr": "Ajouter une règle"
  },
  "timesheet_rule_all_employees": {
    "en": "All employees",
    "fr": "Tous les employés"
  },
  "timesheet_rule_all_organizations": {
    "en": "All organizations",
    "fr": "Toutes les organisations"
  },
  "timesheet_rule_all_roles": {
    "en": "All roles",
    "fr": "Tous les rôles"
  },
  "timesheet_rule_list_help": {
    "en": "Pay Rules",
    "fr": "les règles de paie"
  },
  "timesheet_rule_list_help_intro": {
    "en": "Learn more about ",
    "fr": "En apprendre plus sur"
  },
  "timesheet_rule_list_help_title": {
    "en": "Pay Rules help",
    "fr": "Aide sur les règles de paie"
  },
  "timesheet_rule_refresh": {
    "en": "Refresh",
    "fr": "Rafraîchir"
  },
  "timesheet_rule_refresh_tooltip": {
    "en": "This page is not real-time, refresh to have up-to-date data.",
    "fr": "Cette page n'est pas en temps réel, rafraichissez pour avoir les données à jour."
  },
  "timesheet_rule_refresh_now": {
    "en": "Now",
    "fr": "Maintenant"
  },
  "timesheet_rule_no_rule_tooltip": {
    "en": "No rule applied",
    "fr": "Aucune règle appliquée"
  },
  "timesheet_rule_list_help_description": {
    "en": "You can create, update and re-order multiple pay rules. Pay rules dicate how the hours are separated in the payroll page. Pay rules are applied from top to bottom. If a rule is below another one, it will overwrite the first one if the condtions are met.",
    "fr": "Vous pouvez créer, modifier et ré-arranger l'ordre de plusieurs règles de paie. Les règles de paie dicte comment les heures sont séparées dans la page de paie. Les règles de paiesont appliqués du haut vers le bas. Si une règle est en dessous d'une autre, elle écrasera la première si les conditions sont remplies. "
  },
  "timesheet_rule_list_help_description_important": {
    "en": "Only one rule will be applied for an employee.",
    "fr": "Une seule règle sera appliquée pour un employé."
  },
  "timesheet_rule_list_help_show_more": {
    "en": "Show more",
    "fr": "Montrer plus"
  },
  "timesheet_rule_list_help_show_less": {
    "en": "Show less",
    "fr": "Montrer moins"
  },
  "timesheet_rule_list_help_show_more_text": {
    "en": "It is recommended to have your most general rules at the top and the more specifics ones at the bottom. For example, you could have a rule called \"Federal\" which represent the pay rules for your country and below it, another rule called \"Subcontrators\" which is applied on employees from external organizations.",
    "fr": "Il est recommendé d'avoir vos règles les plus générales dans le haut de la liste et les plus spécifiques, vers le bas. Par exemple, vous pourriez avoir une règle appelée \"Fédérale\" qui représente les règles de paie pour votre pays et en dessous, une règles \"Sous-contractants\" qui est appliquées sur les employés des organizations externes."
  },
  "timesheet_rule_list_delete_dialog_title": {
    "en": "Delete Pay rule",
    "fr": "Supprimer la règle de paie"
  },
  "timesheet_rule_list_delete_dialog_description": {
    "en": "You are about to delete this pay rule. Are you sure you want to continue?",
    "fr": "Vous êtes sur le point de supprimer la règle de paie. Êtes-vous sûr de vouloir continuer?"
  },
  "timesheet_rules_workdays_description": {
    "en": "An array of all the days in the period for an employee with total hours worked on the day, date, weekday, shifts, pensions & activities.",
    "fr": "Un tableau de tous les jours de la période pour un employé avec le total des heures travaillé pour la journée, la date, le jour de la semaine, les primes et les activités."
  },
  "timesheet_rules_workdays_syntax": {
    "en": "workdays = [{ total: 8, date: '2020-01-01', weekday: 'wednesday', pensions: ['Break', 'Lunch'], shifts: [{ name: '9-5', shiftLength: 9, lunchLength: 0.5, weekendStartDay: 'Friday', weekendStartTime : '16:30' , weekendEndDay:\"Sunday\", weekendEndTime: '18:30' }, ] }, ...]",
    "fr": "workdays = [{ total: 8, date: '2020-01-01', weekday: 'wednesday', pensions: ['Break', 'Lunch'], shifts: [{ name: '9-5', shiftLength: 9, lunchLength: 0.5 }] }, ...]"
  },
  "timesheet_rules_workdays_example": {
    "en": "regular = map(workdays, if(x.weekday == 'sunday', 0, x.total))",
    "fr": "regular = map(workdays, x.total)"
  },
  "timesheet_rules_workweeks_description": {
    "en": "An array of all the weeks in the period for an employee with total hours worked in the the week, date of start of week & date of end of week.",
    "fr": "Un tableau de toutes les semaines de la période pour un employé avec le total des heures travaillé pour la semaine, la date de début de la semaine et la date de fin de la semaine"
  },
  "timesheet_rules_daily_overtime_by_activity_syntax": {
    "en": "overtime = dailyOvertimeByActivity(nigthStartTime,nigthEndTime)",
    "fr": "overtime = dailyOvertimeByActivity(nigthStartTime,nigthEndTim)"
  },
  "timesheet_rules_daily_overtime_by_activity_example": {
    "en": "overtime = dailyOvertimeByActivity(\"18:30\",\"06:30\")",
    "fr": "overtime = dailyOvertimeByActivity(\"18:30\",\"06:30\")"
  },
  "timesheet_rules_daily_overtime_by_activity_description": {
    "en": "Will return an array of workdays with evaluated activities. Evaluated activitie will have new attibutes : 'regularDay', 'regularNight', 'overtimeNight', 'overtimeDay'.",
    "fr": "Renvoie un tableau de workdays avec des activités évaluées. L'activité évaluée aura de nouveaux attributs:  'regularDay', 'regularNight', 'overtimeNight', 'overtimeDay'."
  },
  "timesheet_rules_workweeks_syntax": {
    "en": "workweeks = [{ total: 40, start: '2020-01-01', end: '2020-01-08' }, { total: 40, start: '2020-01-08', end: '2020-01-15' }]",
    "fr": "workweeks = [{ total: 40, start: '2020-01-01', end: '2020-01-08' }, { total: 40, start: '2020-01-08', end: '2020-01-15' }]"
  },
  "timesheet_rules_workweeks_example": {
    "en": "overtime = map(workweeks, if(x.total > 40, x.total - 40, 0))",
    "fr": "overtime = map(workweeks, if(x.total > 40, x.total - 40, 0))"
  },
  "timesheet_rules_blocks_description": {
    "en": "An array of all the time blocks in the period for an employee with the daily log date, start and end of the block.",
    "fr": "Un tableau de tous les blocs de temps dans la période pour un employé avec la date du rapport journalier, le début et la fin du bloc."
  },
  "timesheet_rules_blocks_syntax": {
    "en": "blocks= [{ date: '2020-01-01', start: '07:00', end: '12:00' }, { date: '2020-01-01', start: '13:00', end: '17:00' }, ...]",
    "fr": "blocks= [{ date: '2020-01-01', start: '07:00', end: '12:00' }, { date: '2020-01-01', start: '13:00', end: '17:00' }, ...]"
  },
  "timesheet_rules_blocks_example": {
    "en": "todo",
    "fr": "todo"
  },
  "timesheet_rules_total_description": {
    "en": "Total of worked hours of an employee for the pay period that should be used to calculate regular, overtime, double-overtime & time off hours.",
    "fr": "Total d'heures travaillées d'un employé pour la période de paie qui devrait être utilisé pour calculer les heures heures régulières, supplémentaires, doubles et temps libre."
  },
  "timesheet_rules_total_example": {
    "en": "regular = total - overtime",
    "fr": "regular = total - overtime"
  },
  "timesheet_rules_regular_description": {
    "en": "Total of regular hours of an employee for the pay period. Initial value is 0.",
    "fr": "Total des heures regulières d'un employé pour la période de paie. Valeur initiale est 0."
  },
  "timesheet_rules_regular_example": {
    "en": "regular = total - overtime",
    "fr": "regular = total - overtime"
  },
  "timesheet_rules_overtime_description": {
    "en": "Total of overtime hours of an employee for the pay period. Initial value is 0.",
    "fr": "Total des heures supplémentaires d'un employé pour la période de paie. Valeur initiale est 0."
  },
  "timesheet_rules_overtime_example": {
    "en": "overtime = max(dailyWorkedHoursOver(8), weeklyWorkedHoursOver(40)) # Federal Rule",
    "fr": "overtime = max(dailyWorkedHoursOver(8), weeklyWorkedHoursOver(40)) # Règle fédérale"
  },
  "timesheet_rules_double_overtime_description": {
    "en": "Total of double-overtime hours of an employee for the pay period. Initial value is 0.",
    "fr": "Total des heures doubles d'un employé pour la période de paie. Valeur initiale est 0."
  },
  "timesheet_rules_double_overtime_example": {
    "en": "doubleOvertime = dailyWorkHoursOver(12) # British Columbia rule",
    "fr": "doubleOvertime = dailyWorkHoursOver(12) # Règle de la Colombie-Britannique"
  },
  "timesheet_rules_time_off_description": {
    "en": "Total of time off hours of an employee for the pay period. Initial value is 0",
    "fr": "Total du temps libre d'un employé pour la période de paie. Valeur initiale est 0."
  },
  "timesheet_rules_time_off_example": {
    "en": "timeOff = 0",
    "fr": "timeOff = 0"
  },
  "timesheet_rules_daily_worked_hours_over_description": {
    "en": "Compute the total of hours above the threshold each day in the pay period and sum them.",
    "fr": "Calcule le total des heures au-dessus du seuil chaque jour de la période de paie et en fait la sommation."
  },
  "timesheet_rules_daily_worked_hours_over_syntax": {
    "en": "dailyWorkedHoursOver(number)",
    "fr": "dailyWorkedHoursOver(nombre)"
  },
  "timesheet_rules_daily_worked_hours_over_example": {
    "en": "overtime = dailyWorkedHoursOver(8) # For someone who worked 10h a day for 5 days in the period => 10",
    "fr": "overtime = dailyWorkedHoursOver(8) # Pour quelqu'un qui as travaillé 10h par jour pour 5 jours dans la période => 10',"
  },
  "timesheet_rules_weekly_worked_hours_over_description": {
    "en": "Compute the total of hours above the threshold each week in the pay period and sum them.",
    "fr": "Calcule le total des heures au-dessus du seuil chaque semaine de la période de paie et en fait la sommation."
  },
  "timesheet_rules_weekly_worked_hours_over_syntax": {
    "en": "weeklyWorkedHoursOver(number)",
    "fr": "weeklyWorkedHoursOver(nombre)"
  },
  "timesheet_rules_weekly_worked_hours_over_example": {
    "en": "overtime = weeklyWorkedHoursOver(40) # For someone who worked 10h a day for 5 days in the period => 10",
    "fr": "overtime = weeklyWorkedHoursOver(40) # Pour quelqu'un qui as travaillé 10h par jour pour 5 jours dans la période => 10"
  },
  "timesheet_rules_worked_hours_on_weekday_description": {
    "en": "Compute the total of hours on a specific weekday in the pay period and sum them.",
    "fr": "Calcule le total des heures sur une journée de la semaine spécifique de la période de paie et en fait la sommation."
  },
  "timesheet_rules_worked_hours_on_weekday_syntax": {
    "en": "workedHoursOnWeekday(weekday) # \"monday\", \"tuesday\", \"wednesday\", \"thursday\", \"friday\", \"saturday\", \"sunday\"",
    "fr": "workedHoursOnWeekday(weekday) # \"monday\", \"tuesday\", \"wednesday\", \"thursday\", \"friday\", \"saturday\", \"sunday\""
  },
  "timesheet_rules_worked_hours_on_weekday_example": {
    "en": "overtime = workedHoursOnWeekday(\"sunday\") + workedHoursOnWeekday(\"saturday\") # All the hours on weekend are overtime",
    "fr": "overtime = workedHoursOnWeekday(\"sunday\") + workedHoursOnWeekday(\"saturday\") # Toutes les heures de la fin de semaine sont du temps supplémentaire"
  },
  "timesheet_rules_worked_hours_on_date_description": {
    "en": "Compute the total of hours on a specific date in the pay period and sum them.",
    "fr": "Calcule le total des heures a une date spécifique de la période de paie et en fait la sommation."
  },
  "timesheet_rules_worked_hours_on_date_syntax": {
    "en": "workedHoursOnDate(date) # Format is \"DD-MM\" or \"YYYY-MM-DD\"",
    "fr": "workedHoursOnDate(date) # Le format est \"DD-MM\" ou \"YYYY-MM-DD\""
  },
  "timesheet_rules_worked_hours_on_date_example": {
    "en": "overtime = workedHoursOnDate(\"2021-25-12\") + workedHoursOnDate(\"01-01\")",
    "fr": "overtime = workedHoursOnDate(\"2021-25-12\") + workedHoursOnDate(\"01-01\")"
  },
  "timesheet_rules_worked_hours_between_hours_description": {
    "en": "Compute the total of hours between an interval of time in the pay period and sum them.",
    "fr": "Calcule le total des heures dans un interval de temps spécifique de la période de paie et en fait la sommation."
  },
  "timesheet_rules_worked_hours_between_hours_syntax": {
    "en": "workedHoursBetweenHours(start, end) # Format is \"HH:MM\"",
    "fr": "workedHoursBetweenHours(start, end) # Le format est \"HH:MM\""
  },
  "timesheet_rules_worked_hours_between_hours_example": {
    "en": "overtime = workedHoursBetweenHours(\"18:00\", \"00:00\")",
    "fr": "overtime = workedHoursBetweenHours(\"18:00\", \"00:00\")"
  },
  "timesheet_rules_count_pension_description": {
    "en": "Compute the number of pensions for the provided name in the pay period.",
    "fr": "Calcule le nombre de primes pour le nom fourni dans la période de paie."
  },
  "timesheet_rules_count_pension_syntax": {
    "en": "countPension(string)",
    "fr": "countPension(string)"
  },
  "timesheet_rules_count_pension_example": {
    "en": "countPension(\"No Lunch\")",
    "fr": "countPension(\"Pas de diner\")"
  },
  "timesheet_rules_if_description": {
    "en": "Return a value based on a condition",
    "fr": "Retourne une valeure basée sur une condition"
  },
  "timesheet_rules_if_syntax": {
    "en": "if(condition, ifTrueValue, ifFalseValue)",
    "fr": "if(condition, siVraiValeur, siFauxValeur)"
  },
  "timesheet_rules_if_example": {
    "en": "overtime = if(total > 40, total - 40, 0)",
    "fr": "overtime = if(total > 40, total - 40, 0)"
  },
  "timesheet_rules_min_description": {
    "en": "Compute the minimum value of a list of values.",
    "fr": "Calcule la valeur minimale d'une liste de valeurs."
  },
  "timesheet_rules_min_syntax": {
    "en": "min(number...)",
    "fr": "min(nombre...)"
  },
  "timesheet_rules_min_example": {
    "en": "min(2, 1, 4, 3) # 1",
    "fr": "min(2, 1, 4, 3) # 1"
  },
  "timesheet_rules_max_description": {
    "en": "Compute the maximum value of a list of values.",
    "fr": "Calcule la valeur maximale d'une liste de valeurs."
  },
  "timesheet_rules_max_syntax": {
    "en": "max(number...)",
    "fr": "max(nombre...)"
  },
  "timesheet_rules_max_example": {
    "en": "max(2, 1, 4, 3) # 4",
    "fr": "max(2, 1, 4, 3) # 4"
  },
  "timesheet_rules_map_description": {
    "en": "Create a new array with the results of the callback function executed on each entry of the array.",
    "fr": "Créer un nouveau tableau avec les résultats de la fonction de rappel exécutée sur chaque entrée du tableau."
  },
  "timesheet_rules_map_syntax": {
    "en": "map(array, callback)",
    "fr": "map(tableau, rappel)"
  },
  "timesheet_rules_map_example": {
    "en": "map([8, 8, 8], x + 2) # [10, 10, 10]",
    "fr": "map([8, 8, 8], x + 2) # [10, 10, 10]"
  },
  "timesheet_rules_is_positive_description": {
    "en": "Test whether a value is positive: larger than zero.",
    "fr": "Teste si une valeur est positive: supérieure à zéro."
  },
  "timesheet_rules_is_positive_syntax": {
    "en": "isPositive(variable)",
    "fr": "isPositive(variable)"
  },
  "timesheet_rules_is_positive_example": {
    "en": "timeOff = if(isPositive(timeOff), timeOff, 0)",
    "fr": "timeOff = if(isPositive(timeOff), timeOff, 0)"
  },
  "timesheet_rules_is_negative_description": {
    "en": "Test whether a value is negative: smaller than zero.",
    "fr": "Teste si une valeur est négative: inférieure à zéro."
  },
  "timesheet_rules_is_negative_syntax": {
    "en": "isNegative(variable)",
    "fr": "isNegative(variable)"
  },
  "timesheet_rules_is_negative_example": {
    "en": "timeOff = if(isNegative(timeOff), 0, timeOff)",
    "fr": "timeOff = if(isNegative(timeOff), 0, timeOff)"
  },
  "timesheet_rules_is_zero_description": {
    "en": "Test whether a value is zero.",
    "fr": "Teste si une valeur est égale à zéro."
  },
  "timesheet_rules_is_zero_syntax": {
    "en": "isZero(variable)",
    "fr": "isZero(variable)"
  },
  "timesheet_rules_is_zero_example": {
    "en": "timeOff = if(isZero(timeOff), 8, timeOff)",
    "fr": "timeOff = if(isZero(timeOff), 8, timeOff)"
  },
  "timesheet_rules_norm_description": {
    "en": "Calculate the norm of a number or array.",
    "fr": "Calcule la norme d'un nombre ou un tableau."
  },
  "timesheet_rules_norm_syntax": {
    "en": "norm(variable)",
    "fr": "norm(variable)"
  },
  "timesheet_rules_norm_example": {
    "en": "norm([3, 4]) # 5",
    "fr": "norm([3, 4]) # 5"
  },
  "timesheet_rules_sum_description": {
    "en": "Compute the sum of an array or a list with values.",
    "fr": "Calcule la somme d'un tableau ou d'une liste de valeurs."
  },
  "timesheet_rules_sum_syntax": {
    "en": "sum(a, b, c, ...)",
    "fr": "sum(a, b, c, ...)"
  },
  "timesheet_rules_sum_example": {
    "en": "sum([3, 1, 4, 2]) # 10",
    "fr": "sum([3, 1, 4, 2]) # 10"
  },
  "timesheet_rules_size_description": {
    "en": "Calculate the size of a matrix or scalar.",
    "fr": "Calculer la taille d'un tableau ou scalaire."
  },
  "timesheet_rules_size_syntax": {
    "en": "size(variable)",
    "fr": "size(variable)"
  },
  "timesheet_rules_size_example": {
    "en": "size([1, 5, 3, 4]) # 4",
    "fr": "size([1, 5, 3, 4]) # 4"
  },
  "timesheet_rules_filter_description": {
    "en": "Filter the items in an array.",
    "fr": "Filtre les éléments d'un tableau."
  },
  "timesheet_rules_filter_syntax": {
    "en": "filter(x, test)",
    "fr": "filter(x, test)"
  },
  "timesheet_rules_filter_example": {
    "en": "filter([3, 1, 4, 2], x > 2) # [3, 4]",
    "fr": "filter([3, 1, 4, 2], x > 2) # [3, 4]"
  },
  "timesheet_rules_equal_text_description": {
    "en": "Check equality of two strings. Comparison is case sensitive.",
    "fr": "Vérifie l'égalité de deux chaînes de charactères. La comparaison est sensible à la casse."
  },
  "timesheet_rules_equal_text_syntax": {
    "en": "equalText(x, y)",
    "fr": "equalText(x, y)"
  },
  "timesheet_rules_worked_hours_on_timecard_syntax": {
    "en": "workedHoursOnTimecard(timeType) # break, work, transport",
    "fr": "workedHoursOnTimecard(typeDeTemps) # break, work, transport"
  },
  "timesheet_rules_worked_hours_on_timecard_example": {
    "en": "workedHoursOnTimecard(\"transport\") # 6.54",
    "fr": "workedHoursOnTimecard(\"transport\") # 10"
  },
  "timesheet_rules_worked_hours_on_timecard_description": {
    "en": "Compute the time punched on a specific type of time during the pay period",
    "fr": "Calcule le temps poinçonné d'un type spécifique durant la période de paie"
  },
  "timesheet_rules_worked_hours_on_activity_syntax": {
    "en": "workedHoursOnActivity(ActivityName)",
    "fr": "workedHoursOnActivity(ActivityName)"
  },
  "timesheet_rules_worked_hours_on_activity_example": {
    "en": "workedHoursOnActivity(\"Coffrage\") # 10",
    "fr": "workedHoursOnActivity(\"Coffrage\") # 10"
  },
  "timesheet_rules_worked_hours_on_activity_description": {
    "en": "Compute the time work on a specific activity during the pay period",
    "fr": "Calcule le temps travaillé sur un activité spécifié durant la période de paie"
  },
  "timesheet_rules_worked_hours_on_activity_on_weekday_syntax": {
    "en": "workedHoursOnActivityOnWeekday(ActivityName, Weekday) # \"monday\", \"tuesday\", \"wednesday\", \"thursday\", \"friday\", \"saturday\", \"sunday\"",
    "fr": "workedHoursOnActivityOnWeekday(ActivityName, Weekday) # \"monday\", \"tuesday\", \"wednesday\", \"thursday\", \"friday\", \"saturday\", \"sunday\""
  },
  "timesheet_rules_worked_hours_on_activity_on_weekday_example": {
    "en": "workedHoursOnActivityOnWeekday(\"Coffrage\", \"sunday\") # 10",
    "fr": "workedHoursOnActivityOnWeekday(\"Coffrage\", \"sunday\") # 10"
  },
  "timesheet_rules_worked_hours_on_activity_on_weekday_description": {
    "en": "Compute the time work on a specific activity and on a specific weekday during the pay period",
    "fr": "Calcule le temps travaillé sur un activité spécifié ainsi qu'une journée de la semaine spécifique durant la période de paie"
  },
  "timesheet_rules_worked_hours_between_dates_syntax": {
    "en": "workedHoursBetweenDates(start, end) # Format is \"DD-MM\" or \"YYYY-MM-DD\"",
    "fr": "workedHoursBetweenDates(start, end) # Le format est \"DD-MM\" ou \"YYYY-MM-DD\""
  },
  "timesheet_rules_worked_hours_between_dates_example": {
    "en": "workedHoursBetweenDates(\"25-12\", \"01-01\") + workedHoursBetweenDates(\"2020-03-09\",\"2020-03-10\") # 42",
    "fr": "workedHoursBetweenDates(\"25-12\", \"01-01\") + workedHoursBetweenDates(\"2020-03-09\",\"2020-03-10\") # 42"
  },
  "timesheet_rules_worked_hours_between_dates_description": {
    "en": "Compute the total of hours in a date range during the pay period and sum them",
    "fr": "Calculer le total des heures dans une plage de dates pendant la période de paie et les additionner ensemble"
  },
  "timesheet_rules_equal_text_example": {
    "en": "map(workdays, equalText(w.weekday, \"sunday\") ? w.total : 0)",
    "fr": "map(workdays, equalText(w.weekday, \"sunday\") ? w.total : 0)"
  },
  "timesheet_companie_prefrences_error": {
    "en": "Company preferences for the payroll are not valid, you have to change them in the company settings",
    "fr": "Les préférences de la compagnie pour la paie ne sont pas valides, vous devez les changer dans les paramètres de compagnie"
  },
  "timesheet_company_preferences_error": {
    "en": "Company preferences for the payroll are not valid, you have to change them in the company settings",
    "fr": "Les préférences de la compagnie pour la paie ne sont pas valides, vous devez les changer dans les paramètres de compagnie"
  },
  "timesheet_error": {
    "en": "Sorry, we had an error while fetching the payroll data",
    "fr": "Désolé, nous avons eu une erreur lors de la récupération des données de paie"
  },
  "timesheet_fetch": {
    "en": "Generate",
    "fr": "Générer"
  },
  "timesheet_fetch_text": {
    "en": "Select your period and filters then click on the Generate button to generate payroll data",
    "fr": "Sélectionnez votre période et vos filtres, ensuite appuyez sur le bouton ci-dessous afin de générer les données de paie"
  },
  "inventory_search": {
    "en": "Search in small tools & supplies",
    "fr": "Rechercher dans les petits outils & fournitures"
  },
  "inventory_add_item_type": {
    "en": "Add new Item",
    "fr": "Ajouter un nouvel item"
  },
  "inventory_item_name": {
    "en": "Name",
    "fr": "Nom"
  },
  "inventory_isConsumable": {
    "en": "Consumable",
    "fr": "Consommable"
  },
  "inventory_isUniquelyAddressable": {
    "en": "Unique",
    "fr": "Unique"
  },
  "inventory_no_special_type": {
    "en": "Regular",
    "fr": "Ordinaire"
  },
  "inventory_special_type": {
    "en": "Type",
    "fr": "Type"
  },
  "inventory_action_label": {
    "en": "Actions",
    "fr": "Actions"
  },
  "inventory_total_quantity": {
    "en": "Total Quantity",
    "fr": "Quantité totale"
  },
  "item_type_edit_dialog_title": {
    "en": "Edit item",
    "fr": "Modifier l'article"
  },
  "item_type_add_dialog_title": {
    "en": "Add new item",
    "fr": "Ajouter un nouvel élément"
  },
  "item_type_delete_dialog_title": {
    "en": "Delete the Item",
    "fr": "Supprimer l'item"
  },
  "item_type_delete_dialog_text": {
    "en": "You're about to delete this item. Are you sure you want to continue?",
    "fr": "Vous êtes sur le point de supprimer cet article. Êtes-vous certain de vouloir continuer?"
  },
  "item_type_available_label": {
    "en": "Available",
    "fr": "Disponible"
  },
  "item_type_in_use_label": {
    "en": "In Use",
    "fr": "Utilisé"
  },
  "item_type_description_label": {
    "en": "Description",
    "fr": "Description"
  },
  "item_page_item_tab_label": {
    "en": "Item List",
    "fr": "Liste d'articles"
  },
  "item_page_event_tab_label": {
    "en": "Event List",
    "fr": "Liste d'événements"
  },
  "item_page_details_description_empty": {
    "en": "(None)",
    "fr": "(Auncune)"
  },
  "item_list_filters_label": {
    "en": "Filters",
    "fr": "Filtres"
  },
  "item_list_search_placeholder": {
    "en": "Search in the items",
    "fr": "Rechercher dans les acticles"
  },
  "item_list_item_name_label": {
    "en": "Items",
    "fr": "Articles"
  },
  "item_label": {
    "en": "Item",
    "fr": "Article"
  },
  "item_list_item_location_label": {
    "en": "Location",
    "fr": "Emplacement"
  },
  "item_list_item_status_label": {
    "en": "Status",
    "fr": "Statut"
  },
  "item_list_item_quantity_label": {
    "en": "Quantity",
    "fr": "Quantité"
  },
  "item_list_item_actions_label": {
    "en": "Actions",
    "fr": "Actions"
  },
  "item_list_item_unit_label": {
    "en": "Unit",
    "fr": "Unité"
  },
  "item_list_item_consumable_tag": {
    "en": "Consumable",
    "fr": "Consumable"
  },
  "item_list_item_unique_tag": {
    "en": "Unique",
    "fr": "Unique"
  },
  "item_list_item_on_site": {
    "en": "On site",
    "fr": "Sur site"
  },
  "item_list_item_in_transit": {
    "en": "In transit",
    "fr": "En transit"
  },
  "item_order_transfer_button": {
    "en": "Transfer",
    "fr": "Transfert"
  },
  "item_order_item_button": {
    "en": "Order",
    "fr": "Commander"
  },
  "item_order_dialog_order_title": {
    "en": "Order Items",
    "fr": "Commander des articles"
  },
  "item_order_dialog_transfer_title": {
    "en": "Transfer Items",
    "fr": "Transférer des articles"
  },
  "item_order_dialog_location": {
    "en": "Location",
    "fr": "Emplacement"
  },
  "item_order_dialog_add_location": {
    "en": "Add Location",
    "fr": "Ajouter un emplacement"
  },
  "item_order_dialog_add_item": {
    "en": "Add Item",
    "fr": "Ajouter un article"
  },
  "item_order_dialog_add_unique_reference": {
    "en": "Add Unique Reference",
    "fr": "Ajouter une référence unique"
  },
  "item_order_dialog_select_item_label": {
    "en": "Select an item",
    "fr": "Sélectionnez un article"
  },
  "item_order_dialog_select_reciept_location_labe": {
    "en": "Select source location",
    "fr": "Sélectionnez la source"
  },
  "item_order_select_project_label": {
    "en": "Project",
    "fr": "Projet"
  },
  "item_order_select_organization_label": {
    "en": "Organization",
    "fr": "Organisation"
  },
  "item_order_dialog_reciept_location_label": {
    "en": "Source location",
    "fr": "Lieu de la source"
  },
  "item_order_dialog_select_delivery_location_labe": {
    "en": "Select delivery location",
    "fr": "Sélectionnez le lieu de livraison"
  },
  "item_order_dialog_delivery_location_label": {
    "en": "Delivery location",
    "fr": "Lieu de la livraison"
  },
  "item_order_dialog_unique_reference_label": {
    "en": "Uniques Reference",
    "fr": "Référence uniques"
  },
  "item_order_dialog_select_unique_reference_label": {
    "en": "Select unique Reference",
    "fr": "Sélectionnez une référence unique"
  },
  "item_order_dialog_item_quantity_label": {
    "en": "Item quantity",
    "fr": "Quantité d'article"
  },
  "item_order_dialog_invalid_quantity": {
    "en": "Quantity must be a number",
    "fr": "La quantité doit être un nombre"
  },
  "item_order_dialog_invalid_uniqueness": {
    "en": "References should be unqiue",
    "fr": "Les références doivent être uniques"
  },
  "item_order_dialog_order_success": {
    "en": "Order has been placed",
    "fr": "La commande a été effectué"
  },
  "item_order_dialog_order_error": {
    "en": "Unable to place the order",
    "fr": "Impossible de passer la commande"
  },
  "item_order_dialog_transfer_success": {
    "en": "Transfer has been placed",
    "fr": "Le transfert a été effectué"
  },
  "item_order_dialog_transfer_error": {
    "en": "Unable to place the transfer",
    "fr": "Impossible de placer le transfert"
  },
  "help": {
    "en": "Help",
    "fr": "Aide"
  },
  "employee_list_import_creatable_warning_team_name": {
    "en": "Careful, this team is not in the system, it will be created with this employee as team leader. Please verify if you really want to add this new team",
    "fr": "Attention, cette équipe n'est pas dans le système, elle sera donc créée avec cet employé comme chef d'équipe. S'il vous plaît vérifier si vous voulez vraiment ajouter cette nouvelle équipe"
  },
  "employee_list_import_creatable_warning_organization_name": {
    "en": "Careful, this organization is not in the system, it will be created with this name. Please verify if you really want to add this new organization",
    "fr": "Attention, cette organisation n'est pas dans le système, elle sera donc créée avec ce nom. S'il vous plaît vérifier si vous voulez vraiment ajouter cette nouvelle organisation"
  },
  "employee_list_import_creatable_warning_employee_role_name": {
    "en": "Careful, this role is not in the system, it will be created with this name. Please verify if you really want to add this new role",
    "fr": "Attention, ce rôle n'est pas dans le système, il sera donc créé avec ce nom. S'il vous plaît vérifier si vous voulez vraiment ajouter ce nouveau rôle"
  },
  "employee_list_import_unique_error_name": {
    "en": "An employee with this name already exist in the system. Please remove this row or change this value to continue.",
    "fr": "An employee with this name already exist in the system. Please remove this row or change this value to continue."
  },
  "employee_list_import_unique_error_email": {
    "en": "This email already exist in the system. Please change this value to continue.",
    "fr": "This email already exist in the system. Please change this value to continue."
  },
  "employee_list_import_unique_error_code": {
    "en": "An employee with this code already exist in the system. Please remove this row or change this value to continue.",
    "fr": "Un employé avec ce code existe déjà dans le système. Veuillez supprimer cette ligne ou modifier cette valeur pour continuer."
  },
  "employee_list_import_unique_error_role_code": {
    "en": "An employee role with this code already exist in the system. Please remove this row or change this value to continue.",
    "fr": "Un rôle d'employé avec ce code existe déjà dans le système. Veuillez supprimer cette ligne ou modifier cette valeur pour continuer."
  },
  "employee_list_import_error_type": {
    "en": "This type is not valid. Please use the predetermined types available.",
    "fr": "Ce type n'est pas valide. Veuillez utiliser les types prédéterminés disponibles."
  },
  "employee_list_title": {
    "en": "Import of employees",
    "fr": "Importation des employés"
  },
  "employee_list_field_name_label": {
    "en": "Full Name",
    "fr": "Nom complet"
  },
  "employee_list_field_name_description": {
    "en": "The full name of the employee.",
    "fr": "Le nom complet de l'employé."
  },
  "employee_list_field_email_label": {
    "en": "(Optional) Email",
    "fr": "Adresse courriel (Facultatif)"
  },
  "employee_list_field_email_description": {
    "en": "(Optional) The email of the employee. An email is required when you want to create a user for this employee, after a user is created for this employee you will no longer be able to change the email. Use a company email if you want to use the single Sign-On functionality",
    "fr": "(Facultatif) L'adresse courriel de l'employé. Cette adresse courriel est nécessaire lorsque vous souhaitez créer un utilisateur pour cet employé. Après avoir créé un utilisateur, il ne sera plus possible de la modifier. Veuillez utiliser une adresse courriel de l'entreprise si vous souhaitez utiliser la fonctionnalité d'authentification unique"
  },
  "employee_list_field_email_regex_error": {
    "en": "Invalid email address format",
    "fr": "Format d'adresse courriel non valide"
  },
  "employee_list_field_phone_label": {
    "en": "(Optional) Phone",
    "fr": "Téléphone (Facultatif)"
  },
  "employee_list_field_phone_description": {
    "en": "(Optional) The phone number of the employee. It will be use to send SMS to your employee when scheduling, go visit the help center for more information about how to send SMS. Regional codes are accepted.",
    "fr": "(Facultatif) Le numéro de téléphone de l'employé. Il sera utilisé pour envoyer des SMS à votre employé au moment de la planification. Visitez le centre d'aide pour plus d'informations sur comment envoyer des SMS. Les codes régionaux sont acceptés."
  },
  "employee_list_field_phone_regex_error": {
    "en": "Invalid phone format. Verify if there is a space after the phone number or an invalid character in the phone number",
    "fr": "Format de téléphone non valide. Vérifiez s'il y a un espace après le numéro de téléphone ou si un caractère invalide a été utilisé"
  },
  "employee_list_field_employee_role_label": {
    "en": "Role",
    "fr": "Rôle"
  },
  "employee_list_field_employee_role_description": {
    "en": "The role of the employee. His title or function in the company\r\n",
    "fr": "Le rôle de l'employé. Son titre ou sa fonction dans l'entreprise"
  },
  "employee_list_field_organization_label": {
    "en": "Organization",
    "fr": "Organisation"
  },
  "employee_list_field_organization_description": {
    "en": "The organization of the employee. The company to which the employee belongs\n",
    "fr": "L'organisation de l'employé. La compagnie à laquelle l'employé fait partie"
  },
  "employee_list_field_team_name_label": {
    "en": "(Optional) Team",
    "fr": "Équipe (Facultatif)"
  },
  "employee_list_field_team_name_description": {
    "en": "(Optional) The employee's team. It will be used during the planning phase, when a team is scheduled, all team members will be added to the daily report. The team leader will be responsible for checking the time of all team members.",
    "fr": "(Facultatif) L'équipe de l'employé. Elle sera utilisée pendant la phase de planification, quand une équipe sera cédulé tous les membres de l'équipe seront ajoutés dans le rapport journalier. Le chef d'équipe sera responsable de vérifier le temps de tous les membres de l'équipe."
  },
  "employee_list_field_employee_role_code_label": {
    "en": "(Optional) Role Code",
    "fr": "(Facultatif) Code du Rôle"
  },
  "employee_list_field_employee_role_code_description": {
    "en": "(Optional) Unique code for role",
    "fr": "(Facultatif) Code unique du Rôle"
  },
  "employee_list_field_employee_code_label": {
    "en": "(Optional) Code",
    "fr": "(Facultatif) Code"
  },
  "employee_list_field_employee_code_description": {
    "en": "(Optional) Unique code for employee",
    "fr": "(Facultatif) Code unique pour l'employé"
  },
  "employee_role_code_add_button_text": {
    "en": "Add role code",
    "fr": "Ajouter code du rôle"
  },
  "activity_list_title": {
    "en": "Import of product and activities",
    "fr": "Importation des produits et activités"
  },
  "activity_list_field_name_label": {
    "en": "Activity name",
    "fr": "Nom de l'activité"
  },
  "activity_list_field_name_description": {
    "en": "The name of the activity.",
    "fr": "Le nom de l'activitié"
  },
  "activity_list_field_product_name_label": {
    "en": "Product name",
    "fr": "Nom du produit"
  },
  "activity_list_field_product_name_description": {
    "en": "The name of the product.",
    "fr": "Le nom du produit"
  },
  "activity_list_field_product_code_label": {
    "en": "Product code (Optional)",
    "fr": "Code du produit (Facultatif)"
  },
  "activity_list_field_product_code_description": {
    "en": "The code of the product",
    "fr": "Le code du produit"
  },
  "activity_list_field_product_unit_label": {
    "en": "Product unit",
    "fr": "Unité du produit"
  },
  "activity_list_field_product_unit_description": {
    "en": "The name of the product unit",
    "fr": "Le nom de l'unité du produit"
  },
  "activity_list_field_unit_label": {
    "en": "Activity Unit (Optional) ",
    "fr": "Unité de l'activité (Facultatif)"
  },
  "activity_list_field_unit_description": {
    "en": "(Optional) The name of the activity unit",
    "fr": "(Facultatif) Le nom de l'unité du produit"
  },
  "activity_list_field_code_label": {
    "en": "Activity code (Optional)",
    "fr": "Code de l'activité (Facultatif)"
  },
  "activity_list_field_code_description": {
    "en": "(Optional) The code of the activity",
    "fr": "(Facultatif) Le code de l'activité"
  },
  "activity_list_import_creatable_warning_name": {
    "en": "This activity does not exist in the system, it will be created. If you think this is an error, check the spelling",
    "fr": "Cette activité n'existe pas dans le système, elle sera créé. Si vous pensez que cela est une erreur, vérifiez l'orthographe"
  },
  "activity_list_import_creatable_warning_product_name": {
    "en": "This product does not exist in the system, it will be created. If you think this is an error, check the spelling",
    "fr": "Ce produit n'existe pas dans le système, il sera créé. Si vous pensez que cela est une erreur, vérifiez l'orthographe"
  },
  "activity_list_import_creatable_warning_product_unit": {
    "en": "This unit does not exist in the system, it will be created. If you think this is an error, check the spelling",
    "fr": "Cette unité n'existe pas dans le système, elle sera créée. Si vous pensez que cela est une erreur, vérifiez l'orthographe"
  },
  "activity_list_csv_import_error": {
    "en": "Sorry, we had an error with the csv import",
    "fr": "Désolé, nous avons eu une erreur avec l'importation CSV"
  },
  "import_csv_button_label": {
    "en": "Import CSV",
    "fr": "Importation CSV"
  },
  "item_event_consolidate_inventory": {
    "en": "Consolidate Small tools & Supplies",
    "fr": "Consolider les petits outils & fournitures"
  },
  "item_event_delete_dialog_confirm": {
    "en": "Remove",
    "fr": "Retirer"
  },
  "item_event_consume_item": {
    "en": "Consumed",
    "fr": "Consommé"
  },
  "item_event_order_item": {
    "en": "Ordered",
    "fr": "Commandé"
  },
  "item_event_order_transfer": {
    "en": "Transfered",
    "fr": "Transféré"
  },
  "item_event_receive_item": {
    "en": "Received",
    "fr": "Reçu"
  },
  "item_event_remove_item": {
    "en": "Removed",
    "fr": "Retiré"
  },
  "item_event_log_loading": {
    "en": "Loading ...",
    "fr": "Chargement ..."
  },
  "item_event_log_no_more_data": {
    "en": "There is no more events ",
    "fr": "Il n'y a plus d'événements"
  },
  "item_event_delete_dialog_title": {
    "en": "Remove Item Quantity",
    "fr": "Retirer une quantité de l'article"
  },
  "item_event_consume_dialog_title": {
    "en": "Choose a quantity to consume",
    "fr": "Choisir une quantité à consommer"
  },
  "item_event_consume_dialog_confirm": {
    "en": "Consume",
    "fr": "Consommer"
  },
  "item_event_by_label": {
    "en": "by",
    "fr": "par"
  },
  "item_event_to_label": {
    "en": "to",
    "fr": "dans"
  },
  "item_event_of_label": {
    "en": "of",
    "fr": "de"
  },
  "item_inventory_delete_success": {
    "en": "Item deleted successfully",
    "fr": "Article supprimé avec succès"
  },
  "item_inventory_delete_error": {
    "en": "Sorry, we had an error with the item  deletion",
    "fr": "Désolé, une erreur s'est produite lors de la suppression de l'article"
  },
  "item_inventory_delete_dialog": {
    "en": "Delete Item",
    "fr": "Effacer l'article"
  },
  "item_inventory_delete_dialog_text": {
    "en": "You are about to delete this item. Are you sure you want to continue?",
    "fr": "Vous êtes sur le point de supprimer l'article. Êtes-vous sûr de vouloir continuer?"
  },
  "equipment_vehicle_identification_number_label": {
    "en": "VIN",
    "fr": "NIV"
  },
  "equipment_list_import_creatable_warning_team_name": {
    "en": "Careful, this team is not in the system, it will be created with this employee as team leader. Please verify if you really want to add this new team",
    "fr": "Attention, cette équipe n'est pas dans le système, elle sera donc créée avec cet employé comme chef d'équipe. S'il vous plaît vérifier si vous voulez vraiment ajouter cette nouvelle équipe"
  },
  "equipment_list_import_creatable_warning_organization_name": {
    "en": "Careful, this organization is not in the system, it will be created with this name. Please verify if you really want to add this new organization",
    "fr": "Attention, cette organisation n'est pas dans le système, elle sera donc créée avec ce nom. S'il vous plaît vérifier si vous voulez vraiment ajouter cette nouvelle organisation"
  },
  "equipment_list_import_creatable_warning_equipment_type": {
    "en": "Careful, this equipment type is not in the system, it will be created with this name. Please verify if you really want to add this new equipment type",
    "fr": "Attention, ce type d'équipement n'est pas dans le système, elle sera donc créée avec ce nom. S'il vous plaît vérifier si vous voulez vraiment ajouter ce nouveau type d'équipement"
  },
  "equipment_list_import_unique_error_name": {
    "en": "An equipment with this name already exist in the system. Please remove this row or change this value to continue.",
    "fr": "Un équipement avec ce nom existe déjà. S'il vous plaît vérifier le nom et changez la valeur avant de continuer"
  },
  "equipment_list_import_unique_error_default_operator": {
    "en": "Please choose an existing empoyee",
    "fr": "Veuillez choisir un employé existant"
  },
  "equipment_list_csv_import_error": {
    "en": "Sorry, we had an error with the csv import",
    "fr": "Désolé, nous avons eu une erreur avec l'importation CSV"
  },
  "equipment_list_title": {
    "en": "Import of heavy Equipment",
    "fr": "Importation d'équipement lourd"
  },
  "equipment_list_field_name_label": {
    "en": "Name",
    "fr": "Nom"
  },
  "equipment_list_field_name_description": {
    "en": "Name of the equipment",
    "fr": "Nom de l'équipement"
  },
  "equipment_list_field_model_label": {
    "en": "Model (Optional)",
    "fr": "Modèle (Facultatif)"
  },
  "equipment_list_field_model_description": {
    "en": "(Optional) Equipment model",
    "fr": "(Facultatif) Modèle de l'équipement"
  },
  "equipment_list_field_vehicle_identification_number_label": {
    "en": "VIN (Optional)",
    "fr": "NIV (Facultatif)"
  },
  "equipment_list_field_vehicle_identification_number_description": {
    "en": "Vehicle Identification Number if it has one",
    "fr": "Numéro d'identification du véhicule"
  },
  "equipment_list_field_plate_number_label": {
    "en": "Plate Number (Optional)",
    "fr": "Numéro d'immatriculation (Facultatif)"
  },
  "equipment_list_field_plate_number_description": {
    "en": "vehicle plate number",
    "fr": "Numéro d'immatriculation du véhicule"
  },
  "equipment_list_field_brand_label": {
    "en": "Brand (Optional)",
    "fr": "Marque (Facultatif)"
  },
  "equipment_list_field_brand_description": {
    "en": "(Optional) Equipmen brand",
    "fr": "(Facultatif) Marque de l'équipement"
  },
  "equipment_list_field_equipment_type_label": {
    "en": "Equipment type",
    "fr": "Type de l'équipement"
  },
  "equipment_list_field_equipment_type_description": {
    "en": "Equipment type, this is used to be able to filter easily using the type",
    "fr": "Le type est utilisé pour filtrer facilement parmi d'autres équipements"
  },
  "equipment_list_field_organization_label": {
    "en": "Organization",
    "fr": "Organisation"
  },
  "equipment_list_field_organization_description": {
    "en": "The organization of the equipment. The company to which this equipment belongs\n",
    "fr": "L'organisation de l'équipement. L'entreprise à laquelle appartient cet équipement"
  },
  "equipment_list_field_team_name_label": {
    "en": "(Optional) Team",
    "fr": "Équipe (Facultatif)"
  },
  "equipment_list_field_team_name_description": {
    "en": "(Optional) The equipment's team. It will be used during the planning phase, when a team is scheduled, all team members and equipment will be added to the daily report. The team leader will be responsible for checking the time of all team members and equipment.",
    "fr": "(Facultatif) L'équipe de l'équipement. Il sera utilisé pendant la phase de planification, lorsqu'une équipe est programmée, tous les membres de l'équipe et l'équipement seront ajoutés au rapport quotidien. Le chef d'équipe sera responsable de vérifier l'heure de tous les membres de l'équipe et de l'équipement."
  },
  "equipment_list_field_operator_name_label": {
    "en": "Operator (Optional)",
    "fr": "Opérateur (Facultatif)"
  },
  "equipment_list_field_operator_name_description": {
    "en": "(Optional)",
    "fr": "(Facultatif)"
  },
  "material_list_import_creatable_warning_materialtype_name": {
    "en": "Careful, this material type is not in the system, it will be created with this name. Please verify if you really want to add this new material type",
    "fr": "Attention, ce type de matériau n'est pas dans le système, il sera donc créé avec ce nom. S'il vous plaît vérifier si vous voulez vraiment ajouter ce nouveau type de matériau"
  },
  "material_list_import_creatable_warning_materialunit_name": {
    "en": "Careful, this material unit is not in the system, it will be created with this name. Please verify if you really want to add this new unit",
    "fr": "Attention, cette unité de matériau n'est pas dans le système, elle sera donc créé avec ce nom. S'il vous plaît vérifier si vous voulez vraiment ajouter cette nouvelle unité"
  },
  "material_list_field_name_description": {
    "en": "Name of the material",
    "fr": "Nom du matériau"
  },
  "material_list_field_type_description": {
    "en": "Type of the material",
    "fr": "Type du matériau"
  },
  "material_list_field_unit_description": {
    "en": "The unit used to measure this material",
    "fr": "L'unité utilisée pour mesurée ce matériau"
  },
  "material_list_field_organization_description": {
    "en": "Supplier from which this material is acquired (Need to correspond to an organization created)",
    "fr": "Fournisseur auprès duquel ce matériau provient (doit correspondre à une organisation créée)"
  },
  "material_list_csv_import_error": {
    "en": "Sorry, we had an error with the csv import",
    "fr": "Désolé, nous avons eu une erreur à l'importation csv"
  },
  "material_list_field_name_duplicates": {
    "en": "Another row with the same material name exists, only one material will be created and suppliers will be merged",
    "fr": "Une autre rangée avec le même nom de matériau existe, un seul matériau sera créé et leurs fournisseurs seront combinés"
  },
  "item_receive_dialog_title": {
    "en": "Transfer Reception",
    "fr": "Réception du transfert"
  },
  "item_receive_dialog_text": {
    "en": "Accept reception of transfer:",
    "fr": "Accepter la réception du transfert de:"
  },
  "item_receive_dialog_item_label": {
    "en": "Item:",
    "fr": "Article:"
  },
  "item_receive_dialog_quantity_label": {
    "en": "Quantity:",
    "fr": "Quantité:"
  },
  "item_receive_dialog_source_label": {
    "en": "From:",
    "fr": "Source:"
  },
  "item_receive_dialog_target_label": {
    "en": "To:",
    "fr": "Destination:"
  },
  "item_receive_dialog_confirm_label": {
    "en": "Accept",
    "fr": "Accepter"
  },
  "item_receive_dialog_unique_label": {
    "en": "Unique References:",
    "fr": "Référence uniques:"
  },
  "item_action_menu_consolidate": {
    "en": "Consolidate Small Tools & Supplies",
    "fr": "Consolider les petits outils & fournitures"
  },
  "item_action_menu_consume": {
    "en": "Consume Item",
    "fr": "Consommer l'article"
  },
  "item_action_menu_transfer": {
    "en": "Transfer Item",
    "fr": "Transférer l'article"
  },
  "item_action_menu_remove": {
    "en": "Remove Item",
    "fr": "Retirer l'article"
  },
  "item_action_menu_receive": {
    "en": "Receive Item",
    "fr": "Réception de l'article"
  },
  "project_list_title": {
    "en": "Import of projects",
    "fr": "Importation de projets"
  },
  "project_list_field_name_label": {
    "en": "Name",
    "fr": "Nom"
  },
  "project_list_invalid_location": {
    "en": "Invalid Location",
    "fr": "Location invalide"
  },
  "project_list_invalid": {
    "en": "Invalid",
    "fr": "Invalide"
  },
  "project_list_field_name_description": {
    "en": "Name of the project",
    "fr": "Nom du projet"
  },
  "project_list_field_contract_name": {
    "en": "(Optional) Contract Name",
    "fr": "(Facultatif) Nom du contrat"
  },
  "project_list_field_contract_name_description": {
    "en": "The project contract name",
    "fr": "Le nom du contrat du projet"
  },
  "project_list_field_contract_number": {
    "en": "(Optional) Contract Number",
    "fr": "(Facultatif) Numéro du contrat"
  },
  "project_list_field_contract_number_description": {
    "en": "The project contract number",
    "fr": "Le numéro du contrat du projet"
  },
  "project_list_field_code_label": {
    "en": "Code",
    "fr": "Code"
  },
  "project_list_field_code_description": {
    "en": "Code of the project",
    "fr": "Code du projet"
  },
  "project_list_field_manager_label": {
    "en": "Project manager",
    "fr": "Gestionnaire du projet"
  },
  "project_list_field_manager_description": {
    "en": "Project manager. The employee responsible for managing the project.",
    "fr": "Gestionnaire du projet. L'employée qui responsable de gérer le projet."
  },
  "project_list_field_location_label": {
    "en": "Location",
    "fr": "Emplacement"
  },
  "project_list_field_location_description": {
    "en": "Project location",
    "fr": "Emplacement du projet"
  },
  "project_list_field_client_label": {
    "en": "Client",
    "fr": "Client"
  },
  "project_list_field_client_description": {
    "en": "Client organization. The project is for this client organization. ",
    "fr": "L'organisation du client. Le projet est réalisé pour cette organisation."
  },
  "project_list_csv_import_error": {
    "en": "Sorry, we had an error with the csv import",
    "fr": "Désolé, nous avons eu une erreur avec l'importation CSV"
  },
  "project_list_csv_import_success": {
    "en": "Projects are successfully import",
    "fr": "Les projets sont importés avec succès."
  },
  "project_list_import_creatable_warning_organization_name": {
    "en": "Careful, this organization is not in the system, it will be created with this name. Please verify if you really want to add this new organization",
    "fr": "Attention, cette organisation n'est pas dans le système, elle sera donc créée avec ce nom. S'il vous plaît vérifier si vous voulez vraiment ajouter cette nouvelle organisation"
  },
  "project_list_import_unique_error_name": {
    "en": "A project with this name already exist in the system. Please remove this row or change this value to continue.",
    "fr": "Un projet avec ce nom existe déjà. S'il vous plaît vérifier le nom et changez la valeur avant de continuer"
  },
  "project_list_no_validate": {
    "en": "Not validated",
    "fr": "Non validé"
  },
  "project_list_validate": {
    "en": "Validated",
    "fr": "Validé"
  },
  "project_list_import_error_employee": {
    "en": "This employee is not in the system. Please add this employee before importing this project.",
    "fr": "Cet employé n'est pas dans le système. S'il vous plaît ajouter cet employé avant d'importer ce projet."
  },
  "validate_all_locations_button_label": {
    "en": "Validate all locations",
    "fr": "Valider tous les emplacements"
  },
  "item_consolidate_dialog_title": {
    "en": "Consolidate Item Inventory",
    "fr": "Consolider l'inventaire de l'article"
  },
  "item_consolidate_dialog_confirm": {
    "en": "Consolidate",
    "fr": "Consolider"
  },
  "project_list_table_actions": {
    "en": "Actions",
    "fr": "Actions"
  },
  "project_list_validate_state": {
    "en": "State",
    "fr": "État"
  },
  "organization_list_title": {
    "en": "Import of organizations",
    "fr": "Importation des organisations"
  },
  "organization_list_field_name_label": {
    "en": "Name",
    "fr": "Nom"
  },
  "organization_list_field_name_description": {
    "en": "Name of the organization (It can be a client, a supplier, a business, etc...)",
    "fr": "Nom de l'organisation (il peut s'agir d'un client, d'un fournisseur, d'une entreprise, etc ...)"
  },
  "organization_list_field_location_label": {
    "en": "Location (Optional)",
    "fr": "Emplacement (Facultatif)"
  },
  "organization_list_field_location_description": {
    "en": "(Optional) Location of the organization. This is the address of the organization which can be found on Googling map",
    "fr": "(Facultatif) Emplacement de l'organisation. Il s'agit de l'adresse de l'organisation qui peut-être trouvé sur google map"
  },
  "organization_list_field_note_label": {
    "en": "Note (Optional)",
    "fr": "Note (Facultatif)"
  },
  "organization_list_field_note_description": {
    "en": "(Optional) Note of the organization. The note is to describe the organization.",
    "fr": "(Facultatif) Note de l'organisation. La note sert à décrire l'organisation."
  },
  "organization_list_import_unique_error_name": {
    "en": "An organization with this name already exist in the system. Please remove this row or change this value to continue.",
    "fr": "Un organisation avec ce nom existe déjà. S'il vous plaît vérifier le nom et changez la valeur avant de continuer"
  },
  "organization_list_import_creatable_warning_organization_type_name": {
    "en": "Careful, this type is not in the system, it will be created with this name. Please verify if you really want to add this new type",
    "fr": "Attention, ce type n'est pas dans le système, il sera donc créé avec ce nom. S'il vous plaît vérifier si vous voulez vraiment ajouter ce nouveau type"
  },
  "organization_list_csv_import_success": {
    "en": "Organization are successfully import",
    "fr": "Les organisations sont importés avec succès."
  },
  "organization_list_csv_import_error": {
    "en": "Sorry, we had an error with the csv import",
    "fr": "Désolé, nous avons eu une erreur avec l'importation CSV"
  },
  "location_validation_title": {
    "en": "Validate location",
    "fr": "Valider l'emplacement"
  },
  "team_list_title": {
    "en": "Team",
    "fr": "Équipe"
  },
  "team_list_field_name_label": {
    "en": "Name",
    "fr": "Nom"
  },
  "team_list_field_name_description": {
    "en": "Name of the team",
    "fr": "Nom de l'équipe"
  },
  "team_list_field_team_leader_label": {
    "en": "Team leader",
    "fr": "Chef d'équipe"
  },
  "team_list_field_team_leader_description": {
    "en": "Leader of the team",
    "fr": "Chef de l'équipe"
  },
  "team_list_field_scheduler_label": {
    "en": "Scheduler",
    "fr": "Planificateur"
  },
  "team_list_field_scheduler_department": {
    "en": "(Optional) Department",
    "fr": "(Optionnel) Département"
  },
  "team_list_field_scheduler_description": {
    "en": "Scheduler of the team",
    "fr": "Planificateur de l'équipe"
  },
  "team_list_field_department_description": {
    "en": "(Optional) Department of the team",
    "fr": "(Opcional) Département de l'équipe"
  },
  "team_list_field_shift_name_label": {
    "en": "(Optional) Shift",
    "fr": "(Optionnel) Quart de travail"
  },
  "team_list_field_shift_name_description": {
    "en": "(Optional) Assign default shift",
    "fr": "(Optionnel) Assigner un quart de travail par défaut"
  },
  "team_list_csv_import_error": {
    "en": "Sorry, we had an error with the csv import",
    "fr": "Désolé, nous avons eu une erreur avec l'importation CSV"
  },
  "team_list_field_shift_error": {
    "en": "This shift does not exist",
    "fr": "Ce quart de travail n'existe pas"
  },
  "team_list_field_scheduler_error": {
    "en": "This scheduler does not exist",
    "fr": "Ce planificateur n'existe pas"
  },
  "team_list_field_team_leader_error": {
    "en": "No employee with this name exists in the system, please create or import this employee first.",
    "fr": "Aucun employé avec ce nom existe dans le système. S'il vous plaît créer cet employé en premier."
  },
  "No shift with that name exixst in the system": {
    "en": "No employee with this name exists in the system, please create or import this employee first.",
    "fr": "Aucun employé avec ce nom existe dans le système. S'il vous plaît créer cet employé en premier."
  },
  "team_list_field_name_error": {
    "en": "A team with this name already exists.",
    "fr": "Une équipe avec ce nom éxiste déjà."
  },
  "company_preferences_time_record_period": {
    "en": "Time Block Precision",
    "fr": "Précision des blocs de temps"
  },
  "company_preferences_time_record_period_description": {
    "en": "The precision of employees and equipments time block in the daily logs",
    "fr": "La précision des blocs de temps des employés et des équipements dans les rapports journalier"
  },
  "company_preferences_enforce_weather_validation": {
    "en": "Enforce weather validation",
    "fr": "Imposer la validation de la température"
  },
  "company_preferences_enforce_weather_validation_description": {
    "en": "Whether or not it is required to validate the forecast weather on each dailylog before submitting",
    "fr": "Défini si oui ou non la température de chaque rapport journalier doit être validée avant de soumettre"
  },
  "company_preferences_enable_small_tool_and_supplies": {
    "en": "Enable Small Tool And Supplies Feature",
    "fr": "Activer le module de Petits Outils & Fournitures"
  },
  "company_preferences_enable_small_tool_and_description": {
    "en": "This feature allows you to manage all your small tools and supply by creating an inventory of all those resources in the different projects and organizations",
    "fr": "Cette fonctionnalité vous permet de gérer tous vos petits outils et fournitures en créant un inventaire de toutes ces ressources dans les différents projets et organisations"
  },
  "company_preferences_enable_timecard": {
    "en": "Enable Timecard Feature",
    "fr": "Activer le module carte de temps"
  },
  "company_preferences_enable_timecard_description": {
    "en": "This feature allows you to manage your time at work",
    "fr": "Cette fonctionnalité vous permet de gérer votre temps au travail"
  },
  "daily_log_item_list_tab_label": {
    "en": "Items",
    "fr": "Articles"
  },
  "daily_log_event_list_tab_label": {
    "en": "Events",
    "fr": "Événements"
  },
  "organization_item_list_tab_label": {
    "en": "Small Tools & Supplies",
    "fr": "Petits outils & fournitures"
  },
  "organization_event_list_tab_label": {
    "en": "Small Tools & Supplies Logs",
    "fr": "Journal des petits outils & fournitures"
  },
  "common_minute": {
    "en": "minute",
    "fr": "minute"
  },
  "common_minutes": {
    "en": "minutes",
    "fr": "minutes"
  },
  "create_company": {
    "en": "Create company",
    "fr": "Créer une entreprise"
  },
  "name_company": {
    "en": "Name of company",
    "fr": "Nom de l'entreprise"
  },
  "name_company_placeholder": {
    "en": "Enter name of company",
    "fr": "Entrer le nom de l'entreprise"
  },
  "addplaceholderdialog_searchitems": {
    "en": "Filter",
    "fr": "Filtrer"
  },
  "addplaceholderdialog_organization": {
    "en": "Organization",
    "fr": "Organization"
  },
  "addplaceholderdialog_no_results": {
    "en": "No results",
    "fr": "Aucun Résultat"
  },
  "plugins_new_plugin": {
    "en": "New plugin",
    "fr": "Nouveau plugin"
  },
  "plugins_search_placeholders": {
    "en": "Search in plugins",
    "fr": "Rechercher dans les plug-ins"
  },
  "plugins_delete_dialog_title": {
    "en": "Delete the plugin",
    "fr": "Supprimer le plug-in"
  },
  "plugins_delete_dialog_text": {
    "en": "You're about to delete this plugin. Are you sure you want to continue?",
    "fr": "Vous êtes sur le point de supprimer ce plug-in. Êtes-vous certain de vouloir continuer?"
  },
  "plugins_delete_success": {
    "en": "Plugin deleted successfully",
    "fr": "Plug-in supprimé avec succès"
  },
  "plugins_delete_error": {
    "en": "Sorry, we had an error with the plugin deletion",
    "fr": "Désolé, une erreur est survenue lors de la suppression du plug-in"
  },
  "plugins_add_plugin_dialog_title": {
    "en": "Create a new plugin",
    "fr": "Créer un nouveau plug-in"
  },
  "plugins_edit_plugin_dialog_title": {
    "en": "Edit the plugin",
    "fr": "Modifier le plug-in"
  },
  "plugins_insert_success": {
    "en": "Plugin created successfully",
    "fr": "Le plug-in a été créé avec succès"
  },
  "plugins_insert_error": {
    "en": "Sorry, we had an error with the plugin creation",
    "fr": "Désolé, nous avons eu une erreur avec la création du plug-in"
  },
  "plugins_update_success": {
    "en": "Plugin updated successfully",
    "fr": "Le plug-in a été mis à jour avec succès"
  },
  "plugins_update_error": {
    "en": "Sorry, we had an error with the plugin update",
    "fr": "Désolé, nous avons eu une erreur avec la mise à jour du plug-in"
  },
  "applications_delete_dialog_title": {
    "en": "Uninstall the application",
    "fr": "Désinstaller l'applcation"
  },
  "applications_delete_dialog_text": {
    "en": "You're about to uninstall this application. Are you sure you want to continue?",
    "fr": "Vous êtes sur le point de désinstaller cette application. Êtes-vous certain de vouloir continuer?"
  },
  "applications_delete_success": {
    "en": "Application uninstalled successfully",
    "fr": "Application désinstallée avec succès"
  },
  "applications_delete_error": {
    "en": "Sorry, we had an error with the application uninstallation",
    "fr": "Désolé, une erreur est survenue lors de la désinstallation de l'application"
  },
  "webhooks_new_webhook": {
    "en": "New webhook",
    "fr": "Nouveau webhook"
  },
  "webhooks_search_placeholders": {
    "en": "Search in webhooks",
    "fr": "Rechercher dans les webhooks"
  },
  "webhooks_delete_dialog_title": {
    "en": "Delete the webhook",
    "fr": "Supprimer le webhook"
  },
  "webhooks_delete_dialog_text": {
    "en": "You're about to delete this webhook. Are you sure you want to continue?",
    "fr": "Vous êtes sur le point de supprimer ce webhook. Êtes-vous certain de vouloir continuer?"
  },
  "webhooks_delete_success": {
    "en": "Webhook deleted successfully",
    "fr": "Webhook supprimé avec succès"
  },
  "webhooks_delete_error": {
    "en": "Sorry, we had an error with the webhook deletion",
    "fr": "Désolé, une erreur est survenue lors de la suppression du webhook"
  },
  "webhooks_add_webhook_dialog_title": {
    "en": "Create a new webhook",
    "fr": "Créer un nouveau webhook"
  },
  "webhooks_edit_webhook_dialog_title": {
    "en": "Edit the webhook ",
    "fr": "Modifier le webhook "
  },
  "webhooks_insert_success": {
    "en": "Webhook created successfully",
    "fr": "Le webhook a été créé avec succès"
  },
  "webhooks_insert_error": {
    "en": "Sorry, we had an error with the webhook creation",
    "fr": "Désolé, nous avons eu une erreur avec la création du webhook "
  },
  "webhooks_update_success": {
    "en": "Webhook updated successfully",
    "fr": "Le webhook a été mis à jour avec succès"
  },
  "webhooks_update_error": {
    "en": "Sorry, we had an error with the webhook update",
    "fr": "Désolé, nous avons eu une erreur avec la mise à jour du webhook"
  },
  "webhooks_employee_event_title": {
    "en": "Employee events",
    "fr": "Évènements des employés"
  },
  "webhooks_employee_event_description": {
    "en": "Event is triggered when an employee is created, updated or deleted",
    "fr": "L'événement est déclenché lorsqu'un employé est créé, mis à jour ou supprimé"
  },
  "webhooks_dailylog_event_title": {
    "en": "Daily log events",
    "fr": "Évènements des rapports journalier"
  },
  "webhooks_dailylog_event_description": {
    "en": "Event is triggered when a daily log is created, updated or deleted",
    "fr": "L'événement est déclenché lorsqu'un rapport journalier est créé, mis à jour ou supprimé"
  },
  "webhooks_comment_event_title": {
    "en": "Comment events",
    "fr": "Évènements des commentaires"
  },
  "webhooks_comment_event_description": {
    "en": "Event is triggered when a comment is created, updated or deleted",
    "fr": "L'événement est déclenché lorsqu'un commentaire est créé, mis à jour ou supprimé"
  },
  "webhooks_project_event_title": {
    "en": "Project events",
    "fr": "Évènements des projets"
  },
  "webhooks_project_event_description": {
    "en": "Event is triggered when a project is created, updated or deleted",
    "fr": "L'événement est déclenché lorsqu'un projet est créé, mis à jour ou supprimé"
  },
  "webhooks_file_event_title": {
    "en": "Document events",
    "fr": "Évènements des documents"
  },
  "webhooks_file_event_description": {
    "en": "Event is triggered when a document is created, updated or deleted",
    "fr": "L'événement est déclenché lorsqu'un document est créé, mis à jour ou supprimé"
  },
  "webhooks_user_event_title": {
    "en": "User events",
    "fr": "Évènements des utilisateurs"
  },
  "webhooks_user_event_description": {
    "en": "Event is triggered when a user is created, updated or deleted",
    "fr": "L'événement est déclenché lorsqu'un utilisateur est créé, mis à jour ou supprimé"
  },
  "api_credentials_new_api_credentials": {
    "en": "New API credentials",
    "fr": "Nouveaux identifiants d'API"
  },
  "api_credentials_search_placeholders": {
    "en": "Search in API credentials",
    "fr": "Rechercher dans les identifiants d'API"
  },
  "api_credentials_delete_dialog_title": {
    "en": "Delete the API credentials",
    "fr": "Supprimer les identifiant d'API"
  },
  "api_credentials_delete_dialog_text": {
    "en": "You're about to delete these API credentials. Are you sure you want to continue?",
    "fr": "Vous êtes sur le point de supprimer ces identifiants d'API. Êtes-vous certain de vouloir continuer?"
  },
  "api_credentials_delete_success": {
    "en": "API credentials deleted successfully",
    "fr": "Identifiants d'API supprimée avec succès"
  },
  "api_credentials_delete_error": {
    "en": "Sorry, we had an error with the API credentials deletion",
    "fr": "Désolé, une erreur est survenue lors de la suppression des identifiants d'API"
  },
  "api_credentials_add_api_credentials_dialog_title": {
    "en": "Create new API credentials",
    "fr": "Créer de nouveaux identifiants d'API"
  },
  "api_credentials_insert_success": {
    "en": "API credentials created successfully",
    "fr": "Les identifiants d'API ont été crées avec succès"
  },
  "api_credentials_insert_error": {
    "en": "Sorry, we had an error with the API credentials creation",
    "fr": "Désolé, nous avons eu une erreur avec la création des identifiants d'API"
  },
  "api_credentials_client_credentials_dialog_title": {
    "en": "API Credentials",
    "fr": "Identifiants d'API"
  },
  "api_credentials_client_credentials_dialog_description": {
    "en": "Below credential allows you to generate a token that is utilized by Civalgo OAuth, providing you access to the Civalgo API. You need to save these because they won't be available after you close this dialog.",
    "fr": "Les informations d'identification ci-dessous vous permettent de générer un jeton utilisé par Civalgo OAuth, vous donnant accès à l'API de Civalgo. Vous devez les enregistrer car ils ne seront plus disponibles après la fermeture de ce dialogue."
  },
  "copied_to_clipboard": {
    "en": "Copied to clipboard",
    "fr": "Copié dans le presse-papier"
  },
  "project_activity_date_button_label": {
    "en": "Enter the date",
    "fr": "Entrer une date"
  },
  "project_activity_no_attributes": {
    "en": "No attributes",
    "fr": "Pas d'attributes"
  },
  "project_activity_tags_label": {
    "en": "Attributes of the product",
    "fr": "Attribut du produit"
  },
  "project_activity_activity_label": {
    "en": "Name of activity",
    "fr": "Nom de l'activité"
  },
  "project_activity_product_label": {
    "en": "Name of product",
    "fr": "Nom du produit"
  },
  "project_activity_date_dialog_title": {
    "en": "Update scheduled date",
    "fr": "Modifier la date cédulée"
  },
  "project_activity_date_label": {
    "en": "Scheduled Date",
    "fr": "Date cédulée"
  },
  "project_activity_date_update_success": {
    "en": "This project activity date was successfully updated",
    "fr": "Cette date d'activité du projet a été mise à jour avec succès"
  },
  "project_activity_date_update_error": {
    "en": "Sorry, we had an error with the date update",
    "fr": "Désolé, nous avons eu une erreur avec la mise à jour de la date"
  },
  "project_activity_gantt_update_error": {
    "en": "Sorry, we had an error with the gantt update",
    "fr": "Désolé, nous avons eu une erreur avec la mise à jour du gantt"
  },
  "project_info_specified_budget": {
    "en": "Original estimated budget",
    "fr": "Budget estimé d'origine"
  },
  "project_info_edit": {
    "en": "Edit Data",
    "fr": "Modifier les données"
  },
  "project_info_cost_budget": {
    "en": "Total budget cost",
    "fr": "Coût budgétaire total"
  },
  "project_info_not_set": {
    "en": "Not set",
    "fr": "Non définie"
  },
  "project_info_cost_executed": {
    "en": "Total actual cost",
    "fr": "Coût actuel total"
  },
  "project_date": {
    "en": "Date",
    "fr": "Date"
  },
  "project_date_update_success": {
    "en": "This project date was successfully updated",
    "fr": "Cette date du projet a été mise à jour avec succès"
  },
  "project_date_update_error": {
    "en": "Sorry, we had an error with the date update",
    "fr": "Désolé, nous avons eu une erreur avec la mise à jour de la date"
  },
  "project_budget_update_error": {
    "en": "Sorry, we had an error with the budget update",
    "fr": "Désolé, nous avons eu une erreur avec la mise à jour du budget"
  },
  "project_budget_update_success": {
    "en": "This project budget was successfully updated",
    "fr": "Ce budget du projet a été mise à jour avec succès"
  },
  "shift_message_create_success": {
    "en": "Shift created successfully",
    "fr": "Quart de travail créé avec succès"
  },
  "shift_message_create_error": {
    "en": "Sorry, we had an error with the shift creation",
    "fr": "Désolé, nous avons eu une erreur avec la création du quart de travail"
  },
  "shift_message_delete_success": {
    "en": "Shift deleted successfully",
    "fr": "Quart de travail supprimé avec succès"
  },
  "shift_message_delete_error": {
    "en": "Sorry, we had an error with the shift deletion",
    "fr": "Désolé, nous avons eu une erreur avec la suppression du quart de travail"
  },
  "shift_message_update_success": {
    "en": "Shift updated successfully",
    "fr": "Quart de travail mis à jour avec succès"
  },
  "shift_message_update_error": {
    "en": "Sorry, we had an error with the shift update",
    "fr": "Désolé, nous avons eu une erreur avec la mise à jour du quart de travail"
  },
  "shift_message_restore_success": {
    "en": "Shift restored successfully",
    "fr": "Quart de travail restauré avec succès"
  },
  "shift_message_restore_error": {
    "en": "Sorry, we had an error with the shift restoration",
    "fr": "Désolé, nous avons eu une erreur avec la restauration du quart de travail"
  },
  "shift_label_search_placeholder": {
    "en": "Search in shifts",
    "fr": "Rechercher les quarts de travail"
  },
  "shift_label_name": {
    "en": "Name",
    "fr": "Nom"
  },
  "shift_label_description": {
    "en": "Description",
    "fr": "Description"
  },
  "shift_label_start_time": {
    "en": "Start time",
    "fr": "Heure de début"
  },
  "shift_label_end_time": {
    "en": "End time",
    "fr": "Heure de fin"
  },
  "shift_label_break_length": {
    "en": "Break length",
    "fr": "Durée des pauses"
  },
  "shift_label_lunch": {
    "en": "Lunch",
    "fr": "Diner"
  },
  "shift_label_length": {
    "en": "Length",
    "fr": "Durée"
  },
  "shift_label_period": {
    "en": "Period",
    "fr": "Période"
  },
  "shift_label_lunch_length": {
    "en": "Lunch length",
    "fr": "Durée du diner"
  },
  "shift_label_lunch_length_unit_minutes": {
    "en": "minutes",
    "fr": "minutes"
  },
  "shift_label_weekend_start_time": {
    "en": "Weekend start",
    "fr": "Début de fin de semaine"
  },
  "shift_label_weekend_end_time": {
    "en": "Weekend end",
    "fr": "Fin de fin de semaine"
  },
  "shift_label_hours": {
    "en": "Hours",
    "fr": "Heures"
  },
  "shift_button_create": {
    "en": "New shift",
    "fr": "Nouveau quart de travail"
  },
  "shift_dialog_create_title": {
    "en": "Create a new shift",
    "fr": "Créer un quart de travail"
  },
  "shift_dialog_update_title": {
    "en": "Edit the shift",
    "fr": "Modifier le quart de travail"
  },
  "shift_dialog_delete_title": {
    "en": "Delete shift",
    "fr": "Supprimer le quart de travail"
  },
  "shift_dialog_delete_text": {
    "en": "You're about to delete this shift. Are you sure you want to continue?",
    "fr": "Vous êtes sur le point de supprimer ce quart de travail. Êtes-vous certain de vouloir continuer?"
  },
  "shift_page_title": {
    "en": "Work shifts",
    "fr": "Quarts de travail"
  },
  "shift_page_description": {
    "en": "Create and edit work shifts",
    "fr": "Créer et modifier des quarts de travail"
  },
  "no_shift": {
    "en": "No shift",
    "fr": "Pas de quart de travail"
  },
  "timecard_weekly_timecard_time": {
    "en": "Time Card Time",
    "fr": "Temps de la carte de temps"
  },
  "timecard_weekly_dailylog_time": {
    "en": "Daily Log Time",
    "fr": "Temps du rapport journalier"
  },
  "timecard_weekly_dailylog_info": {
    "en": "Daily Logs",
    "fr": "Rapports journaliers"
  },
  "timecard_weekly_title": {
    "en": "Worked hours",
    "fr": "Heures travaillées"
  },
  "timecard_summary_title": {
    "en": "Daily summary",
    "fr": "Résumé journalier"
  },
  "timecard_daily_title": {
    "en": "Work of the day",
    "fr": "Travail du jour"
  },
  "timecard_daily_summary_title": {
    "en": "Daily log information",
    "fr": "Informations du rapport journalier"
  },
  "timecard_daily_open_log": {
    "en": "Open daily log",
    "fr": "Ouvrir le rapport"
  },
  "timecard_daily_information": {
    "en": "Information",
    "fr": "Informations"
  },
  "timecard_weekly_hours_title": {
    "en": "Week of",
    "fr": "Semaine du"
  },
  "timecard_weekly_card_title": {
    "en": "This week",
    "fr": "Cette semaine"
  },
  "timecard_daily_hours_title": {
    "en": "Hours of",
    "fr": "Heures du"
  },
  "timecard_daily_card_title": {
    "en": "Today",
    "fr": "Aujourd'hui"
  },
  "timecard_weekly_nothing_planned": {
    "en": "Nothing planned",
    "fr": "Rien de planifié"
  },
  "timecard_weekly_ended_on": {
    "en": "ended on",
    "fr": "terminé le"
  },
  "timecard_open_maps": {
    "en": "Open in Google Maps",
    "fr": "Ouvrir sur Google Maps"
  },
  "timecard_work_period": {
    "en": "Work period",
    "fr": "Période de travail"
  },
  "timecard_break_period": {
    "en": "Break period",
    "fr": "Période de pause"
  },
  "timecard_transport_period": {
    "en": "Travel period",
    "fr": "Période de transport"
  },
  "timecard_details_button": {
    "en": "Details",
    "fr": "Détails"
  },
  "timecard_punch_button": {
    "en": "Punch in",
    "fr": "Débuter le temps"
  },
  "timecard_punch_out_button": {
    "en": "Punch out",
    "fr": "Arrêter le temps"
  },
  "timesheet_day_punched": {
    "en": "Punched at least once",
    "fr": "Poinçonné au moins une fois"
  },
  "timesheet_x_day_punched": {
    "en": "day punched",
    "fr": "jours poinçonné"
  },
  "timesheet_no_day_punched": {
    "en": "Did not punch",
    "fr": "N'as pas poinçonné"
  },
  "timecard_more_label": {
    "en": "More",
    "fr": "Plus"
  },
  "timecard_time_line_title": {
    "en": "Events",
    "fr": "Evénements"
  },
  "timecard_edit_button": {
    "en": "Edit",
    "fr": "Modifier"
  },
  "timecard_time_line_previous_day": {
    "en": "Started previous day",
    "fr": "Commencé la veille"
  },
  "timecard_time_line_next_day": {
    "en": "Ended next day",
    "fr": "Terminé le lendemain"
  },
  "timecard_time_line_no_events": {
    "en": "No events",
    "fr": "Pas d'événements"
  },
  "timecard_time_line_work_started": {
    "en": "Work started",
    "fr": "Travail commencé"
  },
  "timecard_time_line_work_ended": {
    "en": "Work ended",
    "fr": "Travail terminé"
  },
  "timecard_time_line_work_resumed": {
    "en": "Work resumed",
    "fr": "Travail repris"
  },
  "timecard_time_line_transport_started": {
    "en": "Travel started",
    "fr": "Transport commencé"
  },
  "timecard_time_line_break_started": {
    "en": "Break started",
    "fr": "Pause commencée"
  },
  "timecard_dialog_title": {
    "en": "Manual time logging",
    "fr": "Enregistrement manuel du temps"
  },
  "timecard_manuel_insertion_button": {
    "en": "Manual logging",
    "fr": "Manuel logging"
  },
  "timecard_manuel_add": {
    "en": "Add period",
    "fr": "Ajouter une période"
  },
  "timecard_type_transport": {
    "en": "Travel",
    "fr": "Transport"
  },
  "timecard_type_work": {
    "en": "Work",
    "fr": "Travail"
  },
  "timecard_type_break": {
    "en": "Break",
    "fr": "Pause"
  },
  "timecard_dialog_startedat": {
    "en": "Started",
    "fr": "Commencé"
  },
  "timecard_dialog_endedat": {
    "en": "Ended",
    "fr": "Terminé"
  },
  "timecard_dialog_manual_action_error": {
    "en": "Sorry, we had an error with the time cards update",
    "fr": "Désolé, nous avons eu une erreur avec la modifcation des cartes de temps"
  },
  "timecard_dialog_manual_action_success": {
    "en": "Time cards updated successfully",
    "fr": "les cartes du temps ont été modifié avec succès"
  },
  "timecard_dialog_delete_work_period_error": {
    "en": "Sorry, we had an error with the work period deletion",
    "fr": "Désolé, nous avons eu une erreur avec la suppression de la période de temps"
  },
  "timecard_dialog_delete_work_period_success": {
    "en": "Work period deleted successfully",
    "fr": "Période de temps supprimée avec succès"
  },
  "timecard_punch_nav": {
    "en": "Summary",
    "fr": "Résumé"
  },
  "time_card_work_period_delete_dialog": {
    "en": "Delete work period",
    "fr": "Supprimer la période de temps"
  },
  "time_card_work_period_delete_dialog_text": {
    "en": "You are about to delete this work period. Are you sure you want to continue?",
    "fr": "Êtes-vous certain de vouloir supprimer cette période de temps?"
  },
  "timesheet_rule_output_create_dialog_title": {
    "en": "Create a new output",
    "fr": "Créer une nouvelle sortie"
  },
  "timesheet_rule_output_edit_dialog_title": {
    "en": "Edit the output",
    "fr": "Modifier la sortie"
  },
  "timesheet_rule_output_insert_success": {
    "en": "Output created successfully",
    "fr": "La sortie a été créé avec succès"
  },
  "timesheet_rule_output_insert_error": {
    "en": "Sorry, we had an error with the output creation",
    "fr": "Désolé, nous avons eu une erreur avec la création de la sortie"
  },
  "timesheet_rule_output_update_success": {
    "en": "Output updated successfully",
    "fr": "La sortie a été mis à jour avec succès"
  },
  "timesheet_rule_output_update_error": {
    "en": "Sorry, we had an error with the output update",
    "fr": "Désolé, nous avons eu une erreur avec la mise à jour de la sortie"
  },
  "timesheet_rule_output_delete_dialog_title": {
    "en": "Delete output",
    "fr": "Supprimer la sortie"
  },
  "timesheet_rule_output_delete_dialog_text": {
    "en": "Are you sure you want to remove this output?",
    "fr": "Êtes-vous certain de vouloir supprimer cette sortie?"
  },
  "timesheet_rule_output_delete_success": {
    "en": "Output deleted successfully",
    "fr": "Sortie supprimé avec succès"
  },
  "timesheet_rule_output_delete_error": {
    "en": "Sorry, we had an error with the output deletion",
    "fr": "Désolé, une erreur est survenue lors de la suppression de la sortie"
  },
  "timesheet_rule_add_output": {
    "en": "Add output",
    "fr": "Ajouter une sortie"
  },
  "timesheet_rule_output_variable_name_invalid_space": {
    "en": "Cannot contain spaces",
    "fr": "Ne peut pas contenir des espaces"
  },
  "dailylog_label_default_shift": {
    "en": "Default working shift:",
    "fr": "Quart de travail par défaut:"
  },
  "dailylog_label_view": {
    "en": "View",
    "fr": "Vue"
  },
  "dailylog_label_view_night": {
    "en": "Night view",
    "fr": "Vue de nuit"
  },
  "dailylog_label_view_day": {
    "en": "Day view",
    "fr": "Vue de jour"
  },
  "team_label_preferred _view": {
    "en": "Preferred view",
    "fr": "Vue préférée"
  },
  "dailylog_label_resource_properties": {
    "en": "Properties",
    "fr": "Propriétés"
  },
  "dailylog_dialog_edit_properties_title": {
    "en": "Edit resource properties",
    "fr": "Modifier les propriétés de la ressource"
  },
  "dialog_label_resource_shift_override": {
    "en": "This resource has a custom shift selected",
    "fr": "Cette resource a un quart de travail spécifique"
  },
  "services_dailylog_update_employee_properties_success": {
    "en": "Employee properties updated successfully",
    "fr": "Les propriétés de l'employé ont été mises à jour avec succès"
  },
  "services_dailylog_update_employee_properties_error": {
    "en": "Sorry, we had an error with the employee properties update",
    "fr": "Désolé, nous avons eu une erreur avec la mise à jour des propriétés de l'employé"
  },
  "services_dailylog_update_material_properties_success": {
    "en": "Material properties updated successfully",
    "fr": "Les propriétés du matériau ont été mises à jour avec succès"
  },
  "services_dailylog_update_material_properties_error": {
    "en": "Sorry, we had an error with the material properties update",
    "fr": "Désolé, nous avons eu une erreur avec la mise à jour des propriétés du matériau"
  },
  "services_dailylog_update_equipment_properties_success": {
    "en": "Equipment properties updated successfully",
    "fr": "Les propriétés de l'équipement ont été mises à jour avec succès"
  },
  "services_dailylog_update_equipment_properties_error": {
    "en": "Sorry, we had an error with the equipment properties update",
    "fr": "Désolé, nous avons eu une erreur avec la mise à jour des propriétés de l'équipement"
  },
  "services_dailylog_update_placeholder_properties_success": {
    "en": "Placeholder properties updated successfully",
    "fr": "Les propriétés de la ressource provisoire ont été mises à jour avec succès"
  },
  "services_dailylog_update_placeholder_properties_error": {
    "en": "Sorry, we had an error with the placeholder properties update",
    "fr": "Désolé, nous avons eu une erreur avec la mise à jour des propriétés de la ressource provisoire"
  },
  "dailylog_label_resource_edit_shift": {
    "en": "Edit shift",
    "fr": "Modifier le quart de travail"
  },
  "dailylog_dialog_edit_shift_description": {
    "en": "The chosen shift will overwrite the one defined in the daily log.",
    "fr": "Le quart de travail choisi aura préséance sur celui défini dans le rapport journalier."
  },
  "gantt_info_header_project_activity": {
    "en": "Project / Activity",
    "fr": "Projet / Activité"
  },
  "gantt_archived_toogle_text": {
    "en": "Show archived project",
    "fr": "Afficher les projects archivés"
  },
  "project_product_list_title": {
    "en": "Import of project products and activities",
    "fr": "Importation des produits et activités du projet"
  },
  "project_product_list_field_name_label": {
    "en": "Activity name",
    "fr": "Nom de l'activité"
  },
  "project_product_list_field_name_description": {
    "en": "The name of the activity.",
    "fr": "Le nom de l'activitié"
  },
  "project_product_list_field_product_name_label": {
    "en": "Product name",
    "fr": "Nom du produit"
  },
  "project_product_list_field_product_name_description": {
    "en": "The name of the product.",
    "fr": "Le nom du produit"
  },
  "project_product_list_field_product_code_label": {
    "en": "Product code",
    "fr": "Code du produit"
  },
  "project_product_list_field_product_code_description": {
    "en": "(Optional) The code of the product",
    "fr": "(Facultatif) Le code du produit"
  },
  "project_product_list_field_product_unit_label": {
    "en": "Product unit",
    "fr": "Unité du produit"
  },
  "project_product_list_field_product_unit_description": {
    "en": "The unit of the product",
    "fr": "L'unité du produit"
  },
  "project_product_list_field_product_alias_label": {
    "en": "Product alias",
    "fr": "Alias du produit (Facultatif)"
  },
  "project_product_list_field_product_alias_description": {
    "en": "(Optional) The alias of the product will create a copy of this product with this alias",
    "fr": "(Facultatif) Ceci va permet de créér une copie du produit avec cet alias."
  },
  "project_product_list_field_unit_label": {
    "en": "Activity unit",
    "fr": "L'unité de l'activité (Facultatif)"
  },
  "project_product_list_field_unit_description": {
    "en": "(Optional) The name of the activity unit",
    "fr": "(Facultatif) Le nom de l'unité du produit"
  },
  "project_product_list_field_code_label": {
    "en": "Activity code",
    "fr": "Code de l'activité"
  },
  "project_product_list_field_code_description": {
    "en": "(Optional) The code of the activity",
    "fr": "(Facultatif) Le nom du code de l'activité"
  },
  "project_product_list_field_expected_man_hours_label": {
    "en": "Budgeted employee hours",
    "fr": "Heures d'employés prévues (Facultatif)"
  },
  "project_product_list_field_expected_man_hours_description": {
    "en": "(Optional) The budgeted employee hours of the activity",
    "fr": "(Facultatif) Les heures d'employés prévues de l'activité"
  },
  "project_product_list_field_expected_man_cost_label": {
    "en": "Budgeted employee cost",
    "fr": "Coûts d'employés prévus (Facultatif)"
  },
  "project_product_list_field_expected_man_cost_description": {
    "en": "(Optional) The budgeted employee cost of the activity",
    "fr": "(Facultatif) Les coûts d'employés prévus de l'activité"
  },
  "project_product_list_field_expected_quantity_label": {
    "en": "Budgeted quantity",
    "fr": "Quantité prévue (Facultatif)"
  },
  "project_product_list_field_expected_quantity_description": {
    "en": "(Optional) The budgeted quantity of the activity",
    "fr": "(Facultatif) La quantité prévue de l'activité"
  },
  "project_product_list_field_expected_cost_label": {
    "en": "Budgeted cost ",
    "fr": "Coût prévu (Facultatif)"
  },
  "project_product_list_field_expected_cost_description": {
    "en": "(Optional) The budgeted cost of the activity",
    "fr": "(Facultatif) Le coût prévu de l'activité"
  },
  "project_product_list_field_note_label": {
    "en": "Note",
    "fr": "Note (Facultatif)"
  },
  "project_product_list_field_note_description": {
    "en": "(Optional) The note of the activity. The note is to describe the activty.",
    "fr": "(Facultatif) La note de l'activité. La note sert à décrire l'activité."
  },
  "project_product_list_import_creatable_warning_name": {
    "en": "This activity does not exist in the system, it will be created. If you think this is an error, check the spelling",
    "fr": "Cette activité n'existe pas dans le système, elle sera créé. Si vous pensez que cela est une erreur, vérifiez l'orthographe"
  },
  "project_product_list_import_product_unit_required": {
    "en": "Product unit is required when the product does not already exist in the system",
    "fr": "L'unité du produit est requise lorsque le produit n'existe pas déjà dans le système"
  },
  "project_product_list_import_creatable_warning_product_name": {
    "en": "This product does not exist in the system, it will be created. If you think this is an error, check the spelling",
    "fr": "Ce produit n'existe pas dans le système, il sera créé. Si vous pensez que cela est une erreur, vérifiez l'orthographe"
  },
  "project_product_list_import_creatable_warning_product_unit": {
    "en": "This unit does not exist in the system, it will be created. If you think this is an error, check the spelling",
    "fr": "Cette unité n'existe pas dans le système, elle sera créée. Si vous pensez que cela est une erreur, vérifiez l'orthographe"
  },
  "project_product_list_csv_import_error": {
    "en": "Sorry, we had an error with the csv import",
    "fr": "Désolé, nous avons eu une erreur à l'importation csv"
  },
  "project_product_list_field_startDate_project_activity_label": {
    "en": "Start date",
    "fr": "Date du début (Facultatif)"
  },
  "project_product_list_field_endDate_project_activity_label": {
    "en": "End date",
    "fr": "Date de fin (Facultatif)"
  },
  "project_product_list_field_startDate_project_activity_description": {
    "en": "(Optional)  The start date of the project activity, fomat must be yyyy-mm-dd",
    "fr": "(Facultatif) Date du début de l'activité relier au projet. Le format doit être yyyy-mm-dd"
  },
  "project_product_list_field_endDate_project_activity_description": {
    "en": "(Optional)  The end date of the project activity, fomat must be yyyy-mm-dd",
    "fr": "(Facultatif) Date de fin de l'activité relier au projet. Le format doit être yyyy-mm-dd"
  },
  "project_product_list_field_budgeted_subtrade_quantity_label": {
    "en": "Budgeted subtrade quantity",
    "fr": "Quantité sous-traitant prévue (Facultatif)"
  },
  "project_product_list_field_budgeted_subtrade_quantity_description": {
    "en": "(Optional) The budgeted subtrade quantity of the project activity",
    "fr": "(Facultatif) La quantité de sous-traitant prévue de l'activité "
  },
  "project_product_list_field_budgeted_subtrade_cost_label": {
    "en": "Budgeted subtrade cost",
    "fr": "Coût sous-traitant prévu (Facultatif)"
  },
  "project_product_list_field_budgeted_subtrade_cost_description": {
    "en": "(Optional) The budgeted subtrade cost of the project activity",
    "fr": "(Facultatif) Le coût du sous-traitant prévu de l'activité "
  },
  "project_product_list_field_budgeted_equipment_quantity_label": {
    "en": "Budgeted equipment quantity",
    "fr": "Quantité de l'équipement prévue (Facultatif)"
  },
  "project_product_list_field_budgeted_equipment_quantity_description": {
    "en": "(Optional) The budgeted equipment quantity of the project activity",
    "fr": "(Facultatif) La quantité de l'équipement prévue de l'activité "
  },
  "project_product_list_field_budgeted_equipment_cost_label": {
    "en": "Budgeted equipment cost",
    "fr": "Coût de l'équipement prévu (Facultatif)"
  },
  "project_product_list_field_budgeted_equipment_cost_description": {
    "en": "(Optional) The budgeted equipment cost of the project activity",
    "fr": "(Facultatif) Le coût de l'équipement prévu de l'activité "
  },
  "project_product_list_field_budgeted_rental_quantity_label": {
    "en": "Budgeted rental quantity",
    "fr": "Quantité de la location prévue (Facultatif)"
  },
  "project_product_list_field_budgeted_rental_quantity_description": {
    "en": "(Optional) The budgeted rental quantity of the project activity",
    "fr": "(Facultatif) La quantité de la location prévue de l'activité "
  },
  "project_product_list_field_budgeted_rental_cost_label": {
    "en": "Budgeted rental cost",
    "fr": "Coût de la location prévu (Facultatif)"
  },
  "project_product_list_field_budgeted_rental_cost_description": {
    "en": "(Optional) The budgeted rental cost of the project activity",
    "fr": "(Facultatif) Le coût de la location prévu de l'activité "
  },
  "project_product_list_field_budgeted_material_cost_label": {
    "en": "Budgeted material cost",
    "fr": "Coût du matériau prévu (Facultatif)"
  },
  "project_product_list_field_budgeted_material_cost_description": {
    "en": "(Optional) The budgeted material cost of the project activity",
    "fr": "(Facultatif) Le coût du matériau prévu de l'activité "
  },
  "project_product_list_field_startDate_project_activity_error": {
    "en": "Format is invalid , must be yyyy-mm-dd. Example : 2021-05-21",
    "fr": "Le format est invalide, il doit être yyyy-mm-dd. Exemple : 2021-05-21"
  },
  "project_product_list_field_endDate_project_activity_error": {
    "en": "Format is invalid , must be yyyy-mm-dd. Example : 2021-05-21",
    "fr": "Le format est invalide, il doit être yyyy-mm-dd. Exemple : 2021-05-21"
  },
  "project_product_list_field_ten_digits_error": {
    "en": "The value is invalid, must be 10 digits and not precision. Example: 1000000011",
    "fr": "La valeur est invalide, elle doit être de 10 chiffres et ne peux pas avoir de chiffres après le point. Exemple: 1000000011"
  },
  "project_product_list_field_numeric_error": {
    "en": "The value is invalid, must be 10 digits include 2 digits of precision. Example: 10000000.69",
    "fr": "La valeur est invalide, elle doit être de 10 chiffres incluant les 2 chiffres après le point. Exemple: 10000000.69"
  },
  "project_product_list_field_note_error": {
    "en": "The note is too long, must be up to 140 characters.",
    "fr": "La note est trop longue, elle doit être jusqu'à 140 caractères."
  },
  "project_product_list_product_unit_archived_error": {
    "en": "This unit is archived in our system. Restore it or change the name of this unit.",
    "fr": "Ce unité est archivé dans notre système. Restaurez-le ou changez le nom de cet unité. "
  },
  "project_product_list_activity_archived_error": {
    "en": "This activity is archived in our system. Restore it or change the name of this activity.",
    "fr": "Cette activité est archivée dans notre système. Restaurez-la ou changez le nom de cette activité. "
  },
  "project_product_list_product_name_archived_error": {
    "en": "This product is archived in our system. Restore it or change the name of this product.",
    "fr": "Ce produit est archivé dans notre système. Restaurez-le ou changez le nom de ce produit"
  },
  "dispatch_dialog_title": {
    "en": "Dispatch module",
    "fr": "Module de répartition"
  },
  "dispatch_dialog_team_title": {
    "en": "Teams",
    "fr": "Équipes"
  },
  "dispatch_dialog_activities_title": {
    "en": "Activities",
    "fr": "Activités"
  },
  "dispatch_dialog_status_title": {
    "en": "Status",
    "fr": "État"
  },
  "dispatch_dialog_budget_cost_title": {
    "en": "Current / Budget Cost",
    "fr": "Coût actuel / budgétaire"
  },
  "dispatch_dialog_teams_associated_title": {
    "en": "Teams associated",
    "fr": "Équipes associées"
  },
  "dispatch_dialog_show_only_unassign": {
    "en": "Show only unassign",
    "fr": "Afficher seulement les non-affectés"
  },
  "dispatch_dialog_teams": {
    "en": "teams",
    "fr": "équipes"
  },
  "dispatch_dialog_add_team": {
    "en": "Add team",
    "fr": "Ajouter une équipe"
  },
  "dispatch_dialog_current_cost": {
    "en": "Current Cost:",
    "fr": "Coût actuel:"
  },
  "dispatch_dialog_executed_quantity": {
    "en": "Executed Quantity:",
    "fr": "Quantité exécutée:"
  },
  "dispatch_dialog_properties": {
    "en": "Properties",
    "fr": "Propriétés"
  },
  "dispatch_dialog_work_log_title": {
    "en": "Work log",
    "fr": "Journal de travail"
  },
  "dispatch_dialog_note": {
    "en": "Note",
    "fr": "Note"
  },
  "dispatch_dialog_action_success": {
    "en": "A daily log with the team was successfully created",
    "fr": "Un rapport journalier avec l'équipe a été créé avec succés"
  },
  "dispatch_dialog_action_error": {
    "en": "Sorry, we had an error with the creation of the daily log",
    "fr": "Désolé, nous avons eu une erreur avec la création du rapport journalier"
  },
  "dispatch_dialog_drag_and_drop_text": {
    "en": "Drag and drop team on product activity",
    "fr": "Selectionner et glisser une équipe sur une activité de produit"
  },
  "schedule_page_edit_activity_dialog_expected_cost": {
    "en": "Expected cost",
    "fr": "Coût prévue"
  },
  "schedule_page_edit_activity_dialog_expected_man_hour": {
    "en": "Expected man hour",
    "fr": "Heures d'employés prévues "
  },
  "schedule_page_edit_activity_dialog_expected_quantity": {
    "en": "Expected quantity",
    "fr": "Quantité prévue"
  },
  "schedule_page_edit_activity_dialog_current_progress": {
    "en": "Current progress",
    "fr": "Progrès actuel"
  },
  "schedule_page_edit_activity_dialog_note": {
    "en": "Note",
    "fr": "Note"
  },
  "schedule_page_edit_activity_dialog_date": {
    "en": "Scheduled Date",
    "fr": "Date cédulée"
  },
  "schedule_page_edit_activity_dialog_title": {
    "en": "Edit activity",
    "fr": "Modifier une activité"
  },
  "schedule_page_edit_activity_dialog_success": {
    "en": "The activity details updated successfully",
    "fr": "Les détails du activité ont été mises à jour avec succès"
  },
  "schedule_page_edit_activity_dialog_error": {
    "en": "Sorry, we had an error with the activity details update ",
    "fr": "Désolé, nous avons eu une erreur avec la mise à jour des détails de l'activité "
  },
  "sidebar_long_term_v1": {
    "en": "Long term",
    "fr": "Long terme"
  },
  "project_page_tooltip_expected_cost": {
    "en": "Budgeted cost",
    "fr": "Coût budgeté"
  },
  "project_page_tooltip_expected_man_hour": {
    "en": "Budgeted man hours",
    "fr": "Heures employés budgétées"
  },
  "project_page_tooltip_expected_quantity": {
    "en": "Budgeted quantity",
    "fr": "Quantité budgétée"
  },
  "project_page_tooltip_expected_current_progress": {
    "en": "Current progress",
    "fr": "Progrès actuel"
  },
  "project_page_tooltip_expected_note": {
    "en": "Note",
    "fr": "Note"
  },
  "show_deleted_rows_toggle_label": {
    "en": "Show archived",
    "fr": "Afficher les éléments archivés"
  },
  "schedule_page_edit_activity_dialog_note_placeholder": {
    "en": "Activity description",
    "fr": "Description de l'activité"
  },
  "copy_daily_log_date_error": {
    "en": "This is not a valid date",
    "fr": "Cette date n'est pas valide"
  },
  "material_import_constraint_error_part_1": {
    "en": "Another material with the same name on row",
    "fr": "Il y a un autre matériau avec le même nom à la ligne"
  },
  "material_import_constraint_error_part_2_single": {
    "en": "has a different property than this one:",
    "fr": "a une propriété différente de celle-ci:"
  },
  "material_import_constraint_error_part_2_plural": {
    "en": "has a different property:",
    "fr": "a des propriétés différentes de celle-ci:"
  },
  "activity_drawer_total_budget_aggregated": {
    "en": "Aggregated budgeted Cost",
    "fr": "Coût Budgété Agrégé"
  },
  "activity_drawer_total_budget": {
    "en": "Budgeted Cost",
    "fr": "Coût Budgété"
  },
  "activity_drawer_budgeted": {
    "en": "Budgeted",
    "fr": "Budgété"
  },
  "activity_drawer_resources_budget": {
    "en": "Aggregated Budgeted Cost",
    "fr": "Coût Budgété Agrégé"
  },
  "activity_drawer_budgeted_quantity": {
    "en": "Budgeted Quantity",
    "fr": "Quantité Budgétée"
  },
  "activity_drawer_executed_quantity": {
    "en": "Actual Quantity",
    "fr": "Quantité Actuelle"
  },
  "activity_drawer_executed_costs": {
    "en": "Actual Cost",
    "fr": "Coûts Actuels"
  },
  "activity_drawer_executed": {
    "en": "Actual",
    "fr": "Actuel"
  },
  "activity_drawer_progress": {
    "en": "Progress",
    "fr": "Progrès"
  },
  "activity_drawer_scheduled": {
    "en": "Scheduled",
    "fr": "Cédulée"
  },
  "activity_drawer_current": {
    "en": "Current",
    "fr": "Réelle"
  },
  "activity_drawer_type": {
    "en": "Type",
    "fr": "Type"
  },
  "activity_drawer_expected": {
    "en": "Expected",
    "fr": "Attendue"
  },
  "activity_drawer_finance": {
    "en": "Finance",
    "fr": "Finance"
  },
  "activity_drawer_activity_label": {
    "en": "Activity",
    "fr": "Activité"
  },
  "activity_drawer_quantities": {
    "en": "Quantities",
    "fr": "Quantités"
  },
  "activity_drawer_details": {
    "en": "Details",
    "fr": "Détails"
  },
  "activity_drawer_date_scheduled": {
    "en": "Scheduled",
    "fr": "Attendue"
  },
  "activity_drawer_date_current": {
    "en": "Current",
    "fr": "Actuelle"
  },
  "activity_drawer_details_label": {
    "en": "Details",
    "fr": "Détails"
  },
  "activity_drawer_current_budgeted_cost": {
    "en": "Currently",
    "fr": "Actuellement"
  },
  "activity_drawer_progress_label": {
    "en": "Progress",
    "fr": "Progrès"
  },
  "activity_drawer_equipment_model": {
    "en": "Model",
    "fr": "Modèle"
  },
  "activity_drawer_model_name": {
    "en": "Name",
    "fr": "Nom"
  },
  "activity_drawer_role": {
    "en": "Role",
    "fr": "Rôle"
  },
  "activity_drawer_cost": {
    "en": "Cost",
    "fr": "Coût"
  },
  "activity_drawer_rentals": {
    "en": "Rentals",
    "fr": "Locations"
  },
  "activity_drawer_subtrade": {
    "en": "Subtrade",
    "fr": "Sous-traitants"
  },
  "activity_drawer_resources_no_data": {
    "en": "No data available",
    "fr": "Aucune données disponibles"
  },
  "activity_drawer_date_unspecified": {
    "en": "Unspecified",
    "fr": "Non spécifiée"
  },
  "common_unspecified": {
    "en": "Unspecified",
    "fr": "Non spécifiée"
  },
  "activity_drawer_form_budgeted_cost": {
    "en": "Budgeted Cost",
    "fr": "Coût Budgété"
  },
  "activity_drawer_form_budgeted_quantity": {
    "en": "Budgeted Quantity",
    "fr": "Quantité Budgétée"
  },
  "activity_drawer_form_budgeted_employee_hours": {
    "en": "Budgeted Employee Hours",
    "fr": "Heures Employés Budgétées"
  },
  "activity_drawer_form_budgeted_employee_cost": {
    "en": "Budgeted Employee Cost",
    "fr": "Coût Employés Budgété"
  },
  "activity_drawer_form_budgeted_subtrade_hours": {
    "en": "Budgeted Subtrade Hours",
    "fr": "Heures Sous-traitants Budgétées"
  },
  "activity_drawer_form_budgeted_subtrade_cost": {
    "en": "Budgeted Subtrade Cost",
    "fr": "Coût Sous-traitants Budgété"
  },
  "activity_drawer_form_budgeted_equipment_hours": {
    "en": "Budgeted Equipment Hours",
    "fr": "Heures Équipements Budgétées"
  },
  "activity_drawer_form_budgeted_equipment_cost": {
    "en": "Budgeted Equipment Cost",
    "fr": "Coût Équipements Budgété"
  },
  "activity_drawer_form_budgeted_rental_hours": {
    "en": "Budgeted Rental Hours",
    "fr": "Heures Locations Budgétées"
  },
  "activity_drawer_form_budgeted_rental_cost": {
    "en": "Budgeted Rental Cost",
    "fr": "Coût Locations Budgété"
  },
  "activity_drawer_form_budgeted_material_cost": {
    "en": "Budgeted Material Cost",
    "fr": "Coût Matériaux Budgété"
  },
  "activity_drawer_budget_update_success": {
    "en": "Budget updated successfully ",
    "fr": "Budget mis à jour avec succès"
  },
  "activity_drawer_budget_update_error": {
    "en": "Error updating budget",
    "fr": "Erreur lors de la mise à jour du budget"
  },
  "activity_drawer_budget_create_success": {
    "en": "Budget created successfully ",
    "fr": "Budget créé avec succès"
  },
  "activity_drawer_add_note": {
    "en": "Add note",
    "fr": "Ajouter une note"
  },
  "activity_drawer_budget_create_error": {
    "en": "Error creating budget",
    "fr": "Erreur lors de la création du budget"
  },
  "not_finished_timecard_notification_part_1": {
    "en": "You have an active timecard on",
    "fr": "Vous avez une carte de temps en cours le"
  },
  "not_finished_timecard_notification_part_2": {
    "en": "Click here to be redirected.",
    "fr": "Cliquez ici pour y être redirigé."
  },
  "daily_log_pension_tooltip": {
    "en": "This employee has this pension on his timecard",
    "fr": "L'employé a cette prime sur sa carte de temps"
  },
  "daily_log_pensions_tab_tooltip": {
    "en": "One and more employees have pensions on their timecard",
    "fr": "Un ou plusieurs employés ont des primes sur leurs cartes de temps"
  },
  "daily_log_accept_all_pension_action": {
    "en": "Accept timecard pensions ",
    "fr": "Accepter les primes de la carte de temps"
  },
  "daily_log_accept_pensions_success": {
    "en": "The pensions accepted successfully",
    "fr": "Les primes ont été acceptés avec succès"
  },
  "daily_log_accept_pensions_error": {
    "en": "Sorry, we had an error with the accept of the pensions",
    "fr": "Désolé, nous avons eu une erreur avec l'acceptation des primes."
  },
  "daily_log_disabled_tooltip_pension_section": {
    "en": "You must be on pension section",
    "fr": "Vous devez être dans la section Prime"
  },
  "daily_log_disabled_tooltip_pension_checked": {
    "en": "No timecard pensions to accept",
    "fr": "Aucune prime de cartes de temps à accepter"
  },
  "all_projects": {
    "en": "All projects",
    "fr": "Tout les projets"
  },
  "app_store_search": {
    "en": "Search for app",
    "fr": "Rechercher une application"
  },
  "add_app_store_dialog_title": {
    "en": "Add application",
    "fr": "Ajouter une application"
  },
  "app_store_name_label": {
    "en": "Name",
    "fr": "Nom de l'application"
  },
  "app_store_description_label": {
    "en": "Description",
    "fr": "Description"
  },
  "app_store_install_button_label": {
    "en": "Install",
    "fr": "installer"
  },
  "app_store_installed_failed_error": {
    "en": "Application installation failed",
    "fr": "L'installation de l'application a échoué"
  },
  "app_store_installed_error_access_denied": {
    "en": "Access denied to application, user did not authorize",
    "fr": "Accès refusé à l'application, l'utilisateur n'a pas autorisé"
  },
  "app_store_installation_message_description": {
    "en": "You will be redirected to the application store after the installation",
    "fr": "Vous serez redirigé vers le magasin d'applications après l'installation"
  },
  "app_store_installation_message": {
    "en": "Installing application in Civalgo",
    "fr": "Installation de l'application dans Civalgo"
  },
  "app_store_is_installed_label": {
    "en": "Installed",
    "fr": "Installée"
  },
  "app_store_installed_success": {
    "en": "Application installed successfully",
    "fr": "Application installée avec succès"
  },
  "app_store_permissions_label": {
    "en": "Permissions",
    "fr": "Permissions"
  },
  "dailylog_activities_budgeted": {
    "en": "Budgeted",
    "fr": "Budgété"
  },
  "dailylog_activities_goal": {
    "en": "Qty goal",
    "fr": "Objectif Qté"
  },
  "dailylog_activities_actual": {
    "en": "Actual Qty",
    "fr": "Qté actuelle"
  },
  "dailylog_activities_activitybudget": {
    "en": "Activity Budget",
    "fr": "Budget d'activité"
  },
  "dailylog_activities_removeactivity": {
    "en": "Remove this activity",
    "fr": "Enlever cette activité"
  },
  "common_action_refresh": {
    "en": "Refresh",
    "fr": "Rafraîchir"
  },
  "common_action_refresh_tooltip": {
    "en": "This page is not real-time, refresh to have up-to-date data.",
    "fr": "Cette page n'est pas en temps réel, rafraichissez pour avoir les données à jour."
  },
  "common_action_refresh_state_now": {
    "en": "Now",
    "fr": "Maintenant"
  },
  "common_action_download_csv": {
    "en": "Export",
    "fr": "Exporter"
  },
  "common_action_print": {
    "en": "Print",
    "fr": "Imprimer"
  },
  "productactivitytooltip_nobudgetdata": {
    "en": "No budget data for this activity.",
    "fr": "Aucune donnée budgétaire pour cette activité"
  },
  "common_action_load_more": {
    "en": "Load more",
    "fr": "Charger plus"
  },
  "dailylog_report_filename": {
    "en": "dailylog",
    "fr": "rapport_journalier"
  },
  "reports_daily_logs": {
    "en": "Reports by Daily Logs",
    "fr": "Rapports par rapport journaliers"
  },
  "reports_projects": {
    "en": "Reports by Projects",
    "fr": "Rapports par projets"
  },
  "activity_feed_title": {
    "en": "Activity Feed",
    "fr": "Fil d'activités"
  },
  "activity_feed_refresh_button": {
    "en": "Refresh feed",
    "fr": "Rafraîchir le fil"
  },
  "activity_feed_error_configuration": {
    "en": "There was an error while configuring the activity feed.",
    "fr": "Il y a eu une erreur lors de la configuration du fil d'activités"
  },
  "activity_feed_mark_all_as_read": {
    "en": "Mark all as read",
    "fr": "Tout marquer comme lu"
  },
  "activity_feed_show_unread_activities": {
    "en": "Unread only",
    "fr": "Seulement les non lues"
  },
  "activity_feed_search": {
    "en": "Search activities",
    "fr": "Rechercher des activités"
  },
  "activity_feed_no_activities_to_display": {
    "en": "No activities to display",
    "fr": "Aucune activité à afficher"
  },
  "activity_feed_end_of_feed": {
    "en": "End of the feed",
    "fr": "Fin du fil"
  },
  "activity_feed_new_activities": {
    "en": "You have new activities!",
    "fr": "Vous avez de nouvelles activités!"
  },
  "standard_unit_type_all": {
    "en": "All",
    "fr": "Tous"
  },
  "standard_unit_type_metric": {
    "en": "Metric",
    "fr": "Métrique"
  },
  "standard_unit_type_imperial": {
    "en": "Imperial",
    "fr": "Impérial"
  },
  "standard_unit_category_area": {
    "en": "Area",
    "fr": "Aire"
  },
  "standard_unit_category_length": {
    "en": "Length",
    "fr": "Longueur"
  },
  "standard_unit_category_volume": {
    "en": "Volume",
    "fr": "Volume"
  },
  "standard_unit_category_unit": {
    "en": "Unit",
    "fr": "Unité"
  },
  "standard_unit_category_weight": {
    "en": "Weight",
    "fr": "Poids"
  },
  "standard_unit_category_energy": {
    "en": "Energy",
    "fr": "Énergie"
  },
  "standard_unit_category_time": {
    "en": "Time",
    "fr": "Temps"
  },
  "standard_unit_category_power": {
    "en": "Power",
    "fr": "Puissance"
  },
  "standard_unit_name_km": {
    "en": "Kilometer",
    "fr": "Kilomètre"
  },
  "standard_unit_name_m": {
    "en": "Meter",
    "fr": "Mètre"
  },
  "standard_unit_name_mm": {
    "en": "Millimeter",
    "fr": "Millimètre"
  },
  "standard_unit_name_mi": {
    "en": "Mile",
    "fr": "Mile"
  },
  "standard_unit_name_yd": {
    "en": "Yard",
    "fr": "Verge"
  },
  "standard_unit_name_ft": {
    "en": "Foot",
    "fr": "Pied"
  },
  "standard_unit_name_in": {
    "en": "Inch",
    "fr": "Pouce"
  },
  "standard_unit_name_km2": {
    "en": "Square kilometer",
    "fr": "Kilomètre carré"
  },
  "standard_unit_name_m2": {
    "en": "Square meter",
    "fr": "Mètre carré"
  },
  "standard_unit_name_ha": {
    "en": "Hectare",
    "fr": "Hectare"
  },
  "standard_unit_name_mm2": {
    "en": "Square millimeter",
    "fr": "Millimètre carré"
  },
  "standard_unit_name_mi2": {
    "en": "Square mile",
    "fr": "Mile carré"
  },
  "standard_unit_name_ac": {
    "en": "Acre",
    "fr": "Acre"
  },
  "standard_unit_name_yd2": {
    "en": "Square yard",
    "fr": "Verge carrée"
  },
  "standard_unit_name_ft2": {
    "en": "Square foot",
    "fr": "Pied carré"
  },
  "standard_unit_name_in2": {
    "en": "Square inch",
    "fr": "Pouce carré"
  },
  "standard_unit_name_lf": {
    "en": "Linear foot",
    "fr": "Pied linéaire"
  },
  "standard_unit_name_lm": {
    "en": "Linear meter",
    "fr": "Mètre linéaire"
  },
  "standard_unit_name_m3": {
    "en": "Cubic meter",
    "fr": "Mètre cube"
  },
  "standard_unit_name_l": {
    "en": "Liter",
    "fr": "Litre"
  },
  "standard_unit_name_cm3": {
    "en": "Cubic centimeter",
    "fr": "Centimètre cube"
  },
  "standard_unit_name_mm3": {
    "en": "Cubic millimeter",
    "fr": "Millimètre cube"
  },
  "standard_unit_name_af": {
    "en": "Acre foot",
    "fr": "Pied d'acre"
  },
  "standard_unit_name_yd3": {
    "en": "Cubic yard",
    "fr": "Verge cube"
  },
  "standard_unit_name_ft3": {
    "en": "Cubic foot",
    "fr": "Pied cube"
  },
  "standard_unit_name_in3": {
    "en": "Cubic inch",
    "fr": "Pouce cube"
  },
  "standard_unit_name_bf": {
    "en": "Board foot",
    "fr": "Pied planche"
  },
  "standard_unit_name_gal": {
    "en": "Gallon",
    "fr": "Gallon"
  },
  "standard_unit_name_t": {
    "en": "Metric ton",
    "fr": "Tonne métrique"
  },
  "standard_unit_name_kg": {
    "en": "Kilogram",
    "fr": "Kilogramme"
  },
  "standard_unit_name_g": {
    "en": "Gram",
    "fr": "Gramme"
  },
  "standard_unit_name_shortton": {
    "en": "Short ton",
    "fr": "Tonne courte"
  },
  "standard_unit_name_longton": {
    "en": "Long ton",
    "fr": "Longue tonne"
  },
  "standard_unit_name_lb": {
    "en": "Pound",
    "fr": "Livre"
  },
  "standard_unit_name_oz": {
    "en": "Ounce",
    "fr": "Once"
  },
  "standard_unit_name_kwh": {
    "en": "Kilowatt hour",
    "fr": "Kilowatt heure"
  },
  "standard_unit_name_mj": {
    "en": "Megajoule",
    "fr": "Mégajoule"
  },
  "standard_unit_name_btu": {
    "en": "British thermal unit",
    "fr": "Unité thermale britannique"
  },
  "standard_unit_name_minute": {
    "en": "Minute",
    "fr": "Minute"
  },
  "standard_unit_name_hour": {
    "en": "Hour",
    "fr": "Heure"
  },
  "standard_unit_name_day": {
    "en": "Day",
    "fr": "Jour"
  },
  "standard_unit_name_week": {
    "en": "Week",
    "fr": "Semaine"
  },
  "standard_unit_name_month": {
    "en": "Month",
    "fr": "Mois"
  },
  "standard_unit_name_manhour": {
    "en": "Man hour",
    "fr": "Heure homme"
  },
  "standard_unit_name_manday": {
    "en": "Man day",
    "fr": "Jour homme"
  },
  "standard_unit_name_manweek": {
    "en": "Man week",
    "fr": "Semaine homme"
  },
  "standard_unit_name_manmonth": {
    "en": "Man month",
    "fr": "Mois homme"
  },
  "standard_unit_name_each": {
    "en": "Each",
    "fr": "Chaque"
  },
  "standard_unit_name_pt": {
    "en": "Part",
    "fr": "Pièce"
  },
  "standard_unit_name_floor": {
    "en": "Floor",
    "fr": "Étage"
  },
  "standard_unit_abbreviation_km": {
    "en": "km",
    "fr": "km"
  },
  "standard_unit_abbreviation_m": {
    "en": "m",
    "fr": "m"
  },
  "standard_unit_abbreviation_mm": {
    "en": "mm",
    "fr": "mm"
  },
  "standard_unit_abbreviation_mi": {
    "en": "mi",
    "fr": "mi"
  },
  "standard_unit_abbreviation_yd": {
    "en": "yd",
    "fr": "vg"
  },
  "standard_unit_abbreviation_ft": {
    "en": "ft",
    "fr": "pi"
  },
  "standard_unit_abbreviation_in": {
    "en": "in",
    "fr": "po"
  },
  "standard_unit_abbreviation_km2": {
    "en": "km²",
    "fr": "km²"
  },
  "standard_unit_abbreviation_m2": {
    "en": "m²",
    "fr": "m²"
  },
  "standard_unit_abbreviation_ha": {
    "en": "ha",
    "fr": "ha"
  },
  "standard_unit_abbreviation_mm2": {
    "en": "mm²",
    "fr": "mm²"
  },
  "standard_unit_abbreviation_mi2": {
    "en": "mi²",
    "fr": "mi²"
  },
  "standard_unit_abbreviation_ac": {
    "en": "ac",
    "fr": "ac"
  },
  "standard_unit_abbreviation_yd2": {
    "en": "yd²",
    "fr": "vg²"
  },
  "standard_unit_abbreviation_ft2": {
    "en": "ft²",
    "fr": "pi²"
  },
  "standard_unit_abbreviation_in2": {
    "en": "in²",
    "fr": "po²"
  },
  "standard_unit_abbreviation_lf": {
    "en": "lf",
    "fr": "pi lin."
  },
  "standard_unit_abbreviation_lm": {
    "en": "lm",
    "fr": "m lin."
  },
  "standard_unit_abbreviation_m3": {
    "en": "m³",
    "fr": "m³"
  },
  "standard_unit_abbreviation_l": {
    "en": "L",
    "fr": "L"
  },
  "standard_unit_abbreviation_cm3": {
    "en": "cm³",
    "fr": "cm³"
  },
  "standard_unit_abbreviation_mm3": {
    "en": "mm³",
    "fr": "mm³"
  },
  "standard_unit_abbreviation_af": {
    "en": "AF",
    "fr": "PA"
  },
  "standard_unit_abbreviation_yd3": {
    "en": "yd³",
    "fr": "vg³"
  },
  "standard_unit_abbreviation_ft3": {
    "en": "ft³",
    "fr": "pi³"
  },
  "standard_unit_abbreviation_in3": {
    "en": "in³",
    "fr": "po³"
  },
  "standard_unit_abbreviation_bf": {
    "en": "BF",
    "fr": "pmp"
  },
  "standard_unit_abbreviation_gal": {
    "en": "gal",
    "fr": "gal"
  },
  "standard_unit_abbreviation_t": {
    "en": "t",
    "fr": "t"
  },
  "standard_unit_abbreviation_kg": {
    "en": "kg",
    "fr": "kg"
  },
  "standard_unit_abbreviation_g": {
    "en": "g",
    "fr": "g"
  },
  "standard_unit_abbreviation_shortton": {
    "en": "sh t",
    "fr": "t. courte"
  },
  "standard_unit_abbreviation_longton": {
    "en": "lg t",
    "fr": "longue t."
  },
  "standard_unit_abbreviation_lb": {
    "en": "lb",
    "fr": "lb"
  },
  "standard_unit_abbreviation_oz": {
    "en": "oz",
    "fr": "oz"
  },
  "standard_unit_abbreviation_kwh": {
    "en": "kWh",
    "fr": "kWh"
  },
  "standard_unit_abbreviation_mj": {
    "en": "MJ",
    "fr": "MJ"
  },
  "standard_unit_abbreviation_btu": {
    "en": "BTU",
    "fr": "BTU"
  },
  "standard_unit_abbreviation_minute": {
    "en": "minute",
    "fr": "minute"
  },
  "standard_unit_abbreviation_hour": {
    "en": "hour",
    "fr": "heure"
  },
  "standard_unit_abbreviation_day": {
    "en": "day",
    "fr": "jr"
  },
  "standard_unit_abbreviation_week": {
    "en": "week",
    "fr": "sem"
  },
  "standard_unit_abbreviation_month": {
    "en": "month",
    "fr": "mois"
  },
  "standard_unit_abbreviation_manhour": {
    "en": "m-hour",
    "fr": "heure-h"
  },
  "standard_unit_abbreviation_manday": {
    "en": "m-day",
    "fr": "jour-h"
  },
  "standard_unit_abbreviation_manweek": {
    "en": "m-week",
    "fr": "semaine-h"
  },
  "standard_unit_abbreviation_manmonth": {
    "en": "m-month",
    "fr": "mois-h"
  },
  "standard_unit_abbreviation_each": {
    "en": "ea.",
    "fr": "chq"
  },
  "standard_unit_abbreviation_pt": {
    "en": "pt",
    "fr": "pc"
  },
  "standard_unit_abbreviation_floor": {
    "en": "floor",
    "fr": "étage"
  },
  "gantt_settings": {
    "en": "Settings",
    "fr": "Paramètres"
  },
  "gantt_ui_settings": {
    "en": "Interface size",
    "fr": "Taille de l'interface "
  },
  "gantt_ui_settings_small": {
    "en": "Small",
    "fr": "Petit"
  },
  "gantt_ui_settings_medium": {
    "en": "Medium",
    "fr": "Moyen"
  },
  "gantt_ui_settings_large": {
    "en": "Large",
    "fr": "Grand"
  },
  "gantt_dependencies_enabled": {
    "en": "Draw dependencies",
    "fr": "Dessiner les dépendances"
  },
  "gantt_task_labels_enabled": {
    "en": "Task labels",
    "fr": "Étiquettes de tâches"
  },
  "gantt_project_lines_enabled": {
    "en": "Project lines",
    "fr": "Lignes de projet"
  },
  "gantt_non_working_days_enabled": {
    "en": "Highlight non working days",
    "fr": "Mettre en évidence les jours non ouvrables"
  },
  "gantt_rollups_enabled": {
    "en": "Show rollups",
    "fr": "Afficher les cumulatifs"
  },
  "gantt_progress_line_enabled": {
    "en": "Show progress line",
    "fr": "Afficher la ligne de progrès"
  },
  "gantt_critical_path_enabled": {
    "en": "Critical path",
    "fr": "Chemin critique"
  },
  "gantt_baseline_enabled": {
    "en": "Baseline",
    "fr": "Référence de base"
  },
  "gantt_indicators_enabled": {
    "en": "Indicators",
    "fr": "Indicateurs"
  },
  "gantt_time_ranges_enabled": {
    "en": "Show time ranges",
    "fr": "Afficher les interval de temps"
  },
  "gantt_import_msp_warning": {
    "en": "If you leave this page without confiming, your project will not be imported.",
    "fr": "Si vous quittez la page sans confirmer, votre projet ne seras pas importé"
  },
  "gantt_import_msp": {
    "en": "Import From MS Project",
    "fr": "Importer De MS Project "
  },
  "gantt_export_msp": {
    "en": "Export To MS Project",
    "fr": "Exporter Vers MS Project"
  },
  "gantt_export_excel": {
    "en": "Export To Excel",
    "fr": "Exporter Vers Excel"
  },
  "gantt_project_import_error": {
    "en": "MS Project import failed",
    "fr": "L'importation du MS Project a échoué"
  },
  "gantt_importing_ms_project": {
    "en": "Importing project ...",
    "fr": "Importation du project en cours ..."
  },
  "gantt_importing_ms_project_error": {
    "en": "MS Project structure seems to not be valid with Civalgo project structure",
    "fr": "La structure MS Project ne semble pas être compatible avec Civalgo"
  },
  "gantt_importing_ms_project_error_desc": {
    "en": "Error while processing MS Project file, verify project structure and type before importing and try again",
    "fr": "Une erreure est survenue lors de l'importation, veuillez vérifier la structure du projet et le type du fichier et esseyez à nouveau"
  },
  "gantt_task_name": {
    "en": "Name",
    "fr": "Nom"
  },
  "gantt_task_duration": {
    "en": "Planned Duration",
    "fr": "Durée planifiée"
  },
  "gantt_task_start_end": {
    "en": "Start/End",
    "fr": "Début/Fin"
  },
  "gantt_task_dates": {
    "en": "Dates",
    "fr": "Dates"
  },
  "gantt_task_edit_informations": {
    "en": "Informations",
    "fr": "Informations"
  },
  "gantt_task_start": {
    "en": "Planned Start",
    "fr": "Début planifié"
  },
  "gantt_task_end": {
    "en": "Planned End",
    "fr": "Fin planifiée"
  },
  "gantt_task_actual_start": {
    "en": "Actual Start",
    "fr": "Début réel"
  },
  "gantt_task_actual_end": {
    "en": "Actual End",
    "fr": "Fin réelle"
  },
  "gantt_task_actual_duration": {
    "en": "Actual Duration",
    "fr": "Durée réelle"
  },
  "gantt_task_progress": {
    "en": "Progress",
    "fr": "Progrès"
  },
  "gantt_task_early_start_date": {
    "en": "Early Start",
    "fr": "Début au plus tôt"
  },
  "gantt_task_early_end_date": {
    "en": "Early Finish",
    "fr": "Fin au plus tôt"
  },
  "gantt_task_late_start_date": {
    "en": "Late Start",
    "fr": "Début au plus tard"
  },
  "gantt_task_late_end_date": {
    "en": "Late Finish",
    "fr": "Fin au plus tard"
  },
  "gantt_task_total_slack": {
    "en": "Total Slack",
    "fr": "Marge totale"
  },
  "gantt_task_days_completed": {
    "en": "Days Completed",
    "fr": "Jours terminés"
  },
  "gantt_task_constraint": {
    "en": "Constraint",
    "fr": "Contrainte"
  },
  "gantt_task_constraint_date": {
    "en": "Constraint Date",
    "fr": "Date de la contrainte"
  },
  "gantt_task_constraint_type": {
    "en": "Constraint Type",
    "fr": "Type de contrainte"
  },
  "gantt_task_constraint_type_none": {
    "en": "None",
    "fr": "Aucune"
  },
  "gantt_task_constraint_type_muststarton": {
    "en": "Must start on",
    "fr": "Doit commencer le"
  },
  "gantt_task_constraint_type_mustfinishon": {
    "en": "Must finish on",
    "fr": "Doit finir le"
  },
  "gantt_task_constraint_type_startnoearlierthan": {
    "en": "Start no earlier than",
    "fr": "Début au plus tôt le"
  },
  "gantt_task_constraint_type_startnolaterthan": {
    "en": "Start no later than",
    "fr": "Début au plus tard le"
  },
  "gantt_task_constraint_type_finishnoearlierthan": {
    "en": "Finish no earlier than",
    "fr": "Fin au plus tôt le"
  },
  "gantt_task_constraint_type_finishnolaterthan": {
    "en": "Finish no later than",
    "fr": "Fin au plus tard le"
  },
  "gantt_task_note": {
    "en": "Note",
    "fr": "Note"
  },
  "gantt_task_successors": {
    "en": "Successors",
    "fr": "Successeurs"
  },
  "gantt_task_predecessors": {
    "en": "Predecessors",
    "fr": "Prédécesseurs"
  },
  "gantt_task_successor": {
    "en": "Successor",
    "fr": "Successeur"
  },
  "gantt_task_predecessor": {
    "en": "Predecessor",
    "fr": "Prédécesseur"
  },
  "gantt_task_dependency_id": {
    "en": "ID",
    "fr": "ID"
  },
  "gantt_task_dependency_name": {
    "en": "Name",
    "fr": "Nom"
  },
  "gantt_task_dependency_type": {
    "en": "Type",
    "fr": "Type"
  },
  "gantt_task_dependency_type_start_to_start": {
    "en": "Start-to-Start",
    "fr": "Début-à-Début"
  },
  "gantt_task_dependency_type_start_to_end": {
    "en": "Start-to-Finish",
    "fr": "Début-à-Fin"
  },
  "gantt_task_dependency_type_end_to_start": {
    "en": "Finish-to-Start",
    "fr": "Fin-à-Début"
  },
  "gantt_task_dependency_type_end_to_end": {
    "en": "Finish-to-Finish",
    "fr": "Fin-à-Fin"
  },
  "gantt_task_dependency_type_start_to_start_abv": {
    "en": "SS",
    "fr": "DD"
  },
  "gantt_task_dependency_type_start_to_end_abv": {
    "en": "SF",
    "fr": "DF"
  },
  "gantt_task_dependency_type_end_to_start_abv": {
    "en": "FS",
    "fr": "FD"
  },
  "gantt_task_dependency_type_end_to_end_abv": {
    "en": "FF",
    "fr": "FF"
  },
  "gantt_task_dependency_from": {
    "en": "From",
    "fr": "De"
  },
  "gantt_task_dependency_to": {
    "en": "To",
    "fr": "À"
  },
  "gantt_task_dependency_lag": {
    "en": "Lag",
    "fr": "Décalage"
  },
  "gantt_task_calendar": {
    "en": "Calendar",
    "fr": "Calendrier"
  },
  "gantt_task_effort": {
    "en": "Effort",
    "fr": "Effort"
  },
  "gantt_task_deadline": {
    "en": "Deadline",
    "fr": "Date limite"
  },
  "gantt_task_manually_scheduled": {
    "en": "Manually scheduled",
    "fr": "Planifié manuellement"
  },
  "gantt_task_rollup": {
    "en": "Rollup",
    "fr": "Cumuler"
  },
  "gantt_task_scheduling_mode": {
    "en": "Scheduling mode",
    "fr": "Mode de planification"
  },
  "gantt_task_scheduling_mode_normal": {
    "en": "Normal",
    "fr": "Normal"
  },
  "gantt_task_scheduling_mode_fixed_duration": {
    "en": "Fixed Duration",
    "fr": "Durée fixe"
  },
  "gantt_task_scheduling_mode_fixed_units": {
    "en": "Fixed Units",
    "fr": "Unités fixes"
  },
  "gantt_task_scheduling_mode_fixed_effort": {
    "en": "Fixed Effort",
    "fr": "Effort fixe"
  },
  "gantt_task_effort_driven": {
    "en": "Effort driven",
    "fr": "Poussé par l'effort"
  },
  "gantt_task_general": {
    "en": "General",
    "fr": "Général"
  },
  "gantt_actual": {
    "en": "Actual",
    "fr": "Réel"
  },
  "gantt_add": {
    "en": "Add...",
    "fr": "Ajouter..."
  },
  "gantt_undo": {
    "en": "Undo",
    "fr": "Annuler"
  },
  "gantt_redo": {
    "en": "Redo",
    "fr": "Restaurer"
  },
  "gantt_expand_all": {
    "en": "Expand all",
    "fr": "Ouvrir tout"
  },
  "gantt_collapse_all": {
    "en": "Collapse all",
    "fr": "Réduire tout"
  },
  "gantt_zoom_in": {
    "en": "Zoom in",
    "fr": "Zoomer"
  },
  "gantt_zoom_out": {
    "en": "Zoom out",
    "fr": "Dézoomer"
  },
  "gantt_zoom_to_fit": {
    "en": "Zoom to fit",
    "fr": "Ajuster le zoom"
  },
  "gantt_next_time_span": {
    "en": "Next time span",
    "fr": "Période suivante"
  },
  "gantt_prev_time_span": {
    "en": "Previous time span",
    "fr": "Période précédente"
  },
  "gantt_clear_schedule": {
    "en": "Clear schedule",
    "fr": "Effacer la cédule"
  },
  "gantt_no_rows": {
    "en": "No data",
    "fr": "Aucune donnée"
  },
  "gantt_columns": {
    "en": "Columns",
    "fr": "Colonnes"
  },
  "gantt_hide_column": {
    "en": "Hide column",
    "fr": "Cacher la colonne"
  },
  "gantt_sort_desc": {
    "en": "Sort descending",
    "fr": "Tri décroissant"
  },
  "gantt_sort_asc": {
    "en": "Sort ascending",
    "fr": "Tri croissant"
  },
  "gantt_multi_sort": {
    "en": "Multi-sort",
    "fr": "Multi-tri"
  },
  "gantt_add_sort_asc": {
    "en": "Add sort ascending",
    "fr": "Ajouter tri croissant"
  },
  "gantt_add_sort_desc": {
    "en": "Add sort descending",
    "fr": "Ajouter tri décroissant"
  },
  "gantt_remove_sorter": {
    "en": "Remove sorter",
    "fr": "Supprimer le trieur"
  },
  "gantt_zoom": {
    "en": "Zoom",
    "fr": "Zoom"
  },
  "gantt_date_range": {
    "en": "Date range",
    "fr": "Plage de dates"
  },
  "gantt_date_range_start": {
    "en": "Start date",
    "fr": "Date du début"
  },
  "gantt_date_range_end": {
    "en": "End date",
    "fr": "Date de fin"
  },
  "gantt_date_range_today": {
    "en": "Today",
    "fr": "Aujourd'hui"
  },
  "gantt_filter_tasks": {
    "en": "Filter tasks",
    "fr": "Filtrer les tâches"
  },
  "gantt_filter_tasks_by_name": {
    "en": "By name",
    "fr": "Par nom"
  },
  "gantt_project_start": {
    "en": "Project Start",
    "fr": "Début du projet"
  },
  "gantt_project_end": {
    "en": "Project End",
    "fr": "Fin du projet"
  },
  "gantt_advanced": {
    "en": "Advanced",
    "fr": "Avancé"
  },
  "gantt_edit": {
    "en": "Edit",
    "fr": "Modifier"
  },
  "gantt_delete_task": {
    "en": "Delete",
    "fr": "Supprimer"
  },
  "gantt_add_time_range": {
    "en": "Add time range",
    "fr": "Ajouter un interval de temps"
  },
  "gantt_time_range_name": {
    "en": "Name",
    "fr": "Nom"
  },
  "gantt_time_range_start": {
    "en": "Start",
    "fr": "Début"
  },
  "gantt_time_range_duration": {
    "en": "Duration",
    "fr": "Durée"
  },
  "gantt_time_range_now": {
    "en": "Now",
    "fr": "Maintenant"
  },
  "gantt_time_range_show_now": {
    "en": "Show current date",
    "fr": "Afficher la date actuelle"
  },
  "gantt_indicators_early_start_end": {
    "en": "Early start/end",
    "fr": "Début/Fin au plus tôt"
  },
  "gantt_indicators_late_start_end": {
    "en": "Late start/end",
    "fr": "Début/Fin au plus tard"
  },
  "gantt_copy_record": {
    "en": "Copy record",
    "fr": "Copier la ligne"
  },
  "gantt_cut_record": {
    "en": "Cut record",
    "fr": "Couper la ligne"
  },
  "gantt_remove_row": {
    "en": "Remove record",
    "fr": "Supprimer la ligne"
  },
  "gantt_remove": {
    "en": "Delete...",
    "fr": "Supprimer..."
  },
  "gantt_remove_current_record": {
    "en": "Delete this task only",
    "fr": "Supprimer cette tâche seulement"
  },
  "gantt_remove_current_record_and_children": {
    "en": "Delete this task and children",
    "fr": "Supprimer cette tâche et ses enfants"
  },
  "standard_unit_name": {
    "en": "Name",
    "fr": "Nom"
  },
  "standard_unit_abbreviation": {
    "en": "Abbreviation",
    "fr": "Abréviation"
  },
  "standard_unit_measurement_type": {
    "en": "Measurement Type",
    "fr": "Type de mesure"
  },
  "standard_unit_measurement_system": {
    "en": "Measurement System",
    "fr": "Système de mesure"
  },
  "standard_unit_base_unit": {
    "en": "Base Unit",
    "fr": "Unité de base"
  },
  "standard_unit_conversion_factor": {
    "en": "Conversion Factor",
    "fr": "Facteur de conversion"
  },
  "standard_unit_add_dialog_title": {
    "en": "Add new unit",
    "fr": "Ajouter une nouvelle unité"
  },
  "standard_unit_edit_dialog_title": {
    "en": "Edit new unit",
    "fr": "Modifier une unité"
  },
  "services_standard_unit_insert_success": {
    "en": "Standard unit created successfully",
    "fr": "Unité créée avec succès"
  },
  "services_standard_unit_insert_error": {
    "en": "Sorry, we had an error with the standard unit creation",
    "fr": "Désolé, une erreur est survenue pendant la création de l'unité"
  },
  "services_standard_unit_update_success": {
    "en": "Standard unit updated successfully",
    "fr": "Unité modifiée avec succès"
  },
  "services_standard_unit_update_error": {
    "en": "Sorry, we had an error with the standard unit update",
    "fr": "Désolé, une erreur est survenue pendant la modification de l'unité"
  },
  "services_standard_unit_delete_success": {
    "en": "Standard unit deleted successfully",
    "fr": "Unité supprimée avec succès"
  },
  "services_standard_unit_delete_error": {
    "en": "Sorry, we had an error with the standard unit deletion",
    "fr": "Désolé, une erreur est survenue pendant la suppression de l'unité"
  },
  "services_standard_unit_restore_success": {
    "en": "Standard unit restored successfully",
    "fr": "Unité restorée avec succès"
  },
  "services_standard_unit_restore_error": {
    "en": "Sorry, we had an error with the standard unit restoration",
    "fr": "Désolé, une erreur est survenue pendant la restoration de l'unité"
  },
  "general array_min_1": {
    "en": "Field must have at least one item",
    "fr": "Le champ doit contenir au moins un élément"
  },
  "common_action_delay": {
    "en": "Delay",
    "fr": "Retard"
  },
  "import_unique_error_standardunit": {
    "en": "This unit is not in the system",
    "fr": "Cette unité n'existe pas dans le système"
  },
  "timeline_add_project_to_daily_log_button": {
    "en": "Add this project to an existing daily journal on this day",
    "fr": "Ajouter ce projet à un rapport journalier existant ce jour-ci"
  },
  "weather_dailyLogId array_min_1": {
    "en": "You need to provide at least 1 daily log",
    "fr": "Vous devez fournir au moins 1 rapport journalier"
  },
  "weather_fetch_error": {
    "en": "Sorry we had an error in retrieving weather",
    "fr": "Désolé, une erreur est survenue pendant la lecture de la température"
  },
  "weather_code_395": {
    "en": "Moderate or heavy snow in area with thunder",
    "fr": "Neige modérée ou abondante avec tonnerre"
  },
  "weather_code_392": {
    "en": "Patchy light snow in area with thunder",
    "fr": "Neige faible et inégale avec tonnerre"
  },
  "weather_code_389": {
    "en": "Moderate or heavy rain in area with thunder",
    "fr": "Pluie modérée ou abondante avec tonnerre"
  },
  "weather_code_386": {
    "en": "Patchy light rain in area with thunder",
    "fr": "Pluie faible et inégale avec tonnerre"
  },
  "weather_code_377": {
    "en": "Moderate or heavy showers of ice pellets",
    "fr": "Averses modérées ou abondantes de grêle"
  },
  "weather_code_374": {
    "en": "Light showers of ice pellets",
    "fr": "Faibles averses de grêle"
  },
  "weather_code_371": {
    "en": "Moderate or heavy snow showers",
    "fr": "Averses de neige modérées ou abondantes"
  },
  "weather_code_368": {
    "en": "Light snow showers",
    "fr": "Faibles averses de neige"
  },
  "weather_code_365": {
    "en": "Moderate or heavy sleet showers",
    "fr": "Averses modérées ou abondantes de grésil"
  },
  "weather_code_362": {
    "en": "Light sleet showers",
    "fr": "Faibles averses de grésil"
  },
  "weather_code_359": {
    "en": "Torrential rain shower",
    "fr": "Averses de pluie torrentielle"
  },
  "weather_code_356": {
    "en": "Moderate or heavy rain shower",
    "fr": "Averses de pluie modérées ou abondantes"
  },
  "weather_code_353": {
    "en": "Light rain shower",
    "fr": "Faibles averses de pluie"
  },
  "weather_code_350": {
    "en": "Ice pellets",
    "fr": "Grêle"
  },
  "weather_code_338": {
    "en": "Heavy snow",
    "fr": "Neige abondante"
  },
  "weather_code_335": {
    "en": "Patchy heavy snow",
    "fr": "Neige abondante et inégale"
  },
  "weather_code_332": {
    "en": "Moderate snow",
    "fr": "Neige modérée"
  },
  "weather_code_329": {
    "en": "Patchy moderate snow",
    "fr": "Neige modérée et inégale"
  },
  "weather_code_326": {
    "en": "Light snow",
    "fr": "Neige failble"
  },
  "weather_code_323": {
    "en": "Patchy light snow",
    "fr": "Neige faible et inégale"
  },
  "weather_code_320": {
    "en": "Moderate or heavy sleet",
    "fr": "Grésil modéré ou abondant"
  },
  "weather_code_317": {
    "en": "Light sleet",
    "fr": "Grésil faible"
  },
  "weather_code_314": {
    "en": "Moderate or Heavy freezing rain",
    "fr": "Pluie verglaçante modérée ou abondante"
  },
  "weather_code_311": {
    "en": "Light freezing rain",
    "fr": "Pluie verglaçante faible"
  },
  "weather_code_308": {
    "en": "Heavy rain",
    "fr": "Pluie abondante"
  },
  "weather_code_305": {
    "en": "Heavy rain at times",
    "fr": "Pluie abondante par moment"
  },
  "weather_code_302": {
    "en": "Moderate rain",
    "fr": "Pluie modérée"
  },
  "weather_code_299": {
    "en": "Moderate rain at times",
    "fr": "Pluie modérée par moment"
  },
  "weather_code_296": {
    "en": "Light rain",
    "fr": "Pluie faible"
  },
  "weather_code_293": {
    "en": "Patchy light rain",
    "fr": "Pluie faible et inégale"
  },
  "weather_code_284": {
    "en": "Heavy freezing drizzle",
    "fr": "Bruine verglaçante abondante"
  },
  "weather_code_281": {
    "en": "Freezing drizzle",
    "fr": "Bruine verglaçante"
  },
  "weather_code_266": {
    "en": "Light drizzle",
    "fr": "Bruine faible"
  },
  "weather_code_263": {
    "en": "Patchy light drizzle",
    "fr": "Bruine faible et inégale"
  },
  "weather_code_260": {
    "en": "Freezing fog",
    "fr": "Brouillard glacial"
  },
  "weather_code_248": {
    "en": "Fog",
    "fr": "Brouillard"
  },
  "weather_code_230": {
    "en": "Blizzard",
    "fr": "Tempête de neige"
  },
  "weather_code_227": {
    "en": "Blowing snow",
    "fr": "Poudrerie"
  },
  "weather_code_200": {
    "en": "Thundery outbreaks in nearby",
    "fr": "Éclosions orageuses à proximité"
  },
  "weather_code_185": {
    "en": "Patchy freezing drizzle nearby",
    "fr": "Bruine verglaçante inégale à proximité"
  },
  "weather_code_182": {
    "en": "Patchy sleet nearby",
    "fr": "Grésil inégal à proximité"
  },
  "weather_code_179": {
    "en": "Patchy snow nearby",
    "fr": "Neige inégale à proximité"
  },
  "weather_code_176": {
    "en": "Patchy rain nearby",
    "fr": "Pluie inégale à proximité"
  },
  "weather_code_143": {
    "en": "Mist",
    "fr": "Brume"
  },
  "weather_code_122": {
    "en": "Overcast",
    "fr": "Couvert"
  },
  "weather_code_119": {
    "en": "Cloudy",
    "fr": "Nuageux"
  },
  "weather_code_116": {
    "en": "Partly Cloudy",
    "fr": "Partiellement nuageux"
  },
  "weather_code_113": {
    "en": "Clear/Sunny",
    "fr": "Ensoleillé"
  },
  "weather_api_error": {
    "en": "Sorry something went wrong on updating weather",
    "fr": "Désolé, une erreur est survenue pendant la mise à jour de la température"
  },
  "timeline_tooltip_delay_occured": {
    "en": "Delay occured",
    "fr": "Un retard est survenu"
  },
  "timeline_tooltip_delay_activities_there_were": {
    "en": "There were ",
    "fr": "Il y a"
  },
  "timeline_tooltip_delay_reason": {
    "en": "because of the ",
    "fr": "à cause de"
  },
  "timeline_tooltip_delay_activities": {
    "en": "activities",
    "fr": "activités"
  },
  "timeline_tooltip_delay_impacted": {
    "en": "impacted",
    "fr": "impactés"
  },
  "common_documentation": {
    "en": "Documentation",
    "fr": "Documentation"
  },
  "timeline_delay_dialog_delays": {
    "en": "Delays",
    "fr": "Retards"
  },
  "weather_shift_morning": {
    "en": "Morning",
    "fr": "Avant-midi"
  },
  "weather_shift_afternoon": {
    "en": "Afternoon",
    "fr": "Après-midi"
  },
  "weather_shift_evening": {
    "en": "Evening",
    "fr": "Soir"
  },
  "weather_shift_night": {
    "en": "Night",
    "fr": "Nuit"
  },
  "weather_validation": {
    "en": "Weather Validation",
    "fr": "Validation de la température"
  },
  "weather_todays_forecast_for": {
    "en": "Forecast for",
    "fr": "Prévision pour"
  },
  "weather_hourly_forecast": {
    "en": "Hourly Forecast",
    "fr": "Prévision par heure"
  },
  "weather_mean_wind_speed": {
    "en": "Mean Wind Speed",
    "fr": "Vitesse du vent"
  },
  "weather_wind_direction": {
    "en": "Wind Direction",
    "fr": "Direction du vent"
  },
  "weather_minimum_temperature": {
    "en": "Minimum Temperature",
    "fr": "Température minimale"
  },
  "weather_maximum_temperature": {
    "en": "Maximum Temperature",
    "fr": "Température maximale"
  },
  "weather_cloud_cover": {
    "en": "Cloud Cover",
    "fr": "Couverture nuageuse"
  },
  "weather_sea_level_pressure": {
    "en": "Air pressure",
    "fr": "Pression atmosphérique"
  },
  "weather_sunrise_time": {
    "en": "Sunrise Time",
    "fr": "Lever du soleil"
  },
  "weather_sunset_time": {
    "en": "Sunset Time",
    "fr": "Coucher du soleil"
  },
  "weather_visibility": {
    "en": "Visibility",
    "fr": "Visibilité"
  },
  "weather_relative_humidity": {
    "en": "Relative Humidity",
    "fr": "Humidité"
  },
  "weather_validate": {
    "en": "Validate",
    "fr": "Valider"
  },
  "weather_more_details": {
    "en": "More Details",
    "fr": "Plus de détails"
  },
  "weather_shift_noon": {
    "en": "Noon",
    "fr": "Midi"
  },
  "weather_shift_pm": {
    "en": "PM",
    "fr": "Après-midi"
  },
  "timeline_delay_dialog_add_delay": {
    "en": "Add delay",
    "fr": "Signaler un retard"
  },
  "timeline_delayinfo_dialog_delay_info": {
    "en": "Delay info",
    "fr": "Information sur le retard"
  },
  "dailylog_deleted_team_warning": {
    "en": "This dailylog belongs to an archived team.",
    "fr": "Ce rapport journalier fait partie d'une équipe qui a été archivée."
  },
  "dailylog_deleted_project_warning": {
    "en": "This dailylog contains an archived project.",
    "fr": "Ce rapport journalier contient un projet qui a été archivé."
  },
  "timeline_delayinfo_dialog_project_delayed": {
    "en": "Project delayed",
    "fr": "Projet retardé"
  },
  "timeline_delayinfo_dialog_activities_delayed": {
    "en": "Activities delayed",
    "fr": "Activités retardées"
  },
  "timeline_delayinfo_dialog_who_detected_delay": {
    "en": "Who detected the delay",
    "fr": "Qui a constaté le retard?"
  },
  "timeline_delayinfo_dialog_when_delay_occured": {
    "en": "When delay occured?",
    "fr": "Quand le retard est-il survenu?"
  },
  "timeline_delayinfo_dialog_is_critical": {
    "en": "Is it a Critical delay?",
    "fr": "Est-ce un retard critique?"
  },
  "timeline_delayinfo_dialog_estimated_duration": {
    "en": "Estimated duration",
    "fr": "Durée estimée"
  },
  "timeline_delayinfo_dialog_delay_reason": {
    "en": "Reason",
    "fr": "Raison"
  },
  "timeline_delayinfo_dialog_is_delay_compensable": {
    "en": "Is delay Compensable?",
    "fr": "Le retard est-il indemnisable?"
  },
  "timeline_delayinfo_dialog_additional_info": {
    "en": "Additional information",
    "fr": "Informations supplémentaires"
  },
  "timeline_delay_dialog_critical": {
    "en": "Critical",
    "fr": "Critique"
  },
  "timeline_delay_dialog_estimated_hours": {
    "en": "Hours",
    "fr": "Heures"
  },
  "timeline_delay_dialog_estimated_days": {
    "en": "Days",
    "fr": "Jours"
  },
  "timeline_delay_dialog_estimated_hour": {
    "en": "Hour",
    "fr": "Heure"
  },
  "timeline_delay_dialog_estimated_day": {
    "en": "Day",
    "fr": "Jour"
  },
  "timeline_delayinfo_dialog_full_day": {
    "en": "It lasted all day",
    "fr": "Journée entière"
  },
  "weather_validation_confirm": {
    "en": "Validate",
    "fr": "Valider"
  },
  "weather_validation_error": {
    "en": "Validation Error",
    "fr": "Erreur de validation"
  },
  "weather_validation_success": {
    "en": "Weather validated successfully",
    "fr": "Météo confirmée avec succès"
  },
  "weather_validation_comment": {
    "en": "Additional information",
    "fr": "Informations supplémentaires"
  },
  "weather_validation_comment_placeholder": {
    "en": "Write additional information regarding the weather",
    "fr": "Mentionnez tout autre information pertinente au sujet de la météo"
  },
  "weather_validation_title": {
    "en": "Validate weather",
    "fr": "Valider la météo"
  },
  "weather_validation_allValidated": {
    "en": "All validated",
    "fr": "Tous validés"
  },
  "weather_validation_not_validated": {
    "en": "Not validated",
    "fr": "Non validé"
  },
  "weather_validation_disabled": {
    "en": "The weather cannot be validated when the daily log is completed",
    "fr": "La météo ne peut pas être validée lorsque le rapport journalier est validé"
  },
  "dailylog_delay_delete_success": {
    "en": "Delay deleted successfully",
    "fr": "Retard supprimé avec succès"
  },
  "dailylog_delay_delete_error": {
    "en": "Sorry, we had an error with the delay deletion",
    "fr": "Désolé, une erreur est survenue lors de la suppression du retard"
  },
  "dailylog_delay_delete_dialog_title": {
    "en": "Delete Delay",
    "fr": "Supprimer un retard"
  },
  "dailylog_delay_delete_dialog_text": {
    "en": "You are about to delete this delay. Are you sure you want to continue?",
    "fr": "Vous êtes sur le point de supprimer ce retard. Êtes-vous certain de vouloir continuer?"
  },
  "dailylog_delay_add_success": {
    "en": "Delay created successfully",
    "fr": "Retard créé avec succès"
  },
  "dailylog_delay_add_error": {
    "en": "Sorry, we had an error with the delay creation",
    "fr": "Désolé, une erreur est survenue lors de la création du retard"
  },
  "dailylog_delay_edit_success": {
    "en": "Delay updated successfully",
    "fr": "Retard mis à jour avec succès"
  },
  "dailylog_delay_edit_error": {
    "en": "Sorry, we had an error with the delay update",
    "fr": "Désolé, une erreur est survenue lors de la modification du retard"
  },
  "dailylog_delay_add_title": {
    "en": "Add Delay",
    "fr": "Signaler un retard"
  },
  "dailylog_delay_edit_title": {
    "en": "Edit Delay",
    "fr": "Modifier un retard signalé"
  },
  "dailylog_delay_project_delay": {
    "en": "Project Delayed",
    "fr": "Projet retardé"
  },
  "dailylog_delay_activities_delay": {
    "en": "Activities Delayed (optional)",
    "fr": "Activités retardées (optionnel)"
  },
  "dailylog_delay_add_activity": {
    "en": "Add activity",
    "fr": "Ajouté une activité"
  },
  "dailylog_delay_detect_delay": {
    "en": "Who detected the delay?",
    "fr": "Qui a détecté le retard?"
  },
  "dailylog_delay_when_occured": {
    "en": "When delay occured?",
    "fr": "Quand le retard est-il survenu?"
  },
  "dailylog_delay_full_day": {
    "en": "Full-Day",
    "fr": "Journée entière"
  },
  "dailylog_delay_critical_delay": {
    "en": "Critical Delay",
    "fr": "Retard critique"
  },
  "dailylog_delay_estimated_duration": {
    "en": "Estimated Duration",
    "fr": "Durée estimée"
  },
  "dailylog_delay_no_of_days": {
    "en": "No. of days",
    "fr": "Nombre de jours"
  },
  "dailylog_delay_no_of_hours": {
    "en": "No. of hours",
    "fr": "Nombre d'heures"
  },
  "dailylog_delay_reason": {
    "en": "Reason",
    "fr": "Raison"
  },
  "dailylog_delay_select_reason": {
    "en": "Select reason",
    "fr": "Selectionnez une raison"
  },
  "dailylog_delay_write_delay_reason": {
    "en": "Write the delay reason",
    "fr": "Écrivez la raison du retard"
  },
  "dailylog_delay_is_compensable": {
    "en": "Is delay compensable?",
    "fr": "Le retard est-il indemnisable?"
  },
  "dailylog_delay_additional_info": {
    "en": "Additional Information",
    "fr": "Informations supplémentaires"
  },
  "dailylog_delay_attach_file": {
    "en": "Attach File",
    "fr": "Joindre un fichier"
  },
  "dailylog_delay_days_validation_msg": {
    "en": "Days should be whole number",
    "fr": "Le nombre de jours doit être un nombre entier"
  },
  "dailylog_delay_hours_validation_msg": {
    "en": "Hours should be between 0 to 23",
    "fr": "Les nombre d'heure doit être entre 0 et 23"
  },
  "dailylog_delay_is_excusable_list": {
    "en": "Excusable Delays",
    "fr": "Retards excusables"
  },
  "dailylog_delay_not_excusable_list": {
    "en": "Non-excusable Delays",
    "fr": "Retards non excusables"
  },
  "project_delay_labor_strikes": {
    "en": "Labor strikes",
    "fr": "Grèves ouvrières"
  },
  "project_delay_natural_disasters": {
    "en": "Weather conditions, fires, floods, earthquakes, natural disasters",
    "fr": "Conditions météorologiques, incendies, inondations, tremblements de terre, catastrophes naturelles"
  },
  "project_delay_changes_by_owner": {
    "en": "Changes initiated by the owner",
    "fr": "Modifications initiées par le propriétaire"
  },
  "project_delay_changes_in_plans": {
    "en": "Errors and omissions in the plans, design docs and specifications",
    "fr": "Erreurs et omissions dans les plans, documents de conception et devis"
  },
  "project_delay_site_conditions": {
    "en": "Differing site conditions or concealed conditions",
    "fr": "Conditions de site différentes ou conditions cachées"
  },
  "project_delay_lack_of_action": {
    "en": "Lack of action by governmental or oversight bodies",
    "fr": "Absence d'action de la part des organismes gouvernementaux ou de contrôle"
  },
  "project_delay_outside_agency_intervention": {
    "en": "Intervention by outside agencies",
    "fr": "Intervention d'organismes extérieurs"
  },
  "project_delay_other": {
    "en": "Other",
    "fr": "Autre"
  },
  "project_delay_delayed_mobilization": {
    "en": "Delayed mobilization",
    "fr": "Mobilisation retardée"
  },
  "project_delay_delayed_submission": {
    "en": "Delayed submission of submittals",
    "fr": "Retard avec les soumissions"
  },
  "project_delay_overall_late_performance": {
    "en": "Overall late performance and execution",
    "fr": "Retard généralisé sur la performance et l'exécution"
  },
  "project_delay_subcontractors_late_performance": {
    "en": "Late performance of subcontractors",
    "fr": "Retard d'exécution des sous-traitants"
  },
  "project_delay_suppliers_late_performance": {
    "en": "Late performance by suppliers",
    "fr": "Retard d'exécution des fournisseurs"
  },
  "project_delay_faulty_workmanship": {
    "en": "Faulty workmanship by the contractor or subcontractor",
    "fr": "Faute dans le travail de l'entrepreneur ou du sous-traitant"
  },
  "project_delay_project_specific_labor_strike": {
    "en": "A project specific labor strike caused by either the contractor’s unwillingness to negotiate or by unfair job practices",
    "fr": "Une grève du travail spécifique à un projet causée soit par la réticence de l'entrepreneur à négocier, soit par des mauvaises pratiques de travail"
  },
  "weather_forecast_explanation": {
    "en": "Additional Information (Optional)",
    "fr": "Informations supplémentaires (optionnel)"
  },
  "weather_forecast_explanation_placeholder": {
    "en": "Write additional information regarding the weather",
    "fr": "Mentionnez tout autre information pertinente au sujet de la température"
  },
  "weather_forecast_now": {
    "en": "Now",
    "fr": "Maintenant"
  },
  "weather_validation_acceptAll": {
    "en": "Accept all",
    "fr": "Valider tous"
  },
  "project_view_number_of_project": {
    "en": "Projects scheduled in the past are not shown.",
    "fr": "Les projets planifiés dans le passé sont cachés."
  },
  "select_project_page_label": {
    "en": "Please select a project",
    "fr": "Veuillez sélectionner un projet"
  },
  "common_employee_number": {
    "en": "Employee Number",
    "fr": "Numéro d'employé"
  },
  "common_employee_type": {
    "en": "Employee Type",
    "fr": "Type d'employé"
  },
  "employee_type_craft": {
    "en": "Craft",
    "fr": "Métier"
  },
  "employee_type_staff": {
    "en": "Staff",
    "fr": "Personnel"
  },
  "employee_type_professional_services": {
    "en": "Professional Services",
    "fr": "Services professionnel"
  },
  "employee_type_consultant": {
    "en": "Consultant",
    "fr": "Consultant"
  },
  "employee_type_subcontractor": {
    "en": "Subcontractor",
    "fr": "Sous-contractant"
  },
  "employee_type_union": {
    "en": "Union Contact",
    "fr": "Contact de syndicat"
  },
  "employee_type_union_local_contact": {
    "en": "Union Local Contact",
    "fr": "Contact de syndicat locaux"
  },
  "employee_number": {
    "en": "Number",
    "fr": "Numéro"
  },
  "employee_type": {
    "en": "Type",
    "fr": "Type"
  },
  "employee_list_field_number": {
    "en": "Number",
    "fr": "Numéro"
  },
  "employee_list_field_type": {
    "en": "(Optional) Type",
    "fr": "Type (optionnel)"
  },
  "employee_list_field_number_description": {
    "en": "(Optional) The employee's number. ",
    "fr": "(Optionnel) Numéro d'employé."
  },
  "employee_list_field_type_description": {
    "en": "(Optional) The employee's type.",
    "fr": "(Optionnel) Type d'employé."
  },
  "employee_list_field_operates_name_label": {
    "en": "(Optional) Operates",
    "fr": "(Optionnel) Opère"
  },
  "employee_list_field_operates_name_description": {
    "en": "(Optional) Assign default equipment to employees",
    "fr": "(Optionnel) Assigner un équipement par défaut"
  },
  "employee_list_field_shift_name_label": {
    "en": "(Optional) Shift",
    "fr": "(Optionnel) Quart de travail"
  },
  "employee_list_field_shift_name_description": {
    "en": "(Optional) Assign default shift to employees",
    "fr": "(Optionnel) Assigner un quart de travail par défaut"
  },
  "employee_list_import_creatable_warning_default_shift": {
    "en": "This shift does not exist",
    "fr": "Ce quart de travail n'existe pas"
  },
  "employee_list_import_creatable_warning_default_equipment": {
    "en": "This equipment does not exist",
    "fr": "Cet équipement n'existe pas"
  },
  "timeline_tooltip_delay_projects_and": {
    "en": "projects and",
    "fr": "projets et"
  },
  "timeline_tooltip_delay_projects": {
    "en": "projects",
    "fr": "projets"
  },
  "timeline_tooltip_delay_read_more": {
    "en": "Read More",
    "fr": "Voir plus"
  },
  "validaion_time": {
    "en": "Time",
    "fr": "Temps"
  },
  "dailylog_status_update_warning": {
    "en": "This dailylog contains projects where daily weather are not validated",
    "fr": "Ce journal contient des projets dont les conditions météorologiques n'ont pas été validées"
  },
  "property_removal_text": {
    "en": "associated items affected if this property is removed",
    "fr": "items associées sont affecté par cette propriété"
  },
  "attribute_removal_text": {
    "en": "associated items affected this attribute is removed",
    "fr": "items associées sont affecté par cette attribut"
  },
  "gantt_add_activity": {
    "en": "Add Activity to this product",
    "fr": "Ajouter Activité au produit"
  },
  "gantt_add_product": {
    "en": "Add a Product Below",
    "fr": "Ajouter un produit en dessous"
  },
  "gantt_task_menu_below": {
    "en": "Task Below",
    "fr": "Tâche en-dessous"
  },
  "gantt_task_menu_subtask": {
    "en": "SubTask",
    "fr": "Sous-tâche"
  },
  "gantt_add_wbsItem": {
    "en": "Add Task",
    "fr": "Ajouter Tâche"
  },
  "gantt_task_edit_schedule": {
    "en": "Edit Gantt chart data",
    "fr": "Modifier les data relier au Gantt"
  },
  "gantt_edit_schedule": {
    "en": "Schedule",
    "fr": "Cédule"
  },
  "gantt_edit_activity": {
    "en": "Edit Activities",
    "fr": "Modifier les activitées"
  },
  "gantt_edit_product": {
    "en": "Edit Product",
    "fr": "Modifier le produit"
  },
  "gantt_edit_task_name": {
    "en": "Task Name",
    "fr": "Nom de la tâche"
  },
  "occupations_new_occupation": {
    "en": "New Occupation",
    "fr": "Nouveau Métier"
  },
  "occupations_search_occupation": {
    "en": "Search an occupation",
    "fr": "Rechercher un métier"
  },
  "occupations_list_name": {
    "en": "Name",
    "fr": "Nom"
  },
  "occupations_list_code": {
    "en": "Code",
    "fr": "Code"
  },
  "occupations_dialog_create_title": {
    "en": "Create a new occupation",
    "fr": "Créer un nouveau métier"
  },
  "occupations_dialog_edit_title": {
    "en": "Edit the occupation",
    "fr": "Modifier le métier"
  },
  "occupations_dialog_name_label": {
    "en": "Name",
    "fr": "Nom"
  },
  "occupations_dialog_code_label": {
    "en": "Code",
    "fr": "Code"
  },
  "dialog_title_assign_resource_to_team": {
    "en": "Assign to team",
    "fr": "Assigner à l'équipe"
  },
  "dialog_description_assign_resource_to_team": {
    "en": "This will permanently assign the following resource to the team.",
    "fr": "Cette action assignera de façon permanente la ressource suivante à l'équipe."
  },
  "daily_log_hour_meter_add_hour_meter": {
    "en": "Add hour meter",
    "fr": "Ajouter un compteur horaire"
  },
  "daily_log_hour_meter_edit_hour_meter": {
    "en": "Edit hour meter",
    "fr": "Modifier le compteur horaire"
  },
  "daily_log_hour_meter_add_hour_meter_start": {
    "en": "Hour meter start",
    "fr": "Début du compteur horaire"
  },
  "daily_log_hour_meter_add_hour_meter_end": {
    "en": "Hour meter end",
    "fr": "Fin du compteur horaire"
  },
  "daily_log_hour_meter_add_hour_meter_total": {
    "en": "Total for the day",
    "fr": "Total pour la journée"
  },
  "daily_log_hour_meter_remove": {
    "en": "Remove",
    "fr": "Supprimer"
  },
  "daily_log_hour_meter_delete_dialog_title": {
    "en": "Remove hour meter",
    "fr": "Supprimer le compteur horaire"
  },
  "daily_log_hour_meter_delete_dialog_text": {
    "en": "You are about to delete this hour meter. Are you sure you want to continue?",
    "fr": "Vous êtes sur le point de supprimer ce compteur horaire. Êtes-vous certain de vouloir continuer?"
  },
  "daily_log_hour_meter_modify_hour_meter": {
    "en": "Modify hour meter of the equipment",
    "fr": "Modifier le compteur horaire de l'équipment"
  },
  "hour_meter_modified_success": {
    "en": "Hour meter modified successfully ",
    "fr": "Compteur horaire modifié avec succès"
  },
  "hour_meter_modified_error": {
    "en": "Sorry, we had an error with the hour meter update",
    "fr": "Désolé, une erreur est survenue pendant la modification du compteur horaire"
  },
  "daily_log_hour_meter_decimal_field_validation": {
    "en": "Should be zero or one digit after the decimal point",
    "fr": "Doit être un chiffre après le point décimal"
  },
  "daily_log_hour_meter_max_value_validation": {
    "en": "Value should be less than 16 digits",
    "fr": "La valeur doit etre moins de 16 chiffres"
  },
  "daily_log_hour_meter_end_field_validation": {
    "en": "End should be after the start",
    "fr": "La fin doit être après le début"
  },
  "daily_log_hour_meter_remove_success": {
    "en": "Hour meter removed successfully",
    "fr": "Compteur horaire supprimé avec succès"
  },
  "daily_log_hour_meter_remove_error": {
    "en": "Sorry, we had an error with the hour meter removal",
    "fr": "Désolé, une erreur est survenue lors de la suppression du compteur horaire"
  },
  "common_occupation": {
    "en": "Occupation",
    "fr": "Métier"
  },
  "employee_list_edit_occupation": {
    "en": "Edit occupations",
    "fr": "Modifier les métiers"
  },
  "timeline_filters_clear_filters": {
    "en": "Clear filters",
    "fr": "Enlever les filtres"
  },
  "timeline_filters_clear_all_filters": {
    "en": "Clear all filters",
    "fr": "Enlever tous les filtres"
  },
  "equipment_ownership": {
    "en": "Ownership",
    "fr": "Titulaire"
  },
  "equipment_purchased_date": {
    "en": "Purchased Date",
    "fr": "Date d'achat"
  },
  "equipment_weight": {
    "en": "Weight",
    "fr": "Poids"
  },
  "equipment_length": {
    "en": "Length",
    "fr": "Longueur"
  },
  "equipment_width": {
    "en": "Width",
    "fr": "Largeur"
  },
  "equipment_height": {
    "en": "Height",
    "fr": "Hauteur"
  },
  "equipment_dimensions": {
    "en": "Equipment Dimensions",
    "fr": "Dimensions"
  },
  "equipment_maximum_hauling_capacity": {
    "en": "Maximum Hauling Capacity",
    "fr": "Capacité de transport maximale"
  },
  "equipment_ownership_owned": {
    "en": "Equipment Owned",
    "fr": "Propriétaire"
  },
  "equipment_ownership_outside_rented": {
    "en": "Outside Rented",
    "fr": "Location externe"
  },
  "equipment_weight_unit_kg": {
    "en": "kg",
    "fr": "kg"
  },
  "equipment_weight_unit_kilogram": {
    "en": "kilograms",
    "fr": "kilogrammes"
  },
  "equipment_weight_unit_lb": {
    "en": "lb",
    "fr": "lb"
  },
  "equipment_weight_unit_pound": {
    "en": "pounds",
    "fr": "livres"
  },
  "equipment_dimension_unit_ft": {
    "en": "ft",
    "fr": "pi"
  },
  "equipment_dimension_unit_feet": {
    "en": "feet",
    "fr": "pieds"
  },
  "equipment_dimension_unit_m": {
    "en": "m",
    "fr": "m"
  },
  "equipment_dimension_unit_meter": {
    "en": "meters",
    "fr": "mètres"
  },
  "equipment_dimension_weight_description": {
    "en": "Total weight of the equipment (Optional)",
    "fr": "Poids total de l'equipment (Optionnel)"
  },
  "equipment_dimension_weight_unit": {
    "en": "Weight Unit",
    "fr": "Unité du poids"
  },
  "equipment_dimension_length_description": {
    "en": "Total length of the equipment (Optional)",
    "fr": "Longueur total de l'equipment (Optionnel)"
  },
  "equipment_dimension_length_unit": {
    "en": "Length Unit",
    "fr": "Unité de la longueur"
  },
  "equipment_dimension_width_description": {
    "en": "Total width of the equipment (Optional)",
    "fr": "Largeur total de l'equipment (Optionnel)"
  },
  "equipment_dimension_width_unit": {
    "en": "Width Unit",
    "fr": "Unité de la largeur"
  },
  "equipment_dimension_height_description": {
    "en": "Total height of the equipment (Optional)",
    "fr": "Hauteur total de l'equipment (Optionnel)"
  },
  "equipment_dimension_height_unit": {
    "en": "Height Unit",
    "fr": "Unité de la hauteur"
  },
  "equipment_max_hauling_weight_description": {
    "en": "Maximum hauling weight (Optional)",
    "fr": "Poids transportable maximal (Optionnel)"
  },
  "equipment_max_hauling_weight_unit": {
    "en": "Weight Unit",
    "fr": "Unité de poids transportable"
  },
  "equipment_max_hauling_length_description": {
    "en": "Maximum hauling length (Optional)",
    "fr": "Longueur transportable maximale (Optionnel)"
  },
  "equipment_max_hauling_length_unit": {
    "en": "Length Unit",
    "fr": "Unité de longueur transportable"
  },
  "equipment_max_hauling_width_description": {
    "en": "Maximum hauling width (Optional)",
    "fr": "Largeur transportable maximale (Optionnel)"
  },
  "equipment_max_hauling_width_unit": {
    "en": "Width Unit",
    "fr": "Unité de largeur transportable"
  },
  "equipment_max_hauling_height_description": {
    "en": "Maximum hauling height (Optional)",
    "fr": "Hauteur transportable maximale (Optionnel)"
  },
  "equipment_max_hauling_height_unit": {
    "en": "Height Unit (Optional)",
    "fr": "Unité de hauteur transportable"
  },
  "common_location_us": {
    "en": "United States",
    "fr": "États-Unis"
  },
  "common_location_us_al": {
    "en": "Alabama",
    "fr": "Alabama"
  },
  "common_location_us_ak": {
    "en": "Alaska",
    "fr": "Alaska"
  },
  "common_location_us_az": {
    "en": "Arizona",
    "fr": "Arizona"
  },
  "common_location_us_ar": {
    "en": "Arkansas",
    "fr": "Arkansas"
  },
  "common_location_us_ca": {
    "en": "California",
    "fr": "Californie"
  },
  "common_location_us_co": {
    "en": "Colorado",
    "fr": "Colorado"
  },
  "common_location_us_ct": {
    "en": "Connecticut",
    "fr": "Connecticut"
  },
  "common_location_us_de": {
    "en": "Delaware",
    "fr": "Delaware"
  },
  "common_location_us_dc": {
    "en": "District of Columbia",
    "fr": "Districte de Columbia"
  },
  "common_location_us_fl": {
    "en": "Florida",
    "fr": "Floride"
  },
  "common_location_us_ga": {
    "en": "Georgia",
    "fr": "Georgie"
  },
  "common_location_us_hi": {
    "en": "Hawaii",
    "fr": "Hawaii"
  },
  "common_location_us_id": {
    "en": "Idaho",
    "fr": "Idaho"
  },
  "common_location_us_il": {
    "en": "Illinois",
    "fr": "Illinois"
  },
  "common_location_us_ia": {
    "en": "Iowa",
    "fr": "Iowa"
  },
  "common_location_us_ks": {
    "en": "Kansas",
    "fr": "Kansas"
  },
  "common_location_us_ky": {
    "en": "Kentucky",
    "fr": "Kentucky"
  },
  "common_location_us_la": {
    "en": "Louisiana",
    "fr": "Louisiane"
  },
  "common_location_us_me": {
    "en": "Maine",
    "fr": "Maine"
  },
  "common_location_us_md": {
    "en": "Maryland",
    "fr": "Maryland"
  },
  "common_location_us_ma": {
    "en": "Massachusetts",
    "fr": "Massachusetts"
  },
  "common_location_us_mi": {
    "en": "Michigan",
    "fr": "Michigan"
  },
  "common_location_us_mn": {
    "en": "Minnesota",
    "fr": "Minnesota"
  },
  "common_location_us_ms": {
    "en": "Mississippi",
    "fr": "Mississippi"
  },
  "common_location_us_mo": {
    "en": "Missouri",
    "fr": "Missouri"
  },
  "common_location_us_mt": {
    "en": "Montana",
    "fr": "Montana"
  },
  "common_location_us_ne": {
    "en": "Nebraska",
    "fr": "Nebraska"
  },
  "common_location_us_nv": {
    "en": "Nevada",
    "fr": "Nevada"
  },
  "common_location_us_nh": {
    "en": "New Hampshire",
    "fr": "New Hampshire"
  },
  "common_location_us_nj": {
    "en": "New Jersey",
    "fr": "New Jersey"
  },
  "common_location_us_nm": {
    "en": "New Mexico",
    "fr": "Nouveau Mexique"
  },
  "common_location_us_ny": {
    "en": "New York",
    "fr": "New York"
  },
  "common_location_us_nc": {
    "en": "North Carolina",
    "fr": "Caroline du Nord"
  },
  "common_location_us_nd": {
    "en": "North Dakota",
    "fr": "Dakota du Nord"
  },
  "common_location_us_oh": {
    "en": "Ohio",
    "fr": "Ohio"
  },
  "common_location_us_ok": {
    "en": "Oklahoma",
    "fr": "Oklahoma"
  },
  "common_location_us_or": {
    "en": "Oregon",
    "fr": "Oregon"
  },
  "common_location_us_pa": {
    "en": "Pennsylvania",
    "fr": "Pennsylvanie"
  },
  "common_location_us_ri": {
    "en": "Rhode Island",
    "fr": "Rhode Island"
  },
  "common_location_us_sc": {
    "en": "South Carolina",
    "fr": "Caroline du Sud"
  },
  "common_location_us_sd": {
    "en": "South Dakota",
    "fr": "Dakota du sud"
  },
  "common_location_us_tn": {
    "en": "Tennessee",
    "fr": "Tennessee"
  },
  "common_location_us_tx": {
    "en": "Texas",
    "fr": "Texas"
  },
  "common_location_us_ut": {
    "en": "Utah",
    "fr": "Utah"
  },
  "common_location_us_vt": {
    "en": "Vermont",
    "fr": "Vermont"
  },
  "common_location_us_va": {
    "en": "Virginia",
    "fr": "Virginie"
  },
  "common_location_us_wa": {
    "en": "Washington",
    "fr": "Washington"
  },
  "common_location_us_wv": {
    "en": "West Virginia",
    "fr": "Virgine de l'Ouest"
  },
  "common_location_us_wi": {
    "en": "Wisconsin",
    "fr": "Wisconsin"
  },
  "common_location_us_wy": {
    "en": "Wyoming",
    "fr": "Wyoming"
  },
  "common_location_ca": {
    "en": "Canada",
    "fr": "Canada"
  },
  "common_location_ca_ab": {
    "en": "Alberta",
    "fr": "Alberta"
  },
  "common_location_ca_bc": {
    "en": "British Columbia",
    "fr": "Colombie-Britannique"
  },
  "common_location_ca_mb": {
    "en": "Manitoba",
    "fr": "Manitoba"
  },
  "common_location_ca_nl": {
    "en": "Newfoundland and Labrador",
    "fr": "Terre-Neuve et Labrador"
  },
  "common_location_ca_nb": {
    "en": "New Brunswick",
    "fr": "Nouveau-Brunswick"
  },
  "common_location_ca_nt": {
    "en": "Northwest Territories",
    "fr": "Territoires-du-Nord-Ouest"
  },
  "common_location_ca_ns": {
    "en": "Nova Scotia",
    "fr": "Nouvelle-Écosse"
  },
  "common_location_ca_nu": {
    "en": "Nunavut",
    "fr": "Nunavut"
  },
  "common_location_ca_on": {
    "en": "Ontario",
    "fr": "Ontario"
  },
  "common_location_ca_pe": {
    "en": "Prince Edward Island",
    "fr": "Ile-du-Prince-Édouard"
  },
  "common_location_ca_qc": {
    "en": "Quebec",
    "fr": "Québec"
  },
  "common_location_ca_sk": {
    "en": "Saskatchewan",
    "fr": "Saskatchewan"
  },
  "common_location_ca_yt": {
    "en": "Yukon",
    "fr": "Yukon"
  },
  "holiday_set_preferences_success": {
    "en": "Holiday settings updated successfully",
    "fr": "Configurations mises à jour avec succès "
  },
  "holiday_set_preferences_error": {
    "en": "Sorry, we had an error with the holiday settings update",
    "fr": "Désolé, une erreur est survenue lors de la mise à jour des configurations"
  },
  "holiday_preferences_enable": {
    "en": "Enable Public Holidays",
    "fr": "Activer les jours fériés publiques"
  },
  "holiday_preferences_locations": {
    "en": "Holiday Locations",
    "fr": "Emplacement des jours fériés"
  },
  "popup_location_initial_text": {
    "en": "Enter the project address in the search engine or click on the map to add it",
    "fr": "Entrez l'adresse du projet ou cliquez sur la carte."
  },
  "popup_location_site_location_note": {
    "en": "Site Location Note",
    "fr": "Note de l'emplacement"
  },
  "popup_location_site_location_code": {
    "en": "Site Location Code",
    "fr": "Code de l'emplacement"
  },
  "popup_location_site_location_type": {
    "en": "Site Location Type",
    "fr": "Type de l'emplacement"
  },
  "popup_location_site_location_name": {
    "en": "Site Location Name",
    "fr": "Nom de l'emplacement"
  },
  "popup_location_gps_coordinates": {
    "en": "GPS Coordinates",
    "fr": "Coordonnées GPS"
  },
  "popup_location_site_location_code_placeholder": {
    "en": "Write Site Location Code",
    "fr": "Entrez le code de l'emplacement"
  },
  "popup_location_site_location_type_placeholder": {
    "en": "Select Location Type",
    "fr": "Sélectionnez le type de l'emplacement"
  },
  "popup_location_site_location_name_placeholder": {
    "en": "Write Site Location Name",
    "fr": "Entrez le nom de l'emplacement"
  },
  "popup_location_gps_coordinates_placeholder": {
    "en": "Write GPS Coordinates",
    "fr": "Entrez les coordonnées GPS"
  },
  "custom_holiday_dialog_add": {
    "en": "Add a new holiday",
    "fr": "Ajouter un jour férié"
  },
  "custom_holiday_dialog_edit": {
    "en": "Edit the holiday",
    "fr": "Modifier un jour férié"
  },
  "custom_holiday_description": {
    "en": "Description",
    "fr": "Description"
  },
  "custom_holiday_date": {
    "en": "Date",
    "fr": "Date"
  },
  "custom_holiday_dailog_holiday_location": {
    "en": "Holiday Location",
    "fr": "Emplacement du jour férié"
  },
  "custom_holiday_new_holiday": {
    "en": "New Holiday",
    "fr": "Nouveau jour férié"
  },
  "custom_holiday_delete_dialog_title": {
    "en": "Delete the holiday",
    "fr": "Supprimer le jour férié"
  },
  "custom_holiday_delete_dialog_text": {
    "en": "You're about to delete this holiday. Are you sure you want to continue?",
    "fr": "Vous êtes sur le point de supprimer ce jour férié. Êtes-vous certain de vouloir continuer?"
  },
  "custom_holiday_location": {
    "en": "Location",
    "fr": "Emplacement"
  },
  "custom_holiday_insert_success": {
    "en": "Holiday created successfully",
    "fr": "Jour férié créé avec succès"
  },
  "custom_holiday_insert_error": {
    "en": "Sorry, we had an error with the holiday creation",
    "fr": "Désolé, une erreur est survenue lors de la création du jour férié"
  },
  "custom_holiday_update_success": {
    "en": "Holiday updated successfully",
    "fr": "Jour férié mis à jour avec succès"
  },
  "custom_holiday_update_error": {
    "en": "Sorry, we had an error with the holiday update",
    "fr": "Désolé, une erreur est survenue lors de la modification du jour férié"
  },
  "custom_holiday_search": {
    "en": "Search a holiday",
    "fr": "Rechercher un jour férié"
  },
  "location_type_gravel_pit": {
    "en": "Gravel Pit",
    "fr": "Gravière"
  },
  "location_type_job_site": {
    "en": "Job Site",
    "fr": "Chantier"
  },
  "location_type_main_office": {
    "en": "Main Office",
    "fr": "Bureau principal"
  },
  "location_type_other": {
    "en": "Other",
    "fr": "Autre"
  },
  "location_type_plant": {
    "en": "Plant",
    "fr": "Usine"
  },
  "location_coordinates_error": {
    "en": "GPS coordinates are invalid",
    "fr": "Les coordonnées GPS sont invalides"
  },
  "latitiude_longitude_format": {
    "en": "Format: Latitude, Longitude",
    "fr": "Format: Latitude, Longitude"
  },
  "wagerate_table_title": {
    "en": "Wage rates",
    "fr": "Taux salarials"
  },
  "wagerate_table_effective_date": {
    "en": "Effective date",
    "fr": "Date effective"
  },
  "wagerate_table_effective_date_active": {
    "en": "Active",
    "fr": "Actif"
  },
  "wagerate_table_rate_type": {
    "en": "Type",
    "fr": "Type"
  },
  "wagerate_table_rates": {
    "en": "Rates",
    "fr": "Taux"
  },
  "wagerate_dialog_create_title": {
    "en": "Create new wage rate",
    "fr": "Créer un taux salarial"
  },
  "wagerate_dialog_edit_title": {
    "en": "Edit wage rate",
    "fr": "Modifier un taux salarial"
  },
  "wagerate_dialog_effective_date": {
    "en": "Effective date",
    "fr": "Date effective"
  },
  "wagerate_dialog_rate_type": {
    "en": "Rate type",
    "fr": "Type du taux"
  },
  "wagerate_dialog_applies_to_whole_day": {
    "en": "Applies to whole day",
    "fr": "S'applique à toute la journée"
  },
  "wagerate_dialog_rates": {
    "en": "Rates",
    "fr": "Taux"
  },
  "wagerate_dialog_add_rate_button": {
    "en": "Add rate",
    "fr": "Ajouter taux"
  },
  "wagerate_dialog_rate_starts_at": {
    "en": "Starts at",
    "fr": "Débute à"
  },
  "wagerate_dialog_rate_ends_at": {
    "en": "Ends at",
    "fr": "Termine à"
  },
  "wagerate_dialog_rate_code": {
    "en": "Code",
    "fr": "Code"
  },
  "wagerate_dialog_rate_value": {
    "en": "Rate",
    "fr": "Taux"
  },
  "wagerate_dialog_insert_success": {
    "en": "Wage rate created successfully",
    "fr": "Taux salarial créé avec succès"
  },
  "wagerate_dialog_insert_error": {
    "en": "Sorry, we had an error with the wage rate creation",
    "fr": "Désolé, nous avons eu une erreur lors de la création du taux"
  },
  "wagerate_dialog_update_success": {
    "en": "Wage rate updated successfully",
    "fr": "Taux salarial modifié avec succès"
  },
  "wagerate_dialog_update_error": {
    "en": "Sorry, we had an error with the wage rate update",
    "fr": "Désolé, nous avons eu une erreur lors de la modification du taux"
  },
  "wagerate_delete_success": {
    "en": "Wage rate deleted successfully",
    "fr": "Taux salarial supprimé avec succès"
  },
  "wagerate_delete_error": {
    "en": "Sorry, we had an error deleting the wage rate",
    "fr": "Désolé, nous avons eu une erreur lors de la suppression du taux"
  },
  "wagerate_type_per_week": {
    "en": "Per week",
    "fr": "Par semaine"
  },
  "wagerate_type_per_month": {
    "en": "Per month",
    "fr": "Par mois"
  },
  "wagerate_type_per_year": {
    "en": "Per year",
    "fr": "Par année"
  },
  "wagerate_type_per_day": {
    "en": "Per day",
    "fr": "Par jour"
  },
  "wagerate_type_per_hour": {
    "en": "Per hour",
    "fr": "Par heure"
  },
  "wagerate_schedule_all_day": {
    "en": "All day",
    "fr": "Toute la journée"
  },
  "wagerate_type_per_hour_abbreviation": {
    "en": "/H",
    "fr": "/H"
  },
  "wagerate_type_per_week_abbreviation": {
    "en": "/W",
    "fr": "/S"
  },
  "wagerate_type_per_month_abbreviation": {
    "en": "/M",
    "fr": "/M"
  },
  "wagerate_type_per_year_abbreviation": {
    "en": "/Y",
    "fr": "/A"
  },
  "wagerate_dialog_starts_at_required": {
    "en": "Starts at is required",
    "fr": "L'heure de début doit être spécifiée"
  },
  "wagerate_dialog_ends_at_required": {
    "en": "Ends at is required",
    "fr": "L'heure de fin doit être spécifiée"
  },
  "wagerate_dialog_rate_error": {
    "en": "Rate is required",
    "fr": "Taux est requis"
  },
  "wagerate_dialog_effective_date_required": {
    "en": "Effective date is required",
    "fr": "La date effective est requise"
  },
  "wagerate_dialog_schedule_overlap": {
    "en": "Rate schedules cannot overlap",
    "fr": "Les horaires de taux se chevauchent"
  },
  "wagerate_dialog_whole_day_tooltip": {
    "en": "The \"applies to whole day\" option is used to indicate if an hourly rate has a schedule or is applied to the whole day. This option is automatically set if the rate type is different than \"Per hour\"",
    "fr": "L'option \"s'applique a toute la journée\" est utilisée pour différencié un taux par heure avec horraire et un taux par heure appliqué a toute la journée. Cette option est automatiquement activée pour les taux avec un type différent de \"Par heure\""
  },
  "role_detailed_page_title": {
    "en": "Occupation details",
    "fr": "Détails du métier"
  },
  "role_detailed_page_new_wagerate": {
    "en": "New wage rate",
    "fr": "Nouveau taux salarial"
  },
  "sidebar_integrations": {
    "en": "Integrations",
    "fr": "Intégrations"
  },
  "sidebar_zapier": {
    "en": "Zapier",
    "fr": "Zapier"
  },
  "common_project_new_site_location_label": {
    "en": "Add new site location",
    "fr": "Ajouter un nouvel emplacement"
  },
  "company_preferences_tenant_id": {
    "en": "Tenant ID",
    "fr": "Tenant ID"
  },
  "company_preferences_tenant_id_description": {
    "en": "Civalgo customer ID used to identify the software instance for integration uses",
    "fr": "Identifiant client Civalgo utilisé pour identifier l'instance logicielle pour des utilisations d'intégration"
  },
  "common_copy": {
    "en": "Copy",
    "fr": "Copier"
  },
  "company_preferences_tenant_id_copy_notify": {
    "en": "Tenant ID copied successfully in your clipboard",
    "fr": "Le ID du Tenant a bien été copier dans votre presse papiers"
  },
  "equipment_list_field_ownership_description": {
    "en": "Equipment ownership, either the equipment is owned or rented",
    "fr": "Titulaire de l'équipement (Propriétaire ou Location externe)"
  },
  "equipment_purchased_date_label": {
    "en": "Purchased Date (Optional)",
    "fr": "Date d'achat (Optionnel)"
  },
  "equipment_dimension_weight_label": {
    "en": "Weight (Optional)",
    "fr": "Poids (Optionnel)"
  },
  "equipment_dimension_length_label": {
    "en": "Length (Optional)",
    "fr": "Longueur (Optionnel)"
  },
  "equipment_dimension_width_label": {
    "en": "Width (Optional)",
    "fr": "Largeur (Optionnel)"
  },
  "equipment_dimension_height_label": {
    "en": "Height (Optional)",
    "fr": "Hauteur (Optionnel)"
  },
  "equipment_hauling_weight_label": {
    "en": "Hauling weight (Optional)",
    "fr": "Poids transportable (Optionnel)"
  },
  "equipment_hauling_length_label": {
    "en": "Hauling length (Optional)",
    "fr": "Longueur transportable (Optionnel)"
  },
  "equipment_hauling_width_label": {
    "en": "Hauling width (Optional)",
    "fr": "Largeur transportable (Optionnel)"
  },
  "equipment_hauling_height_label": {
    "en": "Hauling height (Optional)",
    "fr": "Hauteur transportable (Optionnel)"
  },
  "equipment_list_import_unique_error_type": {
    "en": "Please choose an existing type",
    "fr": "Vous devez choisir un type d'équipement existant"
  },
  "standard_equipment_type_category_compaction_equipment": {
    "en": "Compaction Equipment",
    "fr": "Compactage"
  },
  "standard_equipment_type_category_cranes": {
    "en": "Cranes",
    "fr": "Grues"
  },
  "standard_equipment_type_category_paving_equipment": {
    "en": "Paving Equipment",
    "fr": "Pavage"
  },
  "standard_equipment_type_category_dump_trucks": {
    "en": "Dump Trucks",
    "fr": "Camions à benne basculante"
  },
  "standard_equipment_type_category_trailers": {
    "en": "Trailers",
    "fr": "Remorques"
  },
  "standard_equipment_type_category_trucks": {
    "en": "Trucks",
    "fr": "Camions"
  },
  "standard_equipment_type_category_truks": {
    "en": "Trucks",
    "fr": "Camions"
  },
  "standard_equipment_type_category_construction_equipment": {
    "en": "Construction Equipment",
    "fr": "Construction"
  },
  "standard_equipment_type_category_no_category": {
    "en": "No category",
    "fr": "Pas de catégorie"
  },
  "standard_equipment_type_name_aerial_lifts": {
    "en": "Aerial Lifts",
    "fr": "Ascenseurs aériens"
  },
  "standard_equipment_type_name_backhoe_loaders": {
    "en": "Backhoe Loaders",
    "fr": "Chargeuses-pelleteuses"
  },
  "standard_equipment_type_name_compact_track_loaders": {
    "en": "Compact Track Loaders",
    "fr": "Chargeuses compactes sur chenilles"
  },
  "standard_equipment_type_name_crawler_dozers": {
    "en": "Crawler Dozers",
    "fr": "Bulldozers sur chenilles"
  },
  "standard_equipment_type_name_crawler_loaders": {
    "en": "Crawler Loaders",
    "fr": "Chargeuses sur chenilles"
  },
  "standard_equipment_type_name_dumpers": {
    "en": "Dumpers",
    "fr": "Tombereaux"
  },
  "standard_equipment_type_name_excavators": {
    "en": "Excavators",
    "fr": "Excavatrices"
  },
  "standard_equipment_type_name_forklifts": {
    "en": "Forklifts",
    "fr": "Chariots élévateurs"
  },
  "standard_equipment_type_name_light_towers": {
    "en": "Light Towers",
    "fr": "Tours d'éclairage"
  },
  "standard_equipment_type_name_material_handlers": {
    "en": "Material Handlers",
    "fr": "Manutentionnaires"
  },
  "standard_equipment_type_name_motor_graders": {
    "en": "Motor Graders",
    "fr": "Niveleuses"
  },
  "standard_equipment_type_name_off_highway_trucks": {
    "en": "Off-Highway Trucks",
    "fr": "Camions hors route"
  },
  "standard_equipment_type_name_pipelayers": {
    "en": "Pipelayers",
    "fr": "Poseurs de canalisations"
  },
  "standard_equipment_type_name_scrapers": {
    "en": "Scrapers",
    "fr": "Grattoirs"
  },
  "standard_equipment_type_name_skid_steer_loaders": {
    "en": "Skid Steer Loaders",
    "fr": "Chargeuses compactes"
  },
  "standard_equipment_type_name_wheel_dozers": {
    "en": "Wheel Dozers",
    "fr": "Bulldozers sur roues"
  },
  "standard_equipment_type_name_wheel_loaders": {
    "en": "Wheel Loaders",
    "fr": "Chargeuses sur roues"
  },
  "standard_equipment_type_name_combination_rollers": {
    "en": "Combination Rollers",
    "fr": "Rouleaux combinés"
  },
  "standard_equipment_type_name_landfill_compactors": {
    "en": "Landfill Compactors",
    "fr": "Compacteurs de décharge"
  },
  "standard_equipment_type_name_padfoot_compactors": {
    "en": "Padfoot Compactors",
    "fr": "Compacteurs à pieds dameurs"
  },
  "standard_equipment_type_name_pneumatic_rollers": {
    "en": "Pneumatic Rollers",
    "fr": "Rouleaux pneumatiques"
  },
  "standard_equipment_type_name_rammers": {
    "en": "Rammers",
    "fr": "Pilonneuses"
  },
  "standard_equipment_type_name_smooth_drum_rollers": {
    "en": "Smooth Drum Rollers",
    "fr": "Rouleaux de tambour lisses"
  },
  "standard_equipment_type_name_soil_compactors": {
    "en": "Soil Compactors",
    "fr": "Compacteurs de sol"
  },
  "standard_equipment_type_name_trench_compactors": {
    "en": "Trench Compactors",
    "fr": "Compacteurs de tranchées"
  },
  "standard_equipment_type_name_walk_tow_behind_compactors": {
    "en": "Walk / Tow Behind Compactors",
    "fr": "Compacteurs remorquable"
  },
  "standard_equipment_type_name_all_terrain_cranes": {
    "en": "All Terrain Cranes",
    "fr": "Grues tout-terrain"
  },
  "standard_equipment_type_name_carrydeck_cranes": {
    "en": "Carrydeck Cranes",
    "fr": "Grues de portique"
  },
  "standard_equipment_type_name_crawler_cranes": {
    "en": "Crawler Cranes",
    "fr": "Grues sur chenilles"
  },
  "standard_equipment_type_name_knuckleboom_cranes": {
    "en": "Knuckleboom Cranes",
    "fr": "Grues à flèche articulée"
  },
  "standard_equipment_type_name_mini_cranes": {
    "en": "Mini Cranes",
    "fr": "Mini-grues"
  },
  "standard_equipment_type_name_rough_terrain_cranes": {
    "en": "Rough Terrain Cranes",
    "fr": "Grues pour terrain accidenté"
  },
  "standard_equipment_type_name_tower_cranes": {
    "en": "Tower Cranes",
    "fr": "Grues à tour"
  },
  "standard_equipment_type_name_truck_cranes": {
    "en": "Truck Cranes",
    "fr": "Grues sur camion"
  },
  "standard_equipment_type_name_asphalt_pavers": {
    "en": "Asphalt Pavers",
    "fr": "Pavage d'asphalte"
  },
  "standard_equipment_type_name_asphalt_plants": {
    "en": "Asphalt Plants",
    "fr": "Usines d'asphalte"
  },
  "standard_equipment_type_name_cold_planers_milling_machines": {
    "en": "Cold Planers / Milling Machines",
    "fr": "Raboteuses à froid / Fraiseuses"
  },
  "standard_equipment_type_name_concrete_pavers": {
    "en": "Concrete Pavers",
    "fr": "Pavage de béton"
  },
  "standard_equipment_type_name_curb_gutter_machines": {
    "en": "Curb / Gutter Machines",
    "fr": "Machines pour bordures / gouttières"
  },
  "standard_equipment_type_name_car_carrier_transport_trailers": {
    "en": "Car Carrier / Transport Trailers",
    "fr": "Porte-voitures / Remorques de transport"
  },
  "standard_equipment_type_name_drop_deck_lowboy_trailers": {
    "en": "Drop Deck / Lowboy Trailers",
    "fr": "Plate-forme de chute / Remorques surbaissées"
  },
  "standard_equipment_type_name_dump_trailers": {
    "en": "Dump Trailers",
    "fr": "Remorques à benne basculante"
  },
  "standard_equipment_type_name_flatbed_trailers": {
    "en": "Flatbed Trailers",
    "fr": "Remorques à plateau"
  },
  "standard_equipment_type_name_gooseneck_trailers": {
    "en": "Gooseneck Trailers",
    "fr": "Remorques à col de cygne"
  },
  "standard_equipment_type_name_oilfield_trailers": {
    "en": "Oilfield Trailers",
    "fr": "Remorques pour champs pétrolifères"
  },
  "standard_equipment_type_name_pipe_pole_trailers": {
    "en": "Pipe / Pole Trailers",
    "fr": "Remorques pour tuyaux / poteaux"
  },
  "standard_equipment_type_name_reel_cable_trailers": {
    "en": "Reel / Cable Trailers",
    "fr": "Remorques de bobines / câble"
  },
  "standard_equipment_type_name_semi_trailers": {
    "en": "Semi Trailers",
    "fr": "Semi-remorques"
  },
  "standard_equipment_type_name_tag_trailers": {
    "en": "Tag Trailers",
    "fr": "Remorques d'étiquettes"
  },
  "standard_equipment_type_name_tilt_trailers": {
    "en": "Tilt Trailers",
    "fr": "Remorques basculantes"
  },
  "standard_equipment_type_name_traveling_axle_trailers": {
    "en": "Traveling Axle Trailers",
    "fr": "Remorques à essieux mobiles"
  },
  "standard_equipment_type_name_utility_trailers": {
    "en": "Utility Trailers",
    "fr": "Remorques utilitaires"
  },
  "standard_equipment_type_name_bucket_trucks": {
    "en": "Bucket Trucks",
    "fr": "Camions nacelles"
  },
  "standard_equipment_type_name_cab_chassis_trucks": {
    "en": "Cab / Chassis Trucks",
    "fr": "Camions cabine/châssis"
  },
  "standard_equipment_type_name_standard_dump_truck": {
    "en": "Standard Dump Truck",
    "fr": "Camion à benne standard"
  },
  "standard_equipment_type_name_articulated_dump_truck": {
    "en": "Articulated Dump Truck",
    "fr": "Tombereau articulé"
  },
  "standard_equipment_type_name_transfer_dump_truck": {
    "en": "Transfer Dump Truck",
    "fr": "Camion à benne de transfert"
  },
  "standard_equipment_type_name_truck_and_pup": {
    "en": "Truck and Pup",
    "fr": "Camion à petit chargement"
  },
  "standard_equipment_type_name_super_dump_truck": {
    "en": "Super dump Truck",
    "fr": "Super camion benne"
  },
  "standard_equipment_type_name_semi_trailer_bottom_dump_truck": {
    "en": "Semi Trailer Bottom Dump Truck",
    "fr": "Camion à benne basculante semi-remorque (dessous)"
  },
  "standard_equipment_type_name_semi_trailer_end_dump_truck": {
    "en": "Semi Trailer End Dump Truck",
    "fr": "Camion à benne basculante semi-remorque (derrière)"
  },
  "standard_equipment_type_name_double_and_triple_trailer_bottom_dump_truck": {
    "en": "Double and Triple Trailer Bottom Dump Truck",
    "fr": "Camion à benne basculante double et triple remorque"
  },
  "standard_equipment_type_name_side_dump_truck": {
    "en": "Side Dump Truck",
    "fr": "Camion à benne basculant de côté"
  },
  "standard_equipment_type_name_off_road_dump_truck": {
    "en": "Off-Road Dump Truck",
    "fr": "Camion à benne basculante hors route"
  },
  "standard_equipment_type_name_winter_service_dump_truck": {
    "en": "Winter Service Dump Truck",
    "fr": "Camion à benne basculante de service d'hiver"
  },
  "equipment_tooltip_title_non_standard_type": {
    "en": "This is a non-standard type",
    "fr": "Il s'agit d'un type non standard"
  },
  "equipment_number_validation_label": {
    "en": "Must be a number",
    "fr": "Doit être numérique"
  },
  "comment_show_images": {
    "en": "Show images",
    "fr": "Afficher les images"
  },
  "equipment_type_dialog_add_title": {
    "en": "Create a new equipment type",
    "fr": "Créer un nouveau type d'équipement"
  },
  "equipment_type_dialog_edit_title": {
    "en": "Update the equipment type",
    "fr": "Modifier le type d'équipement"
  },
  "equipment_type_dialog_label_name": {
    "en": "Name",
    "fr": "Nom"
  },
  "equipment_type_dialog_label_category": {
    "en": "Category",
    "fr": "Catégorie"
  },
  "equipment_type_insert_success": {
    "en": "Equipment type created successfully",
    "fr": "Type d'équipement créé avec succès"
  },
  "equipment_type_insert_error": {
    "en": "Sorry, we had an error with the equipment type creation",
    "fr": "Désolé, nous avons eu une erreur lors de la création du type d'équipement"
  },
  "equipment_type_update_success": {
    "en": "Equipment type updated successfully",
    "fr": "Type d'équipement mis à jour avec succès"
  },
  "equipment_type_update_error": {
    "en": "Sorry, we had an error with the equipment type update",
    "fr": "Désolé, nous avons eu une erreur lors de la mise à jour du type d'équipement"
  },
  "equipment_purchase_date_validation_label": {
    "en": "Please enter date in format yyyy-mm-dd",
    "fr": "Format de la date: aaaa-mm-jj"
  },
  "equipment_list_field_purchased_date_description": {
    "en": "Purchased Date",
    "fr": "Date d'achat"
  },
  "common_organization_type": {
    "en": "Type",
    "fr": "Type"
  },
  "services_organization_type_insert_success": {
    "en": "Organization Type created successfully",
    "fr": "Type d'organisation créé avec succès"
  },
  "services_organization_type_insert_error": {
    "en": "Sorry, we had an error with the organization type creation",
    "fr": "Désolé, nous avons eu une erreur lors de la création du type d'organisation"
  },
  "services_organization_type_update_success": {
    "en": "Organization Type updated successfully",
    "fr": "Type d'organisation mis à jour avec succès"
  },
  "services_organization_type_update_error": {
    "en": "Sorry, we had an error with the organization type update",
    "fr": "Désolé, nous avons eu une erreur lors de la mise à jour du type d'organisation"
  },
  "services_organization_type_delete_success": {
    "en": "Organization Type deleted successfully",
    "fr": "Type d'organisation supprimé avec succès"
  },
  "services_organization_type_delete_error": {
    "en": "Sorry, we had an error with the organization type deletion",
    "fr": "Désolé, nous avons eu une erreur lors de la suppression du type d'organisation"
  },
  "services_organization_type_restore_success": {
    "en": "Organization Type restored successfully",
    "fr": "Type d'organisation restauré avec succès"
  },
  "services_organization_type_restore_error": {
    "en": "Sorry, we had an error with the organization type restore",
    "fr": "Désolé, nous avons eu une erreur lors de la restauration du type d'organisation"
  },
  "organization_list_field_type_description": {
    "en": "(Optional) Note of the organization. The type is to describe the type of the organization.",
    "fr": "(Optionnel) Note sur l'organisation. Le type est pour décrire le type de l'organisation."
  },
  "organization_list_import_unique_error_type": {
    "en": "This organization type does not exist . Please enter valid organization type",
    "fr": "Ce type d'organisation n'existe pas. Vous devez fournir un type valide."
  },
  "work_hour_type_working": {
    "en": "Working",
    "fr": "Travail"
  },
  "work_hour_type_break": {
    "en": "Break",
    "fr": "Pause"
  },
  "work_hour_type_lunch": {
    "en": "Lunch",
    "fr": "Diner"
  },
  "work_hour_type_travel": {
    "en": "Travel",
    "fr": "Voyage"
  },
  "work_hour_type_transport": {
    "en": "Transport",
    "fr": "Transport"
  },
  "work_hour_type_sick": {
    "en": "Sick",
    "fr": "Maladie"
  },
  "work_hour_type_vacation": {
    "en": "Vacation",
    "fr": "Vacances"
  },
  "work_hour_type_absent": {
    "en": "Absent",
    "fr": "Absence"
  },
  "common_type": {
    "en": "Type",
    "fr": "Type"
  },
  "standard_organization_type_name_client": {
    "en": "Client",
    "fr": "Client"
  },
  "standard_organization_type_name_owner": {
    "en": "Owner",
    "fr": "Propriétaire"
  },
  "standard_organization_type_name_general_contractor": {
    "en": "General Contractor",
    "fr": "Contracteur général"
  },
  "standard_organization_type_name_subcontractor": {
    "en": "Subcontractor",
    "fr": "Sous-contractant"
  },
  "standard_organization_type_name_architect": {
    "en": "Architect",
    "fr": "Architecte"
  },
  "standard_organization_type_name_supplier": {
    "en": "Supplier",
    "fr": "Fournisseur"
  },
  "standard_organization_type_name_vendor": {
    "en": "Vendor",
    "fr": "Vendeur"
  },
  "standard_organization_type_name_government_authority": {
    "en": "Government Authority",
    "fr": "Authorité gouvernementale"
  },
  "standard_organization_type_name_fabricator": {
    "en": "Fabricator",
    "fr": "Fabriquant"
  },
  "standard_organization_type_name_designer": {
    "en": "Designer",
    "fr": "Concepteur"
  },
  "standard_organization_type_name_union": {
    "en": "Union",
    "fr": "Syndicat"
  },
  "standard_organization_type_name_union_local": {
    "en": "Union Local",
    "fr": "Syndicat local"
  },
  "standard_organization_type_name_professional_body": {
    "en": "Professional Body",
    "fr": "Corps professionnel"
  },
  "standard_organization_type_name_local_business_owner": {
    "en": "Local Business Owner",
    "fr": "Propriétaire d'entreprise local"
  },
  "common_project_organization": {
    "en": "Project Organization",
    "fr": "Organisation du projet"
  },
  "auditlog_action_dailyLogStatusChanged": {
    "en": "Status set to",
    "fr": "Status défini à"
  },
  "geoposition_prompt_warning": {
    "en": "Warning, you need to accept or deny the localisation access of the browser to be able to upload files with the camera",
    "fr": "Attention, vous devez accepter ou refuser l'accès à la localisation du navigateur pour pouvoir télécharger des fichiers avec la caméra."
  },
  "popup_location_dialog_main_title": {
    "en": "Select Main Location",
    "fr": "Sélectionner un emplacement principal"
  },
  "popup_location_dialog_site_title": {
    "en": "Select Site Location",
    "fr": "Sélectionner un emplacement"
  },
  "popup_location_dialog_main_header": {
    "en": "Click on the map to select Main location or Modify Gps Coordinates",
    "fr": "Cliquez sur la carte pour sélectionner l'adresse ou les coordonnées GPS de l'emplacement principal"
  },
  "popup_location_dialog_site_header": {
    "en": "Click on the map to select Site location or Modify Gps Coordinates",
    "fr": "Cliquez sur la carte pour sélectionner l'adresse ou les coordonnées GPS de l'emplacement secondaire"
  },
  "popup_location_dialog_address_label": {
    "en": "Display name",
    "fr": "Nom à l'affichage"
  },
  "popup_location_dialog_toggle_gps": {
    "en": "GPS Mode",
    "fr": "Mode GPS"
  },
  "popup_location_dialog_toggle_gps_description": {
    "en": "Toggle to choose between an address or exact GPS coordinates",
    "fr": "Activez/Désactivez pour choisir entre une adresse ou des coordonnées GPS exactes"
  },
  "popup_location_dialog_toggle_gps_longitude": {
    "en": "Longitude",
    "fr": "Longitude"
  },
  "popup_location_dialog_toggle_gps_latitude": {
    "en": "Latitude",
    "fr": "Latitude"
  },
  "popup_location_dialog_address_placeholder": {
    "en": "Display Name",
    "fr": "Nom à l'affichage"
  },
  "common_standard_equipment_types": {
    "en": "Equipment Types",
    "fr": "Types d'equipement"
  },
  "common_legacy_types": {
    "en": "Legacy Types (deprecated)",
    "fr": "Anciens types (obsolète)"
  },
  "coordinate_valid_message": {
    "en": "Please Provide a Valid Coordinate",
    "fr": "Veuillez fournir une coordonnée valide"
  },
  "latitude_required": {
    "en": "Latitude is Required",
    "fr": "La latitude est requise"
  },
  "longitude_required": {
    "en": "Longitude is Required",
    "fr": "La longitude est requise"
  },
  "latitude_description": {
    "en": "Range between -90 & 90",
    "fr": "Plage entre -90 et 90"
  },
  "longitude_description": {
    "en": "Range between -180 & 180",
    "fr": "Plage entre -180 et 180"
  },
  "common_search_to_select": {
    "en": "Select...",
    "fr": "Sélectionner..."
  },
  "job_block_role_override_note": {
    "en": "This activity is executed as:",
    "fr": "Cette activité est exécutée en tant que:"
  },
  "services_organization_with_employee_delete_error": {
    "en": "Cannot delete an organization that contains employees",
    "fr": "Impossible de supprimer une organisation qui contient des employés"
  },
  "work_hour_types_page_title": {
    "en": "Work hour types",
    "fr": "Types de travail"
  },
  "custom_field_dialog_options_min_error": {
    "en": "There should be at least 1 option",
    "fr": "Il devrait avoir au moins une option"
  },
  "custom_field_dialog_options_duplicate_error": {
    "en": "There shouldn't be duplicate option values",
    "fr": "Il ne devrait pas avoir de valeur d'option dupliquée"
  },
  "task_export_wbs_header": {
    "en": "WBS",
    "fr": "SEQ"
  },
  "task_export_code_header": {
    "en": "Code",
    "fr": "Code"
  },
  "task_export_name_header": {
    "en": "Task Name",
    "fr": "Nom de la tâche"
  },
  "task_export_status_header": {
    "en": "Status",
    "fr": "Statut"
  },
  "task_export_start_date_header": {
    "en": "Start",
    "fr": "Début"
  },
  "task_export_end_date_header": {
    "en": "End",
    "fr": "Fin"
  },
  "task_export_duration_header": {
    "en": "Duration",
    "fr": "Durée"
  },
  "task_export_predecessor_header": {
    "en": "Predecessor",
    "fr": "Prédécesseur"
  },
  "task_export_budget_header": {
    "en": "Budget Cost",
    "fr": "Coût Budgété"
  },
  "task_export_actual_header": {
    "en": "Actual Cost",
    "fr": "Coût Actuel"
  },
  "task_export_revenue_header": {
    "en": "Revenue",
    "fr": "Revenu"
  },
  "sidebar_workOrders":{
    "en": "Work Orders",
    "fr": "Bons de travail",
  }
}
