import { usePathname, useSearchParams } from 'next/navigation';
import posthog from 'posthog-js';
import { PostHogProvider as PHProvider, usePostHog } from 'posthog-js/react';
import React, { Suspense, useEffect } from 'react';

import { posthogEnabled, posthogHost, posthogKey } from 'Src/config';

const UNTRACKED_PAGES_LIST = ['/callback'];

function PostHogTracking(): null {
  const pathname = usePathname();
  const searchParams = useSearchParams();
  const posthog = usePostHog();

  // Track pageviews
  useEffect(() => {
    if (pathname && posthog) {
      if (UNTRACKED_PAGES_LIST.includes(pathname)) {
        return;
      }

      posthog.capture('$pageview', { $current_url: window.location.href });
    }
  }, [pathname, searchParams, posthog]);

  return null;
}

function SuspendedPostHogTracking(): React.ReactNode {
  return (
    <Suspense fallback={null}>
      <PostHogTracking />
    </Suspense>
  );
}

export const Posthog: React.FC<Required<React.PropsWithChildren>> = (props) => {
  useEffect(() => {
    if (!posthogKey) {
      throw new Error(
        'Posthog key missing from environment variables but posthog is enabled',
      );
    }
    if (!posthogHost) {
      throw new Error(
        'Posthog host missing from environment variables but posthog is enabled',
      );
    }
    posthog.init(posthogKey, {
      api_host: posthogHost,
      capture_pageview: false, // Disable automatic pageview capture, as we capture manually
      capture_pageleave: true,
    });
  }, []);

  return posthogEnabled ? (
    <PHProvider client={posthog}>
      <SuspendedPostHogTracking />
      {props.children}
    </PHProvider>
  ) : (
    props.children
  );
};
