import 'antd/dist/antd.compact.css';
import 'tailwindcss/base.css';
import '@civalgo/lego/dist/lego.css';
import 'tailwindcss/components.css';
import '../../styles/globals.css';
import 'tailwindcss/utilities.css';

import { NuqsAdapter } from 'nuqs/adapters/next/pages'
import { Tooltip } from '@civalgo/lego';
import { AppProps } from 'next/app';
import dynamic from 'next/dynamic';
import { Inter } from 'next/font/google';
import React, { useCallback, useEffect, useState } from 'react';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';

import { Posthog } from 'Components/AppFrame/Posthog';

import { ciCommitHash } from '../config';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { unauthenticatedPaths } from 'Src/routes';
import router from 'next/router';
import { LanguageProvider } from 'Components/3-organisms/LanguageProvider/LanguageProvider';

const AppFrame = dynamic(
  () => import('Components/AppFrame').then((m) => m.AppFrame),
  { ssr: false },
);

const tanstackQueryClient = new QueryClient();

export const inter = Inter({ subsets: ['latin'], variable: '--font-inter' });
export default function App({
  Component,
  pageProps,
}: AppProps): React.ReactNode {
  const [isOnUnauthenticatedRoute, setIsOnUnauthenticatedRoute] =
    useState(false);
  const refCallback = useCallback(
    // We specifically want to log to the console, to debug caching issues
    () => {
      // eslint-disable-next-line no-console
      console.log('Version:', `portal@${ciCommitHash}`);
    },
    [],
  );

  useEffect(() => {
    if (router.isReady) {
      const currentPath = router.asPath;
      const isUnauthenticated = unauthenticatedPaths.some((path) =>
        currentPath.startsWith(path),
      );
      setIsOnUnauthenticatedRoute(isUnauthenticated);
    }
  }, []);

  return (
    <main className={inter.className} ref={refCallback}>
      <Tooltip.Provider>
        <DndProvider backend={HTML5Backend}>
          <QueryClientProvider client={tanstackQueryClient}>
            <Posthog>
              <NuqsAdapter>
              {isOnUnauthenticatedRoute ? (
                <div className='flex h-dvh flex-col  w-full'>
                <LanguageProvider>
                  <Component {...pageProps} />
                </LanguageProvider>
                </div>
              ) : (
                <AppFrame>
                  <Component {...pageProps} />
                </AppFrame>
              )}
              </NuqsAdapter>
            </Posthog>
          </QueryClientProvider>
        </DndProvider>
      </Tooltip.Provider>
    </main>
  );
}
