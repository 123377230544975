globalThis["__sentryRewritesTunnelPath__"] = undefined;
globalThis["SENTRY_RELEASE"] = {"id":"portal@35.57.0"};
globalThis["__sentryBasePath"] = undefined;
globalThis["__rewriteFramesAssetPrefixPath__"] = "";
// This file configures the initialization of Sentry on the browser.
// The config you add here will be used whenever a page is visited.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

import {
  browserProfilingIntegration,
  browserTracingIntegration,
  init,
  replayIntegration,
} from '@sentry/nextjs';

import { deploymentType, sentryDSN, sentryEnabled } from './src/config';

init({
  dsn: sentryDSN,
  integrations: [
    browserTracingIntegration(),
    replayIntegration(),
    browserProfilingIntegration(),
  ],
  enabled: sentryEnabled,
  environment: deploymentType,
  // To save cost, sample half the traffic in production, and 10% in staging
  tracesSampleRate: deploymentType === 'PRODUCTION' ? 0.5 : 0.1,
  replaysOnErrorSampleRate: 1.0,
  replaysSessionSampleRate: 0.1,
  // Since profilesSampleRate is relative to tracesSampleRate,
  // the final profiling rate can be computed as tracesSampleRate * profilesSampleRate
  // For example, a tracesSampleRate of 0.5 and profilesSampleRate of 0.5 would
  // results in 25% of transactions being profiled (0.5*0.5=0.25)
  profilesSampleRate: 1,
  ignoreErrors: [
    'ResizeObserver',
    /^Invariant: attempted to hard navigate to the same URL/,
  ],
});
